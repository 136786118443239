import { useContext } from 'react';

import { MembershipContext } from 'site-react/data/core/MembershipContext';

function useMembership() {
  const context = useContext(MembershipContext);
  if (!context) {
    throw new Error('useMembership must be used within a MembershipProvider');
  }
  return context;
}

export default useMembership;
