import cn from 'classnames';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import styles from './ErrorMessage.module.css';

const scrollElementIntoView = (element) => {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
};

const throttledScrollElementIntoView = throttle(scrollElementIntoView, 50, {
  trailing: false,
});

const ErrorMessage = ({
  children,
  hasHTML = false,
  isCentered = false,
  isMarginless = false,
  scrollIntoView = false,
}) => {
  const elementRef = useRef();

  useEffect(() => {
    if (scrollIntoView && elementRef.current) {
      throttledScrollElementIntoView(elementRef.current);
    }
  }, [scrollIntoView]);

  if (hasHTML) {
    // aria-live ensures that, when this element appears on the page, its contents
    // is read aloud when using a screen reader.
    return (
      <p
        aria-live="polite"
        className={cn(styles.ErrorMessage, {
          [styles['ErrorMessage--centered']]: isCentered,
          [styles['ErrorMessage--marginless']]: isMarginless,
        })}
        dangerouslySetInnerHTML={{ __html: children }}
        data-testid="ErrorMessage"
        ref={elementRef}
      />
    );
  } else {
    return (
      <p
        aria-live="polite"
        className={cn(styles.ErrorMessage, {
          [styles['ErrorMessage--centered']]: isCentered,
          [styles['ErrorMessage--marginless']]: isMarginless,
        })}
        data-testid="ErrorMessage"
        ref={elementRef}
      >
        {children}
      </p>
    );
  }
};

ErrorMessage.propTypes = {
  /**
   * An error message to show.
   *
   * NOTE: only phrasing content is allowed! This component's root is a <p>, and
   * should not contain layout elements like a `<div>`.
   */
  children: PropTypes.node.isRequired,

  /**
   * Allow the error message to contain markup
   */
  hasHTML: PropTypes.bool,

  /**
   * Should the text be centered?
   */
  isCentered: PropTypes.bool,

  /**
   * Should this render without any margins?
   *
   * By default, the p tag providesa bottom margin but this makes it hard to position
   * appropriately with other related elements. Remove margins when we need to have
   * more control over spacing.
   */
  isMarginless: PropTypes.bool,

  /**
   * Make this error visible when it mounts
   */
  scrollIntoView: PropTypes.bool,
};

export default ErrorMessage;
