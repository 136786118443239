import { keyframes } from '@emotion/css';

/**
 * DEPRECATION NOTICE
 *
 * We are moving our styling tooling to CSS modules. This file will be removed
 * in the future.
 *
 * animation.css is the source of truth for all animation style variables.
 */

/**
 * A "pop" animation. Used to emphasize elements on appearance. Fades in, while
 * briefly over-scaling in size, before springing backward to a normal size.
 */
const pop = keyframes`
from {
  opacity: 0;
  transform: scale(0.7);
}

40% {
  transform: scale(1.05);
}

to {
  opacity: 1;
  transform: scale(1.0);
}
`;

/* eslint-disable sort-keys */
export default {
  pop,
  timings: {
    short: '0.2s',
    medium: '0.35s',
    long: '0.5s',
  },
};
