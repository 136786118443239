import PropTypes from 'prop-types';

export default PropTypes.exact({
  accessHours: PropTypes.string,
  arrivalInstructions: PropTypes.string,
  howToFindUs: PropTypes.string,
  overview: PropTypes.string,
  overviewRemaining: PropTypes.string,
  overviewSnippet: PropTypes.string,
});
