import cn from 'classnames';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';

import { CustomDropdown } from 'site-react/components/form';
import { ErrorMessage, MaterialIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './Select.module.css';
import Label from '../Label';

/**
 * An element that _looks_ like a <select>. Used to apply a Hubble style to a
 * real <select> element, which is invisibly laid on top.
 */
const DisplayComponent = ({
  displayValue = '',
  isIconShown = false,
  status = null,
}) => (
  <div
    className={cn(
      styles['Select-display'],
      isIconShown && styles['Select--icon'],
      status === 'error' && styles['Select--invalid'],
    )}
  >
    <span
      className={styles['Select-text']}
      data-testid="Select-displayComponentText"
    >
      {displayValue}
    </span>

    <MaterialIcon iconType="arrow_drop_down" />
  </div>
);

DisplayComponent.propTypes = {
  /**
   * The value to show in this fake <select> element
   */
  displayValue: PropTypes.string,

  /**
   * Is there an icon shown on this element?
   *
   * Will offset contents to give the icon enough space to appear.
   */
  isIconShown: PropTypes.bool,

  /**
   * Status. Used to show different input states.
   */
  status: PropTypes.oneOf(['error', 'success']),
};

/**
 * A <select> element, styled to look like a Hubble input element.
 */
const Select = forwardRef(
  (
    {
      children,
      errorText = null,
      iconType = null,
      labelText = '',
      onChange = () => {},
      setValue = () => {},
      status = null,
      testId = null,
      value = null,
      ...otherInputProps
    },
    ref,
  ) => {
    const [displayValue, setDisplayValue] = useState(null);

    const selectElement = useRef(null);

    // We need to have a ref for both selectElement, and the ref passed in from
    // forwardRef. useImperativeHandle gives us a way to keep both in sync, so
    // we can use the ref internally (via selectElement), and externally (by
    // a passed ref prop, usually used with React Hook Form).
    useImperativeHandle(ref, () => selectElement.current, []);

    const updateDisplayValue = () => {
      const selectedIndex = selectElement.current.selectedIndex;

      if (selectedIndex >= 0) {
        setDisplayValue(
          selectElement.current.options[selectedIndex].dataset.displayValue ||
            selectElement.current.options[selectedIndex].text,
        );
      }
      return null;
    };

    useEffect(() => {
      // Ensures that the displayValue is always up-to-date with the actual value.
      updateDisplayValue();
    }, [children, value]);

    const handleChange = (event) => {
      // When we're in uncontrolled mode, the earlier effect won't run and
      // update the displayValue. Do it manually here.
      updateDisplayValue();

      onChange(event);
      setValue(event.target.value);
    };

    const inputElement = (
      <>
        {iconType && (
          <MaterialIcon
            className={cn({
              [styles['Select--iconWithLabel']]: !!labelText,
              [styles['Select--iconWithoutLabel']]: !labelText,
            })}
            data-testid="icon"
            iconType={iconType}
          />
        )}
        <CustomDropdown
          data-testid={testId}
          displayComponent={
            <DisplayComponent
              displayValue={displayValue}
              isIconShown={Boolean(iconType)}
              status={status}
            />
          }
          onChange={handleChange}
          ref={selectElement}
          value={value}
          {...otherInputProps}
        >
          {children}
        </CustomDropdown>
      </>
    );

    return (
      <>
        {labelText && <Label labelText={labelText}>{inputElement}</Label>}
        {!labelText && inputElement}
        {status === 'error' && errorText !== null && (
          <>
            <VerticalSpacing size="sm" />
            <ErrorMessage isMarginless scrollIntoView>
              {errorText}
            </ErrorMessage>
          </>
        )}
      </>
    );
  },
);

export default Select;

Select.propTypes = {
  /**
   * Options to show inside this Select. Should be <option> or <optgroup>
   * elements.
   *
   * If you want to show a different value inside the dropdown vs. what's shown
   * when the dropdown is closed, you can attach a data-display-value attribute
   *
   * For example, if you have a country code dropdown, and you want it to show
   * `+44` when it's closed, but inside the dropdown, you want to show
   * `United Kingdom +44`, you could use:
   * ```html
   * <option value="+44" data-display-value="+44">United Kingdom +44</option>
   * ```
   */
  children: PropTypes.node,

  /**
   * Message to show if the status is set to 'error'.
   */
  errorText: PropTypes.string,

  /**
   * This is a string which corresponds to a Material Icon. When set it will be
   * displayed alongside the element.
   */
  iconType: PropTypes.string,

  /**
   * What to display above the input field.
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Callback from <select> when the value changes
   */
  onChange: PropTypes.func,

  /**
   * Callback to pass the entered value back to whichever parent
   * component is using the Input. This component does not manage
   * it's own internal value. You must do that from the parent.
   *
   * If you are validating using React Hook Form, you don't need to set this!
   */
  setValue: PropTypes.func,

  /**
   * Used to dynamically display error messages.
   */
  status: PropTypes.oneOf(['error', 'success']),

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,
};
