const systemMonospace = `
  SFMono-Regular,
  Consolas,
  "Liberation Mono",
  Menlo,
  monospace
`;

const systemSansSerif = `
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Helvetica Neue",
  sans-serif
`;

/* eslint-disable sort-keys */
export default {
  family: {
    default: `
      "Simplon Norm",
      Barlow,
      ${systemSansSerif}
    `,
    monospaced: `
      "Simplon Mono",
      "Roboto Mono",
      ${systemMonospace}
    `,
  },
  weight: {
    medium: 500,
    light: 300,
  },
  size: {
    xxxl: '58px',
    xxl: '42px',
    xl: '28px',
    lg: '21px',
    md: '18px',
    sm: '16px',
    xs: '14px',
    xxs: '12px',
  },
};
