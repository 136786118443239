import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import ToggleButton from './components/ToggleButton';
import ToggleContext from './context/ToggleContext';
import useEffectAfterMount from './hooks/useEffectAfterMount';
import labelsPropTypes from './proptypes/labels.proptypes';

/**
 * Toggle style component designed to choose between 1 of 2 opposing values
 */
const Toggle = ({
  defaultValue,
  disabled = false,
  labels,
  onToggle = () => {},
}) => {
  const [on, setOn] = useState(() => !!labels.indexOf(defaultValue));

  const toggle = useCallback(
    (value) => {
      if (value) {
        setOn(!labels.indexOf(value));
      }
      setOn((previousOn) => !previousOn);
    },
    [labels],
  );

  useEffectAfterMount(() => {
    onToggle(on ? labels[1] : labels[0]);
  }, [on]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ on, toggle }), [on]);

  return (
    <ToggleContext.Provider value={value}>
      <div>
        <ToggleButton disabled={disabled} labels={labels} />
      </div>
    </ToggleContext.Provider>
  );
};

Toggle.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  labels: labelsPropTypes,
  onToggle: PropTypes.func,
};

export default Toggle;
