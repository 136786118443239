import Image from 'next/image';

import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

const ImgixImage = ({ imgixOptions = {}, src, ...props }) => {
  return (
    <Image
      loader={imgixLoader}
      src={getImgixPathWithParameters(src, imgixOptions)}
      {...props}
    />
  );
};

export default ImgixImage;
