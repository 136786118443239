import PropTypes from 'prop-types';

import { paperAirplane } from 'site-react/assets';
import { Link, LinkAsButton } from 'site-react/components/navigation';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Centered, VerticalSpacing } from 'site-react/components/utility';

import styles from './QuoteSuccessfullySubmitted.module.css';

export default function QuoteSuccessfullySubmitted({
  closeModal = () => {},
  ctaCopy,
  phoneNumber,
  viewingRequestUrl,
}) {
  return (
    <>
      <Centered>
        <img alt="" src={paperAirplane} />

        <VerticalSpacing size="lg" />

        <Heading isCentered level="2" type="title1">
          Request sent!
          <br />
          We’ll aim to get your quotes ready within the hour
        </Heading>

        <VerticalSpacing size="lg" />

        <Paragraph isCentered isMarginless>
          <b>In the meantime, why not book a no-pressure viewing.</b>
        </Paragraph>
        <Paragraph isCentered>
          Nothing beats going to see an office in person to get a real feel for
          the space. Operators are always happy to show you around the office
          and building.
        </Paragraph>
      </Centered>

      <VerticalSpacing size="xl" />

      <div className={styles['QuoteSuccessfullySubmitted-actions']}>
        <LinkAsButton
          href={viewingRequestUrl}
          name="Book a viewing online"
          onClick={closeModal}
        >
          {ctaCopy}
        </LinkAsButton>
        {phoneNumber && (
          <Centered>
            <Paragraph isMarginless>
              <b>Or let us schedule one for you</b>
            </Paragraph>
            <Link href={`tel:${phoneNumber.href}`}>
              Call {phoneNumber.display}
            </Link>
          </Centered>
        )}
      </div>
    </>
  );
}

QuoteSuccessfullySubmitted.propTypes = {
  closeModal: PropTypes.func,

  ctaCopy: PropTypes.string.isRequired,

  phoneNumber: PropTypes.shape({
    display: PropTypes.string,
    href: PropTypes.string,
  }),

  viewingRequestUrl: PropTypes.oneOfType([
    PropTypes.string,
    // https://nextjs.org/docs/api-reference/next/link#with-url-object
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,
};
