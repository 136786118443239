import PropTypes from 'prop-types';

import { Grid } from 'site-react/components/page';
import { Badges } from 'site-react/components/typography';

import HqListingPriceGuaranteeUspCard from '../HqListingPriceGuaranteeUspCard';
import HqListingTypeDescriptionModalContent from '../HqListingTypeDescriptionModalContent';
import HqListingUspCard from '../HqListingUspCard';

const HqListingUspSection = ({ buildingType }) => {
  return (
    <Grid gridGapY="sm">
      <Grid.Item colSpan="6">
        <HqListingUspCard
          description={
            <>
              {buildingType.desc} <u>Learn more.</u>
            </>
          }
          modalContent={
            <HqListingTypeDescriptionModalContent
              description={buildingType.longDescription}
              title={buildingType.label}
            />
          }
          title={
            <Badges.Badge
              color={buildingType.label === 'Offer' ? 'brandPrimary' : 'white'}
              isBorderVariant
              label={buildingType.label}
            />
          }
        />
      </Grid.Item>
      <Grid.Item colSpan="6">
        <HqListingPriceGuaranteeUspCard />
      </Grid.Item>
    </Grid>
  );
};

HqListingUspSection.propTypes = {
  buildingType: PropTypes.shape({
    label: PropTypes.string,
    longDescription: PropTypes.string,
  }),
};

export default HqListingUspSection;
