import { useEffect, useState } from 'react';

/**
 * @typedef MediaQueryOutputs
 * @param {boolean} matches - does the document match the media query?
 */

/**
 * Handle with care!
 *
 * If you need to change something in the browser based on @media query, you
 * probably don’t need this hook. Use CSS media queries instead.
 *
 * Sometimes UI requirements are not achievable with CSS media queries alone. In
 * these rare cases, use this hook to make media query matches available
 * to React JavaScript code.
 *
 * @param {string} mediaQuery - the media query to parse
 * @returns {MediaQueryOutputs}
 */
const useMediaQuery = (mediaQuery) => {
  const [matches, setMatches] = useState(undefined);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const queryList = window.matchMedia(mediaQuery);

    if (queryList.matches !== matches) {
      setMatches(queryList.matches);
    }

    const listener = () => {
      setMatches(queryList.matches);
    };
    queryList.addListener(listener);

    return () => queryList.removeListener(listener);
  }, [matches, mediaQuery]);

  return matches;
};

export default useMediaQuery;
