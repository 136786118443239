import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from '../UICard.module.css';

const UICardLink = React.forwardRef(
  (
    {
      analyticsMetadata = null,
      backgroundColor = 'white',
      boxShadow = null,
      children,
      height = 'shrink-wrap',
      href,
      linkType = 'a',
      name,
      onClickCallback = () => {},
      stripEdgeColor = 'brandprimary',
      style = null,
      variant = 'default',
      // NOTE: defaults to "fill", but if "default" is used then we don't apply any width-based styles.
      width = 'fill',
      ...otherLinkProps
    },
    ref,
  ) => {
    if (linkType === 'OutboundLink' && typeof href !== 'string') {
      throw new Error(
        "Implementing href as a url object only works for internal links when `linkType` is 'a'",
      );
    }

    if (otherLinkProps.hasOwnProperty('className')) {
      throw new Error(
        'You should not override or extend the built in styling of this component. If you need something more bespoke you should implement it as a bespoke component.',
      );
    }

    const onClickWithAnalytics = () => {
      onClickCallback();
      analytics.track(
        'Link clicked',
        {
          label: name,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
    };

    const className = cn(styles['UICard'], {
      [styles['UICard--stripEdge']]: variant === 'strip-edge',
      [styles['UICard--fullHeight']]: height === 'fill',
      [styles['UICard--fullWidth']]: width === 'fill',
      [styles['UICard--shrinkWrapHeight']]: height === 'shrink-wrap',
      [styles['UICard--shrinkWrapWidth']]: width === 'shrink-wrap',
      [styles['UICard--boxShadowHoverFocus']]:
        !!boxShadow && boxShadow === 'focus',
      [styles['UICard--boxShadowHoverResting']]:
        !!boxShadow && boxShadow === 'resting',
      [styles['UICard--boxShadowHoverSubtle']]:
        !!boxShadow && boxShadow === 'subtle',
      [styles['UICard--boxShadowHoverRaised']]:
        !!boxShadow && boxShadow === 'raised',
      [styles['UICard--padding']]: 'padding' in (style || {}),
      [styles['UICard--border']]: !!style?.border,
      [styles['UICard--borderRadius']]: !!style?.borderRadius,
    });

    if (linkType === 'OutboundLink') {
      return (
        <OutboundLink
          className={className}
          href={href}
          onClick={onClickWithAnalytics}
          ref={ref}
          style={{
            '--UICard--backgroundColor': `var(--color-${backgroundColor})`,
            '--UICard--border': style?.border ? style.border : 'none',
            '--UICard--borderRadius': style?.borderRadius
              ? style.borderRadius
              : 'none',
            '--UICard--boxShadow': `var(--shadow-${boxShadow})`,
            '--UICard--padding':
              'padding' in (style || {}) ? style.padding : 'none',
            '--UICard--stripEdgeColor': `var(--color-${stripEdgeColor})`,
          }}
          {...otherLinkProps}
        >
          {children}
        </OutboundLink>
      );
    } else {
      return (
        <NextLink
          className={className}
          href={href}
          onClick={onClickWithAnalytics}
          ref={ref}
          style={{
            '--UICard--backgroundColor': `var(--color-${backgroundColor})`,
            '--UICard--border': style?.border ? style.border : 'none',
            '--UICard--borderRadius': style?.borderRadius
              ? style.borderRadius
              : 'none',
            '--UICard--boxShadow': `var(--shadow-${boxShadow})`,
            '--UICard--padding':
              'padding' in (style || {}) ? style.padding : 'none',
            '--UICard--stripEdgeColor': `var(--color-${stripEdgeColor})`,
          }}
          {...otherLinkProps}
        >
          {children}
        </NextLink>
      );
    }
  },
);

UICardLink.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /** Which of our colors we would like to use as a background
   * "backgroundColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  backgroundColor: PropTypes.string,

  /** Which of our shadows we would like to use
   * "boxShadow" should be a `--shadow-{substring}` substring of the shadow name in theme/shadow.css
   */
  boxShadow: PropTypes.string,

  /** Content to show in the UICardLink */
  children: PropTypes.node.isRequired,

  /** Do we want this UICardLink to fill the height of its container or shrink wrap to its contents? */
  height: PropTypes.oneOf(['fill', 'shrink-wrap']),

  /**
   * Where this link leads. Or, if you're using an app link, the route to link
   * to (ie. the path within Next's `pages` folder for the page you're linking
   * to)
   */
  href: PropTypes.oneOfType([
    PropTypes.string,
    // https://nextjs.org/docs/api-reference/next/link#with-url-object
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,

  /**
   * The type of the link.:
   * - a for internal links in this project. Gets benefits of performance enhancements
   *   from next/link.
   * - OutboundLink for a link that leads off-site. Requires different logic for
   *   analytics tracking.
   */
  linkType: PropTypes.oneOf(['OutboundLink', 'a']),

  /**
   * Label to be sent with analytics event
   */
  name: PropTypes.string.isRequired,

  /**
   * With the exception of `href`, any attribute native to the <a> tag
   * can be passed as a prop and directly spread as an attribute on the component.
   *
   * For instance, if you need to pass a datatest-id, do so like this:
   * ```
   * <UICardLink href="/" data-testid="my-link">Home</UICardLink>
   * ```
   */
  otherLinkProps: PropTypes.any,

  /** Only applicable if variant is "strip-edge"
   * "stripEdgeColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  stripEdgeColor: PropTypes.string,

  /** Optionally override some styling */
  style: PropTypes.shape({
    /** Any valid border CSS value will work */
    border: PropTypes.string,
    /** Any valid padding shorthand CSS value will work */
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  /** Which style card do we want to render? */
  variant: PropTypes.oneOf(['default', 'strip-edge']),

  /** Do we want this UICardLink to fill the width of its container, shrink wrap to its contents, or follow the default? */
  width: PropTypes.oneOf(['default', 'fill', 'shrink-wrap']),
};

export default UICardLink;
