import isValidHubbleUrl from 'site-react/helpers/isValidHubbleUrl';

const postLoginRedirect = (url = null, defaultUrl = '/office-space-london') => {
  if (url !== null && isValidHubbleUrl(url)) {
    window.location.assign(url);
  } else {
    window.location.assign(defaultUrl);
  }
};

export default postLoginRedirect;
