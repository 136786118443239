import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import theme from 'site-react/theme';

import styles from './Curve.module.css';

const Curve = ({
  bottomColor = 'white',
  height = 'md',
  heights = [],
  topColor = 'neutral-900',
  type = 'down',
}) => {
  const getBreakpointHeightVariables = useMemo(() => {
    const style = {};
    if (heights?.length > 0) {
      heights.forEach(({ breakpoint, height }) => {
        style[`--Curve-${breakpoint}-height`] = `${Curve.heights[height]}px`;
      });
    }
    return style;
  }, [heights]);

  const getBreakpointModifiers = useMemo(() => {
    let classNames = [];
    if (heights?.length > 0) {
      classNames = heights.map(({ breakpoint }) => {
        return styles[`Curve--${breakpoint}-height`];
      });
    }
    return classNames;
  }, [heights]);

  return (
    <div
      className={cn(styles['Curve'], ...getBreakpointModifiers, {
        [styles['Curve--transform']]: type === 'wave-inverted',
        [styles['Curve--svgTransform']]: type === 'wave-mirrored',
      })}
      style={{
        '--Curve-backgroundColor': `${type === 'down' || type === 'wave-inverted' ? `var(--color-${bottomColor})` : `var(--color-${topColor})`}`,
        '--Curve-height': `${Curve.heights[height]}px`,
        '--Curve-svg--type-fill': `${type === 'wave-inverted' ? `var(--color-${topColor})` : `var(--color-${bottomColor})`}`,
        ...getBreakpointHeightVariables,
      }}
    >
      {type.startsWith('wave') ? (
        <svg
          className={styles['Curve-svg']}
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 320 20.45"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 18S54.1-9.38 137.05 9.31 307 13 320 0v20.45H.08L0 18z" />
        </svg>
      ) : (
        <span
          className={styles['Curve-ellipse']}
          style={{
            '--Curve-ellipse-backgroundColor': `${
              type === 'down'
                ? `var(--color-${topColor})`
                : `var(--color-${bottomColor})`
            }`,
            '--Curve-ellipse-clipPath': `${
              type === 'down'
                ? 'ellipse(69.5% 200% at 40% -100%)'
                : 'ellipse(69.5% 200% at 60% 200%)'
            }`,
          }}
        />
      )}
    </div>
  );
};

Curve.colors = [
  'brandsecondary',
  'brandsecondary-light',
  'neutral-900',
  'transparent',
  'white',
  'gradient-blue',
  'gradient-pink',
];

Curve.heights = {
  lg: theme.spacing.lg * 2.5,
  md: theme.spacing.lg * 2,
  sm: theme.spacing.lg,
};

Curve.types = [
  /**
   * "down" uses the bottom colour as the background colour so
   * it's generally used at the top of a section where the lower colour
   * matches the section's background
   */
  'down',
  /**
   * "up" uses the top colour as the background colour so
   * it's generally used at the bottom of a section where the upper colour
   * matches the section's background
   */
  'up',
  /**
   * "wave" uses the top colour as the background colour so
   * it's generally used at the bottom of a section where the upper colour
   * matches the section's background
   */
  'wave',
  /**
   * "wave-inverted" uses the bottom colour as the background colour so
   * it's generally used at the top of a section where the lower colour
   * matches the section's background
   */
  'wave-inverted',
  /*
   * "wave-mirrored" flips the wave shape upside down along the x-axis
   */
  'wave-mirrored',
];

Curve.propTypes = {
  /**
   *  Color of the bottom half of the Curve divider
   */
  bottomColor: PropTypes.oneOf(Curve.colors),

  /**
   *  Height of the Curve divider
   */
  height: PropTypes.oneOf(Object.keys(Curve.heights)),
  /**
   * Height of the curve at a certain breakpoint
   */
  heights: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.oneOf(Object.keys(theme.breakpoints)),
      height: PropTypes.oneOf(Object.keys(Curve.heights)),
    }),
  ),
  /**
   * Color of the top half of the Curve divider
   */
  topColor: PropTypes.oneOf(Curve.colors),

  /**
   *
   * Type of Curve
   */
  type: PropTypes.oneOf(Curve.types),
};

export default Curve;
