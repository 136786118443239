import PropTypes from 'prop-types';

const HoppyContainer = ({ building, children, pricePlan = null }) => {
  const getTitleCopy = () => {
    let titleCopy = `~${pricePlan.capacity} people`;
    if (pricePlan?.sqft > 0) {
      titleCopy += `, ${pricePlan.sqft} sqft`;
    }

    return titleCopy;
  };

  const output = {
    hoppy: {
      image: building.images[0]
        ? `${building.images[0].img}?w=200&h=130&fit=crop&auto=compress`
        : null,
      name: building.name,
      officeInfo:
        pricePlan === null
          ? ''
          : `Private Office - £${pricePlan.price.toLocaleString()} per month (excl VAT) | ${getTitleCopy()}`,
    },
  };
  return children(output);
};

HoppyContainer.propTypes = {
  /**
   * Building object.
   */
  building: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        img: PropTypes.string,
      }),
    ),
    name: PropTypes.string.isRequired,
  }).isRequired,

  /**
   * A rendering function.
   */
  children: PropTypes.func.isRequired,

  /**
   * The currently selected price plan. Normally comes from context.
   */

  pricePlan: PropTypes.shape({
    capacity: PropTypes.number,
    id: PropTypes.number,
    price: PropTypes.number,
  }),
};

export default HoppyContainer;
