/**
 * Parse the coordinates of the corners from a google maps object
 * @param {Object} map - the google maps map object
 * @return {Array} - an array of coordinates 4 pairs of coordinates
 */
const getCoordinates = (map) => {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  let n = ne.lat();
  let e = ne.lng();
  let s = sw.lat();
  let w = sw.lng();

  // This is the format we need for the elastic search geojson query.
  // the 4 corners, and then the first corner again.
  return [w, n, e, n, e, s, w, s, w, n];
};

export default getCoordinates;
