import analytics from 'site-react/helpers/Analytics';

/**
 * Add listeners to the marker element to define actions on hover, click, etc.
 * @param {google.maps} GoogleMap - the google maps api
 * @param {google.maps.Map} map - the map object
 * @param {google.maps.Marker} marker - the marker object (with a result attached)
 * @param {Function} setActiveId - callback for setting the active id
 */
const addMarkerListeners = ({
  GoogleMap,
  iconSet,
  isActive,
  map,
  marker,
  setActiveId,
}) => {
  const { result } = marker;
  const { activeIcon, defaultIcon, hoverIcon } = iconSet;

  // When a marker is active, we disable is clickable to prevent
  // the mouse events from triggering on moving away from the selected marker.
  if (isActive) {
    marker.addListener('click', () => {
      setActiveId();
    });
    return;
  }

  marker.addListener(`mouseover`, () => {
    marker.setIcon(hoverIcon);
  });

  marker.addListener('mouseout', () => {
    marker.setIcon(defaultIcon);
  });

  const [lng, lat] = result.buildingLocation.coordinates;
  marker.addListener('click', () => {
    marker.setIcon(activeIcon);
    setActiveId(result.buildingId);
    map.panTo(new GoogleMap.LatLng(lat, lng));
    analytics.track(
      'Map Marker Clicked',
      { listing_id: result.buildingId },
      { sendPageProperties: true },
    );
  });
};

export default addMarkerListeners;
