import { useEffect, useState } from 'react';

const useValidateMinMax = ({ setValid, valueMax, valueMin }) => {
  const [maxError, setMaxError] = useState('');
  const [minError, setMinError] = useState('');

  useEffect(() => {
    if (valueMax < 0) {
      setMaxError('Please provide a valid number');
      setValid(false);
    } else if (valueMin < 0) {
      setMinError('Please provide a valid number');
      setValid(false);
    } else if (valueMax && valueMin && valueMax < valueMin) {
      setMaxError('Value must be more than min');
      setValid(false);
    } else {
      setMaxError('');
      setMinError('');

      setValid(true);
    }
  }, [setMaxError, setMinError, setValid, valueMax, valueMin]);

  return {
    maxError,
    minError,
  };
};

export default useValidateMinMax;
