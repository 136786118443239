import cn from 'classnames';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { Meta } from 'site-react/components/seo';
import CookieBanner from 'site-react/features/CookieBanner';
import Footer from 'site-react/features/Footer';
import {
  RecommendationListBuildModeBanner,
  FreeTrialBanner,
  GhostLoginBanner,
} from 'site-react/features/PageBanner';
import SiteNavigation from 'site-react/features/SiteNavigation';
import removeHTML from 'site-react/helpers/removeHTML';
import useAdvisor from 'site-react/hooks/useAdvisor';
import useBrowserProcess from 'site-react/hooks/useBrowserProcess';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';
import useUser from 'site-react/hooks/useUser';

import styles from './PageLayout.module.css';
import defaultMeta from '../../content/meta/default';

const PageLayout = ({
  activeWorkspace = null,
  children = null,
  isCookieBannerHidden = false,
  isFooterHidden = false,
  isNavigationHidden = false,
  pageBanner = null,
  meta = {
    title: defaultMeta.title,
    titleSuffix: defaultMeta.titleSuffix,
  },
  navbarIsAnimated = false,
}) => {
  const { isBrowserProcess } = useBrowserProcess();
  const { hasSetPreferences } = useCookiePreferences();
  const { logout, parentUser, user } = useUser();
  const { exitBuildMode, recommendationList } = useAdvisor();

  const title = `${removeHTML(meta.title) || defaultMeta.title} ${
    removeHTML(meta.titleSuffix) || defaultMeta.titleSuffix
  }`;

  const pageBannerOptions = useMemo(() => {
    return {
      'free-trial': {
        Component: FreeTrialBanner,
        isSticky: false,
        props: {},
      },
      'ghost-login': {
        Component: GhostLoginBanner,
        isSticky: false,
        props: { onLogout: logout, user },
      },
      'recommendations-list-build-mode': {
        Component: RecommendationListBuildModeBanner,
        isSticky: true,
        props: {
          onExit: exitBuildMode,
          recommendationList: recommendationList,
        },
      },
    };
  }, [exitBuildMode, logout, recommendationList, user]);

  const PageBanner = useMemo(() => {
    if (recommendationList) {
      return pageBannerOptions['recommendations-list-build-mode'];
    } else if (parentUser) {
      return pageBannerOptions['ghost-login'];
    } else {
      return pageBannerOptions[pageBanner];
    }
  }, [pageBanner, pageBannerOptions, parentUser, recommendationList]);

  const HeaderWrapper =
    PageBanner && PageBanner.isSticky ? 'div' : 'React.Fragment';

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--PageBanner-stickyHeight',
      PageBanner && PageBanner.isSticky ? '58px' : '0px',
    );
  }, [PageBanner]);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Meta {...meta} />
      {isNavigationHidden ? (
        PageBanner && (
          <HeaderWrapper
            className={cn({
              [styles['PageLayout-header']]: PageBanner && PageBanner.isSticky,
            })}
          >
            <PageBanner.Component {...PageBanner.props} />
          </HeaderWrapper>
        )
      ) : (
        <HeaderWrapper
          className={cn({
            [styles['PageLayout-header']]: PageBanner && PageBanner.isSticky,
          })}
        >
          {PageBanner ? <PageBanner.Component {...PageBanner.props} /> : null}
          <SiteNavigation
            activeWorkspace={activeWorkspace}
            darkThemeAnimated={navbarIsAnimated}
          />
        </HeaderWrapper>
      )}
      {!hasSetPreferences && isBrowserProcess && !isCookieBannerHidden && (
        <CookieBanner />
      )}

      {children}

      {isFooterHidden ? null : <Footer />}
    </>
  );
};

PageLayout.propTypes = {
  /**
   * Which workspace nav item should have active styling applied, if any
   */
  activeWorkspace: PropTypes.oneOf([
    'hq-private-offices',
    'part-time-offices',
    'pass-coworking',
    'pass-meeting-room',
    'pass-private-office',
    null,
  ]),
  isCookieBannerHidden: PropTypes.bool,
  isFooterHidden: PropTypes.bool,
  isNavigationHidden: PropTypes.bool,
  meta: PropTypes.shape({
    title: PropTypes.string,
    titleSuffix: PropTypes.string,
  }),
  navbarIsAnimated: PropTypes.bool,
  pageBanner: PropTypes.oneOf(['free-trial']),
};

export default PageLayout;
