import Cookies from 'js-cookie';

function pollCookieValue(cookieName, callback, interval = 1000) {
  let previousValue = Cookies.get(cookieName);

  function checkCookie() {
    const currentValue = Cookies.get(cookieName);
    if (currentValue !== previousValue) {
      previousValue = currentValue;
      callback(currentValue);
    }
  }

  const intervalId = setInterval(checkCookie, interval);

  return () => clearInterval(intervalId);
}

export default pollCookieValue;
