import PropTypes from 'prop-types';
import React from 'react';

import Badge from './Badge';
import styles from './Badges.module.css';

const Badges = ({ badges, isSmallVariant = false }) => (
  <div className={styles.Badges}>
    {badges.map((badge) => (
      <Badge
        color={badge.color}
        desc={badge.desc}
        isBorderVariant={badge.isBorderVariant}
        isHubbleOffer={badge.isHubbleOffer}
        isInverted={badge.isInverted}
        isSmall={isSmallVariant}
        key={badge.label}
        label={badge.label}
      />
    ))}
  </div>
);

Badges.Badge = Badge;

Badges.propTypes = {
  /**
   * The array of label objects.
   */
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      isBorderVariant: PropTypes.bool,
      isHubbleOffer: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,

  /**
   * Are the labels small?
   */
  isSmallVariant: PropTypes.bool,
};

export default Badges;
