import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { VerticalSpacing } from 'site-react/components/utility';

import styles from './Accordion.module.css';

const Accordion = ({ question, answer, underlineQuestion }) => {
  return (
    <details
      className={cn(styles.Accordion, {
        [styles['Accordion--underlineQuestion']]: underlineQuestion,
      })}
    >
      <summary className={styles['Accordion-summary']}>{question}</summary>
      <VerticalSpacing size="md" />
      {answer}
    </details>
  );
};

Accordion.propTypes = {
  answer: PropTypes.node.isRequired,
  question: PropTypes.node.isRequired,
  underline: PropTypes.bool,
};

export default Accordion;
