import { captureException } from '@sentry/nextjs';

/**
 * Log an error into Sentry.
 *
 * Sentry comes from a CDN, so we can't just import it. We need to check that it
 * exists on the page before attempting to use it, in case the script failed to
 * load for any reason.
 * @param {Error} error - the error to raise into Sentry
 */
const logError = (error) => {
  captureException(error);

  // Allow console here, because this is a central worst-case error handling
  // function. We want these errors to be discoverable in dev.
  // eslint-disable-next-line no-console
  console.error(error);
};

export default logError;
