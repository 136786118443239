import cn from 'classnames';
import { useContext, useState } from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import buttonStyles from 'site-react/components/styles/button.module.css';
import { TextWithIcon } from 'site-react/components/typography';
import { Spinner } from 'site-react/components/utility';
import { AlertContext } from 'site-react/data/core/AlertContext';
import logError from 'site-react/helpers/logError';
import useAdvisor from 'site-react/hooks/useAdvisor';

import styles from './OfficeCardRecommendationListButton.module.css';

const spaciousApi = new SpaciousApi();

export default function OfficeCardRecommendationListButton({
  isPartTime = false,
  pricePlanId,
}) {
  const { addError } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);

  const { recommendationList, refreshRecommendationList } = useAdvisor();

  const isPricePlanInRecommendationList = recommendationList.items.some(
    (item) =>
      item.pricePlanId === pricePlanId && item.isPartTime === isPartTime,
  );

  async function addToRecommendationList() {
    const payload = {
      isPartTime,
      pricePlanId,
    };

    try {
      await spaciousApi.createAdminRecommendationListItem(
        recommendationList.id,
        payload,
      );
    } catch (error) {
      logError(error);
      addError('Something went wrong adding to recommendation list');
    }
  }

  async function removeFromRecommendationList() {
    const recommendationListItem = recommendationList.items.find(
      (item) =>
        item.pricePlanId === pricePlanId && item.isPartTime === isPartTime,
    );

    try {
      await spaciousApi.deleteAdminRecommendationListItem(
        recommendationList.id,
        recommendationListItem.id,
      );
    } catch (error) {
      logError(error);
      addError('Something went wrong removing from recommendation list');
    }
  }

  async function handleClick() {
    setIsLoading(true);

    if (isPricePlanInRecommendationList) {
      await removeFromRecommendationList();
      await refreshRecommendationList();
    } else {
      await addToRecommendationList();
      await refreshRecommendationList();
    }

    setIsLoading(false);
  }

  const buttonContent = isPricePlanInRecommendationList ? (
    'Remove'
  ) : (
    <TextWithIcon iconName="add" iconPosition="left" text="Recs" />
  );

  return (
    <button
      className={cn(
        buttonStyles.Button,
        buttonStyles['Button--compact'],
        styles.OfficeCardRecommendationListButton,
        {
          [styles['OfficeCardRecommendationListButton--add']]:
            !isPricePlanInRecommendationList,
          [styles['OfficeCardRecommendationListButton--remove']]:
            isPricePlanInRecommendationList,
        },
      )}
      disabled={isLoading}
      onClick={handleClick}
      type="button"
    >
      {isLoading ? <Spinner size="small" /> : buttonContent}
    </button>
  );
}
