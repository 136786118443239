import AuthApi from 'site-react/api/AuthApi';
import PassApi from 'site-react/api/PassApi';
import config from 'site-react/config';
import analytics from 'site-react/helpers/Analytics';
import getAppUrl from 'site-react/helpers/getAppUrl';
import logError from 'site-react/helpers/logError';

function userPropertyPolyfill(user) {
  return {
    ...user,
    is_active: user.isActive,
    is_serviced_office_vr_ready: user.isServicedOfficeVrReady,
    last_login: user.lastLogin,
    name: user.fullName,
    phone_country_code: user.phoneCountryCode,
    phone_number: user.phoneNumber,
    regional_phone_number: user.regionalPhoneNumber,
    serviced_office_provider: user.servicedOfficeProvider,
  };
}

/**
 * Call Segment's `identify` function, to link the user's email to
 * the anonymous id for future analytics calls.
 *
 * If the user is in an organisation, also calls Segment's `group`
 * function.
 *
 * @param {Object} user - the user object coming from the auth api.
 */
const identifyUser = async (user) => {
  if (!user.id) {
    return;
  }
  const identification = {
    budget: user.budget || null,
    company: user.company,
    company_size: user.people || null,
    email: user.email,
    is_ghost_login: !!user.loggedInAs,
    is_hubble_employee: user.email.toLowerCase().endsWith('@hubblehq.com'),
    name: user.name,
    phone: user.phone_country_code + user.regional_phone_number || '',
    search_city: 'London',
  };
  analytics.identify(user.id, identification, {
    integrations: {
      Intercom: {
        // This is an HMAC, generated with a secret key on our server
        // and validated by Intercom. It prevents someone from impersonating
        // someone else in Intercom.
        user_hash: user.hash,
      },
    },
  });

  let accountPlan = null;

  try {
    const response = await new PassApi().getPlan();
    accountPlan = response.body;
  } catch (error) {
    logError(error);
  }

  if (user.organisation) {
    // Mark this user as being part of a "group" of other users. In other words:
    // tell our analytics providers about this user's organisation.
    analytics.group(user.organisation.id, {
      createdAt: user.organisation.createdAt,
      employees: user.organisation.size,
      id: user.organisation.id,
      isHighValue: user.organisation.isHighValue,
      name: user.organisation.name,
      paymentType: accountPlan?.paymentType,
    });
  }
};

class AuthService {
  constructor() {
    this.authApi = new AuthApi();
    this.cache = {};
  }

  /**
   * Checks the cache synchronusly to see if it holds a user object
   */
  cachedUser() {
    const { cache } = this;

    if (cache.user) return { ...cache.user };

    return null;
  }

  /**
   * Checks the api to see if a user is logged in
   */
  async isLoggedIn() {
    const user = await this.user();

    if (user !== null) {
      return true;
    }

    return false;
  }

  /**
   * Gets the full user object from the server
   * returns null if not logged in.
   */
  async user() {
    const { cache } = this;
    if (cache.user) return { ...cache.user };

    try {
      const response = await this.authApi.getUser({ cache: 'no-cache' });
      const user = response.body;
      // We are migrating to a new camelcase API
      const deserializedUser = {
        ...userPropertyPolyfill(user),
        loggedInAs: user.loggedInAs
          ? { ...userPropertyPolyfill(user.loggedInAs) }
          : null,
      };
      cache.user = deserializedUser;
      return { ...cache.user };
    } catch (error) {
      return null;
    }
  }

  /**
   * Force a refresh of the user data from the server.
   *
   * This will also ensure that local storage is up to date.
   */
  async refresh() {
    this.cache = {};

    const user = await this.user();
    if (user) {
      identifyUser(user);
    }
    return user;
  }

  /**
   * Sends the user through the login flow.
   *
   * @param {string} returnUrl the URL to return to upon a successful login
   */
  /* Method doesn't use `this`, but needs to be a class method to exist on a singleton */
  /* eslint-disable-next-line class-methods-use-this */
  requestLogin(returnUrl, content) {
    let signupUrl = new URL('/signup', getAppUrl(config));

    signupUrl.searchParams.set('next', returnUrl);
    if (content) {
      signupUrl.searchParams.set('content', content);
    }

    window.location.assign(signupUrl);
  }

  /**
   * Update the user and then refresh the user information
   * we have on the system so we know we are always up to
   * date.
   *
   * @param {Object} updatedUserData - Key value pairs to be updated
   * @memberof AuthService
   */
  async updateUser(updatedUserData) {
    await this.authApi.updateUser(updatedUserData);
    return this.refresh();
  }

  /**
   * Logs out a user, clears all cookies and local storage.
   */
  async logout() {
    // Local storage values that need removing on logout
    const localStorageToRemove = [
      'ls.logged_in',
      'ls.user',
      'ls.conciergeDismissed',
      'ls.requestedConcierge',
    ];
    const isLoggedIn = await this.isLoggedIn();

    // If there is no logged in state then just exit
    if (!isLoggedIn) return;

    this.cache = {};
    localStorageToRemove.map((key) => localStorage.removeItem(key));
    analytics.reset();

    await this.authApi.logout();
  }
}

// Export singleton to keep user state
export default new AuthService();
export { AuthService as AuthServiceConstructor };
