import { css } from '@emotion/css';
import NextLink from 'next/link';

import { Grid, Section } from 'site-react/components/page';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import theme from 'site-react/theme';

import FeaturedCard from './FeaturedCard';

/**
 * A lot of manual effort to make sure that:
 * - The content of the Mega Menu respects the gutter/grid system provided
 * by Section and Grid/Grid.Item
 * - The right hand side has a background color that can break out of the
 * gutter/grid system while appearing as a parent container for it's perceived
 * contents
 */
const featuredContentBackgroundStyle = css`
  position: relative;

  ::before {
    background: var(--color-brandsecondary-background);
    bottom: 0;
    content: '';
    display: block;
    left: -16px;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: ${theme.zIndex.nav + 1};

    /**
     * It's really hard to make this background color snap to the edges once the
     * Section component starts introducing dynamically sized gutters so this is a
     * workaround where we say "make the background wide enough that it fits from left to
     * right between this breakpoint and the next, but just hide how wide it is".
     */
    @media (min-width: 768px) {
      left: -128px;
      overflow: hidden;
      width: calc(100vw + 128px);
    }

    /**
     * At this point the nav is in the header and the Mega Menu if the full width
     * of the viewport. We need to make sure the background color is wide enough
     * to cover just the section of the Mega Menu that contains the "Featured" content.
     */
    @media (min-width: 992px) {
      bottom: -24px;
      left: -24px;
      top: -24px;
    }
  }
`;

/**
 * Some more manual effort to ensure that:
 * - The content with the pseudo background has some perceived padding to
 * be visually consistent
 * - Ensure the content appears over the pseudo background
 */
const featuredContentStyle = css`
  padding: var(--space-md) 0;
  position: inherit;
  z-index: ${theme.zIndex.nav + 2};

  @media (min-width: 992px) {
    padding: 0;
  }
`;

const SolutionsMenuContent = () => (
  <Section verticalPadding="lg">
    <Grid>
      <Grid.Item colSpan="2" rowSpan="1">
        <Heading level="4" type="title3">
          Office Space
        </Heading>
      </Grid.Item>
      <Grid.Item colSpan="3" rowStart="2">
        <UnstyledList>
          <li>
            <NextLink href="/part-time-offices">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Part-time offices</b>}
              />
              <VerticalSpacing size="sm" />
              <Paragraph contentType="content2">
                Your own office 1-3 days per week, up to 75% cheaper than a
                full-time office
              </Paragraph>
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/full-time-office-enquiry?source=navBar">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Full-time offices</b>}
              />
              <VerticalSpacing size="sm" />
              <Paragraph contentType="content2">
                Permanent offices for your team with flexible contracts
              </Paragraph>
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
        </UnstyledList>
      </Grid.Item>
      <Grid.Item colSpan="2" colStart="5" rowSpan="1">
        <Heading level="4" type="title3">
          On-Demand
        </Heading>
      </Grid.Item>
      <Grid.Item colSpan="2" colStart="5" rowStart="2">
        <UnstyledList>
          <li>
            <NextLink href="/meeting-rooms/">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Meeting rooms</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/private-day-offices">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Day offices</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/coworking/london">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Coworking passes</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="https://search.hubblehq.com/events/">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Event spaces</b>}
              />
              <VerticalSpacing size="sm" />
            </NextLink>
          </li>
        </UnstyledList>
      </Grid.Item>
      <Grid.Item colSpan="3" rowStart="2">
        <NextLink href="/pass">
          <TextWithIcon
            contentType="content1"
            iconName="chevron_right"
            iconSize="lg"
            text={<b>Monthly plans</b>}
          />
          <VerticalSpacing size="sm" />
          <Paragraph contentType="content2">
            Save up to 30% per booking with Hubble On-Demand monthly plans
          </Paragraph>
          <VerticalSpacing size="sm" />
        </NextLink>
      </Grid.Item>
      <Grid.Item colSpan="4" colStart="10" rowSpan="3" rowStart="1">
        <div className={featuredContentBackgroundStyle}>
          <div className={featuredContentStyle}>
            <Heading type="content3">
              <b>Featured</b>
            </Heading>
            <VerticalSpacing size="sm" />
            <UnstyledList>
              <li>
                <FeaturedCard
                  ctaContent="Get the guide"
                  description="The ultimate workspace strategy guide"
                  heading="Guide"
                  href="https://search.hubblehq.com/ultimate-guide-to-workspace-strategy/?utm_source=blog&utm_medium=referral"
                  imageAlt="Ultimate workspace strategy guide"
                  imageSrc="/site/navigation/solutions/workplace-strategy.png"
                  linkType="OutboundLink"
                />
                <VerticalSpacing size="lg" />
              </li>
              <li>
                <FeaturedCard
                  ctaContent="Read more"
                  description="The ultimate office cost calculator"
                  heading="Tools"
                  href="/blog/office-space-calculator"
                  imageAlt="Communal office space"
                  imageSrc="/site/navigation/solutions/calculator.jpg"
                />
              </li>
            </UnstyledList>
          </div>
        </div>
      </Grid.Item>
    </Grid>
  </Section>
);

export default SolutionsMenuContent;
