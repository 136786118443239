import React from 'react';

import { Modal } from 'site-react/components/page';
import { BottomAccessoryButton } from 'site-react/features/ImageCarousel';

import Tour360ContentPopup from './Tour360ContentPopup';

const Tour360 = ({ tourUrl }) => (
  <Modal
    centerContent
    deprecatedCloseAnalytics="360° Tour Popup Close"
    deprecatedOpenAnalytics="360° Tour Popup Open"
    id="360-tour-modal"
    isFullScreen
    modalName="360 Tour"
    renderTrigger={({ openModal }) => (
      <BottomAccessoryButton
        iconType="360"
        label="360° Tour"
        onClick={openModal}
        testId="TourButton"
      />
    )}
  >
    <Tour360ContentPopup tourUrl={tourUrl} />
  </Modal>
);

export default Tour360;
