import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './RuledHeading.module.css';

/**
 * A `Heading` with a horizontal rule, used to help visually separate information-dense pages, like listings and viewings.
 *
 * The use of `RuledHeading` should be limited; in most cases, `Heading` is preferred.
 */
const RuledHeading = ({ children = null, level }) => (
  <>
    <Heading level={level} type="title3">
      {children}
    </Heading>
    <VerticalSpacing size="md" />
    <div className={styles.RuledHeading} />
  </>
);

RuledHeading.propTypes = {
  /**
   * Content to show in the heading.
   */
  children: PropTypes.node,

  /**
   * The heading level. Level '1' gives an h1, '2' an h2, etc. etc.
   */
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
};

export default RuledHeading;
