import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './CloseButton.module.css';

const CloseButton = ({ onClickCallback = null, isFullScreen = false }) => (
  <button
    className={cn(
      styles.CloseButton,
      isFullScreen && styles['CloseButton--fullScreen'],
      !isFullScreen && styles['CloseButton--mobileFullScreen'],
    )}
    id="modal-close-btn"
    onClick={onClickCallback}
    type="button"
  >
    <MaterialIcon className={styles['CloseButton-icon']} iconType="close" />
  </button>
);

CloseButton.propTypes = {
  isFullScreen: PropTypes.bool,
  onClickCallback: PropTypes.func,
};

export default CloseButton;
