import HubbleBaseApi from '../HubbleBaseApi';

class PassApi extends HubbleBaseApi {
  /**
   * Creates a coworking pass booking.
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {string} buildingId Unique identifier for the building the booking is being made for.
   * @param {string} company Where the user works.
   * @param {string} email Contact email address for the booking user.
   * @param {string} name The name of the user booking the space.
   * @param {string} phoneNumber Contact phone number for the booking user.
   *
   */
  async createHotDeskBooking(payload) {
    return this.post('pass/coworking-bookings', payload);
  }

  /**
   * Creates a coworking pass booking in the pending state and returns a booking ID
   * to be passed to Stripe.
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {number} buildingId Unique identifier for the building the booking is being made for.
   * @param {array} attendees List of people included in the booking, internal or external
   * @param {bool} isPrivate Whether incognito was selected or not
   *
   */
  async createCoworkingBookingPending(payload) {
    return this.post('pass/coworking-bookings/pending', payload);
  }

  /**
   * Validates a coworking pass booking for logged out users
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {number} buildingId Unique identifier for the building the booking is being made for.
   * @param {array} attendees List of people included in the booking, internal or external
   * @param {bool} isPrivate Whether incognito was selected or not
   *
   */
  async validateCoworkingBooking(payload) {
    return this.post('pass/coworking-bookings/validate', payload);
  }

  /**
   * Creates a private office pass booking.
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {string} buildingId Unique identifier for the building the booking is being made for.
   * @param {number} headCount for how many people
   *
   */
  async createPrivateOfficeBooking(payload) {
    return this.post('pass/private-office-bookings', payload);
  }

  /**
   * Create a pending private office booking request for PAYG bookings
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {string} buildingId Unique identifier for the building the booking is being made for.
   * @param {number} headCount for how many people
   */
  async createPrivateOfficeBookingRequestPending(payload) {
    return this.post('pass/private-office-bookings/pending', payload);
  }

  /**
   * Validates a pending private office booking request for logged out users
   *
   * @param {string} bookingDate The date that the booking is being made for.
   * @param {string} buildingId Unique identifier for the building the booking is being made for.
   * @param {number} headCount for how many people
   */
  async validatePrivateOfficeBookingRequest(payload) {
    return this.post('pass/private-office-bookings/validate', payload);
  }

  /**
   * Create a meeting room booking request
   *
   * @param {object} payload The booking request payload
   * @param {string} payload.bookingDate The date that the booking request is being made for.
   * @param {string} payload.bookingStartTime The start time of the booking request.
   * @param {string} payload.bookingEndTime The end time of the booking request.
   * @param {string} [payload.requestedFacilities] The required facilities / requests for the meeting room.
   * @param {number} payload.buildingId The ID of the building.
   * @param {number} payload.productId The ID of the meeting room product.
   */
  async createMeetingRoomBookingRequest(payload) {
    return this.post('pass/meeting-room-bookings', payload);
  }

  /**
   * Get the availability of a meeting room booking request
   *
   * @param {object} payload The booking request payload
   * @param {string} payload.bookingDate The date that the booking request is being made for.
   * @param {string} payload.bookingStartTime The start time of the booking request.
   * @param {string} payload.bookingEndTime The end time of the booking request.
   * @param {string} [payload.requestedFacilities] The required facilities / requests for the meeting room.
   * @param {number} payload.buildingId The ID of the building.
   * @param {number} payload.productId The ID of the meeting room product.
   */
  async getMeetingRoomBookingAvailability(payload) {
    return this.get('pass/meeting-room-bookings/availability', payload);
  }

  /**
   * Create a pending meeting room booking request for PAYG bookings
   *
   * @param {object} payload The booking request payload
   * @param {string} payload.bookingDate The date that the booking request is being made for.
   * @param {string} payload.bookingStartTime The start time of the booking request.
   * @param {string} payload.bookingEndTime The end time of the booking request.
   * @param {string} [payload.requestedFacilities] The required facilities / requests for the meeting room.
   * @param {number} payload.buildingId The ID of the building.
   * @param {number} payload.productId The ID of the meeting room product.
   */
  async createMeetingRoomBookingRequestPending(payload) {
    return this.post('pass/meeting-room-bookings/pending', payload);
  }

  /**
   * Validates a pending meeting room booking request for logged out users
   *
   * @param {object} payload The booking request payload
   * @param {string} payload.bookingDate The date that the booking request is being made for.
   * @param {string} payload.bookingStartTime The start time of the booking request.
   * @param {string} payload.bookingEndTime The end time of the booking request.
   * @param {string} [payload.requestedFacilities] The required facilities / requests for the meeting room.
   * @param {number} payload.buildingId The ID of the building.
   * @param {number} payload.productId The ID of the meeting room product.
   */
  async validateMeetingRoomBookingRequest(payload) {
    return this.post('pass/meeting-room-bookings/validate', payload);
  }

  /**
   * Sets the status of an entire booking to cancelled
   *
   * @param {number} bookingId the ID of the booking from which the attendee is removed
   */
  async deleteHotDeskBooking(bookingId) {
    return this.delete(`pass/coworking-bookings/${bookingId}/`);
  }

  /**
   * Delete a meeting room booking
   * @param {number} bookingId: The ID of the booking that should be cancelled
   */
  async deleteMeetingRoomBooking(bookingId) {
    return this.delete(`pass/meeting-room-bookings/${bookingId}/`);
  }

  /**
   * Sets the status of an entire booking to cancelled
   *
   * @param {number} bookingId the ID of the booking from which the attendee is removed
   */
  async deletePrivateOfficeBooking(bookingId) {
    return this.delete(`pass/private-office-bookings/${bookingId}/`);
  }

  /**
   * Get all attendee entries for a user
   */
  async getHotDeskAttendees(payload) {
    return this.get('pass/coworking-booking-attendees', payload);
  }

  /**
   * Removes an attendee from a booking
   *
   * @param {number} bookingId the ID of the booking from which the attendee is removed
   * @param {number} id the ID of the attendee that is removed from the booking
   */
  async deleteHotDeskBookingAttendee(bookingId, id) {
    return this.delete(`pass/coworking-bookings/${bookingId}/attendees/${id}/`);
  }

  /**
   * Batch update private office attendees
   *
   * @param {number} bookingId the ID of the booking from which the attendee is removed
   */
  async batchUpdatePrivateOfficeAttendees(bookingId, payload) {
    return this.post(
      `pass/private-office-bookings/${bookingId}/attendees/batch/`,
      payload,
    );
  }

  /**
   * Batch update attendees for meeting room bookings
   *
   * @param {number} bookingId
   * @param payload An array of attendee email addresses
   */
  async batchUpdateMeetingRoomBookingAttendees(bookingId, payload) {
    return this.post(
      `pass/meeting-room-bookings/${bookingId}/attendees/batch/`,
      payload,
    );
  }

  /**
   * Get the stripe setup page for creating a BACS account to setup future payments.
   */
  async getBacsSetup(payload) {
    return this.get('pass/bacs-setup', payload);
  }

  /**
   * Get all coworking pass bookings
   *
   * @param {number} offset The offset from which this endpoint is returning the next 10 results
   */
  async getHotDeskBookings(payload) {
    return this.get('pass/coworking-bookings', payload);
  }

  /**
   * Get single coworking pass booking
   *
   * @param {number} bookingId  The ID of the coworking booking
   */
  async getCoworkingBooking(bookingId, opts) {
    return this.get(`pass/coworking-bookings/${bookingId}`, undefined, opts);
  }

  /**
   * Get all meeting room bookings
   * @param {number} payload.offset The offset from which this endpoint is returning the next 10 results
   */
  async getMeetingRoomBookings(payload) {
    return this.get('pass/meeting-room-bookings', payload);
  }

  /**
   * Get single meeting room booking
   * @param {number} bookingId: The ID of the meeting room booking
   */
  async getMeetingRoomBooking(bookingId, opts) {
    return this.get(`pass/meeting-room-bookings/${bookingId}`, undefined, opts);
  }

  /**
   * Get all private office pass bookings
   *
   * @param {number} offset The offset from which this endpoint is returning the next 10 results
   */
  async getPrivateOfficeBookings(payload) {
    return this.get('pass/private-office-bookings', payload);
  }

  /**
   * Get single private office pass booking
   *
   * @param {number} bookingId booking id
   */
  async getPrivateOfficeBooking(bookingId, opts) {
    return this.get(
      `pass/private-office-bookings/${bookingId}`,
      undefined,
      opts,
    );
  }

  /**
   * Get the user’s organisation’s bookings for a specifc building and date
   *
   * @param {string} building_id The building to query
   * @param {string} date The date on which to query, in format yyyy-mm-dd
   */
  async getBuildingAttendees(payload) {
    return this.get('pass/building-attendees', payload);
  }

  /**
   * Get the stripe checkout session
   */
  async getCheckout(payload, opts) {
    return this.get('pass/checkout', payload, opts);
  }

  /**
   * Get the stripe PAYG checkout session
   *
   * Only difference with getCheckout above is that this one
   * doesn't require the user's email to be verified to make a booking
   */
  async getPAYGCheckout(payload, opts) {
    return this.get('pass/checkout/payg', payload, opts);
  }

  /**
   * Get user's stripe customer portal link
   */
  async getCustomerPortal(payload, opts) {
    return this.get('pass/customer-portal', payload, opts);
  }

  /**
   * Get the user’s organisation’s daily summary feed
   *
   * @param {string} start_date Date to start feed from, in format yyyy-mm-dd
   * @param {string} end_date Date to continue the feed to in format yyyy-mm-dd
   */
  async getFeed(payload) {
    return this.get('pass/feed', payload);
  }

  /**
   * Get the account details of a user's organisation
   */
  async getFinanceAccount() {
    return this.get('pass/finance-account');
  }

  /**
   * Get information about the user's current plan
   */
  async getPlan() {
    return this.get('pass/plan');
  }

  /**
   * Get buildings available for pass booking
   */
  async getSearch(payload, opts) {
    return this.get('pass/search', payload, opts);
  }

  /**
   * Get meeting rooms available for pass booking
   */
  async getMeetingRoomSearch(payload, opts) {
    return this.get('meeting-rooms/search', payload, opts);
  }

  /**
   * Get an organisation's subscription details
   */
  async getSubscription() {
    return this.get('pass/subscription');
  }

  /**
   * Get an organisation's upcoming invoice details
   */
  async getUpcomingInvoice() {
    return this.get('pass/upcoming-invoice');
  }

  async authoriseSlackIntegration(payload) {
    return this.post('pass/slack/authorise', payload);
  }

  async getSlackIntegrationStatus() {
    return this.get('pass/slack/status');
  }

  async revokeSlackIntegration() {
    return this.post('pass/slack/revoke');
  }
}

export default PassApi;
