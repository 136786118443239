import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { MaterialIcon, Price } from 'site-react/components/typography';

import formatTerm from './formatTerm';
import styles from './Icon.module.css';

function Icon({ bool }) {
  return (
    <MaterialIcon
      className={cn(styles.Icon, {
        [styles['isFalse']]: !bool,
      })}
      iconType={bool ? 'check_circle' : 'cancel'}
    />
  );
}

class RowBuilder {
  constructor(items) {
    this.items = items;
    this.getDeskRow.bind(this);
  }

  getDeskRow() {
    return {
      data: this.items.map((item) => ({
        content: item.pricePlan.desks,
        id: `${item.id}-Desks`,
      })),
      header: { content: 'Desks', id: 'desks' },
    };
  }

  getOccupancyRow() {
    return {
      data: this.items.map((item) => ({
        content: item.isPartTime ? (
          <React.Fragment key={`pt-access-hours-${item.id}`}>
            <b>Part-time</b>
            <br />
            <span>1-3 days per week</span>
          </React.Fragment>
        ) : (
          <React.Fragment key={`ft-access-hours-${item.id}`}>
            <b>Full-time</b>
            <br />
            <span>24/7 access</span>
          </React.Fragment>
        ),
        id: `${item.id}-Occupancy type`,
      })),
      header: { content: 'Occupancy type', id: 'occupancy-type' },
    };
  }

  getFullTimePriceRow() {
    return {
      data: this.items.map((item) => {
        function getContent() {
          if (item.isPartTime) {
            return 'N/A';
          }
          return item.pricePlan.fullTimeMonthlyPrice ? (
            <b key={`pp-fto-price-${item.id}`}>
              <Price
                amount={parseInt(item.pricePlan.fullTimeMonthlyPrice, 10)}
              />
            </b>
          ) : (
            'N/A'
          );
        }

        return {
          content: getContent(),
          id: `${item.id}-Monthly full-time price`,
        };
      }),
      header: {
        content: 'Monthly full-time price',
        id: 'monthly-full-time-price',
      },
    };
  }

  getPartTimeMonthlyPriceRow() {
    return {
      header: { content: 'Monthly packages', id: 'monthly-packages' },
      subHeaders: [
        {
          data: this.items.map((item) => {
            function getContent() {
              if (!item.isPartTime) {
                return 'N/A';
              }

              return item.pricePlan.partTimeOffPeakPrice ? (
                <React.Fragment key={item.id}>
                  <span>1 off-peak day</span>{' '}
                  <b>
                    <Price
                      amount={parseInt(item.pricePlan.partTimeOffPeakPrice, 10)}
                    />
                  </b>
                  <br />1 peak day{' '}
                  <b>
                    <Price
                      amount={parseInt(item.pricePlan.partTimePeakPrice, 10)}
                    />
                  </b>
                </React.Fragment>
              ) : (
                'N/A'
              );
            }

            return {
              content: getContent(),
              id: `${item.id}-1 day per week`,
            };
          }),
          header: {
            content: (
              <b>
                1 day / <abbr title="week">wk</abbr>
              </b>
            ),
            id: '1-day-per-week',
          },
        },
        {
          data: this.items.map((item) => {
            const offPeakPrice = parseInt(item.pricePlan.partTimeOffPeakPrice);
            const peakPrice = parseInt(item.pricePlan.partTimePeakPrice);

            function getContent() {
              if (!item.isPartTime) {
                return 'N/A';
              }

              return offPeakPrice ? (
                <React.Fragment key={item.id}>
                  <span>2 off-peak days</span>{' '}
                  <b>
                    <Price amount={parseInt(offPeakPrice * 2, 10)} />
                  </b>
                  <br />1 off peak + 1 peak day{' '}
                  <b>
                    <Price amount={parseInt(offPeakPrice + peakPrice, 10)} />
                  </b>
                  <br />2 peak days{' '}
                  <b>
                    <Price amount={parseInt(peakPrice * 2, 10)} />
                  </b>
                </React.Fragment>
              ) : (
                'N/A'
              );
            }

            return {
              content: getContent(),
              id: `${item.id}-2 days per week`,
            };
          }),
          header: {
            content: (
              <b>
                2 days / <abbr title="week">wk</abbr>
              </b>
            ),
            id: '2-days-per-week',
          },
        },
        {
          data: this.items.map((item) => {
            const offPeakPrice = parseInt(item.pricePlan.partTimeOffPeakPrice);
            const peakPrice = parseInt(item.pricePlan.partTimePeakPrice);

            function getContent() {
              if (!item.isPartTime) {
                return 'N/A';
              }

              return offPeakPrice ? (
                <React.Fragment key={item.id}>
                  2 off-peak + 1 peak days{' '}
                  <b>
                    <Price
                      amount={parseInt(offPeakPrice * 2 + peakPrice, 10)}
                    />
                  </b>
                  <br />1 off peak + 2 peak days{' '}
                  <b>
                    <Price
                      amount={parseInt(offPeakPrice + peakPrice * 2, 10)}
                    />
                  </b>
                  <br />3 peak days{' '}
                  <b>
                    <Price amount={parseInt(peakPrice * 3, 10)} />
                  </b>
                </React.Fragment>
              ) : (
                'N/A'
              );
            }

            return {
              content: getContent(),
              id: `${item.id}-3 days per week`,
            };
          }),
          header: {
            content: (
              <b>
                3 days / <abbr title="week">wk</abbr>
              </b>
            ),
            id: '3-days-per-week',
          },
        },
      ],
    };
  }

  getFacilitiesUtilitiesRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('utilities')}
            key={item.id}
          />
        ),
        id: `${item.id}-Utility bills included`,
      })),
      header: {
        content: 'Utility bills included',
        id: 'utility-bills-included',
      },
    };
  }

  getFacilitiesFurnitureRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('furniture')}
            key={item.id}
          />
        ),
        id: `${item.id}-Furniture included`,
      })),
      header: { content: 'Furniture included', id: 'furniture-included' },
    };
  }

  getBuildingLocationRow() {
    return {
      data: this.items.map((item) => ({
        content: item.building.location,
        id: `${item.id}-Location`,
      })),
      header: { content: 'Location', id: 'location' },
    };
  }

  getBuildingTypeRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <React.Fragment key={item.id}>
            <b>{item.building.buildingType.label}</b>
            <br />
            {item.building.buildingType.desc}
          </React.Fragment>
        ),
        id: `${item.id}-Office type`,
      })),
      header: { content: 'Office type', id: 'office-type' },
    };
  }

  getSqftRow() {
    return {
      data: this.items.map((item) => ({
        content: item.pricePlan.sqft ?? '-',
        id: `${item.id}-Sq ft`,
      })),
      header: { content: 'Sq ft', id: 'sq-ft' },
    };
  }

  getSqftPerDeskRow() {
    return {
      data: this.items.map((item) => ({
        content: item.pricePlan.sqftPerDesk ? item.pricePlan.sqftPerDesk : '-',
        id: `${item.id}-Sq ft / desk`,
      })),
      header: { content: 'Sq ft / desk', id: 'sq-ft-per-desk' },
    };
  }

  getFloorRow() {
    return {
      data: this.items.map((item) => ({
        content: item.pricePlan.floor[0] ?? '-',
        id: `${item.id}-Floor`,
      })),
      header: { content: 'Floor', id: 'floor' },
    };
  }

  getTermRow() {
    return {
      data: this.items.map((item) => ({
        content: formatTerm(item.pricePlan),
        id: `${item.id}-Term`,
      })),
      header: { content: 'Term', id: 'term' },
    };
  }

  getAccessHoursRow() {
    return {
      data: this.items.map((item) => ({
        content: item.isPartTime
          ? (item.pricePlan.partTimeAccessHours ?? '-')
          : '-',
        id: `${item.id}-Access hrs`,
      })),
      header: { content: 'Access hrs', id: 'access-hours' },
    };
  }

  getPartTimeAdditionalCostsRow() {
    return {
      data: this.items.map((item) => ({
        content: item.pricePlan.partTimeAdditionalCosts
          ? item.pricePlan.partTimeAdditionalCosts
          : 'N/A',
        id: `${item.id}-Additional costs`,
      })),
      header: { content: 'Additional costs', id: 'additional-costs' },
    };
  }

  getCommunalMeetingRoomsRow() {
    return {
      data: this.items.map((item) => ({
        content: item.building.communalMeetingRooms ?? '-',
        id: `${item.id}-Communal meeting rooms`,
      })),
      header: {
        content: 'Communal meeting rooms',
        id: 'communal-meeting-rooms',
      },
    };
  }

  getPartTimeCanLeaveEquipmentRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon bool={item.pricePlan.partTimeCanLeaveEquipment} key={item.id} />
        ),
        id: `${item.id}-Leaving equipment`,
      })),
      header: { content: 'Leaving equipment', id: 'leaving-equipment' },
    };
  }

  getFacilitiesPetFriendlyRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('pets-allowed')}
            key={item.id}
          />
        ),
        id: `${item.id}-Pet friendly`,
      })),
      header: { content: 'Pet friendly', id: 'pet-friendly' },
    };
  }

  getFacilitiesReceptionRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('reception')}
            key={item.id}
          />
        ),
        id: `${item.id}-Reception`,
      })),
      header: { content: 'Reception', id: 'reception' },
    };
  }

  getFacilitiesBikeStorageRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('bike-storage')}
            key={item.id}
          />
        ),
        id: `${item.id}-Bike storage`,
      })),
      header: { content: 'Bike storage', id: 'bike-storage' },
    };
  }

  getFacilitiesShowersRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('showers')}
            key={item.id}
          />
        ),
        id: `${item.id}-Showers`,
      })),
      header: { content: 'Showers', id: 'showers' },
    };
  }

  getFacilitiesRoofTerraceRow() {
    return {
      data: this.items.map((item) => ({
        content: (
          <Icon
            bool={item.building.facilities.includes('roof-terrace')}
            key={item.id}
          />
        ),
        id: `${item.id}-Roof terrace`,
      })),
      header: { content: 'Roof terrace', id: 'roof-terrace' },
    };
  }

  getAvailableFromRow() {
    return {
      data: this.items.map((item) => {
        let content = 'Available now';

        // This is the same logic that we're using for the listing pages. There's a very small likelihood that
        // a non-available price plan will end up on these lists, so for now, we're sticking to this logic.
        if (item.pricePlan.availableFrom) {
          const nonFormattedPricePlanDateTime = DateTime.fromISO(
            item.pricePlan.availableFrom,
          );
          if (nonFormattedPricePlanDateTime > DateTime.now()) {
            content = nonFormattedPricePlanDateTime.toFormat('dd/MM/yyyy');
          }
        }

        return {
          content: content,
          id: `${item.id}-Available`,
        };
      }),
      header: { content: 'Available', id: 'available' },
    };
  }

  getNearestStationRow() {
    return {
      data: this.items.map((item) => ({
        content: item.building.nearestStation,
        id: `${item.id}-nearest-station`,
      })),
      header: { content: 'Nearest station', id: 'nearest-station' },
    };
  }
}

export default RowBuilder;
