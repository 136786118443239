import { DateTime } from 'luxon';

import getNextAvailableAppointment from './getNextAvailableAppointment';
import toLondonTime from './toLondonTime';

/**
 * Given a date (as an ISO string), returns an array of unavailable times.
 *
 * This is determined by:
 * 1. checking if the date we're looking at is in the future - if it is, all times
 *    are available, so we return an empty array
 * 2. if it is the current date, walks backward from now until open time and disables
 *    every half hour interval.
 *
 * N.B. for testing: depends on the current time to determine what future times are available.
 *
 * @param {string} date the date to check
 */
export default function getTimesForDay(
  date,
  {
    startTime = '8:30',
    endTime = '18:00',
    buildingOpenTime = startTime,
    buildingCloseTime = endTime,
  } = {},
) {
  const nowInLondon = toLondonTime(DateTime.utc());
  const nextAppointmentISO = getNextAvailableAppointment(
    nowInLondon,
    buildingOpenTime,
    buildingCloseTime,
  );

  // { setZone: true } ensures that the time values are in London time.
  // Normally, Luxon will try and convert to the user's native time.
  const nextAppointment = DateTime.fromISO(nextAppointmentISO, {
    setZone: true,
  });

  const [startHour, startMinute] = startTime.split(':');
  const [endHour, endMinute] = endTime.split(':');
  const [buildingOpenHour, buildingOpenMinute] = buildingOpenTime.split(':');
  const [buildingCloseHour, buildingCloseMinute] = buildingCloseTime.split(':');

  const startDateTime = toLondonTime(
    DateTime.fromISO(date).set({
      hour: startHour,
      minute: startMinute,
    }),
    true,
  );
  const endDateTime = toLondonTime(
    DateTime.fromISO(date).set({
      hour: endHour,
      minute: endMinute,
    }),
    true,
  );

  const buildingOpenDateTime = toLondonTime(
    DateTime.fromISO(date).set({
      hour: buildingOpenHour,
      minute: buildingOpenMinute,
    }),
    true,
  );
  const buildingCloseDateTime = toLondonTime(
    DateTime.fromISO(date).set({
      hour: buildingCloseHour,
      minute: buildingCloseMinute,
    }),
    true,
  );

  let someDateTime =
    startDateTime < buildingOpenDateTime ? startDateTime : buildingOpenDateTime;
  const times = [];

  while (someDateTime <= Math.max(endDateTime, buildingCloseDateTime)) {
    times.push({
      disabled:
        someDateTime < nextAppointment ||
        someDateTime < buildingOpenDateTime ||
        someDateTime > buildingCloseDateTime,
      time: someDateTime.toFormat('HH:mm'),
    });

    someDateTime = someDateTime.plus({ minute: 15 });
  }

  return times;
}
