import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './HorizontalRule.module.css';

export default function HorizontalRule({ isDashed = false }) {
  return (
    <hr
      className={cn(styles.HorizontalRule, {
        [styles['HorizontalRule--dashed']]: isDashed,
        [styles['HorizontalRule--solid']]: !isDashed,
      })}
    />
  );
}

HorizontalRule.propTypes = {
  /**
   * Is the rule a dashed line, rather than a solid line?
   */
  isDashed: PropTypes.bool,
};
