import { createContext } from 'react';

const Context = createContext({
  addToShortlist: () => {},
  animateNavIcon: false,
  isShortlistLoaded: false,
  onSignupOrLogin: () => {},
  removeFromShortlist: () => {},
  setShowSignupModal: () => {},
  shortlist: [],
  showSignupModal: false,
});

const { Consumer, Provider } = Context;

export { Consumer, Provider };

export default Context;
