import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './InputGroup.module.css';

/**
 * A component that combines multiple input elements so that they appear
 * attached.
 *
 * For example, an InputGroup containing a Select and a TextInput would mean
 * that the Select is joined to the TextInput at its right border.
 */
const InputGroup = ({ children }) => (
  <div className={cn('hbl-InputGroup', styles['InputGroup'])}>{children}</div>
);

InputGroup.propTypes = {
  /**
   * Child form elements to show in this InputGroup.
   */
  children: PropTypes.node,
};

export default InputGroup;
