import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';

const UTMContext = createContext({});

const validUtmParameters = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

function useUtm() {
  const [utmValues, setUtmValues] = useState({});
  const router = useRouter();

  useEffect(() => {
    const params = new URLSearchParams(router.query);
    let utmItems = [];

    for (const [key, value] of params.entries(router.query)) {
      if (validUtmParameters.includes(key)) {
        utmItems.push([key, value]);
      }
    }

    if (utmItems.length > 0) {
      setUtmValues((existing) => {
        return {
          ...existing,
          ...Object.fromEntries(utmItems),
        };
      });
    }
  }, [router.query]);

  return utmValues;
}

/**
 * Checks the current URL for UTM parameters, and stores them for future use.
 *
 * N.B. we're very careful not to use persistent storage here, such as cookies
 * or local storage! Under the GDPR and the ePrivacy directive, we can't persist
 * non-essential data to the user's machine without their express consent.
 *
 * This context will keep UTM parameters between page navigation, but if the
 * user refreshes their browser, or navigates between pages in a non-standard
 * way (such as opening a link in a new tab), the UTM information will be lost.
 */
export default function UTMContextProvider({ children }) {
  const utm = useUtm();

  return <UTMContext.Provider value={utm}>{children}</UTMContext.Provider>;
}
export { UTMContext };
