import throttle from 'lodash.throttle';
import router from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Context from './context';
import featureFlags from './featureFlags';

const throttledUpdateRemoteFlags = throttle(
  () => featureFlags.updateRemoteFlags(),
  10000, // 10 seconds. Realistically, we don't ever need to refresh flags more than that.
);

/**
 * Provides feature flags to the rest of the page. Handles change events, so
 * other components don't have to. Will always return the most up-to-date flags
 * object as its value.
 */
const FeatureFlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState(featureFlags.getFlags());

  useEffect(() => {
    const listener = () => {
      setFlags(featureFlags.getFlags());
    };

    featureFlags.addListener(listener);

    featureFlags.updateRemoteFlags();
    featureFlags.initLocalFlags();

    return () => featureFlags.removeListener(listener);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', throttledUpdateRemoteFlags);

    return () =>
      router.events.off('routeChangeStart', throttledUpdateRemoteFlags);
  }, []);

  return <Context.Provider value={flags}>{children}</Context.Provider>;
};

FeatureFlagsProvider.propTypes = {
  /**
   * Content to show
   */
  children: PropTypes.node,
};

export default FeatureFlagsProvider;
