import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, TextInput } from 'site-react/components/form';
import { Link } from 'site-react/components/navigation';
import { Grid } from 'site-react/components/page';
import { ErrorMessage, Paragraph } from 'site-react/components/typography';
import { PersonaContext } from 'site-react/data/core/PersonaContext';
import analytics from 'site-react/helpers/Analytics';
import getDataLayer from 'site-react/helpers/dataLayer';
import validEmailMatcher from 'site-react/helpers/formValidation/validEmailMatcher';
import useUser from 'site-react/hooks/useUser';

import attemptSignup from '../../helpers/attemptSignup';
import handleSignupError from '../../helpers/handleSignupError';
import postLoginRedirect from '../../helpers/postLoginRedirect';

const SignupForm = ({
  children,
  existingErrors = null,
  forcedEmail = null,
  initialOrganisation = null,
  isAbleToRedirect = false,
  isHostSignup = false,
  isOrganisationInvite = false,
  onSignup = () => {},
  referralSource = 'signup',
  returnUrl = null,
}) => {
  const { checkUser } = useUser();
  const [errorMessages, setErrorMessages] = useState(() => existingErrors);
  const { persistPersona } = useContext(PersonaContext);

  const validationSchema = Yup.object({
    ...(!isOrganisationInvite && {
      organisationName: Yup.string()
        .required('Please enter your organisation’s name.')
        .test(
          'isWhiteSpace',
          'Please enter a valid organisation name',
          (value) => !!value.trim(),
        ),
    }),
    ...(isHostSignup && {
      phoneNumber: Yup.string()
        .trim()
        .matches(
          /^\+[1-9]{1}[0-9]{3,14}$/,
          'Phone number not valid, please remember country code.',
        )
        .required('Please enter your phone number'),
    }),
    email: Yup.string()
      .required('Please enter your work email address.')
      .transform((str) => str.replace(/(\(|\))/, '')) // stop () being passed in to yup regex and erroring
      .matches(validEmailMatcher.regex, validEmailMatcher.message),
    firstName: Yup.string()
      .required('Please enter your first name.')
      .test(
        'isWhiteSpace',
        'Please enter a valid first name',
        (value) => !!value.trim(),
      ),
    lastName: Yup.string()
      .required('Please enter your last name.')
      .test(
        'isWhiteSpace',
        'Please enter a valid last name',
        (value) => !!value.trim(),
      ),
    password: Yup.string().required('Please enter a password.'),
  });

  const {
    formState: { errors, isSubmitting, touchedFields },
    handleSubmit,
    register,
    getValues,
  } = useForm({
    defaultValues: {
      email: forcedEmail || '',
      firstName: '',
      lastName: '',
      organisationName: initialOrganisation || '',
      password: '',
      phoneNumber: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const values = getValues();

  const isValidField = (errorValue, touchFieldValue, value) => {
    if (errorValue) {
      if (!touchFieldValue && value === '') {
        return 'error';
      } else if (touchFieldValue) {
        return 'error';
      }
    } else return null;
  };

  const onSubmitHandler = async (values) => {
    const {
      email,
      firstName,
      lastName,
      organisationName,
      password,
      phoneNumber,
    } = values;
    setErrorMessages(null);

    const newUser = {
      email,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password,
      referralSource,
      ...(!isOrganisationInvite && {
        organisation: {
          name: organisationName.trim(),
        },
      }),
      ...(isHostSignup && {
        regionalPhoneNumber: phoneNumber,
      }),
    };
    try {
      await attemptSignup(newUser);
      let analyticsMessage = 'Signed Up';

      if (
        typeof referralSource !== 'undefined' &&
        referralSource === 'shortlist'
      ) {
        analyticsMessage = 'Signed Up through Shortlist';
      }

      getDataLayer().push({
        event: 'sign_up',
      });

      analytics.track(analyticsMessage, {
        email,
        provider: 'Spacious',
      });

      await persistPersona();

      if (isAbleToRedirect) {
        await onSignup();
        postLoginRedirect(returnUrl);
      } else {
        await checkUser();
        await onSignup();
      }
    } catch (error) {
      const errorMessage = handleSignupError(error);

      setErrorMessages([errorMessage]);

      return null;
    }
  };

  return (
    <form
      method="post"
      name="Signup"
      noValidate
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Grid>
        <Grid.Item colSpan="12">{children}</Grid.Item>

        <Grid.Item colSpan="6">
          <TextInput
            errorText={errors.firstName?.message}
            isValidationManaged
            labelText="First Name"
            name="firstName"
            placeholder="Please enter"
            status={isValidField(
              errors.firstName,
              touchedFields.firstName,
              values.firstName,
            )}
            {...register('firstName')}
          />
        </Grid.Item>
        <Grid.Item colSpan="6">
          <TextInput
            errorText={errors.lastName?.message}
            isValidationManaged
            labelText="Last Name"
            name="lastName"
            placeholder="Please enter"
            status={isValidField(
              errors.lastName,
              touchedFields.lastName,
              values.lastName,
            )}
            {...register('lastName')}
          />
        </Grid.Item>
        {!isOrganisationInvite && (
          <Grid.Item colSpan="12">
            <TextInput
              errorText={errors.organisationName?.message}
              isValidationManaged
              labelText="Organisation Name"
              name="organisationName"
              placeholder="Please enter"
              status={isValidField(
                errors.organisationName,
                touchedFields.organisationName,
                values.organisationName,
              )}
              {...register('organisationName')}
            />
          </Grid.Item>
        )}
        <Grid.Item colSpan="12">
          <TextInput
            disabled={!!forcedEmail}
            errorText={errors.email?.message}
            isValidationManaged
            labelText="Work Email"
            name="email"
            placeholder="e.g. name@workemail.com"
            status={isValidField(
              errors.email,
              touchedFields.email,
              values.email,
            )}
            type="email"
            {...register('email')}
          />
        </Grid.Item>
        {isHostSignup && (
          <Grid.Item colSpan="12">
            <TextInput
              errorText={errors.phoneNumber?.message}
              isValidationManaged
              labelText="Phone Number"
              name="phoneNumber"
              placeholder="e.g. +447584930294"
              status={isValidField(
                errors.phoneNumber,
                touchedFields.phoneNumber,
                values.phoneNumber,
              )}
              type="tel"
              {...register('phoneNumber')}
            />
          </Grid.Item>
        )}
        <Grid.Item colSpan="12">
          <TextInput
            errorText={errors.password?.message}
            isValidationManaged
            labelText="Password"
            name="password"
            placeholder="Please enter"
            status={isValidField(
              errors.password,
              touchedFields.password,
              values.password,
            )}
            type="password"
            {...register('password')}
          />
        </Grid.Item>
        {errorMessages?.length > 0 ? (
          <Grid.Item colSpan="12">
            {errorMessages.map((error) => (
              <React.Fragment key={error}>
                <ErrorMessage isCentered>{error}</ErrorMessage>
              </React.Fragment>
            ))}
          </Grid.Item>
        ) : null}
        <Grid.Item colSpan="12">
          <Button
            data-testid="signup-button"
            disabled={isSubmitting}
            isBlock
            isLoading={isSubmitting}
            name="Create free account"
            type="submit"
          >
            Create free account
          </Button>
        </Grid.Item>
        <Grid.Item colSpan="12">
          <Paragraph isCentered type="content3">
            {/* Using <a>, and not <Link>, because these links open in a new tab */}
            By signing up, you agree to our{' '}
            <Link
              href="/legals"
              isAppLink={false}
              isBold
              styleType="secondary"
              target="_blank"
            >
              terms & conditions
            </Link>{' '}
            and{' '}
            <Link
              href="/privacy-policy"
              isAppLink={false}
              isBold
              styleType="secondary"
              target="_blank"
            >
              privacy policy
            </Link>
          </Paragraph>
        </Grid.Item>
      </Grid>
    </form>
  );
};

SignupForm.propTypes = {
  /**
   * Extra content displayed above the signup form to add context to the signup
   * process. Useful if using this component as a part of a specific user journey,
   * such as Pass.
   */
  children: PropTypes.node,

  /**
   * Non-validation errors, such as errors from a social auth service.
   */
  existingErrors: PropTypes.arrayOf(PropTypes.string),

  /**
   * Hard-set the value of the email field. Useful for invitation acceptance.
   */
  forcedEmail: PropTypes.string,

  /**
   * Is this login able to redirect upon completion?
   *
   * If a login form is embedded in a page with custom onLogin/onSignup handlers,
   * this should probably be false. Generally, it will only be true on a page
   * that is dedicated to logging in or signing up.
   */
  isAbleToRedirect: PropTypes.bool,

  /**
   * Is this signup for a host user?
   *
   * When a host signs up we ask for a phone number as extra information
   * so we can contact them with any concerns regarding their listing, this
   * is required and is not a field on normal signup.
   */
  isHostSignup: PropTypes.bool,

  /**
   * Is this signup a part of the Organisation Invite flow?
   *
   * If true, we shouldn't display an Organisation field or send that property in
   * the signup payload as this will be populated after accepting the
   * Organisation Invite after signup.
   */
  isOrganisationInvite: PropTypes.bool,

  /**
   * Callback when a signup completes
   */
  onSignup: PropTypes.func,

  /**
   * The intake source of this user (e.g. pass)
   */
  referralSource: PropTypes.string,

  /**
   * URL link to redirect user after they sign up. Only needed if using
   * Signup as a full page feature such as on /signup. Should not be set if using signup as
   * an in-page modal.
   */
  returnUrl: PropTypes.string,
};

export default SignupForm;
