import { useEffect, useState } from 'react';

import FacilitiesApi from 'site-react/api/FacilitiesApi';

const useFacilitiesList = ({ isAvailableOnHq, isAvailableOnPass } = {}) => {
  const [facilitiesList, setFacilitiesList] = useState(undefined);

  useEffect(() => {
    (async () => {
      const response = await new FacilitiesApi().getFacilities({
        isAvailableOnHq,
        isAvailableOnPass,
      });

      setFacilitiesList(response.body);
    })();
  }, [isAvailableOnHq, isAvailableOnPass, setFacilitiesList]);

  return facilitiesList;
};

export default useFacilitiesList;
