import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from './BackButton.module.css';

const BackButton = React.forwardRef(
  (
    {
      analyticsMetadata = {},
      contentType = 'label1',
      elementType = 'button',
      href = '',
      label = 'Go Back',
      onBackButtonClick = () => {},
      styleType = 'primary',
      ...props
    },
    ref,
  ) => {
    let attributes = {
      'aria-label': label,
      className: cn(
        styles.BackButton,
        styles[`BackButton--${contentType}`],
        styles[`BackButton--${styleType}`],
        {
          [styles['BackButton-link']]: elementType === 'a',
        },
      ),
      onClick: (...args) => {
        analytics.track('BackButton clicked', { label, ...analyticsMetadata });
        onBackButtonClick(...args);
      },
      ...props,
    };

    if (elementType === 'button') {
      attributes.type = 'button';
      return (
        <button {...attributes} data-testid="BackButton" ref={ref}>
          <MaterialIcon
            className={styles['BackButton-icon']}
            iconType="arrow_back"
          />
          {label}
        </button>
      );
    } else {
      attributes.href = href;
      return (
        <NextLink
          {...attributes}
          data-testid="BackButton"
          href={href}
          ref={ref}
        >
          <MaterialIcon
            className={styles['BackButton-icon']}
            iconType="arrow_back"
          />
          {label}
        </NextLink>
      );
    }
  },
);

BackButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  contentType: PropTypes.oneOf([
    'content1',
    'content2',
    'content3',
    'label1',
    'title1',
    'title2',
    'title3',
    'title4',
  ]),

  /**
   * The type of the BackButton: a for links, button for actions
   */
  elementType: PropTypes.oneOf(['a', 'button']),

  /**
   * href attribute. Only applies to <a> elementTypes.
   */
  href: PropTypes.string,

  /**
   * Main text of the button.
   */
  label: PropTypes.string,

  /**
   * Callback when the button is clicked
   */
  onBackButtonClick: PropTypes.func,

  styleType: PropTypes.oneOf(['primary', 'secondary']),
};

export default BackButton;
