/**
 * ░░░░░▄▀▀▀▄░░░░░░░░░░░░░░░░░
 * ▄███▀░◐░░░▌░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▐░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐▄▄░░░░░░░░░░░░░░
 * ░░░░▌░░░░▄▀▒▒▀▀▀▀▄░░░░░░░░░
 * ░░░▐░░░░▐▒▒▒▒▒▒▒▒▀▀▄░░░░░░░
 * ░░░▐░░░░▐▄▒▒▒▒▒▒▒▒▒▒▀▄░░░░░
 * ░░░░▀▄░░░░▀▄▒▒▒▒▒▒▒▒▒▒▀▄░░░
 * ░░░░░░▀▄▄▄▄▄█▄▄▄▄▄▄▄▄▄▄▄▀▄░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░▄▄▌▌▄▌▌░░░░░░░░░░░
 *
 * MIGRATION NOTICE
 *
 * We are in the process of re-writing our components using function components
 * and React Hooks, rather than extending Component.
 *
 * We have found that hooks make our components better, and - once you get the
 * hang of them - produce fewer bugs and edge cases than conventional lifecycle
 * methods (like componentDidMount and getDerivedStateFromProps).
 *
 * However, hooks can’t be used from class components. Therefore, we are trying
 * to eliminate class components wherever we can.
 *
 * If you’re changing this file, please consider contributing to this effort by
 * changing this component to be a function that uses hooks, and then removing
 * this notice.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import authService from 'site-react/auth/AuthService';

import { Provider } from './context';

function hasLoggedInAsUser(user) {
  return user && user.loggedInAs && !user.loggedInAs.error;
}

class UserProvider extends Component {
  constructor(props) {
    super(props);

    this.checkUser = this.checkUser.bind(this);
    this.logout = this.logout.bind(this);
    this.refreshUserData = this.refreshUserData.bind(this);
    this.updateUser = this.updateUser.bind(this);

    const cachedUser = authService.cachedUser();
    let user = null;
    let parentUser = null;

    if (cachedUser) {
      user = hasLoggedInAsUser(cachedUser) ? cachedUser.loggedInAs : cachedUser;
      parentUser = hasLoggedInAsUser(cachedUser) ? cachedUser : null;
    }

    this.state = {
      value: {
        checkUser: this.checkUser,
        isLoggedIn: Boolean(cachedUser),
        /*
         * If there's no cached user, we don't know the user state yet, so
         * it's loading
         */
        isUserLoading: !cachedUser,
        logout: this.logout,
        logoutTarget: typeof window !== 'undefined' && new EventTarget(),
        parentUser,
        refreshUserData: this.refreshUserData,
        updateUser: this.updateUser,
        user,
      },
    };
  }

  async componentDidMount() {
    await this.refreshUserData();
  }

  async checkUser() {
    await this.refreshUserData();
  }

  async logout() {
    const {
      value: { logoutTarget },
    } = this.state;

    await authService.logout();
    await this.refreshUserData();

    logoutTarget.dispatchEvent(new CustomEvent('logout', {}));
  }

  async refreshUserData() {
    const user = await authService.refresh();
    const isLoggedIn = await authService.isLoggedIn();

    if (isLoggedIn && user) {
      this.setState((state) => ({
        value: {
          ...state.value,
          isLoggedIn,
          isUserLoading: false,
          parentUser: hasLoggedInAsUser(user) ? user : null,
          user: hasLoggedInAsUser(user) ? user.loggedInAs : user,
        },
      }));
    } else {
      this.setState((state) => ({
        value: {
          ...state.value,
          isLoggedIn,
          isUserLoading: false,
          parentUser: null,
          user: null,
        },
      }));
    }
  }

  async updateUser(userData) {
    await authService.updateUser(userData);
    await this.refreshUserData();
  }

  render() {
    const { children = null } = this.props;
    const { value } = this.state;

    return <Provider value={value}>{children}</Provider>;
  }
}

UserProvider.propTypes = {
  /**
   * Child components.
   */
  children: PropTypes.node,
};

export default UserProvider;
