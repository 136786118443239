import PropTypes from 'prop-types';
import React from 'react';

import {
  Heading,
  Paragraph,
  RuledHeading,
} from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

import { FacilitiesList } from '../FacilitiesList';

const communalMeetingRoomsInfo = ({
  meetingRooms,
  costPerHour,
  usesCreditSystem,
}) => {
  let costDescription;
  if (costPerHour === 0) {
    costDescription = 'free of charge';
  } else if (usesCreditSystem) {
    costDescription = 'operating on a credit system';
  } else {
    costDescription = `at a cost of from £${costPerHour} per hour`;
  }

  return `${meetingRooms} meeting rooms are available in the building ${costDescription}`;
};

const Facilities = ({
  availableFacilities = [],
  unavailableFacilities = [],
  costPerHour = null,
  meetingRooms = null,
  usesCreditSystem = false,
}) => (
  <>
    <RuledHeading level="2">Building Facilities</RuledHeading>
    <VerticalSpacing size="md" />

    {meetingRooms ? (
      <>
        <Heading level="3" type="title4">
          Communal Meeting Rooms
        </Heading>
        <VerticalSpacing size="md" />
        <Paragraph>
          {communalMeetingRoomsInfo({
            costPerHour,
            meetingRooms,
            usesCreditSystem,
          })}
        </Paragraph>
        <HorizontalRule />
        <VerticalSpacing size="md" />
      </>
    ) : null}

    <FacilitiesList facilities={availableFacilities} />

    {unavailableFacilities && (
      <>
        <VerticalSpacing size="md" />

        <Heading level="3" type="title4">
          Not Available
        </Heading>

        <VerticalSpacing size="md" />

        <FacilitiesList
          facilities={unavailableFacilities}
          knockback
          useIcon={false}
        />
      </>
    )}
  </>
);

const facilitiesListShape = PropTypes.arrayOf(
  PropTypes.shape({
    group: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.number,
    isAvailableOnHq: PropTypes.bool,
    isAvailableOnPass: PropTypes.bool,
    name: PropTypes.string,
    order: PropTypes.number,
    slug: PropTypes.string,
  }),
);

Facilities.propTypes = {
  /**
   * List of available facilities.
   */
  availableFacilities: facilitiesListShape,
  costPerHour: PropTypes.number,
  meetingRooms: PropTypes.number,
  /**
   * List of unavailable facilities.
   */
  unavailableFacilities: facilitiesListShape,
  usesCreditSystem: PropTypes.bool,
};

export default Facilities;
