import EventEmitter from 'events';

import React, { createContext, useMemo } from 'react';

export const context = createContext({
  events: new EventEmitter(),
  openTimePicker: () => {},
});

export const ListingContextProvider = ({ children }) => {
  const events = useMemo(() => new EventEmitter(), []);
  const value = useMemo(
    () => ({
      // For triggering listing-related events elsewhere on the page.
      // For example, DownloadPDF can tell get the correct listing info
      // without having to pass it as a prop.
      events,
      openTimePicker: () => events.emit('openTimePicker'),
    }),
    [events],
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};
