import { useState, useCallback } from 'react';

/**
 * @typedef LocalStorageHookOutputs
 * @param {Object} storedValue - the value retrived from the local storage key (or the initial value)
 * @param {Function} setValue - callback function to set a new storedValue
 * * @param {Function} removeValue - callback function to remove a storedValue
 */

/**
 * Sync state to local storage so that it persists through a page refresh. Usage
 * is similar to useState except we pass in a local storage key so we can
 * default to that value on page load instead of the specified initial value.
 *
 * @param {string} key - a local storage key
 * @param {Object} initialValue - initial value to store
 * @returns {LocalStorageHookOutputs}
 */
const useLocalStorage = (key, initialValue) => {
  /*
   * State to store the value
   */
  const [storedValue, setStoredValue] = useState(() => {
    // Setting initial state with a function so logic is only executed once
    try {
      const item = process.browser && window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  /**
   * Wrapped version of useState's setter function that persists the new value
   * to localStorage.
   */
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        setStoredValue(initialValue);
      }
    },
    [initialValue, key, storedValue],
  );

  /**
   * Reset the stored value and remove it from localStorage.
   */
  const removeValue = useCallback(() => {
    try {
      setStoredValue(initialValue);

      window.localStorage.removeItem(key);
    } catch (error) {
      setStoredValue(initialValue);
    }
  }, [initialValue, key]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
