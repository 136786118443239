import { HttpError, NetworkError } from '@hubble/request';
import Router from 'next/router';
import React from 'react';

import ShortlistApi from 'site-react/api/ShortlistApi';
import AuthService from 'site-react/auth/AuthService';
import { Link } from 'site-react/components/navigation';
import analytics from 'site-react/helpers/Analytics';
import logError from 'site-react/helpers/logError';

const handleError = (error, addError) => {
  if (error instanceof NetworkError) {
    addError(
      'Your network looks like it’s disconnected, so we couldn’t add that to your favourites.',
    );
  } else if (error instanceof HttpError) {
    switch (error.statusCode) {
      case 401:
        // The user may have logged out in another tab.
        addError(
          'It looks like you’ve been logged out, so we couldn’t add that to your favourites.',
        );
        break;

      default:
        addError(
          'Something went wrong, and we couldn’t add that to your favourites. Please try again later.',
        );
        logError(error);
    }
  }
};

const attemptAddToShortlist = async (
  shortlistId,
  addError,
  addSuccess,
  isPartTime,
) => {
  const api = new ShortlistApi();

  try {
    const {
      body: {
        meta: { leadValue, currency },
        shortlist,
      },
    } = await api.createFavourite(shortlistId, isPartTime);

    // This is used for our Facebook ad campaigns. It needs to be sent from the browser,
    // as it utilises third-party cookies.
    analytics.track(
      'Office Shortlisted',
      {
        content_ids: [shortlistId],
        content_type: 'product',
        currency,
        value: leadValue,
      },
      {
        integrations: {
          All: false,
          'Facebook Pixel': true,
        },
      },
    );

    if (addSuccess) {
      const removeAlert = addSuccess(
        <React.Fragment key="office-added-to-shortlist-banner">
          This office has been added to your shortlist. You can view this in{' '}
          <Link
            analyticsMetadata={{
              data_analytics_id: 'add-to-shortlist-view-shortlist',
            }}
            href="/dashboard/hq/shortlist"
            referralSource="attemptAddToShortlist"
            testId="add-to-shortlist-view-shortlist"
          >
            My Hubble
          </Link>
        </React.Fragment>,
      );
      const onNextPageLoad = () => {
        removeAlert();

        // Once we've shown this message once, don't show it again.
        Router.events.off('routeChangeComplete', onNextPageLoad);
      };
      Router.events.on('routeChangeComplete', onNextPageLoad);
    }

    // Force a refresh of the user - this makes sure that local storage is up
    // to date.
    AuthService.refresh();

    return shortlist;
  } catch (error) {
    handleError(error, addError);
    return null;
  }
};

export default attemptAddToShortlist;
