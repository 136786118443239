import { HttpError, NetworkError } from '@hubble/request';

import logError from 'site-react/helpers/logError';

/**
 * Handle a search error. Gracefully determines a user-facing error message, depending on the severity of the error. For more severe errors, will log into Sentry so that a developer can investigate.
 *
 * @param {Error} error - an error, resulting from a search request
 * @returns a user-friendly error message that can be displayed
 * @private
 * @memberof handleDownloadPDFRequestError
 */
const handleDownloadPDFRequestError = (error) => {
  if (error instanceof HttpError) {
    return `Something went wrong, and we couldn’t send you the PDF. (Error code: ${error.statusCode})`;
  }

  if (error instanceof NetworkError) {
    return 'We couldn’t send you the PDF. Your network may be disconnected, or HubbleHQ may be down.';
  }

  logError(error);
  return 'An error occurred, and we couldn’t send you the PDF.';
};

export default handleDownloadPDFRequestError;
