import { default as NextLink } from 'next/link';

import { Heading } from 'site-react/components/typography';
import analytics from 'site-react/helpers/Analytics';

import styles from './NanoCard.module.css';

/**
 * NanoCard
 *
 * Manage offset by setting:
 * `--nano-card-marker-offset: {number}px;`
 * on a parent element, often the map or map wrapper.
 *
 */

const Root = ({
  children,
  analyticsMetadata = {},
  onLinkClick = () => {},
  href,
  linkTitle,
}) => {
  const onClick = (...args) => {
    analytics.track('Nano card clicked', analyticsMetadata, {
      sendPageProperties: true,
    });

    onLinkClick(...args);
  };

  return (
    <NextLink
      className={styles['NanoCard']}
      href={href}
      onClick={onClick}
      title={linkTitle}
    >
      {children}
    </NextLink>
  );
};

const Img = ({ alt = '', src, imageSize = 96 }) => {
  return (
    <div className={styles['NanoCard-imgWrapper']}>
      <img
        alt={alt}
        className={styles['NanoCard-img']}
        src={`${src}?q=30&h=${imageSize * 2}&w=${imageSize * 2}`}
        srcSet={`${src}?q=30&h=${imageSize * 2}&w=${
          imageSize * 2
        }, ${src}?dpr=2&h=${imageSize * 2}&q=30&w=${imageSize * 2} 2x`}
      />
    </div>
  );
};

const Header = ({ children }) => {
  return (
    <div className={styles['NanoCard-header']}>
      <Heading level="2" type="title4">
        <div className={styles['NanoCard-headerText']}>{children}</div>
      </Heading>
    </div>
  );
};

const Content = ({ children }) => {
  return <div className={styles['NanoCard-content']}>{children}</div>;
};

const Footer = ({ children }) => {
  return <div className={styles['NanoCard-footer']}>{children}</div>;
};

export { Root, Img, Header, Content, Footer };
