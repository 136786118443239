import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

class FacilitiesApi extends HubbleBaseApi {
  /**
   * Fetch list of facilities.
   */
  async getFacilities({ isAvailableOnHq, isAvailableOnPass }) {
    const payload = {
      ...(typeof isAvailableOnHq === 'boolean' && {
        is_available_on_hq: isAvailableOnHq,
      }),
      ...(typeof isAvailableOnPass === 'boolean' && {
        is_available_on_pass: isAvailableOnPass,
      }),
    };

    const response = await this.get(`facilities`, payload);

    return response;
  }
}

export default FacilitiesApi;
