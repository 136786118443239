import { useCallback, useContext, useMemo, useState } from 'react';

import { Context as ShortlistContext } from 'site-react/data/core/ShortlistContext';
import logError from 'site-react/helpers/logError';

/**
 * @typedef FavouriteToggleHookInputs
 * @property {number} pricePlanId - the ID of the price plan to toggle
 * @property {number} budget - the user budget, if they choose to toggle on. Normally, this should be the price associated with the price plan.
 * @memberof module:hooks
 */

/**
 * @typedef FavouriteToggleHookOutputs
 * @param {bool} isShortlisted - is the item in the user's shortlist?
 * @param {bool} isShortlistLoading - should the button be in a loading state?
 * @param {function} onShortlistClick - toggle the item; call to remove it if it's shortlisted, and add it if it's not.
 * @memberof module:hooks
 */

/**
 * A React hook, which supplies props that can be applied to a listing card to
 * control shortlisting.
 *
 * Integrates with the ShortlistContext, handles loading states, adding and
 * removing, and persisting to the server.
 *
 * N.B. this hook does not expose any error state or status. The ShortlistContext
 * will use the AlertHandler context to alert the user to issues, should they arise.
 *
 * @param {module:hooks.FavouriteToggleHookInputs} params - state and callbacks that are used to build this hook
 * @return {module:hooks.FavouriteToggleHookOutputs}
 * @memberof module:hooks
 * @public
 */
const useFavouriteToggle = (pricePlanId, isPartTime) => {
  const [isShortlistLoading, setIsShortlistLoading] = useState(false);

  const { addToShortlist, removeFromShortlist, shortlist } =
    useContext(ShortlistContext);

  const isShortlisted = useMemo(
    () => shortlist.includes(pricePlanId),
    [shortlist, pricePlanId],
  );

  const onShortlistClick = useCallback(async () => {
    setIsShortlistLoading(true);

    const toggleItem = () =>
      isShortlisted
        ? removeFromShortlist(pricePlanId)
        : addToShortlist(pricePlanId, isPartTime);

    try {
      await toggleItem();
    } catch (error) {
      // addToShortlist/removeFromShortlist are designed to never throw an error. If it does,
      // there's not much we can do besides log it to Sentry.
      logError(error);
    }

    setIsShortlistLoading(false);
  }, [
    addToShortlist,
    isPartTime,
    isShortlisted,
    pricePlanId,
    removeFromShortlist,
  ]);

  return {
    isShortlisted,
    isShortlistLoading,
    onShortlistClick,
  };
};

export default useFavouriteToggle;
