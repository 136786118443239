import Image from 'next/image';

import { Grid } from 'site-react/components/page';
import {
  Heading,
  Paragraph,
  ListItemWithIcon,
} from 'site-react/components/typography';
import TrustpilotCard from 'site-react/features/TrustpilotCard';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './HqListingAdvisorModalContent.module.css';

const HqListingAdvisorModalContent = () => {
  return (
    <div className={styles['HqListingAdvisorModalContent']}>
      <div>
        <Image
          alt="Hubble advisor group"
          height={81}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/team/blob-style/advisory-group-landscape.png',
            { q: 50, w: 256 },
          )}
          width={256}
        />
      </div>

      <div className={styles['HqListingAdvisorModalContent-heading']}>
        <Heading type="title1"> Free expert help when you need it </Heading>
      </div>

      <div className={styles['HqListingAdvisorModalContent-intro']}>
        <Paragraph type="content1">
          With over 25 years of combined experience, our team of experts places
          a company in their new home every three hours. They’re friendly, super
          responsive and work hard to get the best deals.
        </Paragraph>
      </div>

      <div className={styles['HqListingAdvisorModalContent-points']}>
        <Paragraph type="content1"> Our advisors can help: </Paragraph>
        <ul>
          <ListItemWithIcon
            iconName="check_circle"
            text="Get you the best deals, negotiating an average discount of 15%"
          />
          <ListItemWithIcon
            iconName="check_circle"
            text="Search the entire market, no stone unturned"
          />
          <ListItemWithIcon
            iconName="check_circle"
            text="Send you personalised recommendations"
          />
          <ListItemWithIcon
            iconName="check_circle"
            text="Do the hard work for you, organising viewings, requesting floorplans and more..."
          />
        </ul>
      </div>

      <div>
        <Grid>
          <Grid.Item colSpan="6">
            <TrustpilotCard
              body="It minimized the pain of looking for the new office space. Viewings were set very quickly & Henry took into account all the requirements."
              credit="Stasa"
              title="Easy Process That Put My Requirements First"
            />
          </Grid.Item>

          <Grid.Item colSpan="6">
            <TrustpilotCard
              body="Brilliant! A great overview of all the office spaces in London with some good discounts too!..."
              credit="Rourke"
              title="Brilliant, would definitely recommend"
            />
          </Grid.Item>
        </Grid>
      </div>
    </div>
  );
};

export default HqListingAdvisorModalContent;
