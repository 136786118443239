import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import NavBarMenuItem from './NavBarMenuItem';
import styles from './SimpleMenu.module.css';

/**
 * A simple menu for brief content, like a single list of links.
 *
 * NOTE: you _must_ set `relative` on the `NavBar.MenuItem` that triggers it, in order to get the positioning correct.
 */
const SimpleMenu = ({ align = 'right', children }) => {
  const { isOpen } = useContext(NavBarMenuItem.Context);

  const alignStyle = {
    left: styles['SimpleMenu--alignLeft'],
    right: styles['SimpleMenu--alignRight'],
  }[align];

  return (
    <div
      aria-hidden={!isOpen}
      className={cn(styles.SimpleMenu, alignStyle, {
        [styles['SimpleMenu--open']]: isOpen,
      })}
    >
      {children}
    </div>
  );
};

SimpleMenu.propTypes = {
  /**
   * Where should this menu align itself, relative to its trigger?
   *
   * If set to `right`, then the right side of the menu will align with the right side of the trigger at large breakpoints.
   * If set to `left`, then the left side of the menu will align with the left side of the trigger at large breakpoints.
   */
  align: PropTypes.oneOf(['left', 'right']),

  /**
   * Children, expecting inline styled elements
   */
  children: PropTypes.node,
};

export default SimpleMenu;
