import { useEffect, useMemo, useState } from 'react';

/**
 * Identifies the currently-scrolled-to section of a page.
 *
 * Given a set of `observables`, `useScrollSpy` will tell you which element is
 * currently visible. If more than one element is visible, then the element that
 * is closest to the top of the page is used.
 */
export default function useScrollSpy({
  observables = [],
  rootMargin = '0px 0px 0px 0px',
}) {
  const [intersectingElements, setIntersectingElements] = useState([]);

  const currentElement = useMemo(() => {
    let element = intersectingElements.length ? intersectingElements[0] : null;

    intersectingElements.forEach((intersectingElement) => {
      if (
        intersectingElement.getBoundingClientRect().top <
        element.getBoundingClientRect().top
      ) {
        element = intersectingElement;
      }
    });

    return element;
  }, [intersectingElements]);

  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        setIntersectingElements((intersecting) => {
          let replacementIntersecting = [...intersecting];
          entries.forEach((entry) => {
            if (
              entry.isIntersecting &&
              !replacementIntersecting.includes(entry.target)
            ) {
              replacementIntersecting.push(entry.target);
            } else if (
              !entry.isIntersecting &&
              replacementIntersecting.includes(entry.target)
            ) {
              replacementIntersecting = replacementIntersecting.filter(
                (element) => element !== entry.target,
              );
            }
          });

          return replacementIntersecting;
        });
      },
      {
        rootMargin,
      },
    );

    observables.forEach((observable) => io.observe(observable));

    return () => io.disconnect();
  }, [observables, rootMargin]);

  return { currentElement };
}
