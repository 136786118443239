import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Asset.module.css';

/**
 * A Hubble asset - photography, illustrations, or small decorations that are
 * commonly used throughout the site.
 *
 * `src` should be an `import` from one of the files in `shared/assets`; for example:
 *
 * ```
 * import Asset, { purpleGreen } from 'site-react/shared/Asset';
 *
 * <Asset src={purpleGreenSparkle} alt="" />
 * ```
 */
export default function Asset({
  alt,
  className = null,
  height = null,
  loading = null,
  sizingBehaviour = 'natural',
  sizes = null,
  src,
  srcSet = null,
  width = null,
}) {
  return (
    <img
      alt={alt}
      className={cn(
        {
          [styles['Asset--fill']]: sizingBehaviour === 'fill',
          [styles['Asset--fillToNatural']]: sizingBehaviour === 'fillToNatural',
          [styles['Asset--fixed']]: sizingBehaviour === 'fixed',
        },
        className,
      )}
      loading={loading}
      sizes={sizes}
      src={src}
      srcSet={srcSet}
      style={{
        '--Asset-height': height,
        '--Asset-width': width,
      }}
    />
  );
}

Asset.propTypes = {
  /**
   * Alt text. For purely decorative images, use an empty string.
   */
  alt: PropTypes.string.isRequired,

  /**
   * A height, with units. Examples: 155px, 50%
   */
  height: PropTypes.string,

  /**
   * The img sizes attribute. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Img
   */
  sizes: PropTypes.string,

  /**
   * Sizing behaviour. Sizing is normally done along the horizontal axis.
   * - `natural` uses the natural sizing of the asset
   * - `fill` will scale the asset to fill its container
   * - `fillToNatural` will scale the asset to fill its container, but will never become larger than its natural size
   * - `fixed` will size the asset to given dimensions. You must specify at least one of `height` or `width` with this behaviour.
   */
  sizingBehaviour: PropTypes.oneOf([
    'natural',
    'fill',
    'fillToNatural',
    'fixed',
  ]),

  /**
   * The source path of the asset.
   */
  src: PropTypes.string.isRequired,

  /**
   * For non-vector images, the set of sources at various pixel densities or sizes.
   */
  srcSet: PropTypes.string,

  /**
   * A width, with units. Examples: 155px, 50%
   */
  width: PropTypes.string,
};
