import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

/**
 * Use Query String State
 *
 * Sets an object that keeps in sync with the query string
 */
const useQueryStringState = () => {
  const { replace, query } = useRouter();

  const [state, setState] = useState(query);

  // reflect query string changes
  useEffect(() => {
    setState(query);
  }, [query]);

  const setValue = useCallback(
    (newValue) => {
      // Strip out undefined values
      const newQuery = Object.keys(newValue).reduce((acc, key) => {
        if (typeof newValue[key] !== 'undefined') {
          return { ...acc, [key]: newValue[key] };
        }

        return acc;
      }, {});

      // if same, ignore
      if (JSON.stringify(newQuery) === JSON.stringify(state)) {
        return;
      }

      // strip id, slug from query string (for Listing pages)
      delete newQuery.id;
      delete newQuery.slug;

      replace(
        {
          hash: window.location.hash,
          pathname: window.location.pathname,
          query: newQuery,
        },
        undefined,
        {
          // Prevents browser scroll snapping into place when called
          scroll: false,
          // Prevents ssr methods re-running unnecessarily
          shallow: true,
        },
      );

      setState(newQuery);
    },
    [replace, setState, state],
  );

  return [state, setValue];
};

export default useQueryStringState;
