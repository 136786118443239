export default {
  description:
    'Find office space & desks to rent. Flexible contracts, no agents. Compare prices & book viewings with Hubble now.',
  fb: {
    admins: '510790232',
    appId: '568152729905087',
  },
  img: 'https://hubble.imgix.net/site/meta/hubblehq-default-preview.png?auto=compress',
  index: 'index',
  og: {
    locale: 'en_GB',
    siteName: 'Hubble',
    type: 'website',
  },
  title: 'Office Space & Desks to Rent',
  titleSuffix: ' | Hubble',
  twitter: {
    card: 'summary_large_image',
    creator: '@Hubble',
    site: '@Hubble',
  },
};
