import { createContext } from 'react';

export const OfficeAccessOptions = {
  FullTime: 'fullTime',
  PartTime: 'partTime',
};

const context = createContext({
  officeAccess: OfficeAccessOptions.FullTime,
  pricePlan: null,
  setOfficeAccess: () => {},
  setPricePlan: () => {},
  viewingRequestRef: null,
});

const { Consumer, Provider } = context;

export { Provider, Consumer };
export default context;
