import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ListItemWithIcon } from 'site-react/components/typography';
import { UnstyledList } from 'site-react/components/utility';

import styles from './FacilitiesList.module.css';

const FacilitiesList = ({
  facilities = [],
  knockback = false,
  useIcon = true,
}) => (
  <UnstyledList
    className={cn(styles.FacilitiesList, {
      [styles['FacilitiesList--knockback']]: knockback,
    })}
    type="ol"
  >
    {facilities.map((facility) => (
      <ListItemWithIcon
        contentType="content2"
        iconName={useIcon ? facility?.icon || 'done' : 'clear'}
        key={facility.id}
        text={facility?.name}
      />
    ))}
  </UnstyledList>
);

const facilitiesListShape = PropTypes.arrayOf(
  PropTypes.shape({
    group: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.number,
    isAvailableOnHq: PropTypes.bool,
    isAvailableOnPass: PropTypes.bool,
    name: PropTypes.string,
    order: PropTypes.number,
    slug: PropTypes.string,
  }),
);

FacilitiesList.propTypes = {
  /**
   * List of facilities.
   */
  facilities: facilitiesListShape,

  /**
   * Knock the colour of the text down a notch.
   */
  knockback: PropTypes.bool,

  /**
   * Should the facility icon used? Otherwise, uses the default.
   */
  useIcon: PropTypes.bool,
};

export default FacilitiesList;
