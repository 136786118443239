import Image from 'next/image';
import React from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import { Modal } from 'site-react/components/page';
import {
  ActionText,
  Heading,
  ListItemWithIcon,
  TextWithIcon,
} from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './AdvisorPromotionCard.module.css';
import HqListingAdvisorModalContent from '../../../HqListingAdvisorModalContent';

const AdvisorPromotionCard = () => {
  return (
    <div className={styles.AdvisorPromotionCard}>
      <Image
        alt="Hubble advisor group"
        className={styles['AdvisorPromotionCard-image']}
        height={61}
        loader={imgixLoader}
        src={getImgixPathWithParameters(
          '/site/team/blob-style/advisory-group.png',
          { q: 50, w: 72 },
        )}
        width={72}
      />

      <div className={styles['AdvisorPromotionCard-content']}>
        <Modal
          modalName="hq-listing-usp-advisor"
          renderTrigger={({ openModal }) => (
            <ActionText
              isUnderlined
              label="Free help from our experts"
              onClick={openModal}
              styleType="secondary"
            >
              <Heading level="4" type="title4">
                Free help from our experts
              </Heading>
            </ActionText>
          )}
        >
          <HqListingAdvisorModalContent />
        </Modal>

        <VerticalSpacing size="sm" />
        <UnstyledList>
          <ListItemWithIcon
            contentType="content3"
            iconColor="neutral-900"
            iconName="check_circle"
            text="Instantly confirmed viewings"
          />
          <ListItemWithIcon
            contentType="content3"
            iconColor="neutral-900"
            iconName="check_circle"
            text="Quick quote from our expert team"
          />
          <ListItemWithIcon
            contentType="content3"
            iconColor="neutral-900"
            iconName="check_circle"
            text="Guaranteed best price"
          />
        </UnstyledList>
        <VerticalSpacing size="sm" />

        <OutboundLink
          href="https://uk.trustpilot.com/review/hubblehq.com"
          target="_blank"
        >
          <Image
            alt="Trustpilot rating of 4.8 stars"
            className={styles['AdvisorPromotionCard-trustpilotImage']}
            height={20}
            loader={imgixLoader}
            src={getImgixPathWithParameters(
              '/site/homepage/trustpilot-black-letters.svg',
              { auto: 'compress' },
            )}
            width={260}
          />
        </OutboundLink>
        <div className={styles['AdvisorPromotionCard-telWrapper']}>
          <OutboundLink href="tel:02045876070">
            <TextWithIcon
              contentType="content2"
              iconName="call"
              iconPosition="left"
              iconSize="md"
              text="020 4587 6070"
            />
          </OutboundLink>
        </div>
      </div>
    </div>
  );
};

export default AdvisorPromotionCard;
