import { HttpError, NetworkError } from '@hubble/request';
import { default as NextLink } from 'next/link';
import React from 'react';

import logError from 'site-react/helpers/logError';

/**
 * Handle a "bad request" signup error.
 *
 * @param {Error} error - an error with status code 400, resulting from a signup request
 * @returns a user-friendly error message that can be displayed
 * @private
 * @memberof module:login
 */
const handle400 = (error) => {
  if (error?.body?.email) {
    if (typeof error.body.email === 'string') {
      return error.body.email;
    } else if (Array.isArray(error.body.email)) {
      if (
        error.body.email.includes(
          'spacious user with this email already exists.',
        )
      ) {
        return (
          <>
            A user with that email address already exists -{' '}
            <NextLink href="/login">log in instead?</NextLink>
          </>
        );
      } else {
        // There's no case where we actually want to show more than one error.
        // Throw away subsequent ones, rather than overwhelming the user.
        return error.body.email[0];
      }
      // No way around this - we've got to use __all__ to maintain legacy code.
      // eslint-disable-next-line no-underscore-dangle
    } else if (error.body.__all__ && error.body.__all__.length >= 1) {
      // There's no case where we actually want to show more than one error.
      // Throw away subsequent ones, rather than overwhelming the user.
      // eslint-disable-next-line no-underscore-dangle
      return error.body.__all__[0];
    } else {
      return error.error || error.body;
    }
  }
};

/**
 * Handle an unexpected HTTP signup error.
 *
 * @param {Error} error - an error resulting from a signup request, that isn't otherwise explicitly handled
 * @returns a user-friendly error message that can be displayed
 * @private
 * @memberof module:login
 */
const handleGenericHttpError = (error) => {
  // Unexpected state. Log to Sentry, and show a generic message.
  logError(error);
  if (error.error) {
    return `We were unable to sign you up. ${error.error}`;
  }
  return `We were unable to sign you up. Error code: ${error.statusCode}`;
};

/**
 * Handle a signup error. Gracefully determines a user-facing error message, depending on the severity of the error. For more severe errors, will log into Sentry so that a developer can investigate.
 *
 * @param {Error} error - an error, resulting from a signup request
 * @returns a user-friendly error message that can be displayed
 * @private
 * @memberof module:login
 */
const handleLoginError = (error) => {
  if (error instanceof HttpError) {
    switch (error.statusCode) {
      case 400:
        return handle400(error);
      default:
        return handleGenericHttpError(error);
    }
  } else if (error instanceof NetworkError) {
    return 'We were unable to sign you up. Your network may be disconnected, or Hubble may be down.';
  } else {
    logError(error);
    return 'An error occurred, and we were unable to sign you up.';
  }
};

export default handleLoginError;
