/**
 * A custom loader for next/image that uses imgix to serve images.
 *
 * Docs on what Next expects here: https://nextjs.org/docs/pages/api-reference/components/image#loader
 *
 * @param {object} args arguments passed by next/image to the loader
 * @param {string} args.src the original image URL from the src prop
 * @param {number} args.width the width of the image that next/image is specifically requesting, in exact pixels
 * @param {number} args.quality the quality of the image that next/image is specifically requesting, from 0-100
 */
export default ({ src, width, quality }) => {
  const url = new URL(src, 'https://hubble.imgix.net');

  const params = url.searchParams;

  // -- Default parameters --
  if (params.get('auto') === null) {
    params.set('auto', 'format,compress');
  }

  if (params.get('fit') === null) {
    params.set('fit', 'max');
  }

  if (params.get('q') === null) {
    // NOTE: this may be overridden later if Next specifies a quality
    params.set('q', '50');
  }

  // -- Calculated parameters --

  // next/image only tells us the width. If the image URL specifies a height,
  // then that height needs to scale proportionally to the width. We can do
  // this by calculating the aspect ratio of the image, and then setting the
  // height to the aspect ratio * width.
  //
  // We only need to do this if height is set in the URL parameters. We also
  // can't do this if we don't know the original width. So, both `h` and `w` are
  // required in this code path.
  const urlHeight = params.get('h');
  const urlWidth = params.get('w');

  if (urlHeight && urlWidth) {
    // Calculate aspect ratio of the underlying image from the URL parameters
    const ratio = urlWidth / urlHeight;

    // Using the width specified in the arguments, calculate the height
    params.set('h', Math.round(width / ratio).toString());
  }

  // -- Overriding parameters --

  // When next/image specifies a width, it takes precedence over anything set
  // in the URL params, because the width specified in the arguments to this
  // function tell us exactly how the image will be rendered on screen.
  if (width) {
    params.set('w', width.toString());
  }

  // Trust the quality passed into this function. next/image will use a lower
  // quality the higher the screen's pixel density, so this will vary, and that
  // means we can't depend on the URL params to determine quality.
  if (quality) {
    params.set('q', quality.toString());
  }

  return url.href;
};
