import PropTypes from 'prop-types';
import React from 'react';

/* Json-ld schema for SEO
 *
 * What JsonLD does is take some schema data, written in the form of a js object,
 * and convert it into a js script, specifically so that it can render as valid json-ld data.
 * If you were to pass the original js object into Google' schema validator tool
 * ( https://search.google.com/structured-data/testing-tool/u/0/ )
 * then it would be invalid, but the script output of JsonLD *would* be valid when tested.
 * Intended usage is that you can include a JsonLD element in your React component
 * within the render method, and the schema data will render out for SEO purposes.
 * The advantage is that you don't have to clutter up your markup with lots of microdata,
 * which can be time-consuming to debug and make the markup hard to read.
 *
 * @example <JsonLD data={componentData} />
 * @see {@link https://blog.haroen.me/json-ld-with-react}
 * @param {object} schema data in js object format
 * @returns {script element} stringified data wrapped in a <script> tag
 */
const JsonLD = ({ data = [] }) =>
  typeof data === 'object' ? (
    <script
      // This solves rendering differences between client and server.
      // We can't just use JSON.stringify() as a child without getting warnings.
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      type="application/ld+json"
    />
  ) : null;

JsonLD.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
    ]),
  ),
};

export default JsonLD;
