import { useRouter } from 'next/router';
import React from 'react';
import { useForm } from 'react-hook-form';

import { InputGroup, TextInput } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';

import styles from './AdvisorSearchRecommendationLists.module.css';

export default function AdvisorSearchRecommendationLists({
  searchQuery = '',
  setIsLoading,
}) {
  const router = useRouter();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      searchQuery,
    },
  });

  async function onSubmit(data) {
    setIsLoading(true);

    router.replace({
      pathname: router.pathname,
      query: { ...router.query, searchQuery: data.searchQuery },
    });
  }

  return (
    <form
      data-testid="SearchBar"
      method="post"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <label className="u-visuallyHidden" htmlFor="recommendationListSearch">
        Recommendation List Search
      </label>
      <InputGroup>
        <TextInput
          autoCorrect="off"
          id="recommendationListSearch"
          name="searchQuery"
          placeholder="Search by name or email"
          type="search"
          {...register('searchQuery')}
        />
        <button
          className={styles['AdvisorSearchRecommendationLists-control']}
          label="Search"
          type="submit"
        >
          <span className="u-visuallyHidden">Search recommendation lists</span>
          <MaterialIcon aria-hidden="true" iconType="search" />
        </button>
      </InputGroup>
    </form>
  );
}
