import { css, keyframes } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';
import theme from 'site-react/theme';

const actionStyle = css`
  align-items: center;
  color: var(--color-neutral-900);
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: var(--space-sm) var(--space-md);
  text-align: center;

  :disabled {
    color: var(--color-neutral-500);
    cursor: default;
  }

  border-right: 2px solid var(--color-neutral-100);
  flex-direction: column;

  &:last-child {
    border-right: 0;
  }
`;

const textStyle = css`
  font: var(--font-style-content-3);
  white-space: nowrap; /* Don't allow text to wrap onto multiple lines. */

  button:hover &,
  button:focus & {
    text-decoration: underline;
  }

  button:disabled:hover &,
  button:disabled:focus & {
    text-decoration: none;
  }
`;

const iconBaseStyle = (iconColor) => css`
  margin-right: var(--space-sm);
  color: ${theme.colors[iconColor]};

  @media (min-width: 992px) {
    margin-right: 0;
  }
`;

const buttonHeight = theme.spacing.xl;
const buttonRadius = buttonHeight / 2;

const loadingAnimation = keyframes`
  0% {
    transform: scale(0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const loadingStyles = css`
  animation: ${loadingAnimation} 0.75s infinite ease-in-out;
  background-color: var(--color-neutral-300);
  border-radius: ${buttonRadius}px;
  height: ${buttonHeight}px;
  width: ${buttonHeight}px;
`;

const BottomAccessoryButton = ({
  iconType = '',
  isEnabled = true,
  isLoading = false,
  enabledIconColor = 'neutral900',
  label = '',
  onClick,
  testId,
  title = '',
}) => (
  <UnstyledButton
    aria-label={label}
    className={actionStyle}
    isEnabled={isEnabled}
    label={label}
    onClick={onClick}
    testId={testId}
    title={title}
  >
    {isLoading ? (
      <div className={loadingStyles} />
    ) : (
      <>
        <MaterialIcon
          className={iconBaseStyle(enabledIconColor)}
          iconType={iconType}
        />
        <span className={textStyle}>{label}</span>
      </>
    )}
  </UnstyledButton>
);

BottomAccessoryButton.propTypes = {
  /**
   * Color of the enabled icon.
   */
  enabledIconColor: PropTypes.string,

  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconType: PropTypes.string,

  /**
   * Is the user able to open this modal?
   */
  isEnabled: PropTypes.bool,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string.isRequired,

  /**
   * Callback when the "Request floor plan" button is activated
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Optional string for the Unstyled Button to render in a `data-testid`
   *  attribute to allow element to be found in tests
   */
  testId: PropTypes.string,

  /**
   * The button's title. Note: this is used as the tooltip, not as the label.
   */
  title: PropTypes.string,
};

export default BottomAccessoryButton;
