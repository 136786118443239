import PropTypes from 'prop-types';
import React from 'react';

import { CloseButton } from 'site-react/components/navigation';
import { MaterialIcon } from 'site-react/components/typography';

import {
  checkboxTypeStyle,
  checkedCheckboxStyle,
  checkedIconStyle,
  labelSpacingStyle,
  noResultsStyle,
  redoSearchStyle,
  redoSearchLabelStyle,
  toggleStyle,
  uncheckedCheckboxStyle,
} from './SearchAsIMoveToggle.styles';

/**
 * Use 'SearchAsIMove' to enable or disable searching as the user drags the map.
 * Will appear on a map if isSearchable prop is true.
 */
const SearchAsIMoveToggle = ({ onToggle = () => {}, status = 'active' }) => (
  <>
    {status === 'no-results' && (
      <div
        className={noResultsStyle}
        data-testid="SearchAsIMoveToggle-no-results"
      >
        <span className={labelSpacingStyle}>No results. Try zooming out</span>
        <CloseButton color="white" onClick={() => onToggle('inactive')} />
      </div>
    )}
    {status === 'active' && (
      <button
        className={toggleStyle}
        data-testid="SearchAsIMoveToggle-active"
        onClick={() => {
          onToggle('inactive');
        }}
        type="button"
      >
        <div className={checkedCheckboxStyle}>
          <MaterialIcon className={checkedIconStyle} iconType="done" />
          <input
            checked
            className={checkboxTypeStyle}
            name="active"
            readOnly
            type="checkbox"
            value=""
          />
        </div>
        <span>Search as I move the map</span>
      </button>
    )}
    {status === 'inactive' && (
      <button
        className={toggleStyle}
        data-testid="SearchAsIMoveToggle-inactive"
        onClick={() => {
          onToggle('active');
        }}
        type="button"
      >
        <div className={uncheckedCheckboxStyle}>
          <input
            checked
            className={checkboxTypeStyle}
            name="inactive"
            readOnly
            type="checkbox"
            value=""
          />
        </div>
        <span>Search as I move the map</span>
      </button>
    )}
    {status === 'redo-search' && (
      <button
        className={redoSearchStyle}
        data-testid="SearchAsIMoveToggle-redo-search"
        onClick={() => {
          onToggle('active');
        }}
        type="button"
      >
        <span className={redoSearchLabelStyle}>
          <MaterialIcon className={labelSpacingStyle} iconType="cached" />
          Redo search here
        </span>
      </button>
    )}
  </>
);

export default SearchAsIMoveToggle;

SearchAsIMoveToggle.propTypes = {
  /**
   * Method to change the status of the component when a user interacts with the toggle.
   */
  onToggle: PropTypes.func,
  /**
   * Tells the component which type of toggle to render:
   *
   * active: User is searching as they scroll. If they click the toggle the status changes
   * to inactive.
   *
   * inactive: User is not searching as they scroll. If they click the toggle the status
   * changes to active. If they continue to scroll the status changes to redo-search.
   *
   * redo-search: Variation of inactive. If they click the toggle status changes to active.
   *
   * no-results: When triggered, moves from an active variant to an inactive variant. If the user
   * clicks the close button the status changes to inactive. If they continue to scroll the status
   * will change to redo-search.
   *
   */
  status: PropTypes.oneOf(['active', 'inactive', 'no-results', 'redo-search']),
};
