import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useContext } from 'react';

import config from 'site-react/config';
import { AlertContext } from 'site-react/data/core/AlertContext';
import logError from 'site-react/helpers/logError';
import useLocalStorage from 'site-react/hooks/useLocalStorage';
import usePrevious from 'site-react/hooks/usePrevious';
import useUser from 'site-react/hooks/useUser';

import attemptAddToShortlist from './attemptAddToShortlist';
import attemptRemoveFromShortlist from './attemptRemoveFromShortlist';
import Context from './context';

const localStorageKey = `hubble_${config.ENV}_trackShortlist_shortlistProvider`;

// For more about why this is a context, and contexts in general, see our docs
// on the tech wiki:
// https://www.notion.so/hubblehq/react-components-9bb14d0b51124808b44420f42d93c30e#1801f8e30d8d4702a5cbe0c4c81be3ff

function ShortlistProvider({ children = null, ...props }) {
  const { addError, addSuccess } = useContext(AlertContext);
  const { isLoggedIn, user, checkUser } = useUser();
  const [isShortlistLoaded, setIsShortlistLoaded] = useState(false);
  const [shortlist, setShortlist] = useState([]);
  const [performedActions, setPerformedActions] = useLocalStorage(
    'performedActions',
    [],
  );
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [animateNavIcon, setAnimateNavIcon] = useState(false);

  const previousIsLoggedIn = usePrevious(isLoggedIn);
  const previousIsShortlistLoaded = usePrevious(isShortlistLoaded);

  const onSignupOrLogin = useCallback(async () => {
    await checkUser();
    setShowSignupModal(false);
  }, [checkUser]);

  const handleUserNotLoggedIn = (shortlistId, isPartTime) => {
    try {
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({ isPartTime, shortlistId }),
      );
    } catch (e) {
      // Fail silently - let the user log in and re-shortlist manually.
      logError(
        "Local storage failed. Listing won't be shortlisted after login.",
      );
    }

    setShowSignupModal(true);
  };

  /**
   * Add an office to shortlist, add extra parameters to update a user with
   */
  const addToShortlist = useCallback(
    async (shortlistId, isPartTime) => {
      if (!isLoggedIn) {
        handleUserNotLoggedIn(shortlistId, isPartTime);
        return;
      }

      if (isShortlistLoaded && !shortlist.includes(shortlistId)) {
        // we only want the success toast to show up only when a shortlist hasn't been performed before
        const addSuccessFunction = performedActions.includes('shortlist')
          ? null
          : addSuccess;

        // we only want to animate the icon when previously the shortlist was empty and we are adding the first item
        if (shortlist.length === 0) {
          setAnimateNavIcon(true);
        }

        const newShortlist = await attemptAddToShortlist(
          shortlistId,
          addError,
          addSuccessFunction,
          isPartTime,
        );
        if (newShortlist) {
          if (!performedActions.includes('shortlist')) {
            setPerformedActions([...performedActions, 'shortlist']);
          }
        } else {
          return;
        }

        setShortlist([...newShortlist]);
      }
    },
    [
      addError,
      addSuccess,
      isLoggedIn,
      isShortlistLoaded,
      shortlist,
      performedActions,
      setPerformedActions,
    ],
  );

  useEffect(() => {
    /**
     * Post login handles getting the localStorage key which has the
     * shortlist information and adding it to the shortlist.
     */
    async function handleShortlistPostLogin() {
      try {
        if (window.localStorage.getItem(localStorageKey) !== null) {
          const { isPartTime, shortlistId } = JSON.parse(
            window.localStorage.getItem(localStorageKey),
          );

          if (shortlistId) {
            await addToShortlist(parseInt(shortlistId), isPartTime);
          }
          window.localStorage.removeItem(localStorageKey);
        }
      } catch (e) {
        logError('Nothing was shortlisted post login');
      }
    }

    if (
      isLoggedIn &&
      isShortlistLoaded &&
      (previousIsLoggedIn !== isLoggedIn ||
        previousIsShortlistLoaded !== isShortlistLoaded) // Prior to adding this extra check, `handleShortlistPostLogin` would be triggered multiple times
    ) {
      handleShortlistPostLogin();
    }
  }, [
    isLoggedIn,
    isShortlistLoaded,
    addToShortlist,
    previousIsLoggedIn,
    previousIsShortlistLoaded,
  ]);

  async function removeFromShortlist(shortlistId) {
    if (isShortlistLoaded && shortlist.includes(shortlistId)) {
      const isRemoved = await attemptRemoveFromShortlist(shortlistId, addError);
      if (!isRemoved) return;

      setShortlist(shortlist.filter((id) => id !== shortlistId));
    }
  }

  /**
   * When the user has changed - usually on page load, but also if a user logs
   * in or logs out - we want to load in the latest shortlist from the server.
   *
   * We'll only want to set `isShortlistLoaded` to true if a user is logged in,
   * since we only use this functionality for logged in users. Logging out
   * causes a page reload, so we don't need to worry about an else statement.
   */
  const userUpdated = useCallback(
    (user) => {
      if (isLoggedIn && user) {
        setIsShortlistLoaded(true);
        setShortlist([...user.favourites]);
      }
    },
    [isLoggedIn],
  );

  useEffect(() => {
    userUpdated(user);
  }, [user, userUpdated]);

  return (
    <Context.Provider
      value={{
        addToShortlist,
        animateNavIcon,
        isShortlistLoaded,
        onSignupOrLogin,
        removeFromShortlist,
        setShowSignupModal,
        shortlist,
        showSignupModal,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  );
}

ShortlistProvider.propTypes = {
  /**
   * Child components
   */
  children: PropTypes.node,
};

export default ShortlistProvider;
