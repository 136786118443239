import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import theme from 'site-react/theme';

import Context from './context';
import styles from './Grid.module.css';

const Grid = ({
  breakpoint = 'lg',
  children,
  gridGapX = 'md',
  gridGapY = 'lg',
}) => {
  return (
    <div
      className={cn(
        styles['Grid'],
        styles[`Grid--${breakpoint}`],
        styles['Grid--gap'],
      )}
      style={{
        '--grid-gap-x': `var(--space-${gridGapX})`,
        '--grid-gap-y': `var(--space-${gridGapY})`,
      }}
    >
      <Context.Provider value={{ breakpoint }}>{children}</Context.Provider>
    </div>
  );
};

Grid.alignments = ['center', 'end', 'start', 'stretch'];

Grid.propTypes = {
  /*
   * Breakpoint at which to collapse the grid to a single column
   * defaults to 'lg'
   */
  breakpoint: PropTypes.oneOf(Object.keys(theme.breakpoints)),
  /*
   * One or more Grid.Item components
   */
  children: PropTypes.node.isRequired,

  /*
   * Adjust the gaps between the top/bottom (y) and start/end
   * of Grid.Item's
   */
  gridGapX: PropTypes.oneOf(Object.keys(theme.spacing)),
  gridGapY: PropTypes.oneOf(Object.keys(theme.spacing)),
};

const GridItem = ({
  align = null,
  children = null,
  colSpan = null,
  colStart = null,
  justify = null,
  minWidth = null,
  order = null,
  rowSpan = null,
  rowStart = null,
}) => {
  const contextValues = useContext(Context);
  const breakpoint = contextValues?.breakpoint ?? 'lg';

  return (
    <div
      className={cn(styles['GridItem'], styles[`GridItem--${breakpoint}`], {
        [styles['GridItem--minWidth']]: minWidth,
      })}
      style={{
        '--GridItem-columnEnd': `${colSpan ? `span ${colSpan}` : 'auto'}`,
        '--GridItem-columnStart': `${colStart ?? 'auto'}`,
        '--GridItem-rowEnd': `${rowSpan ? `span ${rowSpan}` : 'auto'}`,
        '--GridItem-rowStart': `${rowStart ?? 'auto'}`,
        ...(align && {
          '--GridItem-align': `${align}`,
        }),
        ...(justify && {
          '--GridItem-justify': `${justify}`,
        }),
        ...(minWidth && {
          '--GridItem--minWidth': `${minWidth}`,
        }),
        ...(order && {
          '--GridItem-order': `${order}`,
        }),
      }}
    >
      {children}
    </div>
  );
};

Grid.Item = GridItem;

Grid.Item.displayName = 'Grid.Item';

Grid.Item.propTypes = {
  /*
   * Value of the item’s `align-self` CSS property
   */
  align: PropTypes.oneOf(Grid.alignments),
  /*
   * Content to display in the item
   */
  children: PropTypes.node,
  /*
   * `span` argument of the item’s `grid-column-end` CSS property
   */
  colSpan: PropTypes.string,
  /*
   * Value of the item’s `grid-column-start` CSS property
   */
  colStart: PropTypes.string,
  /*
   * Value of the item’s `justify-self` CSS property
   */
  justify: PropTypes.oneOf(Grid.alignments),
  /*
   * Value of the item’s `min-width` CSS property
   */
  minWidth: PropTypes.string,
  /**
   * Value of the item’s `order` CSS property
   */
  order: PropTypes.string,
  /*
   * `span` argument of the item’s `grid-row-end` CSS property
   */
  rowSpan: PropTypes.string,
  /*
   * Value of the item’s `grid-row-start` CSS property
   */
  rowStart: PropTypes.string,
};

export default Grid;
