import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './GhostLoginBanner.module.css';

const GhostLoginBanner = ({ onLogout, user }) => (
  <div className={styles.GhostLoginBanner}>
    <span className={styles['GhostLoginBanner-content']}>
      <MaterialIcon
        className={styles['GhostLoginBanner-icon']}
        iconType="perm_identity"
      />
      <span>
        You are <strong>ghost logged in</strong> as {user.firstName}{' '}
        {user.lastName} {!!user.company && `(${user.company})`}
      </span>
    </span>
    <button
      className={styles['GhostLoginBanner-logOut']}
      data-testid="ghost-login-banner-logout"
      onClick={onLogout}
      type="button"
    >
      Logout
    </button>
  </div>
);

GhostLoginBanner.propTypes = {
  onLogout: PropTypes.func.isRequired,
  /**
   * The user you are logged in as
   */
  user: PropTypes.shape({
    company: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phone_country_code: PropTypes.string,
    regional_phone_number: PropTypes.string,
  }).isRequired,
};

export default GhostLoginBanner;
