import { Link, OutboundLink } from 'site-react/components/navigation';
import { Paragraph } from 'site-react/components/typography';
import { Asset } from 'site-react/components/utility';

import { facebook, instagram, linkedin } from './assets';
import styles from './LegalLinks.module.css';

export default function LegalLinks() {
  return (
    <div className={styles['LegalLinks']}>
      <div className={styles['LegalLinks-legal']}>
        <Paragraph isDeEmphasized isMarginless type="content3">
          Copyright © HubbleHQ {new Date().getFullYear()}
        </Paragraph>
        <Link href="/legals" isUnderlined={false} styleType="onDark">
          Terms and Conditions
        </Link>
        <Link href="/privacy-policy" isUnderlined={false} styleType="onDark">
          Privacy Policy
        </Link>
        <Link
          href="/cookie-preferences"
          isUnderlined={false}
          styleType="onDark"
        >
          Cookie Preferences
        </Link>
        <Link href="/security" isUnderlined={false} styleType="onDark">
          Security
        </Link>
        <Link href="/sitemap" isUnderlined={false} styleType="onDark">
          Sitemap
        </Link>
      </div>
      <div className={styles['LegalLinks-social']}>
        <OutboundLink
          href="https://www.linkedin.com/company/3519114"
          target="_blank"
        >
          <Asset alt="LinkedIn" src={linkedin} />
        </OutboundLink>
        <OutboundLink
          href="https://www.instagram.com/hubblehq/"
          target="_blank"
        >
          <Asset alt="Instagram" src={instagram} />
        </OutboundLink>
        <OutboundLink href="https://www.facebook.com/HubbleHQ/" target="_blank">
          <Asset alt="Facebook" src={facebook} />
        </OutboundLink>
      </div>
    </div>
  );
}
