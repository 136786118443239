import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomDropdown } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';

const displayComponentStyle = css`
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  display: grid;
  grid-template-areas: 'detail carat';
  grid-template-columns: 1fr auto;
  justify-items: stretch;
  padding: var(--space-sm);
  width: 100%;

  @media (min-width: 992px) {
    grid-template-areas:
      'detail detail carat'
      'capacity availability carat';
    grid-template-columns: auto 1fr auto;
  }
`;

const displayContentStyle = css`
  font: var(--font-style-content-3);
  display: flex;
  grid-area: detail;
  flex-direction: column;

  span:first-child {
    font-weight: var(--font-weight-medium);
  }

  span:nth-child(2) {
    display: none;
  }

  span:last-child {
    color: var(--color-neutral-500);
  }

  @media (min-width: 992px) {
    font-weight: var(--font-weight-medium);

    flex-direction: row;
    > span:nth-child(2) {
      display: block;
    }

    span:last-child {
      color: var(--color-neutral-900);
    }
  }
`;

const capacityStyle = css`
  font: var(--font-style-content-3);
  display: none;
  grid-area: capacity;
  margin-right: var(--space-md);
  @media (min-width: 992px) {
    display: block;
  }
`;

const wrapperStyle = css`
  flex: 1;
`;

const DisplayComponent = ({ title, description }) => {
  return (
    <div className={displayComponentStyle}>
      <span className={displayContentStyle}>{title}</span>
      <span className={capacityStyle}>{description}</span>
      <MaterialIcon
        className={css`
          grid-area: carat;
        `}
        iconType="arrow_drop_down"
      />
    </div>
  );
};

const DropdownProducts = ({
  isDisabled,
  name,
  onChange,
  options,
  selectedId = '',
}) => {
  const selectedOption = options.find((option) => option.id === selectedId);

  return (
    <div className={wrapperStyle}>
      <CustomDropdown
        disabled={isDisabled}
        displayComponent={
          <DisplayComponent
            description={selectedOption?.description ?? ''}
            title={selectedOption?.title ?? '-'}
          />
        }
        name={name}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={selectedId}
      >
        {options.length ? (
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))
        ) : (
          <option disabled>No products available</option>
        )}
      </CustomDropdown>
    </div>
  );
};

DropdownProducts.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  selectedId: PropTypes.string.isRequired,
};

export default DropdownProducts;
