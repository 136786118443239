import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

class ShortlistApi extends HubbleBaseApi {
  /**
   * Adds an item to the user's shortlist.
   *
   * @param {number} pricePlanId the price plan to shortlist
   */
  async createFavourite(pricePlanId, isPartTime) {
    return this.post(
      `offices/${pricePlanId}/favourite?create_lead=true&is_part_time=${isPartTime}`,
    );
  }

  /**
   * Removes an item from the user's shortlist.
   *
   * N.B. the naming here is unwieldy, but does represent the structure of our
   * API. In the future, we may want to add a `DELETE /favourite` endpoint,
   * which would allow us to rename this to `deleteFavourite`, which better
   * reflects actual functionality.
   *
   * @param {number} pricePlanId the price plan to remove from a shortlist
   */
  async createUnfavourite(pricePlanId) {
    return this.post(`offices/${pricePlanId}/unfavourite`);
  }

  async deleteFavourites() {
    return this.delete('offices/clear');
  }
}

export default ShortlistApi;
