import { useRouter, usePathname } from 'next/navigation';

import { Button } from 'site-react/components/form';
import { TextWithIcon } from 'site-react/components/typography';
import usePermissions from 'site-react/hooks/usePermissions';
import useUser from 'site-react/hooks/useUser';

import { askAdminLabelStyle } from './styles';
import getBookingCTA from '../../../helpers/getBookingCTA';
import getCompoundMeetingRoomId from '../../../helpers/getCompoundMeetingRoomId';

const MeetingRoomBookButton = ({ product }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { isLoggedIn } = useUser();
  const permissions = usePermissions();

  let buttonContent = 'Book';

  if (product.isInstantlyBookable) {
    buttonContent = (
      <TextWithIcon
        contentType="content2"
        iconName="bolt"
        iconPosition="left"
        iconSize="md"
        text={getBookingCTA(
          product.productType,
          isLoggedIn,
          product.isInstantlyBookable,
        )}
      />
    );
  }

  const bookButton = (
    <Button
      isCompact
      name="book"
      onClick={() => {
        const compoundMeetingRoomId = getCompoundMeetingRoomId(
          product,
          product.pricePerHour ? 'per-hour' : 'full-day',
        );

        router.push({
          pathname: `${pathname}/book-meeting-room`,
          query: {
            option: compoundMeetingRoomId,
          },
        });
      }}
      styleAtSmall="auto"
      type="button"
    >
      {buttonContent}
    </Button>
  );

  if (isLoggedIn) {
    // is the user logged in
    if (permissions.canRequestMeetingRoom) {
      // if they are and have the correct permissions then show the book button
      return bookButton;
    } else {
      // if they don't have the correct permissions show them an error
      return (
        <span className={askAdminLabelStyle} role="alert">
          Contact your company admin to book
        </span>
      );
    }
  } else {
    // if they're not logged in show them the book button
    // they will be directed to log in after filling out
    // the booking form
    return bookButton;
  }
};

export default MeetingRoomBookButton;
