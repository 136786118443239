import compareByKey from 'site-react/helpers/compareByKey';

const byIndex = compareByKey('distance');

/**
 * @param {array} landmarks (building.landmarks)
 * @returns {object} closestArea
 * This function finds the building's landmark that has the shortest
 * distance from the building itself. If the data is 'clean' we spit out
 * the landmark data as an object.
 */
const getClosestLandmarkArea = (buildingLandmarks) => {
  if (buildingLandmarks.length === 0) {
    return null;
  }

  let closestLandmark = buildingLandmarks.sort(byIndex);

  if (closestLandmark.length > 1) {
    closestLandmark = closestLandmark.slice(0, 1);
  }

  if (closestLandmark.length === 0) {
    return null;
  }

  const closestArea = closestLandmark[0].landmark || null;
  return closestArea;
};

export default getClosestLandmarkArea;
