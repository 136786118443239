import { yupResolver } from '@hookform/resolvers/yup';
import { HttpError, NetworkError } from '@hubble/request';
import { DateTime } from 'luxon';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ViewingsApi from 'site-react/api/ViewingsApi';
import {
  Button,
  InputGroup,
  Label,
  Select,
  TextInput,
} from 'site-react/components/form';
import { Modal } from 'site-react/components/page';
import { ErrorMessage, TextWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';
import Signup from 'site-react/features/Signup';
import analytics from 'site-react/helpers/Analytics';
import { hqBusinessDayHelper } from 'site-react/helpers/BusinessDayHelper';
import getCountryCodes, {
  getPinnedCountryCodes,
} from 'site-react/helpers/CountryCodes';
import getDataLayer from 'site-react/helpers/dataLayer';
import holidays from 'site-react/helpers/holidays';
import logError from 'site-react/helpers/logError';
import useUser from 'site-react/hooks/useUser';
import useUTM from 'site-react/hooks/useUTM';
import { BuildingPropTypes, PricePlanPropTypes } from 'site-react/proptypes';

import styles from './Form.module.css';
import { getTimesForDay, getNextAvailableAppointment } from '../../helpers';
import SignupSideContent from '../SignupSideContent';

const viewingsApi = new ViewingsApi();

function roundUpToNearestQuarterHour(dateTime) {
  const minutes = dateTime.minute;
  const remainder = minutes % 15;
  const adjustment = remainder === 0 ? 0 : 15 - remainder;

  return dateTime
    .plus({ minutes: adjustment })
    .set({ millisecond: 0, second: 0 });
}

function getNearestNonShortNoticeSlot(viewingsStartTime, viewingsEndTime) {
  // 8 hours
  const timeToAddInMinutes = 480;

  const now = roundUpToNearestQuarterHour(DateTime.utc());

  let newDateTime = hqBusinessDayHelper.plusBusinessMinutes(
    now,
    timeToAddInMinutes,
  );

  if (viewingsStartTime) {
    const [startHours, startMinutes] = viewingsStartTime.split(':').map(Number);
    const newDateTimeWithStartTime = newDateTime.set({
      hour: startHours,
      minute: startMinutes,
    });

    if (newDateTime < newDateTimeWithStartTime) {
      newDateTime = newDateTimeWithStartTime;
    }
  }

  if (viewingsEndTime) {
    const [startHours, startMinutes] = viewingsStartTime.split(':').map(Number);

    const [endHours, endMinutes] = viewingsEndTime.split(':').map(Number);
    const newDateTimeWithEndTime = newDateTime.set({
      hour: endHours,
      minute: endMinutes,
    });

    if (newDateTime > newDateTimeWithEndTime) {
      newDateTime = hqBusinessDayHelper.plusBusinessDays(newDateTime, 1).set({
        hour: startHours,
        minute: startMinutes,
      });
    }
  }

  return newDateTime;
}

export default function Form({ access, building, pricePlan }) {
  const router = useRouter();
  const utmParams = useUTM();

  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(() => false);
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(() => false);
  const [errorMessage, setErrorMessage] = useState(() => null);

  const [formInputAnalyticsSent, setFormInputAnalyticsSent] = useState(false);

  const { checkUser, isLoggedIn, parentUser, updateUser, user } = useUser();

  const minimumShortNoticeAppointmentDate = useMemo(() => {
    const minimumAppointmentISO = getNextAvailableAppointment(
      DateTime.utc(),
      building.viewingsStartTime || '8:30',
      building.viewingsEndTime || '18:00',
    );

    return DateTime.fromISO(minimumAppointmentISO, {
      setZone: true,
    });
  }, [building]);

  const minimumNormalNoticeAppointmentDate = getNearestNonShortNoticeSlot(
    building.viewingsStartTime,
    building.viewingsEndTime,
  );

  const maximumAppointmentDate = minimumShortNoticeAppointmentDate.plus({
    days: 28,
  });

  const validationSchema = Yup.object({
    bookingDate: Yup.date()
      .typeError('Please select a date')
      .required('Please select a date')
      .test(
        'is-weekday',
        'Must be a weekday',
        (value) => DateTime.fromJSDate(value).weekday < 6,
      )
      .test(
        'is-holiday',
        ({ value }) =>
          `${DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_FULL)} is a holiday`,
        (value) => {
          const isoDate = DateTime.fromJSDate(value).toISODate();
          return !holidays.includes(isoDate);
        },
      )
      .min(
        minimumShortNoticeAppointmentDate.toISODate(),
        `Viewings cannot be booked before ${minimumShortNoticeAppointmentDate.toLocaleString(
          DateTime.DATE_FULL,
        )}`,
      )
      .max(
        maximumAppointmentDate.toISODate(),
        `Viewings cannot be booked after ${maximumAppointmentDate.toLocaleString(
          DateTime.DATE_FULL,
        )}`,
      ),
    bookingTime: Yup.string().required(),
    organisationName: Yup.string().required(
      'Please provide an organisation name',
    ),
    phoneCountryCode: Yup.string().required(),
    phoneNumber: Yup.string()
      .required(
        'Please enter your number in case the provider needs to contact you about your booking',
      )
      .test(
        'has-enough-characters',
        'Please provide a valid phone number',
        (value) => value.replace(/\s+/g, '').length >= 7,
      ),
  }).required();

  const {
    clearErrors,
    formState,
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      bookingDate: minimumNormalNoticeAppointmentDate.toISODate(),
      bookingTime:
        minimumNormalNoticeAppointmentDate.toFormat('HH:mm') ??
        building.viewingsStartTime ??
        '8:30',
      organisationName: user?.organisation?.name ?? '',
      phoneCountryCode: user?.phoneCountryCode ?? '+44',
      phoneNumber: user?.regionalPhoneNumber ?? '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const values = watch();

  const times = useMemo(() => {
    return getTimesForDay(values.bookingDate, {
      buildingCloseTime: building.viewingsEndTime || '18:00',
      buildingOpenTime: building.viewingsStartTime || '8:30',
    });
  }, [
    building.viewingsEndTime,
    building.viewingsStartTime,
    values.bookingDate,
  ]);

  useEffect(() => {
    /**
     * If when changing dates the previously selected time is now disabled, select the first available time
     */
    if (times.find((time) => time.time === values.bookingTime)?.disabled) {
      const firstAvailableTime = times.find((time) => !time.disabled);
      if (firstAvailableTime) {
        setValue('bookingTime', firstAvailableTime.time);
      }
    }
  }, [values.bookingTime, setValue, times]);

  const onSubmit = useCallback(
    async (data) => {
      clearErrors();

      analytics.track(`Viewing Request Form Submitted`);

      if (!isLoggedIn) {
        setIsSignUpModalOpen(true);
        return;
      }

      const date = DateTime.fromJSDate(data.bookingDate).toISODate();

      const viewingDateTime = DateTime.fromISO(`${date}T${data.bookingTime}`, {
        zone: 'Europe/London',
      }).toISO();

      const promises = [
        viewingsApi.createViewing({
          buildingId: building.id,
          isPartTime: access === 'partTime',
          numberOfDesks: pricePlan.capacity,
          pricePlanId: pricePlan.id,
          requestedBy: parentUser ? 'ghost_user' : 'user',
          viewingDatetime: viewingDateTime,
        }),
        updateUser({
          budget: Math.round(pricePlan.price),
          budget_source: 'Viewing Requested',
          company: data.organisationName,
          people: pricePlan.capacity,
          phone_country_code: data.phoneCountryCode,
          regional_phone_number: data.phoneNumber,
          search_city: building.city?.slug,
        }),
      ];

      const [viewingResponse, userResponse] =
        await Promise.allSettled(promises);

      if (userResponse.status === 'rejected') {
        const error = userResponse.reason;
        // Fail without visual feedback and alert the dev team
        logError(error);
      }

      if (viewingResponse.status === 'rejected') {
        const error = viewingResponse.reason;
        logError(error);
        if (error.body?.viewingDatetime) {
          setError('bookingDate', {
            message: error.body.viewingDatetime[0],
          });
        } else {
          if (error instanceof HttpError) {
            setErrorMessage(
              `Something went wrong, and we couldn’t book your viewing. (Error code: ${error.statusCode})`,
            );
          } else if (error instanceof NetworkError) {
            setErrorMessage(
              'We couldn’t book your viewing. Your network may be disconnected, or HubbleHQ may be down.',
            );
          } else {
            setErrorMessage(
              'An error occurred, and we couldn’t book your viewing.',
            );
          }
        }
        return;
      } else {
        let event_type;
        if (access === OfficeAccessOptions.PartTime) {
          event_type = 'viewing_request_pto';
        } else {
          event_type = 'viewing_request_fto';
        }
        getDataLayer().push({
          currency: 'gbp',
          event: 'generate_lead',
          event_type: event_type,
          value: pricePlan.price,
        });

        analytics.track(
          `${access === OfficeAccessOptions.PartTime ? 'PTO Viewing Requested' : 'Viewing Requested'}`,
          {
            city: building.city ? building.city.name : undefined,
            value: Math.round(pricePlan.price),
            ...utmParams,
          },
        );

        router.push({
          pathname: '/booking-complete',
          query: {
            access,
            viewing: viewingResponse.value.body.id,
          },
        });
      }
    },
    [
      access,
      building.city,
      building.id,
      clearErrors,
      isLoggedIn,
      parentUser,
      pricePlan.capacity,
      pricePlan.id,
      pricePlan.price,
      router,
      setError,
      updateUser,
      utmParams,
    ],
  );

  useEffect(() => {
    if (isSignUpSuccessful) {
      // Automatically re-trigger the form submission after the user has signed up
      setIsSignUpSuccessful(false);
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isSignUpSuccessful, onSubmit]);

  useEffect(() => {
    if (formState.isDirty && formInputAnalyticsSent === false) {
      analytics.track(`Viewing Request Form Inputted`);
      setFormInputAnalyticsSent(true);
    }
  }, [formInputAnalyticsSent, formState.isDirty]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['Form-formFields']}>
          <div>
            <Label labelText="Date you want to view" />
            <div data-testid="day-picker">
              <DayPicker
                className={styles['Form-datepicker']}
                classNames={{
                  chevron: styles['Form-chevron'],
                  day: styles['Form-day'],
                  month_caption: styles['Form-monthCaption'],
                  months: styles['Form-months'],
                  nav: styles['Form-nav'],
                  weekday: styles['Form-weekday'],
                }}
                defaultMonth={DateTime.fromJSDate(
                  values.bookingDate
                    ? DateTime.fromISO(values.bookingDate).toJSDate()
                    : new Date(),
                )}
                disabled={[
                  ...holidays.map((holiday) => {
                    return new Date(holiday);
                  }),
                  {
                    after: maximumAppointmentDate.toJSDate(),
                    before: minimumShortNoticeAppointmentDate.toJSDate(),
                  },
                  { dayOfWeek: [0, 6] },
                ]}
                mode="single"
                modifiersClassNames={{
                  disabled: styles['Form-day--disabled'],
                  selected: styles['Form-day--selected'],
                  today: styles['Form-day--today'],
                }}
                onSelect={(date) => {
                  clearErrors('bookingDate');
                  const dateISO = DateTime.fromJSDate(date).toISODate();
                  setValue('bookingDate', dateISO);
                }}
                required
                selected={DateTime.fromISO(values.bookingDate).toJSDate()}
                today={new Date()}
              />
            </div>
            {formState.errors.bookingDate && (
              <ErrorMessage>
                {formState.errors.bookingDate.message}
              </ErrorMessage>
            )}
          </div>
          <div className={styles['Form-formFieldsGroup']}>
            <Select
              data-testid="booking-time"
              labelText="Time"
              {...register('bookingTime')}
            >
              {times.map((time) => (
                <option
                  disabled={time.disabled}
                  key={time.time}
                  value={time.time}
                >
                  {time.time}
                </option>
              ))}
            </Select>

            <VerticalSpacing size="lg" />

            <TextInput
              errorText={formState.errors.organisationName?.message}
              isValidationManaged
              labelText="Organisation Name"
              placeholder="e.g. Wizard of Ops Ltd."
              required
              status={formState.errors?.organisationName?.message && 'error'}
              {...register('organisationName')}
            />

            <VerticalSpacing size="lg" />

            <Label
              htmlFor="viewing-request-phone-number"
              labelText="Phone number"
            >
              <InputGroup>
                <Select
                  data-testid="phone-number-country-code"
                  name="phoneNumberCountryCode"
                  {...register('phoneCountryCode')}
                >
                  {getPinnedCountryCodes().map((country) => (
                    <option
                      data-display-value={country.dial_code}
                      data-testid={`pinned-phone-country-code-${country.dial_code}`}
                      key={`pinned-${country.code}`}
                      value={country.dial_code}
                    >
                      {country.name} {country.dial_code}
                    </option>
                  ))}

                  <optgroup label="All country codes">
                    {getCountryCodes().map((country) => (
                      <option
                        data-display-value={country.dial_code}
                        data-testid={`phone-country-code-${country.dial_code}`}
                        key={country.code}
                        value={country.dial_code}
                      >
                        {country.name} {country.dial_code}
                      </option>
                    ))}
                  </optgroup>
                </Select>

                <TextInput
                  data-testid="phone-number"
                  errorText={formState.errors.phoneNumber?.message}
                  id="viewing-request-phone-number"
                  isValidationManaged
                  pattern="[0-9 ]{7,}" /* numbers only, 7 characters minimum required and spaces becasue humans put space in phone numbers */
                  placeholder="e.g. 07412123456"
                  required
                  status={formState.errors?.phoneNumber?.message && 'error'}
                  title="A valid phone number must be numbers only, with a minimum of 7 digits"
                  type="tel"
                  {...register('phoneNumber')}
                />
              </InputGroup>
            </Label>
          </div>
        </div>
        <VerticalSpacing size="xxl" />
        <Button
          data-testid="button"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          name="Book now"
          onClick={() => {
            if (!formInputAnalyticsSent && !formState.isDirty) {
              analytics.track(`Viewing Request Form Inputted`);
              setFormInputAnalyticsSent(true);
            }
          }}
          styleAtSmall="fullWidth"
          type="submit"
        >
          <TextWithIcon
            contentType="content2"
            iconName="bolt"
            iconPosition="left"
            iconSize="md"
            text="Book now"
          />
        </Button>
        {errorMessage && <ErrorMessage isCentered>{errorMessage}</ErrorMessage>}
      </form>
      {isSignUpModalOpen && (
        <Modal
          id="signup"
          isOpenOnRender
          modalName="Sign up to book a viewing"
          onCloseModal={() => {
            setIsSignUpModalOpen(false);
          }}
          renderTrigger={() => {}}
        >
          {({ closeModal }) => {
            async function onAuthSuccess() {
              await checkUser();

              setIsSignUpSuccessful(true);
              closeModal();
            }
            return (
              <Signup
                initialOrganisation={values.organisationName}
                isSignupByDefault
                layoutStyle="modal"
                onLogin={async () => {
                  await onAuthSuccess();
                }}
                onSignup={async () => {
                  await onAuthSuccess();
                }}
                signupTitle="Sign up to complete your booking"
              >
                <SignupSideContent />
              </Signup>
            );
          }}
        </Modal>
      )}
    </>
  );
}

Form.propTypes = {
  access: PropTypes.oneOf(Object.values(OfficeAccessOptions)).isRequired,
  building: BuildingPropTypes.isRequired,
  pricePlan: PricePlanPropTypes.isRequired,
};
