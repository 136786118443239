import PropTypes from 'prop-types';

import { Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import BuildingTable from './components/BuildingTable';
import formatBuildingTableData from './helpers/formatBuildingTableData';

const noResultsCopy = {
  disregarded: 'Your list of rejected options is empty',
  'hubble-picks':
    'Your list of Hubble picks is empty. Check back soon for updates.',
};

export default function RecommendationList({
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  page,
  recommendationList,
  selectedColIndex = null,
  setRecommendationList,
}) {
  if (!recommendationList.items.length) {
    return (
      <>
        <VerticalSpacing size="xxl" />
        <Paragraph isCentered>
          <b>{noResultsCopy[page]}</b>
        </Paragraph>
      </>
    );
  }

  return (
    <BuildingTable
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selectedColIndex={selectedColIndex}
      {...formatBuildingTableData(recommendationList, setRecommendationList)}
    />
  );
}

RecommendationList.propTypes = {
  onMouseEnter: PropTypes.func,

  onMouseLeave: PropTypes.func,

  page: PropTypes.oneOf(['hubble-picks', 'disregarded']).isRequired,

  recommendationList: PropTypes.shape({
    advisor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        advisorNotes: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        customerNotes: PropTypes.string,
        id: PropTypes.number.isRequired,
        isPartTime: PropTypes.bool,
        likedAt: PropTypes.string,
        pricePlanId: PropTypes.number.isRequired,
        recommendationList: PropTypes.number.isRequired,
        rejectedAt: PropTypes.string,
        status: PropTypes.oneOf([
          'liked',
          'pending',
          'viewing requested',
          'rejected',
        ]),
        updatedAt: PropTypes.string,
        viewingRequestedAt: PropTypes.string,
      }),
    ),
    name: PropTypes.string.isRequired,
    requesterEmail: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,

  selectedColIndex: PropTypes.number,

  setRecommendationList: PropTypes.func.isRequired,
};
