import { css } from '@emotion/css';
import React from 'react';

import { Grid, Section } from 'site-react/components/page';
import { Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import AreaItem from './AreaItem';
import hqLocationContent from './data/hqLocationContent.json';
import passLocationContent from './data/passLocationContent.json';
import LocationItem from './LocationItem';

const borderStyle = css`
  @media (min-width: 992px) {
    border-left: 1px solid var(--color-neutral-100);
    padding-left: var(--space-lg);
  }
`;

export default function LocationMenuContent() {
  return (
    <Section verticalPadding="lg">
      <Grid>
        <Grid.Item colSpan="6">
          <Paragraph isMarginless>
            Top locations with workspaces you can
            <br />
            <b>book by the hour or day</b>
          </Paragraph>
          <VerticalSpacing size="md" />
          <Grid breakpoint="md">
            <Grid.Item colSpan="4">
              <AreaItem href="/coworking/united-kingdom" label="UK">
                {passLocationContent.unitedKingdom.map((location) => (
                  <LocationItem
                    href={location.href}
                    key={location.label}
                    label={location.label}
                  />
                ))}
              </AreaItem>
            </Grid.Item>
            <Grid.Item colSpan="4">
              <AreaItem label="Europe">
                {passLocationContent.europe.map((location) => (
                  <LocationItem
                    href={location.href}
                    key={location.label}
                    label={location.label}
                  />
                ))}
              </AreaItem>
            </Grid.Item>
            <Grid.Item colSpan="4">
              <AreaItem label="Worldwide">
                {passLocationContent.worldwide.map((location) => (
                  <LocationItem
                    href={location.href}
                    key={location.label}
                    label={location.label}
                  />
                ))}
              </AreaItem>
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item colSpan="6">
          <div className={borderStyle}>
            <Paragraph isMarginless>
              Top locations with workspaces you can
              <br />
              <b>rent by the month</b>
            </Paragraph>
            <VerticalSpacing size="md" />
            <Grid breakpoint="md">
              <Grid.Item colSpan="4">
                <AreaItem href="/office-space-united-kingdom" label="Cities">
                  {hqLocationContent.unitedKingdom.map((location) => (
                    <LocationItem
                      href={location.href}
                      key={location.label}
                      label={location.label}
                    />
                  ))}
                </AreaItem>
              </Grid.Item>
              <Grid.Item colSpan="4">
                <AreaItem label="London Regions">
                  {hqLocationContent.londonRegions.map((location) => (
                    <LocationItem
                      href={location.href}
                      key={location.label}
                      label={location.label}
                    />
                  ))}
                </AreaItem>
              </Grid.Item>
              <Grid.Item colSpan="4">
                <AreaItem href="/sitemap/london" label="London Areas">
                  {hqLocationContent.londonAreas.map((location) => (
                    <LocationItem
                      href={location.href}
                      key={location.label}
                      label={location.label}
                    />
                  ))}
                </AreaItem>
              </Grid.Item>
            </Grid>
          </div>
        </Grid.Item>
      </Grid>
    </Section>
  );
}
