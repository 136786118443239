import { ListItemWithIcon } from 'site-react/components/typography';
import formatTimeInYearsMonths from 'site-react/helpers/formatTimeInYearsMonths';

import styles from './OfficeCardStatsList.module.css';

const OfficeStatsList = ({
  sqft,
  maxMonthsTerm,
  minMonthsTerm,
  floor,
  accessHours,
  canLeaveMonitor,
}) => {
  return (
    <ul aria-label="Office Stats" className={styles['office-stats']}>
      {minMonthsTerm ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="date_range"
          text={`Min term: ${formatTimeInYearsMonths(minMonthsTerm)}`}
        />
      ) : null}
      {maxMonthsTerm ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="date_range"
          text={`Max term ${formatTimeInYearsMonths(maxMonthsTerm)}`}
        />
      ) : null}
      {sqft ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="view_array"
          text={`[${sqft} sqft]`}
        />
      ) : null}
      {floor.length ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="business"
          text={`Floor ${floor.join(', ')}`}
        />
      ) : null}
      {accessHours ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="access_time"
          text={`Access: ${accessHours}`}
        />
      ) : null}
      {canLeaveMonitor ? (
        <ListItemWithIcon
          contentType="content2"
          iconName="airplay"
          text="Can leave monitors"
        />
      ) : null}
    </ul>
  );
};

export default OfficeStatsList;
