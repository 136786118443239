import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TextInput } from 'site-react/components/form';
import { ErrorMessage } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './NumberRangeField.module.css';
import useValidateMinMax from '../../validation/useValidateMinMax/useValidateMinMax';

/**
 * Use 'NumberRangeField' to set a minimum and maximum value for a field.
 */
const NumberRangeField = ({
  iconType = null,
  isRequired = false,
  max = null,
  maxLabel = 'Max',
  maxPlaceholder = 'Max',
  min = null,
  minLabel = 'Min',
  minPlaceholder = 'Min',
  requiredErrorMessage = '',
  setMax = () => {},
  setMin = () => {},
  setValid = () => {},
}) => {
  const [isReadyToValidateMax, setIsReadyToValidateMax] = useState(false);
  const [isReadyToValidateMin, setIsReadyToValidateMin] = useState(false);
  const [maxError, setMaxError] = useState();
  const [minError, setMinError] = useState();
  const [requiredError, setRequiredError] = useState();
  const { maxError: maxValidationError, minError: minValidationError } =
    useValidateMinMax({
      setValid,
      valueMax: max ? Number(max) : null,
      valueMin: min ? Number(min) : null,
    });

  useEffect(() => {
    setMaxError('');
    setMinError('');
    if (isReadyToValidateMin || isReadyToValidateMax) {
      if (max || min) {
        setRequiredError(null);
      }
      if (!max && !min) {
        setRequiredError(requiredErrorMessage);
      }
      if (maxValidationError) {
        setMaxError(maxValidationError);
      }
      if (minValidationError) {
        setMinError(minValidationError);
      }
    }
  }, [
    isReadyToValidateMax,
    isReadyToValidateMin,
    max,
    maxValidationError,
    min,
    minValidationError,
    requiredErrorMessage,
  ]);

  return (
    <div className={styles['NumberRangeField-wrapper']}>
      <VerticalSpacing size="md" />
      <div className={styles['NumberRangeField-inputFieldWrapper']}>
        <div className={styles['NumberRangeField-input']}>
          <TextInput
            data-testid="NumberRangeField-Min"
            errorText={minError}
            iconType={iconType}
            inputMode="numeric"
            isValidationManaged
            labelText={minLabel}
            max={max}
            min="1"
            onBlur={() => setIsReadyToValidateMin(true)}
            onChange={(event) => setMin(Number(event.target.value))}
            pattern="[0-9]*"
            placeholder={minPlaceholder}
            status={minError && isReadyToValidateMin ? 'error' : null}
            type="text"
            value={min ? String(min) : ''}
          />
        </div>
        <div className={styles['NumberRangeField-hyphen']}>to</div>
        <div className={styles['NumberRangeField-input']}>
          <TextInput
            data-testid="NumberRangeField-Max"
            errorText={maxError}
            iconType={iconType}
            id="maxSize"
            inputMode="numeric"
            isValidationManaged
            labelText={maxLabel}
            min={min || '0'}
            onBlur={() => setIsReadyToValidateMax(true)}
            onChange={(event) => setMax(Number(event.target.value))}
            pattern="[0-9]*"
            placeholder={maxPlaceholder}
            status={maxError && isReadyToValidateMax ? 'error' : null}
            type="text"
            value={max ? String(max) : ''}
          />
        </div>
      </div>

      {isRequired &&
        (isReadyToValidateMin || isReadyToValidateMax) &&
        requiredError && (
          <>
            <VerticalSpacing size="sm" />
            <ErrorMessage isCentered scrollIntoView>
              {requiredErrorMessage}
            </ErrorMessage>
          </>
        )}
    </div>
  );
};

export default NumberRangeField;

NumberRangeField.propTypes = {
  /**
   * This is a string which corresponds to a Material Icon
   */
  iconType: PropTypes.string,

  /**
   * Can this field be omitted?
   * NOTE: This prop currently makes no visual change to the component. It is very tricky
   * to find a good design for this. You will need to visually represent that this
   * field is required outside of the component/come up with a good way of doing so
   * with Vinnie.
   */
  isRequired: PropTypes.bool,

  /**
   * The initial maximum value to be passed into the field
   */
  max: PropTypes.number,

  /**
   * Label for the Max value. This appears above the input area upon input
   */
  maxLabel: PropTypes.string,

  /**
   * Placeholder for the Max value. This disappears upon input
   */
  maxPlaceholder: PropTypes.string,

  /**
   * The initial minimum value to be passed into the field
   */
  min: PropTypes.number,

  /**
   * Label for the Min value. This appears above the input area upon input
   */
  minLabel: PropTypes.string,

  /**
   * Placeholder for the Min value. This disappears upon input
   */
  minPlaceholder: PropTypes.string,

  /**
   * What to display if the field is required and neither values have been set
   */
  requiredErrorMessage: PropTypes.string,

  /**
   * Function to handle returning maximum value onChange of field max field
   */
  setMax: PropTypes.func,

  /**
   * Function to handle returning minimum value onChange of field max field
   */
  setMin: PropTypes.func,

  /**
   * Callback when the input validitity changes. Called with the new value as
   * the only argument.
   */
  setValid: PropTypes.func,
};
