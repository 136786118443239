import { default as NextLink } from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';

import styles from './OfficeCardHoppyLink.module.css';

const OfficeCardHoppyLink = ({ pricePlanId }) => {
  const router = useRouter();
  const pathname = usePathname();

  const { setPricePlan } = useContext(ViewingRequestContext);

  const { buildingId, slug, option, ...params } = router.query;
  const pricePlanIdUrlSearchParams = new URLSearchParams({
    ...params,
    officeAccess: OfficeAccessOptions.FullTime,
    option: pricePlanId,
  }).toString();

  return (
    <NextLink
      className={styles['OfficeCardHoppyLink']}
      href={{
        hash: 'office-details',
        pathname: pathname,
        query: pricePlanIdUrlSearchParams,
      }}
      onClick={() => setPricePlan(pricePlanId)}
      replace={true}
      scroll={false}
    >
      {pricePlanId}
    </NextLink>
  );
};

export default OfficeCardHoppyLink;
