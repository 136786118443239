import PropTypes from 'prop-types';

import RenderedMarkdownPricePlanContentProptypes from './RenderedMarkdownPricePlanContent.proptypes';

export default PropTypes.exact({
  overview: PropTypes.string,
  overviewRemaining: PropTypes.string,
  overviewSnippet: PropTypes.string,
  pricePlans: PropTypes.arrayOf(RenderedMarkdownPricePlanContentProptypes),
});
