import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'site-react/components/form';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './CheckboxList.module.css';

const CheckboxList = ({
  arrayList,
  checkedList,
  isDisabled = false,
  onChange,
}) => (
  <ul aria-label="Facilities Checkboxes" className={styles.CheckboxList}>
    {arrayList.map((item) => (
      <li key={item.slug}>
        <VerticalSpacing size="md" />
        <Checkbox
          checked={checkedList[item.slug] || checkedList[item.id] || false}
          disabled={isDisabled}
          id={item.id.toString()}
          label={item.name}
          name={item.slug}
          onChange={onChange}
          value={item.id.toString()}
        />
      </li>
    ))}
  </ul>
);

CheckboxList.propTypes = {
  /**
   * Array of Locations that have all the data needed for checkbox
   */
  arrayList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
  ).isRequired,

  /**
   * An object with Locations as keys and true or false as the value
   */
  checkedList: PropTypes.objectOf(PropTypes.bool).isRequired,

  isDisabled: PropTypes.bool,

  /**
   * A function that determines a change in input
   */
  onChange: PropTypes.func.isRequired,
};

export default CheckboxList;
