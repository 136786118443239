import React from 'react';

import { ViewingRequestConsumer } from 'site-react/data/listing/ViewingRequestContext';

import HoppyContainer from './HoppyContainer';

const HoppyContainerWithContext = (props) => (
  <ViewingRequestConsumer>
    {({ pricePlan }) => <HoppyContainer {...props} pricePlan={pricePlan} />}
  </ViewingRequestConsumer>
);

export default HoppyContainerWithContext;
