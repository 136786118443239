import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Markup.module.css';

/**
 * Applies styles to basic HTML elements. Useful for content coming from 3rd-
 * party platforms, like WordPress, or user-defined content, like Markdown.
 *
 * Should be reserved for places where using core components, or applying styles
 * using class names, would be too cumbersome to do otherwise.
 *
 * All props will be passed directly onto the wrapper element, except for:
 * * `className`: will be applied _after_ the default styles - you can use this
 *   to override the standard CSS in this component.
 * * `elementType`: can be used to specify a more semantic element type, such
 *   as `article`.
 */
export default function Markup({
  className,
  elementType: ElementType = 'div',
  ...props
}) {
  return <ElementType className={cn(styles['Markup'], className)} {...props} />;
}

Markup.propTypes = {
  /**
   * The content to show. (NOTE: for raw HTML, you may need to use
   * `dangerouslySetInnerHTML` instead.)
   */
  children: PropTypes.node,

  /**
   * Additional styles to apply.
   */
  className: PropTypes.string,

  /**
   * The element type to use for the wrapper element.
   */
  elementType: PropTypes.string,
};
