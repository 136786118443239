import PropTypes from 'prop-types';
import React from 'react';

import AdvisorPhoto from './AdvisorPhoto';
import styles from './AdvisorPhotos.module.css';
import photos from './photos';

const AdvisorPhotos = ({ advisors, photoSize = 'small' }) => (
  <div className={styles[`AdvisorPhotos--${photoSize}`]}>
    {advisors.map((advisor) => {
      /**
       * The rebranded photo designs have little embellishments on the left
       * and right photos to act as a sort of border. The logic below ensures
       * that we can keep declaring our advisors prop exactly as before but
       * the left and right photos will use the embellished variations.
       *
       */
      if (advisor in photos) {
        return (
          <AdvisorPhoto
            advisorUrl={photos[advisor]}
            key={advisor}
            photoSize={photoSize}
          />
        );
      }
      return '';
    })}
  </div>
);

AdvisorPhotos.propTypes = {
  /**
   * This property is a string array set which is used to look up the advisor photo urls in photos.js
   */
  advisors: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * This property determines whether the size of the photos should be large.
   */
  photoSize: PropTypes.oneOf(['small', 'medium']),
};

export default AdvisorPhotos;
