import { css } from '@emotion/css';

import { UICard } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Asset, VerticalSpacing } from 'site-react/components/utility';

const contentStyle = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-right: var(--space-md);
`;

const imageStyle = css`
  max-width: 100px;
  margin-right: var(--space-md);
`;

const ProductOverviewWrapper = ({ title, description, imageSrc, children }) => {
  return (
    <UICard
      boxShadow="resting"
      style={{
        border: `1px solid var(--color-neutral-50)`,
        padding: `var(--space-md)`,
      }}
    >
      <div className={contentStyle}>
        <div className={imageStyle}>
          <Asset
            alt={`${title} illustration`}
            sizingBehaviour="fill"
            src={imageSrc}
          />
        </div>
        <div>
          <Heading level="3" type="title4">
            {title}
          </Heading>
          <VerticalSpacing size="sm" />
          <Paragraph isMarginless type="content3">
            {description}
          </Paragraph>
        </div>
      </div>
      {children}
    </UICard>
  );
};

export default ProductOverviewWrapper;
