/**
 * Generally speaking these PropTypes have been based from the serializers we use in
 * our backend services.
 */
export { default as AreaPropTypes } from './Area.proptypes.js';
export { default as BuildingPropTypes } from './Building.proptypes.js';
export { default as CoworkingProductPropTypes } from './CoworkingProduct.proptypes.js';
export { default as ElasticsearchBuildingPropTypes } from './ElasticsearchBuilding.proptypes.js';
export { default as HostBuildingPropTypes } from './HostBuilding.proptypes.js';
export { default as MeetingRoomBookingPropTypes } from './MeetingRoomBooking.proptypes.js';
export { default as MeetingRoomProductPropTypes } from './MeetingRoomProduct.proptypes.js';
export { default as PricePlanPropTypes } from './PricePlan.proptypes.js';
export { default as PrivateOfficeProductPropTypes } from './PrivateOfficeProduct.proptypes.js';
export { default as UserPropTypes } from './User.proptypes.js';
