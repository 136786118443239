import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import LeadsApi from 'site-react/api/LeadsApi';
import PdfApi from 'site-react/api/PdfApi';
import { Button, TextInput } from 'site-react/components/form';
import {
  ErrorMessage,
  Paragraph,
  SuccessMessage,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import leadTypes from 'site-react/data/leads/leadTypes.json';
import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';
import { DropdownPricePlan } from 'site-react/features/Listing';
import getDataLayer from 'site-react/helpers/dataLayer';
import logError from 'site-react/helpers/logError';
import usePartTime from 'site-react/hooks/usePartTime';
import usePricePlan from 'site-react/hooks/usePricePlan';
import useUser from 'site-react/hooks/useUser';

import styles from './Form.module.css';
import handleDownloadPDFRequestError from './handleDownloadPDFRequestError';

function getFullTimeLeadValue(price) {
  return Math.round(price);
}

const Form = ({ building, handleAnalytics = () => {} }) => {
  const { calculatePrice } = usePartTime();
  const { pricePlan: selectedPricePlan } = usePricePlan();
  const { user, updateUser } = useUser();

  const { officeAccess } = useContext(ViewingRequestContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('You must set a valid email address')
      .required('You must set a work email address'),
  });

  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setErrorMessage(null);
    setSuccessMessage(null);

    let search_city;
    if (building && building.city) {
      search_city = building.city.slug;
    }
    const people = selectedPricePlan.desksAvailable;

    const leadValue =
      officeAccess === OfficeAccessOptions.PartTime
        ? calculatePrice(selectedPricePlan)
        : getFullTimeLeadValue(selectedPricePlan.price);

    const pdfApi = new PdfApi();
    const leadsApi = new LeadsApi();

    //store the lead in database, create hubspot task, send the pdf to user
    try {
      await leadsApi.createLead({
        budget: leadValue,
        building_id: building.id,
        building_name: building.name,
        company_size: people,
        cta_page_location: 'listing-download-pdf',
        email: data.email,
        event_type:
          officeAccess === OfficeAccessOptions.PartTime
            ? leadTypes.downloadPdfPto
            : leadTypes.downloadPdf,
        host_id: building.hostId,
        priceplan_id: selectedPricePlan.id,
        search_city,
      });
      const payload = {
        listing: {
          id: building.id,
          name: building.name,
          slug: building.slug,
        },
        pricePlan: {
          id: selectedPricePlan.id,
          name: 'private-office',
        },
        userEmail: data.email,
      };
      await pdfApi.createListingPdf(payload);
      setSuccessMessage('Success! It’ll be in your inbox shortly');
    } catch (error) {
      setErrorMessage(handleDownloadPDFRequestError(error));
    }

    const EventTypeConfig = {
      [OfficeAccessOptions.FullTime]: 'download_pdf_fto',
      [OfficeAccessOptions.PartTime]: 'download_pdf_pto',
    };

    getDataLayer().push({
      currency: 'GBP',
      event: 'generate_lead',
      event_type: EventTypeConfig[officeAccess],
      value: leadValue,
    });

    //update user's budget
    if (user) {
      try {
        updateUser({
          budget: leadValue,
          budget_source: 'Download as PDF',
          people,
          search_city,
        });
      } catch (err) {
        logError(err);
      }
    }
    handleAnalytics('onSubmit', leadValue, building, user);
  };

  return (
    <form
      name="Download PDF Request Form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Paragraph>
        Select the office at <strong>{building.name}</strong> you are interested
        in, enter your email address, and we’ll ping a PDF straight to your
        inbox:
      </Paragraph>
      <DropdownPricePlan
        pricePlans={building.pricePlans}
        selectedPricePlan={selectedPricePlan}
      />
      <VerticalSpacing size="lg" />

      <TextInput
        errorText={formState.errors?.email?.message}
        id="inputEmailAddress"
        isValidationManaged
        labelText="Work Email"
        name="email"
        placeholder="e.g. name@workemail.com"
        required
        status={formState.errors?.email?.message && 'error'}
        testId="DownloadPDF-emailInput"
        type="email"
        {...register('email')}
      />
      <VerticalSpacing size="xl" />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      <div className={styles['Form-button']}>
        <Button
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          name="Send me the PDF"
          type="submit"
        >
          Send me the PDF
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pricePlans: PropTypes.arrayOf(
      PropTypes.shape({
        desksAvailable: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    slug: PropTypes.string,
  }).isRequired,

  /**
   * Handling the analytics function.
   */
  handleAnalytics: PropTypes.func,
};

export default Form;
