import { css, cx } from '@emotion/css';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import imgixLoader from 'site-react/helpers/imgixLoader';

const guideItemWrapperStyle = css`
  align-items: center;
  display: flex;
  gap: var(--space-md);
  margin-bottom: var(--space-md);
`;

export default function GuideItem({ href, imageAlt, imagePath, label }) {
  return (
    <li>
      <Link className={cx(guideItemWrapperStyle)} href={href}>
        <Image
          alt={imageAlt}
          height={54}
          loader={imgixLoader}
          src={imagePath}
          width={54}
        />
        <div>
          <Heading type="content2">{label}</Heading>
        </div>
      </Link>
    </li>
  );
}
