import React from 'react';

import { ListItemWithIcon } from 'site-react/components/typography';
import { UnstyledList } from 'site-react/components/utility';

import styles from './SignupSideContent.module.css';

export default function SignupSideContent() {
  return (
    <aside className={styles.SignupSideContent}>
      <UnstyledList className={styles['SignupSideContent-list']}>
        <ListItemWithIcon
          iconColor="neutral-900"
          iconName="check_circle"
          text="Your viewing details will be saved to your Hubble account and emailed to you"
        />
        <ListItemWithIcon
          iconColor="neutral-900"
          iconName="check_circle"
          text="Your viewing will be instantly confirmed and added to your calendar"
        />
        <ListItemWithIcon
          iconColor="neutral-900"
          iconName="check_circle"
          text={
            <p className={styles['SignupSideContent-listItemText']}>
              <strong>Spam-free zone</strong> - we won&apos;t share your contact
              details with third parties, ever
            </p>
          }
        />
      </UnstyledList>
    </aside>
  );
}
