/**
 * Handles different key events depending on the key pressed,
 * allows you to pass in an object with different handlers per key.
 *
 * @example
 * // Example key events object
 * {
 *    Enter: (e) => { console.log(e) },
 *    ArrowUp: () => {},
 *    ArrowDown: () => {}
 *  }
 *
 * @param {} keyEvents - An object of key events to handle
 * @return Function - Function setup to handle a key event
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values Key Values}
 */
export default function keyEventsHandler(keyEvents) {
  return (e) => {
    if (!keyEvents[e.key]) return;
    keyEvents[e.key](e);
  };
}
