import cn from 'classnames';
import React, { useContext } from 'react';

import CloseButton from './../../components/CloseButton';
import styles from './ModalNewTemplateWithSidebar.module.css';
import { ModalNewContext } from '../../context/ModalNewContext';

/**
 * !!!!! IMPORTANT !!!!
 *
 * This template file is to represent a very specific modal template
 * where you have a sidebar that scrolls seperately from a right hand column.
 *
 * # Copy & paste is an excellent option.
 * if you find yourself editing it to add in more variation to how the modal
 * can look then please either create a custom modal view without a template
 * or create a template that we can use in future.
 *
 * # This file is not special
 * This file is to be deleted when the template is no longer used.
 */

export const ModalNewTemplateWithSidebar = ({ children }) => (
  <div className={styles['ModalNewTemplateWithSidebar']}>{children}</div>
);

export const ModalNewTemplateWithSidebarSidebar = ({
  bgColor = null,
  bgGradient = null,
  children,
}) => (
  <div
    className={cn(
      styles['ModalNewTemplateWithSidebar-gridItem'],
      styles['ModalNewTemplateWithSidebar-sidebar'],
    )}
    style={{
      '--Modal-sidebar--bgColor': bgColor ? `var(--color-${bgColor})` : null,
      '--Modal-sidebar-bgGradient': bgGradient
        ? `var(--color-gradient-${bgGradient})`
        : null,
    }}
  >
    <div className={styles['ModalNewTemplateWithSidebar-contentContainer']}>
      {children}
    </div>
  </div>
);

export const ModalNewTemplateWithSidebarContent = ({ children }) => {
  const { closeModal, isLocked } = useContext(ModalNewContext);

  return (
    <div className={styles['ModalNewTemplateWithSidebar-gridItem']}>
      <div className={styles['ModalNewTemplateWithSidebar-contentContainer']}>
        {!isLocked && <CloseButton onClickCallback={closeModal} />}
        {children}
      </div>
    </div>
  );
};
