import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './TextWithIcon.module.css';

const TextWithIcon = ({
  color = null,
  contentType = null,
  iconColor = null,
  iconName,
  iconPosition = 'right',
  iconSize = 'sm',
  labelType = null,
  text,
}) => {
  return (
    <span
      className={cn(styles.TextWithIcon, {
        [styles[`TextWithIcon--${contentType}`]]: contentType && !labelType,
        [styles[`TextWithIcon--${labelType}`]]: labelType && !contentType,
        [styles['TextWithIcon--colorBrandPrimary']]: color === 'brandPrimary',
      })}
    >
      {iconPosition === 'right' ? <span> {text}</span> : null}
      <span>
        <MaterialIcon
          className={cn(styles['TextWithIcon-icon'], {
            [styles['TextWithIcon--iconPositionLeft']]: iconPosition === 'left',
            [styles['TextWithIcon--iconPositionRight']]:
              iconPosition === 'right',
            [styles['TextWithIcon--iconSizeSm']]: iconSize === 'sm',
            [styles['TextWithIcon--iconSizeMd']]: iconSize === 'md',
            [styles['TextWithIcon--iconSizeLg']]: iconSize === 'lg',
            [styles['TextWithIcon--iconSizeXl']]: iconSize === 'xl',
            [styles['TextWithIcon--marginBottom']]:
              contentType === 'content1' || contentType === 'content3',
            [styles['TextWithIcon--iconColor']]: iconColor,
          })}
          iconType={iconName}
          style={{
            '--TextWithIcon-iconColor': `var(--color-${iconColor})`,
          }}
        />
      </span>
      {iconPosition === 'left' ? <span> {text} </span> : null}
    </span>
  );
};

TextWithIcon.propTypes = {
  /**
   * Text color. If used without setting an iconColor prop, this will set
   * the color of the text and icon.
   * See theme/colors.js for available colors
   */
  color: PropTypes.oneOf(['brandPrimary', 'neutral900']),
  /**
   * Typography rules to apply to the content.
   */
  contentType: PropTypes.oneOf([
    'content1',
    'content2',
    'content3',
    'title1',
    'title2',
    'title3',
    'title4',
  ]),
  /**
   * The color of the icon. Does not impact the color of the text.
   */
  iconColor: PropTypes.oneOf(['brandprimary', 'neutral-900', 'success']),
  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconName: PropTypes.string.isRequired,
  /**
   * Will the icon be displayed to the left of the text or the right?
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Size of the icon as found in theme.font.size
   */
  iconSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /**
   * Type of typography defined label
   */
  labelType: PropTypes.oneOf(['label1', 'label2']),
  /**
   * Text content to be displayed alongside the icon.
   */
  text: PropTypes.node.isRequired,
};

export default TextWithIcon;
