import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

const OfficeCardFeaturesList = ({
  additionalCosts,
  featuresIncluded,
  featuresExcluded,
  leavingEquipment,
}) => {
  return (
    <>
      <Paragraph isMarginless>
        Some amenities might not be available with agreements under four days
        per week. Please speak to one of our workspace advisors for more
        details.
      </Paragraph>
      {featuresIncluded ? (
        <>
          <VerticalSpacing size="md" />
          <Heading type="content2">
            <strong>Included</strong>
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: featuresIncluded,
            }}
          />
        </>
      ) : null}

      {featuresExcluded ? (
        <>
          <VerticalSpacing size="md" />
          <Heading type="content2">
            <strong>Not Included</strong>
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: featuresExcluded,
            }}
          />
        </>
      ) : null}

      {additionalCosts ? (
        <>
          <VerticalSpacing size="md" />
          <Heading type="content2">
            <strong>Additional costs</strong>
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: additionalCosts,
            }}
          />
        </>
      ) : null}

      {leavingEquipment ? (
        <>
          <VerticalSpacing size="md" />
          <Heading type="content2">
            <strong>Leaving equipment</strong>
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: leavingEquipment,
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default OfficeCardFeaturesList;
