/**
 * ░░░░░▄▀▀▀▄░░░░░░░░░░░░░░░░░
 * ▄███▀░◐░░░▌░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▐░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐▄▄░░░░░░░░░░░░░░
 * ░░░░▌░░░░▄▀▒▒▀▀▀▀▄░░░░░░░░░
 * ░░░▐░░░░▐▒▒▒▒▒▒▒▒▀▀▄░░░░░░░
 * ░░░▐░░░░▐▄▒▒▒▒▒▒▒▒▒▒▀▄░░░░░
 * ░░░░▀▄░░░░▀▄▒▒▒▒▒▒▒▒▒▒▀▄░░░
 * ░░░░░░▀▄▄▄▄▄█▄▄▄▄▄▄▄▄▄▄▄▀▄░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░▄▄▌▌▄▌▌░░░░░░░░░░░
 *
 * MIGRATION NOTICE
 *
 * We are in the process of re-writing our components using function components
 * and React Hooks, rather than extending Component.
 *
 * We have found that hooks make our components better, and - once you get the
 * hang of them - produce fewer bugs and edge cases than conventional lifecycle
 * methods (like componentDidMount and getDerivedStateFromProps).
 *
 * However, hooks can’t be used from class components. Therefore, we are trying
 * to eliminate class components wherever we can.
 *
 * If you’re changing this file, please consider contributing to this effort by
 * changing this component to be a function that uses hooks, and then removing
 * this notice.
 */

import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from 'site-react/components/page';
import analytics from 'site-react/helpers/Analytics';
import { GlobalEvents, keyEventsHandler } from 'site-react/helpers/Events';

import styles from './ImageCarousel.module.css';
import ImageCarouselExpandButton from './ImageCarouselExpandButton';
import ImageCarouselMoveButton from './ImageCarouselMoveButton';
import ImageCarouselPosition from './ImageCarouselPosition';

/*
 * Throughout this component, accessibility features are implemented to
 * largely match https://www.w3.org/WAI/tutorials/carousels/functionality/
 */

class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    const { startPosition = 0 } = this.props;

    this.state = { currentItemIndex: startPosition };

    this.globalEvents = new GlobalEvents({
      keyup: {
        debounce: false,
        handler: keyEventsHandler({
          ArrowLeft: this.handlePrevious.bind(this),
          ArrowRight: this.handleNext.bind(this),
        }),
        useCapture: true,
      },
    });

    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }

  static getCarouselItems(children, selectedIndex, fullScreen) {
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          isFullScreen: fullScreen,
          prefetch: [selectedIndex + 1, selectedIndex + 2].includes(index),
          selected: selectedIndex === index,
          // Pre-load the next 2 images
        });
      }
    });
  }

  componentDidMount() {
    const { fullScreen = false } = this.props;

    if (fullScreen) {
      this.globalEvents.listen();
    }
  }

  componentDidUpdate() {
    const { fullScreen = false } = this.props;

    if (fullScreen) {
      this.globalEvents.listen();
    } else {
      this.globalEvents.remove();
    }
  }

  componentWillUnmount() {
    this.globalEvents.remove();
  }

  handleNext(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      children = null,
      trackingId,
      trackingLocation = 'carousel',
    } = this.props;
    const { currentItemIndex } = this.state;
    const totalItems = React.Children.count(children);

    if (currentItemIndex < totalItems - 1) {
      this.setState({ currentItemIndex: currentItemIndex + 1 });
    }

    if (currentItemIndex === totalItems - 1) {
      this.setState({ currentItemIndex: 0 });
    }

    analytics.track('Carousel Next Clicked', {
      content_ids: [trackingId],
      currentIndex: currentItemIndex,
      location: trackingLocation,
      nextIndex: currentItemIndex + 1,
    });
  }

  handlePrevious(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      children = null,
      trackingId,
      trackingLocation = 'carousel',
    } = this.props;
    const { currentItemIndex } = this.state;
    const totalItems = React.Children.count(children);

    if (currentItemIndex > 0) {
      this.setState({ currentItemIndex: currentItemIndex - 1 });
    }

    if (currentItemIndex === 0) {
      this.setState({ currentItemIndex: totalItems - 1 });
    }

    analytics.track('Carousel Previous Clicked', {
      content_ids: [trackingId],
      currentIndex: currentItemIndex,
      location: trackingLocation,
      nextIndex: currentItemIndex - 1,
    });
  }

  render() {
    const {
      allowExpansion = false,
      bottomAccessories = null,
      children = null,
      compact = false,
      desktopRatio = '3:2',
      fullScreen = false,
      isRounded = false,
      mobileRatio = '4:3',
      overrideAspectRatio = false,
      showButtonsOnHover = false,
      showPosition = true,
      smallArrowButtons = false,
      topAccessories = null,
      trackingId,
      trackingLocation = 'carousel',
    } = this.props;
    const { currentItemIndex } = this.state;

    const totalItems = React.Children.count(children);
    const singleItem = totalItems <= 1;

    const aspectRatio = {
      '2:1': { height: 1, width: 2 },
      '3:2': { height: 2, width: 3 },
      '4:3': { height: 3, width: 4 },
    };

    return (
      <div
        className={cn({
          [styles['ImageCarousel--fullScreenStyle']]: fullScreen,
        })}
      >
        <div
          className={cn(styles['ImageCarousel--sizerStyle'], {
            [styles['ImageCarousel--showButtonsOnHoverStyle']]:
              showButtonsOnHover,
            [styles['ImageCarousel--sizerCompactStyle']]: compact,
            [styles['ImageCarousel--overrideAspectRatioStyleDesktop']]:
              overrideAspectRatio,
            [styles['ImageCarousel--overrideAspectRatioStyleMobile']]:
              overrideAspectRatio,
            [styles['ImageCarousel--sizerFullScreenStyle']]: fullScreen,
          })}
          style={{
            '--desktop-aspect-ratio-height': aspectRatio[desktopRatio].height,
            '--desktop-aspect-ratio-width': aspectRatio[desktopRatio].width,
            '--mobile-aspect-ratio-height': aspectRatio[mobileRatio].height,
            '--mobile-aspect-ratio-width': aspectRatio[mobileRatio].width,
          }}
        >
          <ul
            className={cn(styles['ImageCarousel--wrapperStyle'], {
              [styles['ImageCarousel--wrapperFullScreenStyle']]: fullScreen,
              [styles['ImageCarousel--wrapperRoundedStyle']]:
                !fullScreen && isRounded && !bottomAccessories,
              [styles[
                'ImageCarousel--wrapperRoundedWithBottomAccessoriesStyle'
              ]]: !fullScreen && isRounded && bottomAccessories,
            })}
          >
            {this.constructor.getCarouselItems(
              children,
              currentItemIndex,
              fullScreen,
            )}
          </ul>

          {showPosition && (
            <ImageCarouselPosition
              current={currentItemIndex + 1}
              max={totalItems}
            />
          )}
          {allowExpansion && (
            <Modal
              centerContent
              id="image-carousel-modal"
              isFullScreen
              modalName="Image Carousel"
              renderTrigger={({ openModal }) => (
                <ImageCarouselExpandButton
                  analyticsMetadata={{
                    content_ids: [trackingId],
                    location: trackingLocation,
                  }}
                  label="Carousel Expand Button Clicked"
                  onClick={openModal}
                />
              )}
            >
              <ImageCarousel
                {...this.props}
                allowExpansion={false}
                fullScreen
                startPosition={currentItemIndex}
              />
            </Modal>
          )}

          <ImageCarouselMoveButton
            direction="previous"
            disabled={singleItem}
            isExpandedView={fullScreen}
            onClick={this.handlePrevious}
            smallArrowButtons={smallArrowButtons}
          />
          <ImageCarouselMoveButton
            direction="next"
            disabled={singleItem}
            isExpandedView={fullScreen}
            onClick={this.handleNext}
            smallArrowButtons={smallArrowButtons}
          />
          {topAccessories && !fullScreen && (
            <div className={styles['ImageCarousel--topAccessoriesStyle']}>
              {topAccessories}
            </div>
          )}
        </div>
        {bottomAccessories && !fullScreen && (
          <div
            className={cn(styles['ImageCarousel--bottomAccessoriesStyle'], {
              [styles['ImageCarousel--bottomAccessoriesRoundedStyle']]:
                isRounded,
            })}
          >
            {bottomAccessories}
          </div>
        )}
      </div>
    );
  }
}

ImageCarousel.propTypes = {
  /**
   * Allow this carousel to expand into a full-screen modal?
   */
  allowExpansion: PropTypes.bool,

  /**
   * When set, runs a banner underneath the image carousel containing actions
   */
  bottomAccessories: PropTypes.node,

  /**
   * Items to show in the carousel. Should be `CarouselItem`s.
   *
   * Must be list items (<li />).
   */
  children: PropTypes.node,

  /**
   * Compact style variant
   */
  compact: PropTypes.bool,

  /**
   * Overridden aspect ratio value for desktop breakpoint.
   */
  desktopRatioRatio: PropTypes.oneOf(['2:1', '3:2', '4:3']),

  /**
   * Full-screen variant. Enabling full-screen activates arrow key navigation
   */
  fullScreen: PropTypes.bool,

  /**
   * Bool that applies rounded border-radius if true.
   */
  isRounded: PropTypes.bool,

  /**
   * Overridden aspect ratio value for mobile breakpoint.
   */
  mobileRatio: PropTypes.oneOf(['2:1', '3:2', '4:3']),

  /**
   * Bool that overrides default aspect ratio if true.
   */
  overrideAspectRatio: PropTypes.bool,

  /**
   * Wether to only show the arrow buttons on hover.
   */
  showButtonsOnHover: PropTypes.bool,

  /**
   * Wether to show the Carousel position.
   */
  showPosition: PropTypes.bool,

  /**
   * Small arrow buttons variant
   */
  smallArrowButtons: PropTypes.bool,

  /**
   * The slide number to open with
   */
  startPosition: PropTypes.number,

  /**
   * When set, runs a banner along the top of the image carousel containing actions
   */
  topAccessories: PropTypes.node,

  /**
   * The ID that should be associated with this carousel. Used for analytics.
   *
   * Suggested value: the current listing or office space ID.
   */
  trackingId: PropTypes.number.isRequired,

  /**
   * The location that should be associated with this carousel.
   * Used for analytics.
   *
   * Suggested value: name of the current page.
   */
  trackingLocation: PropTypes.string,
};

export default ImageCarousel;
