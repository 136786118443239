import { TextWithIcon } from 'site-react/components/typography';

import HqListingPriceGuaranteeModalContent from '../HqListingPriceGuaranteeModalContent';
import HqListingUspCard from '../HqListingUspCard';

const HqListingPriceGuaranteeUspCard = () => {
  return (
    <HqListingUspCard
      description="We don’t pass on any costs to you, and can negotiate an average discount of 15%"
      modalContent={<HqListingPriceGuaranteeModalContent />}
      title={
        <TextWithIcon
          color="neutral900"
          contentType="content1"
          iconName="workspace_premium"
          iconPosition="left"
          iconSize="lg"
          text={
            <b>
              <u>Best price guarantee</u>
            </b>
          }
        />
      }
    />
  );
};

export default HqListingPriceGuaranteeUspCard;
