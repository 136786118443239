import Cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { AlertContext } from 'site-react/data/core/AlertContext';
import isHubbleUser from 'site-react/helpers/isHubbleUser';
import logError from 'site-react/helpers/logError';
import useUser from 'site-react/hooks/useUser';

import pollCookieValue from './helpers/pollCookieValue';

const AdvisorContext = createContext();

const spaciousApi = new SpaciousApi();

const cookieName = 'hbl_active_recommendation_list';

export default function AdvisorContextProvider({ children }) {
  const { isUserLoading, user } = useUser();
  const [recommendationList, setRecommendationList] = useState(null);

  const { addError } = useContext(AlertContext);

  const initialRender = useRef(true);

  const shouldRemoveCookie = !isUserLoading && !isHubbleUser(user);

  function removeCookie() {
    Cookies.remove(cookieName);
  }

  function setCookie(id) {
    if (isNaN(parseInt(id))) {
      throw new Error(`Invalid recommendation list id value ${id}`);
    }

    Cookies.set(cookieName, id, {
      expires: 1,
    });
  }

  const fetchRecommendationList = useCallback(
    async (id) => {
      try {
        const response = await spaciousApi.getAdminRecommendationList(id);
        setRecommendationList(response.body);
      } catch (error) {
        logError(error);
        addError('Something went wrong');
        setRecommendationList(null);
      }
    },
    [addError, setRecommendationList],
  );

  async function refreshRecommendationList() {
    if (recommendationList) {
      await fetchRecommendationList(recommendationList.id);
    }
  }

  useEffect(() => {
    /**
     * Effect to handle fetching the recommendation list on initial render
     */
    if (initialRender.current) {
      if (shouldRemoveCookie) {
        removeCookie();
        return;
      }

      const cookie = Cookies.get(cookieName);

      if (cookie) {
        fetchRecommendationList(cookie);
      }

      initialRender.current = false;
    }
  }, [fetchRecommendationList, shouldRemoveCookie, user]);

  useEffect(() => {
    /**
     * Effect to handle nullifying the recommendation list if the user.admin status changes
     */
    if (shouldRemoveCookie) {
      removeCookie();
      setRecommendationList(null);
    }
  }, [isUserLoading, shouldRemoveCookie, user]);

  useEffect(() => {
    /**
     * Effect to handle fetching the recommendation list when the cookie changes
     */
    if (shouldRemoveCookie) {
      removeCookie();
      return;
    }

    function handleCookieChange(newCookieValue) {
      if (newCookieValue) {
        fetchRecommendationList(newCookieValue);
      } else {
        setRecommendationList(null);
      }
    }

    const stopPolling = pollCookieValue(cookieName, handleCookieChange);

    return () => stopPolling();
  }, [addError, fetchRecommendationList, shouldRemoveCookie, user]);

  function enterBuildMode(id) {
    // Clean up any existing cookies and recommendation list before setting a new one
    removeCookie();
    setRecommendationList(null);

    setCookie(id);
  }

  function exitBuildMode() {
    removeCookie();
    setRecommendationList(null);
  }

  const value = {
    enterBuildMode,
    exitBuildMode,
    recommendationList,
    refreshRecommendationList,
  };

  return (
    <AdvisorContext.Provider value={value}>{children}</AdvisorContext.Provider>
  );
}

export { AdvisorContext };
