import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorMessage } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './TextArea.module.css';
import Label from '../Label';

/**
 * `TextArea` allows users to enter a sizeable amount of free-form text.
 *
 */
const TextArea = React.forwardRef(
  (
    {
      errorText = null,
      height = null,
      id = null,
      labelText = '',
      placeholder = '',
      status = null,
      width = null,
      ...otherInputProps
    },
    ref,
  ) => {
    const TextArea = (
      <textarea
        className={cn(styles.TextArea, {
          [styles['TextArea--invalid']]: status === 'error',
        })}
        id={id}
        placeholder={placeholder}
        ref={ref}
        style={{
          '--TextArea--height': height ? `${height}px` : 'auto',
          '--TextArea--maxHeight': height
            ? `${height * 2}px`
            : `calc(var(--space-base) * 40)`,
          '--TextArea--maxWidth': width ? `${width}px` : 'none',
        }}
        {...otherInputProps}
      />
    );

    return (
      <>
        {labelText && (
          <Label htmlFor={id} labelText={labelText}>
            {TextArea}
          </Label>
        )}
        {!labelText && TextArea}
        {status === 'error' && errorText !== null && (
          <>
            <VerticalSpacing size="sm" />
            <ErrorMessage isMarginless scrollIntoView>
              {errorText}
            </ErrorMessage>
          </>
        )}
      </>
    );
  },
);

TextArea.propTypes = {
  /**
   * Error text for the `TextArea`. Only displays if status prop set to "error".
   */
  errorText: PropTypes.string,

  /**
   * Height of the <textarea /> in pixels.
   */
  height: PropTypes.number,

  /**
   * ID of the <textarea />. Aids with accessibility of the label.
   */
  id: PropTypes.string,

  /**
   * Label text for the `TextArea`.
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Placeholder text for the <textarea />.
   */
  placeholder: PropTypes.string,

  /**
   * Used to dynamically display error messages.
   */
  status: PropTypes.oneOf(['error', 'success']),

  /**
   * Width of the <textarea /> in pixels.
   */
  width: PropTypes.number,
};

export default TextArea;
