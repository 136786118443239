import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Curve, Section } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import analytics from 'site-react/helpers/Analytics';

import PageLayout from '../layouts/PageLayout';

// For more information about this file, see
// https://nextjs.org/docs/#custom-error-handling

const Error = ({ statusCode = null }) => {
  const title = Error.getErrorTitle(statusCode);
  const message = Error.getErrorMessage(statusCode);

  useEffect(() => {
    analytics.setPageProperties({
      page_category: 'static',
      page_type: `${statusCode ?? 'unknown'}-error`,
    });

    // NOTE: using analytics.track here, rather than pageTrack, because we want
    // to see this event in Pendo.
    //
    // For pages, we normally set up Pendo tracking by URL, not pageTrack event,
    // because pageTrack doesn't get sent to Pendo. But since an error page URL
    // is inherently unknown for tracking purposes, that approach doesn't work
    // here. By using a track() call, this event will appear in Pendo under its
    // "Track Events" category.
    analytics.track(
      `${statusCode ?? 'Unknown'} error occurred`,
      {},
      { sendPageProperties: true },
    );
  }, [statusCode]);

  return (
    <PageLayout>
      <Section verticalPadding="xxxl">
        <Heading isCentered level="1" type="hero2">
          {title}
        </Heading>
        <VerticalSpacing size="sm" />
        <Paragraph isCentered type="content1">
          {message}
        </Paragraph>
      </Section>
      <Curve bottomColor="neutral-900" topColor="transparent" type="wave" />
    </PageLayout>
  );
};

/**
░░░░░░░WKkxdx0N░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░WN0xdoc:oK░░░░░░░░░░░░░░░░░░░░░░░░
░░WX0O00Oxxkxc;dN░░░░░░░░░░░░░░░░░░░░░░░
░░Xxdox00kxdoc;oN░░░░░░░░░░░░░░░░░░░░░░░
░░Nkok0Kklcc:;:0░░░░░░░░░░░░░░░░░░░░░░░░
░░░WNNOxddoc;lKW░░░░░░░░░░░░░░░░░░░░░░░░
░░░░NOkkdoc;,okkxddddddxOKXW░░WNNNNW░░░░
░░░W0xxxdl::;;;;::::;;;;;;cok00OO000X░░░
░░░WOdddolcc::c:llolc:,;,,;;,,;codkk0W░░
░░░░Kolllccc:cc:cldxxc,,;;;:;,'.;odkK░░░
░░░░W0l:::clc:cclkOdl;';:::::;'.'ox0W░░░
░░░░░░Xkl:ldl;;cokOkdc,;;::::;,'':ON░░░░
░░░░░░░░Nkdodl::dkOO0kc,,,;;;;;,.:K░░░░░
░░░░░░░░░WXkoll::clodo:'''',;;;:oKW░░░░░
░░░░░░░░░░░WN0kolcc:::::;,:lok0XW░░░░░░░
░░░░░░░░░░░░░░W0lcoollllcxKNW░░░░░░░░░░░
░░░░░░░░░░░░░░░Nd;lk0xlloK░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░Ndl0WW0ldKW░░░░░░░░░░░░░░
░░░░░░░░░N0O0KKkcdNNXdlK░░░░░░░░░░░░░░░░
░░░░░░░░W0dlclxkxkkdlcoKN░░░░░░░░░░░░░░░
░░░░░░░░░░NOOK0xddoccok0XW░░░░░░░░░░░░░░
░░░░░░░░░░░░░Nkxxod0XN░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░NXWKkX░░░░░░░░░░░░░░░░░░░░░
 *
 * DEPRECATION NOTICE
 *
 * `getInitialProps` is now officially "legacy behaviour" according to Next:
 * https://nextjs.org/docs/pages/api-reference/functions/get-initial-props
 *
 * Depending on the use case this should be refactored to either `getServerSideProps` or
 * `getStaticProps`:
 * - https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props
 * - https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-props
 *
 * If you are working in this file this needs to be refactored.
 */
Error.getInitialProps = async (contextData) => {
  // NOTE: this code is taken straight from Sentry, and _currently_ requires
  // that we continue to use getInitialProps.
  // See: https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#react-render-errors-in-pages-router
  await Sentry.captureUnderscoreErrorException(contextData);

  const { res, err } = contextData;

  const statusCode = (res && res.statusCode) || (err && err.statusCode) || null;
  return { statusCode };
};

Error.getErrorMessage = (statusCode) => {
  // If we don't have a status code, it means there was a runtime error
  // in the app.
  const errorMessages = {
    404: 'Sorry, we couldn’t find that page.',
    500: 'An internal error occurred.',
  };

  return (
    (statusCode && errorMessages[`${statusCode}`]) ||
    'We were unable to complete your request.'
  );
};

Error.getErrorTitle = (statusCode) => {
  // If we don't have a status code, it means there was a runtime error
  // in the app.
  return statusCode ? `${statusCode} Error` : 'An error occurred';
};

Error.propTypes = {
  statusCode: PropTypes.number,
};

export default Error;
