import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './InfoPanel.module.css';

const InfoPanel = ({
  children = null,
  styleType = 'brandsecondary',
  ...props
}) => {
  if (props.hasOwnProperty('className')) {
    throw new Error(
      'You should not override or extend the built in styling of this component. If you need something more bespoke you should implement it as a bespoke component.',
    );
  }

  return (
    <aside
      className={cn(styles['InfoPanel'])}
      style={{
        '--InfoPanel-background-color': `rgba(var(--color-${styleType}-rgb) / 10%)`,
        '--InfoPanel-border': `var(--color-${styleType}) solid 2px`,
      }}
      {...props}
    >
      {children}
    </aside>
  );
};

InfoPanel.propTypes = {
  /**
   * Children to be rendered within the aside
   */
  children: PropTypes.node,

  /**
   * What color the panel should be. The colors we use must be rgb values.
   */
  styleType: PropTypes.oneOf(['brandprimary', 'brandsecondary', 'error']),
};

export default InfoPanel;
