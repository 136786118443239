import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

// If I import the whole of utilities there a circular import and it explodes. Boom.
// eslint-disable-next-line no-restricted-imports
import UnstyledList from 'site-react/components/utility/UnstyledList';
import JsonLD from 'site-react/helpers/jsonLD';

import styles from './Breadcrumbs.module.css';

/**
 * generates the <script> element for json-ld breadcrumb schema
 * @param  {Array} links derived from this.props.links
 * @return {Object} breadcrumbSchemaData
 */
const generateSchema = (links) => {
  const schemaLinkArray = [];
  links.forEach((link, index) => {
    const linkInfo = {
      '@type': 'ListItem',
      item: {
        '@id': link.url,
        name: link.label,
      },
      position: index + 1,
    };
    schemaLinkArray.push(linkInfo);
  });

  const breadcrumbSchemaData = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: schemaLinkArray,
  };

  return breadcrumbSchemaData;
};

const Breadcrumbs = ({ links, onLinkClicked = () => {} }) => (
  <nav aria-label="breadcrumb">
    <JsonLD data={generateSchema(links)} />
    <UnstyledList className={styles['Breadcrumbs-list']} type="ol">
      {links.map((link, index) => (
        <li className={styles['Breadcrumbs-breadcrumb']} key={link.url}>
          <NextLink
            aria-current={links.length === index + 1 ? 'page' : null}
            className={styles['Breadcrumbs-link']}
            href={link.url}
            onClick={() => {
              onLinkClicked(link, index);
            }}
          >
            {link.label}
          </NextLink>
        </li>
      ))}
    </UnstyledList>
  </nav>
);

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,

  onLinkClicked: PropTypes.func,
};

export default Breadcrumbs;
