import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  Fieldset,
  TextInput,
} from 'site-react/components/form';

import styles from './Filters.module.css';
import CustomCheckbox from '../CustomCheckbox';

const days = [
  { display: 'MO', label: 'Mon', name: 'monday' },
  { display: 'TU', label: 'Tue', name: 'tuesday' },
  { display: 'WE', label: 'Wed', name: 'wednesday' },
  { display: 'TH', label: 'Thu', name: 'thursday' },
  { display: 'FR', label: 'Fri', name: 'friday' },
];

export default function Filters({
  enableOccupancy = true,
  onChange = () => {},
  onSubmit = () => {},
  searchState,
}) {
  return (
    <form
      className={styles.Filters}
      noValidate
      onSubmit={(event) => {
        event.preventDefault();

        onSubmit();
      }}
    >
      <div className={styles['Filters--minSizeWrapper']}>
        <TextInput
          aria-label="Min Size"
          iconType="person"
          inputMode="numeric"
          labelText="Min Size"
          onChange={(event) => {
            onChange({ peopleMin: event.target.value });
          }}
          pattern="[0-9]*"
          placeholder="e.g. 10"
          type="text"
          value={searchState.peopleMin}
        />
      </div>
      {enableOccupancy && (
        <Fieldset legend="Occupancy" name="occupancy">
          <div className={styles['Filters--occupancy']}>
            <div className={styles['Filters--checkboxWrapper']}>
              <Checkbox
                checked={searchState.access.includes('fullTime')}
                data-testid="full-time"
                id="full-time"
                label={
                  <b className={styles['Filters--label']}>Full&#8209;time</b>
                }
                name="Full-time"
                onChange={(event) => {
                  if (event.target.checked) {
                    onChange({
                      access: [...searchState.access, 'fullTime'],
                    });
                  } else {
                    onChange({
                      access: searchState.access.filter(
                        (access) => access !== 'fullTime',
                      ),
                    });
                  }
                }}
              />
            </div>
            <div
              className={[
                styles['Filters--checkboxWrapper'],
                styles['Filters--checkboxWrapperPartTime'],
              ].join(' ')}
            >
              <Checkbox
                checked={searchState.access.includes('partTime')}
                data-testid="part-time"
                id="part-time"
                label={
                  <span className={styles['Filters--label']}>
                    <b>Part&#8209;time</b> choose 1-3 days
                  </span>
                }
                name="Part-time"
                onChange={(event) => {
                  if (event.target.checked) {
                    onChange({
                      access: [...searchState.access, 'partTime'],
                    });
                  } else {
                    onChange({
                      access: searchState.access.filter(
                        (access) => access !== 'partTime',
                      ),
                    });
                  }
                }}
              />
              <div className={styles['Filters--dates']}>
                {days.map((day) => {
                  return (
                    <CustomCheckbox
                      checked={searchState.days.includes(day.name)}
                      content={day.display}
                      disabled={
                        !searchState.access.includes('partTime') ||
                        (searchState.days.length >= 3 &&
                          !searchState.days.includes(day.name))
                      }
                      id={day.name}
                      key={day.name}
                      name={day.name}
                      onChange={(event) => {
                        if (
                          searchState.days.length === 1 &&
                          !event.target.checked
                        ) {
                          // Don't allow the last day to be unchecked
                          return;
                        }
                        if (event.target.checked) {
                          onChange({
                            days: [...searchState.days, day.name],
                          });
                        } else {
                          onChange({
                            days: searchState.days.filter(
                              (selectedDay) => selectedDay !== day.name,
                            ),
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fieldset>
      )}
      <div className="u-displayBelowMd">
        <Button
          disabled={!searchState.access.length}
          isBlock
          name="Apply"
          type="submit"
        >
          Apply filters
        </Button>
      </div>
    </form>
  );
}

Filters.propTypes = {
  /**
   * Show or hide the occupancy filters
   */
  enableOccupancy: PropTypes.bool,
  /**
   * Function to call when the form is changed.
   */
  onChange: PropTypes.func,

  /**
   * Function to call when the form is submitted.
   */
  onSubmit: PropTypes.func,

  /**
   * Object containing the current state of the form.
   */
  searchState: PropTypes.shape({
    access: PropTypes.arrayOf(PropTypes.string).isRequired,
    days: PropTypes.arrayOf(PropTypes.string).isRequired,
    peopleMin: PropTypes.string,
  }).isRequired,
};
