import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { HubbleLogo } from 'site-react/components/page';
import analytics from 'site-react/helpers/Analytics';

import HubbleLogoText from './logo';
import styles from './NavigationLogo.module.css';

const navigationLogoClicked = () => {
  analytics.track('Navigation Logo Clicked');
};

const NavigationLogo = ({ isLogoTextDisplayed = false }) => (
  <NextLink
    className={styles.NavigationLogo}
    href="/"
    onClick={navigationLogoClicked}
  >
    {isLogoTextDisplayed ? (
      <>
        <div className={styles['NavigationLogo--hideOnMobile']}>
          <HubbleLogoText
            className={styles['NavigationLogo-logo']}
            height="48"
          />
        </div>
        <div className={styles['NavigationLogo--hideOnDesktop']}>
          <HubbleLogo
            className={styles['NavigationLogo-logo']}
            color="currentColor"
            isLink={false}
            size={48}
          />
        </div>
      </>
    ) : (
      <HubbleLogo color="currentColor" isLink={false} size={48} />
    )}
  </NextLink>
);

NavigationLogo.propTypes = {
  isLogoTextDisplayed: PropTypes.bool,
};

export default NavigationLogo;
