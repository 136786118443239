import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './Checkbox.module.css';

export const labelAlignOptions = {
  center: 'center',
  start: 'start',
};

const Checkbox = React.forwardRef(
  (
    {
      checked,
      defaultChecked,
      id,
      isLarge,
      labelAlign = labelAlignOptions.center,
      label,
      labelVisuallyHidden,
      name,
      onChange,
      value,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(styles['Checkbox-wrapper'], {
        [styles['Checkbox--disabled']]: props?.disabled,
        [styles['Checkbox--alignStart']]:
          labelAlign === labelAlignOptions.start,
      })}
    >
      <div className={styles['Checkbox']}>
        <input
          checked={checked}
          className={cn(styles['Checkbox-input'], {
            [styles['Checkbox-input--lg']]: isLarge,
          })}
          defaultChecked={defaultChecked}
          id={id}
          name={name}
          onChange={onChange}
          ref={ref}
          type="checkbox"
          value={value}
          {...props}
        />
        <MaterialIcon
          className={cn(styles['Checkbox-icon'], {
            [styles['Checkbox-icon--lg']]: isLarge,
          })}
          iconType="done"
        />
      </div>
      <label
        className={
          labelVisuallyHidden
            ? styles['Checkbox-label--hidden']
            : styles['Checkbox-label']
        }
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  ),
);

Checkbox.propTypes = {
  /**
   * Is the checkbox checked? (controlled component)
   */
  checked: PropTypes.bool,

  /**
   * Does the checkbox have a defualt value (uncontrolled component)
   */
  defaultChecked: PropTypes.bool,

  /**
   * Checkbox id
   */
  id: PropTypes.string.isRequired,

  /**
   * Extra large checkboxes for use in mobile designs
   */
  isLarge: PropTypes.bool,

  /**
   * Label of checkbox
   */
  label: PropTypes.node.isRequired,

  /**
   * How should the label contents align with the checkbox?
   */
  labelAlign: PropTypes.oneOf(Object.values(labelAlignOptions)),

  /**
   * To display/ style the label separately
   * on a visual basis but still have a label for a11y
   */
  labelVisuallyHidden: PropTypes.bool,

  /**
   * Name of checkbox
   */
  name: PropTypes.string.isRequired,

  /**
   * Function to emit changes to a parent component.
   *
   * Users of this component must implement an onChange handler.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Value of checkbox, only required for controlled components
   */
  value: PropTypes.string,
};

export default Checkbox;
