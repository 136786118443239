import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Section } from 'site-react/components/page';
import { Spinner } from 'site-react/components/utility';
import isHubbleUser from 'site-react/helpers/isHubbleUser';
import useUser from 'site-react/hooks/useUser';

import Error from '../../../pages/_error';

/**
 * There are certain pages where we only want to reveal if the user is logged on.
 * Otherwise, redirect the user to the login page
 *
 * The adminOnly flag will only allow an admin to see the page. HOWEVER this is false security
 * and if there is information that we really need to protect or keep secret then any API calls made need
 * to have the same admin only restriction on the endpoint. Works fine to hide a page for a bit though.
 */
const LoginRequired = ({
  adminOnly = false,
  children,
  content,
  isSignUp = false,
  postLoginRedirectUrl = '',
  referralSource = null,
}) => {
  const { replace } = useRouter();
  const { isLoggedIn, isUserLoading, user } = useUser();

  useEffect(() => {
    // If the user's not logged in, and we're done loading, redirect to login
    if (!isLoggedIn && !isUserLoading) {
      const params = new URLSearchParams();

      if (postLoginRedirectUrl) {
        params.set('next', postLoginRedirectUrl);
      }

      if (referralSource) {
        params.set('referralSource', referralSource);
      }

      if (content) {
        params.set('content', content);
      }

      let newPath = isSignUp ? '/signup' : '/login';

      if (params.toString().length > 0) {
        newPath += `?${params.toString()}`;
      }

      replace(newPath);
    }
  }, [
    content,
    isLoggedIn,
    isSignUp,
    isUserLoading,
    postLoginRedirectUrl,
    referralSource,
    replace,
  ]);

  if (!isUserLoading && adminOnly && (!isLoggedIn || !isHubbleUser(user))) {
    return <Error statusCode={404} />;
  }

  return isLoggedIn ? (
    children
  ) : (
    <Section verticalPadding="xl">
      <Spinner />
    </Section>
  );
};

LoginRequired.propTypes = {
  /**
   * Only allows a logged in user see children.
   */
  adminOnly: PropTypes.bool,

  /**
   * Child components
   */
  children: PropTypes.node.isRequired,

  /**
   * What custom content, if any, should be shown alongside the signup form?
   */
  content: PropTypes.oneOf(['hybrid', 'office-first', 'remote-first']),

  /**
   * When the user isn't logged in, use the signup page instead of login
   */
  isSignUp: PropTypes.bool,

  /**
   * URL link to redirect user after they log in
   */
  postLoginRedirectUrl: PropTypes.string,

  /**
   * The intake source of this user (e.g. pass)
   */
  referralSource: PropTypes.string,
};

export default LoginRequired;
