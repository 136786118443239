import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button } from 'site-react/components/form';
import { Link, OutboundLink } from 'site-react/components/navigation';

import styles from './CopyLink.module.css';

const CopyLink = ({
  copyLinkButtonStyleImportance = 'secondary',
  linkStyle = 'primary',
  linkType = 'displayOnly',
  url,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className={styles.CopyLink}>
      <div className={styles['CopyLink-link']}>
        {linkType === 'internalLink' && (
          <Link href={url} styleType={linkStyle}>
            {url}
          </Link>
        )}
        {linkType === 'outboundLink' && (
          <OutboundLink href={url} target="_blank">
            {url}
          </OutboundLink>
        )}
        {linkType === 'displayOnly' && <span>{url}</span>}
      </div>

      <div className={styles['CopyLink-button']}>
        <Button
          disabled={isCopied}
          isCompact
          name={isCopied ? 'Copied!' : 'Copy Link'}
          onClick={() => {
            navigator.clipboard.writeText(url);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
          }}
          styleAtSmall="auto"
          styleType={copyLinkButtonStyleImportance}
          type="button"
        >
          {isCopied ? 'Copied!' : 'Copy link'}
        </Button>
      </div>
    </div>
  );
};

CopyLink.propTypes = {
  /**
   * Style of the link to render
   */
  linkStyle: PropTypes.oneOf(['primary', 'secondary']),

  /**
   * What kind of link is this and, therefore, what kind of behaviour should be applied
   * when accessing it?
   *
   * displayOnly - This is not a link and won't behave as such. Instead, we are just using
   * the URL as a decorative display.
   *
   * internalLink - Is the link for a page that exists in the current app? Clicking
   * an app link won't reload the page; instead, it will simply download the
   * new scripts that are needed to load the next page.
   *
   * App links are much faster than regular links, and should be used any time
   * we're linking to a page within the current app.
   *
   * outboundLink - An outbound link, ie. one that leads to a domain that isn't hosted in this project.
   * Adds link tracking, with a slight delay to increase the likelihood of our
   * tracking events reaching Amplitude/Google Analytics/Segment.
   */
  linkType: PropTypes.oneOf(['displayOnly', 'internalLink', 'outboundLink']),

  /**
   * The URL we are copying in the component.
   */
  url: PropTypes.string.isRequired,
};

export default CopyLink;
