/**
 * Get the dataLayer object from the window object. Returns `null` if the
 * data layer is not available—for example, on the server side.
 *
 * Calling `getDataLayer()` returns the data layer object, which can be used
 * in place of the `window.dataLayer` object that Google references in their
 * GTM or Google Analytics documentation.
 *
 * NOTE: the data layer is actually called `hubbleDataLayer` for now, to avoid
 * any conflict with the old `dataLayer` that Segment uses. The `hubbleDataLayer`
 * is only for our newer analytics stack.
 *
 * @returns {Array|null} the dataLayer object or `null`
 */
export default function getDataLayer() {
  if (typeof window !== 'undefined' && window.hubbleDataLayer) {
    return window.hubbleDataLayer;
  }
  return null;
}

/**
 * The gtag function, which can be used to initiate some GTM/Google
 * Analytics-related configuration, like consent updates or config changes.
 *
 * If the data layer is not available, this function will act as a no-op.
 *
 * @param  {...any} args arguments to pass into the on-page gtag function
 */
export function gtag(/* NOTE: this function accepts any number of arguments, despite what this signature suggests! */) {
  const dataLayer = getDataLayer();

  if (dataLayer) {
    // NOTE: we're using `arguments` here, to pass through all arguments given
    // to this function. Normally, we would use the spread (`...`) operator, but
    // GTM wants the `arguments` object, which has a specific type that GTM
    // can detect to switch into a type of `gtag` mode. That's what makes this
    // function different from a regular `dataLayer.push()` call.
    dataLayer.push(arguments);
  }
}
