import React from 'react';

import { Link } from 'site-react/components/navigation';
import { Paragraph, RuledHeading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getListingPageUrl from 'site-react/helpers/getListingPageUrl';

const HqProductOffering = ({ buildingId, buildingSlug }) => {
  const hqListingLink = getListingPageUrl({
    buildingId: buildingId,
    buildingSlug: buildingSlug,
  });

  return (
    <>
      <RuledHeading level="2">Looking for a new office?</RuledHeading>
      <VerticalSpacing size="md" />
      <Paragraph>
        This building has a range of full-time and part-time offices available,
        which could be perfect as the next home for your business.
      </Paragraph>
      <Link href={hqListingLink}> View offices in this building</Link>
    </>
  );
};

export default HqProductOffering;
