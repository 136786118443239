import { Heading, Price } from 'site-react/components/typography';

import styles from './OfficeCardTotalCost.module.css';

const OfficeCardTotalCost = ({ totalPrice }) => {
  return (
    <span className={styles.OfficeCardTotalCost}>
      <Heading type="title3">
        <b>
          <Price amount={totalPrice} /> / mo
        </b>
      </Heading>
      <span className={styles['u-typographyContent3;']}>
        &nbsp;excl.&nbsp;VAT
      </span>
    </span>
  );
};

export default OfficeCardTotalCost;
