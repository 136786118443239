import { captureException } from '@sentry/nextjs';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

const ALERT_THRESHOLD = 500;

export default function useDetectExcessiveUrlChanges() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [urlChanges, setUrlChanges] = useState(0);
  const [hasAlerted, setHasAlerted] = useState(false);

  useEffect(() => {
    // Any time the path name/URL params change, increment the counter
    setUrlChanges((urlChanges) => urlChanges + 1);
  }, [pathname, searchParams]);

  useEffect(() => {
    // If the URL changes more than the threshold, alert Sentry
    if (urlChanges > ALERT_THRESHOLD && !hasAlerted) {
      setHasAlerted(true);

      captureException(
        new Error(
          `The URL has changed ${urlChanges} times. There is likely a redirect loop on this page.`,
        ),
        {
          tags: {
            // We have Sentry set up to always alert us immediately when this
            // tag is set.
            // (Normally, browser-based exceptions need to happen a certain
            // number of times before Sentry alerts us.)
            alertable: true,
          },
        },
      );
    }
  }, [hasAlerted, urlChanges]);

  return urlChanges;
}
