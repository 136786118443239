import cn from 'classnames';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import ResultsWrapper from './components/ResultsWrapper';
import styles from './TypeAhead.module.css';

/**
 * Use TypeAhead to pass some search value into a method to be called onChange.
 *
 * This is designed to be used alongside features to autocomplete some search
 * value, such as location search, office search or user lookup.
 *
 * This component provides a very basic component with which to wrap the results
 * of the typeahead search, but the responsibility of styling those results and
 * handling the logic within is with the developer implementing this component into
 * their work.
 */
const TypeAhead = ({
  handleChange,
  handleSubmit = null,
  results: Results = null,
  ...props
}) => {
  const [value, setValue] = useState(() => '');

  const debouncedHandleChange = useMemo(
    () => debounce(handleChange, 50),
    [handleChange],
  );

  useEffect(() => {
    return () => {
      // Cease pending requests on dismount
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  return (
    <div className={styles['TypeAhead-wrapper']}>
      <div className={styles['TypeAhead-inputGroup']}>
        <input
          autoComplete="off"
          className={cn(styles['TypeAhead-input'], {
            [styles['TypeAhead-input--hasResults']]: Boolean(
              value.length > 2 && Results,
            ),
          })}
          onChange={(event) => {
            setValue(event.target.value);
            if (event.target.value.length > 2) {
              debouncedHandleChange(event.target.value);
            }
          }}
          type="text"
          value={value}
          {...props}
        />
        {value && (
          <button
            aria-label="Clear search query"
            className={styles['TypeAhead-clearButton']}
            onClick={() => setValue('')}
            type="button"
          >
            <MaterialIcon
              className={styles['TypeAhead-icon']}
              iconType="close"
            />
          </button>
        )}
        <input
          aria-label="Search"
          className={cn(styles['TypeAhead-searchButton'], {
            [styles['TypeAhead-searchButton--hasResults']]: Boolean(
              value.length > 2 && Results,
            ),
          })}
          disabled={!handleSubmit}
          onClick={() => handleSubmit(value)}
          type="submit"
          // Material Icon font allows us to set the value to an icon.
          value="search"
        />
      </div>
      {value.length > 2 && Results && (
        <ResultsWrapper>{Results}</ResultsWrapper>
      )}
    </div>
  );
};

TypeAhead.propTypes = {
  /**
   * Function to call onChange of input field
   */
  handleChange: PropTypes.func.isRequired,

  /**
   * Function to call if submit button is called with value
   */
  handleSubmit: PropTypes.func,

  /**
   * Representation of the results of the typeahead search
   */
  results: PropTypes.node,
};

export default TypeAhead;
