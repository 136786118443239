import compareByKey from 'site-react/helpers/compareByKey';

const byIndex = compareByKey('distance');
const walkingSpeed = 3.1;

/**
 * @param {number} distance
 * @returns {number} time in minutes
 *
 * This is a bit of a magic function. In the future, we should get this data
 * from an API (Google or CityMapper), but this provides us a reasonable,
 * consistent estimation for now.
 *
 */
const getWalkingTimeInMinutes = (distance) =>
  Math.round(60 / (walkingSpeed / distance));

/**
 * @param {array} landmarks
 *
 * This function receives an array of landmarks (via our building data) and sorts
 * by their distance value (in ascending order). The top 3 landmark objects with
 * the shortest distances are then returned in their sorted order.
 *
 */
const pickLandmarks = (landmarks, sliceSize) => {
  const sorted = landmarks.sort(byIndex);
  return sorted.slice(0, sliceSize);
};

/**
 * @param {array} landmarks
 *
 * This function receives an array of sorted landmarks (3 or smaller in length)
 * and processes them into objects that can be used by the listing Location component.
 *
 * @example output
 * {
 *   "lines": [
 *    {
 *       "image": "northernLine.jpg",
 *       "description": ""
 *     }
 *   ],
 *   "kind": "transit-stop",
 *   "name": "Mornington Crescent",
 *   "walkingMinutes": 5
 * }
 *
 */
const transformLandmarksToNearbyLocations = (landmarks, sliceSize) => {
  const slicedAndSorted = pickLandmarks(landmarks, sliceSize);
  return slicedAndSorted.map(({ distance, landmark, subType }) => {
    return {
      isSearchArea: landmark.isSearchArea,
      // `kind` is hard coded for now, but should enable different types in the future
      kind: 'transit-stop',
      lines: (landmark.images || []).map((image) => ({
        description: image.description || '', // TODO: placeholder for line name. See EN-551.
        image: image.img,
      })),
      name: landmark.name,
      slug: landmark.slug,
      subType: subType || landmark.subType,
      walkingMinutes: getWalkingTimeInMinutes(distance),
    };
  });
};

export default transformLandmarksToNearbyLocations;
export { getWalkingTimeInMinutes, pickLandmarks };
