import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';
import { analyticsMetadataPropTypes } from 'site-react/helpers/Analytics';

const buttonStyle = css`
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const iconStyle = css`
  color: white;
`;

const iconWrapperStyle = css`
  background-color: rgb(var(--color-black-rgb) / 80%);
  position: absolute;
  bottom: var(--space-md);
  right: var(--space-md);
  height: var(--space-lg);
  width: var(--space-lg);
`;

const ImageCarouselExpandButton = ({
  analyticsMetadata = {},
  label = '',
  onClick,
}) => (
  <UnstyledButton
    analyticsMetadata={analyticsMetadata}
    attributes={{
      'aria-label': 'Open full screen carousel',
    }}
    className={buttonStyle}
    elementType="button"
    label={label}
    onClick={onClick}
  >
    <span className={iconWrapperStyle}>
      <MaterialIcon className={iconStyle} iconType="zoom_out_map" />
    </span>
  </UnstyledButton>
);

ImageCarouselExpandButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string,

  /**
   * Callback when the button activates
   */
  onClick: PropTypes.func.isRequired,
};

export default ImageCarouselExpandButton;
