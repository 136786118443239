import { css } from '@emotion/css';

import font from './font';

/**
 * DEPRECATION NOTICE
 *
 * We are moving our styling tooling to CSS modules. This file will be removed
 * in the future.
 *
 * typography.css is the source of truth for all typography style variables.
 */

/* eslint-disable sort-keys */
const lineHeight = {
  xxl: 2.0 /* spacing.lg / font.size.xxs */,
  xl: 1.6666 /* (spacing.lg * 1.25) / font.size.m */,
  lg: 1.5 /* spacing.lg / font.size.sm */,
  md: 1.3333 /* (spacing.lg + spacing.base) / font.size.lg */,
  sm: 1.2858 /* (spacing.lg * 1.5) / font.size.xl */,
  xs: 1.1429 /* (spacing.lg * 2) / font.size.xxl */,
  xxs: 1.1034 /* (spacing.xl * 2) / font.size.xxxl */,
  reset: 1,
};

// "TITLE 3"
const titleText3 = css`
  font-size: ${font.size.lg}; /* 21px */
  font-weight: ${font.weight.medium};
  line-height: ${lineHeight.md}; /* 28px */
`;

// "TITLE 4"
const titleText4 = css`
  font-size: ${font.size.md}; /* 18px */
  font-weight: ${font.weight.medium};
  line-height: ${lineHeight.md}; /* 24px */
`;

// "CONTENT 3"
const contentText3 = css`
  font-size: ${font.size.xs}; /* 14px */
  font-weight: ${font.weight.light};
  line-height: ${lineHeight.lg}; /* 21px */
`;

// "LARGE LABEL"
const largeLabel = css`
  font-family: ${font.family.monospaced};
  font-size: ${font.size.xs}; /* 14x */
  font-weight: ${font.weight.medium};
  letter-spacing: 2px;
  line-height: ${lineHeight.lg}; /* 21px */
  text-transform: uppercase;
`;

// "SMALL LABEL"
const smallLabel = css`
  font-family: ${font.family.monospaced};
  font-size: ${font.size.xxs}; /* 12px */
  font-weight: ${font.weight.medium};
  letter-spacing: 1px;
  line-height: ${lineHeight.md}; /* 16px */
  text-transform: uppercase;
`;

export {
  contentText3,
  largeLabel,
  lineHeight,
  smallLabel,
  titleText3,
  titleText4,
};
