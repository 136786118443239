import { yupResolver } from '@hookform/resolvers/yup';
import { HttpError } from '@hubble/request';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import SpaciousApi from 'site-react/api/SpaciousApi';
import {
  Button,
  Checkbox,
  Fieldset,
  InputGroup,
  Label,
  Radio,
  Select,
  TextArea,
  TextInput,
} from 'site-react/components/form';
import { UICard } from 'site-react/components/page';
import { ErrorMessage, Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getCountryCodes, {
  getPinnedCountryCodes,
} from 'site-react/helpers/CountryCodes';
import getDataLayer from 'site-react/helpers/dataLayer';
import logError from 'site-react/helpers/logError';
import usePartTime from 'site-react/hooks/usePartTime';

import styles from './Form.module.css';
import CustomCheckbox from '../CustomCheckbox';

const daysChoosingOptionValues = {
  ChooseDays: 'choose-days',
  NotSure: 'not-sure',
};

const validationSchema = Yup.object().shape({
  daysChoosingOption: Yup.string().oneOf([
    daysChoosingOptionValues.ChooseDays,
    daysChoosingOptionValues.NotSure,
  ]),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('You must enter an email address'),
  includeExtras: Yup.bool(),
  isFullyCostedProposal: Yup.bool(),
  phoneCountryCode: Yup.string(),
  phoneNumber: Yup.string(),
  preferredDays: Yup.array()
    .min(
      1,
      'Select at least one day to see offices available on a part-time basis',
    )
    .max(
      3,
      'Select fewer than four days to see offices available on a part-time basis',
    ),
  requiredInformation: Yup.string(),
  size: Yup.string().required('You must enter a size'),
});

const days = [
  { display: 'MO', label: 'Mon', name: 'monday' },
  { display: 'TU', label: 'Tue', name: 'tuesday' },
  { display: 'WE', label: 'Wed', name: 'wednesday' },
  { display: 'TH', label: 'Thu', name: 'thursday' },
  { display: 'FR', label: 'Fri', name: 'friday' },
];

const spaciousApi = new SpaciousApi();

export default function Form({
  buildingName,
  location,
  pricePlanId = null,
  pricePlanSize = null,
  onSuccess = () => {},
  userEmail,
}) {
  const { partTimePreferences, setPartTimePreferences } = usePartTime();

  const [preferredDays, setPreferredDays] = useState(partTimePreferences.days);

  const { formState, handleSubmit, register, setError, setValue, watch } =
    useForm({
      defaultValues: {
        daysChoosingOption: daysChoosingOptionValues.ChooseDays,
        email: userEmail ? userEmail : null,
        preferredDays: [],
        size: pricePlanSize ? pricePlanSize : null,
      },
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
    });

  async function onSubmit(data) {
    setPartTimePreferences({
      ...partTimePreferences,
      days: [...preferredDays],
    });

    let combinedPhoneNumber;
    if (data.phoneNumber) {
      combinedPhoneNumber = `${data.phoneCountryCode}${data.phoneNumber}`;
    }

    delete data.phoneCountryCode;
    delete data.phoneNumber;

    if (data.daysChoosingOption === daysChoosingOptionValues.NotSure) {
      delete data.preferredDays;
    } else {
      data.preferredDays = data.preferredDays.join(',');
    }

    delete data.daysChoosingOption;

    try {
      await spaciousApi.createQuotePartTime({
        ...data,
        buildingName,
        location,
        ...(combinedPhoneNumber && { phoneNumber: combinedPhoneNumber }),
        ...(pricePlanId && { pricePlanId }),
      });

      getDataLayer().push({
        event: 'generate_lead',
        event_type: 'get_a_quote_listing_pto',
      });

      onSuccess(data.size);
    } catch (error) {
      if (error instanceof HttpError) {
        const failedFields = Object.keys(error.body || {});

        if (
          failedFields.length &&
          (failedFields.some((field) => validationSchema.fields[field]) ||
            failedFields.includes('nonFieldErrors'))
        ) {
          failedFields.forEach((field) => {
            if (validationSchema.fields[field]) {
              setError(field, {
                message: error.body[field][0],
              });
            } else if (field === 'nonFieldErrors') {
              setError('root.error', {
                message: error.body.nonFieldErrors,
              });
            }
          });
        } else {
          logError(error);
          setError('root.error', {
            message: 'An error occurred. Please try again.',
          });
        }
      } else {
        logError(error);
        setError('root.error', {
          message: 'An error occurred. Please try again.',
        });
      }
    }
  }

  useEffect(() => {
    setValue('preferredDays', preferredDays);
  }, [preferredDays, setValue]);

  return (
    <form className={styles.Form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Heading level="2" type="title3">
        Please send me:
      </Heading>

      <VerticalSpacing size="md" />

      <UICard
        style={{
          border: '1px solid var(--color-neutral-600)',
          borderRadius: 'var(--space-xs)',
          padding: 'var(--space-sm)',
        }}
      >
        <Checkbox
          id="isFullyCostedProposal"
          label={
            <>
              <b>A fully-costed office proposal</b>
              <br />
              with a breakdown of part-time pricing
            </>
          }
          labelAlign="start"
          {...register('isFullyCostedProposal')}
        />
      </UICard>

      <UICard
        style={{
          border: '1px solid var(--color-neutral-600)',
          borderRadius: 'var(--space-xs)',
          padding: 'var(--space-sm)',
        }}
      >
        <Checkbox
          id="includeExtras"
          label={
            <>
              <b>Floorplans & office photos</b>
              <br />
              where available
            </>
          }
          labelAlign="start"
          {...register('includeExtras')}
        />
      </UICard>

      <VerticalSpacing size="md" />

      <TextArea
        errorText={formState.errors?.requiredInformation?.message}
        id="requiredInformation"
        labelText="Any other information you need?"
        placeholder="e.g. can I leave monitors?"
        status={formState.errors.requiredInformation ? 'error' : undefined}
        {...register('requiredInformation')}
      />

      <VerticalSpacing size="md" />

      <TextInput
        errorText={formState.errors?.size?.message}
        inputMode="numeric"
        isValidationManaged
        labelText="Team Size"
        pattern="[0-9]*"
        placeholder="e.g. 10"
        status={formState.errors.size ? 'error' : undefined}
        type="text"
        {...register('size')}
      />

      <VerticalSpacing size="md" />

      <Fieldset legend="On which days of the week?" name="preferredDays">
        <Radio
          className={styles['Form-daysChoosingOption']}
          id="chooseDays"
          label="Choose preferred days"
          {...register('daysChoosingOption')}
          value={daysChoosingOptionValues.ChooseDays}
        />

        <div className={styles['Form-days']}>
          {days.map((day) => {
            return (
              <CustomCheckbox
                checked={preferredDays.includes(day.name)}
                content={day.display}
                disabled={
                  watch('daysChoosingOption') ===
                    daysChoosingOptionValues.NotSure ||
                  (preferredDays.length >= 3 &&
                    !preferredDays.includes(day.name))
                }
                id={day.name}
                key={day.name}
                name={day.name}
                onChange={(event) => {
                  if (preferredDays?.length === 1 && !event.target.checked) {
                    // Don't allow the last day to be unchecked
                    return;
                  }
                  if (event.target.checked) {
                    setPreferredDays([...preferredDays, day.name]);
                  } else {
                    setPreferredDays([
                      ...preferredDays.filter(
                        (preferredDay) => preferredDay !== day.name,
                      ),
                    ]);
                  }
                }}
                value={day.name}
              />
            );
          })}
        </div>

        <VerticalSpacing size="md" />

        <Radio
          className={styles['Form-daysChoosingOption']}
          id="notSure"
          label="Not sure"
          {...register('daysChoosingOption')}
          value={daysChoosingOptionValues.NotSure}
        />
      </Fieldset>

      <VerticalSpacing size="md" />

      <TextInput
        errorText={formState.errors?.email?.message}
        isValidationManaged
        labelText="Email Address"
        placeholder="e.g. name@companyname.com"
        required
        status={formState.errors.email ? 'error' : undefined}
        type="email"
        {...register('email')}
      />

      <VerticalSpacing size="md" />

      <Label htmlFor="viewing-request-phone-number" labelText="Phone number">
        <InputGroup>
          <Select
            data-testid="phone-number-country-code"
            name="phoneNumberCountryCode"
            {...register('phoneCountryCode')}
          >
            {getPinnedCountryCodes().map((country) => (
              <option
                data-display-value={country.dial_code}
                data-testid={`pinned-phone-country-code-${country.dial_code}`}
                key={`pinned-${country.code}`}
                value={country.dial_code}
              >
                {country.name} {country.dial_code}
              </option>
            ))}

            <optgroup label="All country codes">
              {getCountryCodes().map((country) => (
                <option
                  data-display-value={country.dial_code}
                  data-testid={`phone-country-code-${country.dial_code}`}
                  key={country.code}
                  value={country.dial_code}
                >
                  {country.name} {country.dial_code}
                </option>
              ))}
            </optgroup>
          </Select>

          <TextInput
            data-testid="phone-number"
            errorText={formState.errors.phoneNumber?.message}
            id="viewing-request-phone-number"
            isValidationManaged
            pattern="[0-9 ]{7,}" /* numbers only, 7 characters minimum required and spaces becasue humans put space in phone numbers */
            placeholder="e.g. 07412123456"
            required
            status={formState.errors?.phoneNumber?.message && 'error'}
            title="A valid phone number must be numbers only, with a minimum of 7 digits"
            type="tel"
            {...register('phoneNumber')}
          />
        </InputGroup>
      </Label>

      <span className={styles['Form-subheading']}>
        So we can discuss your quote in more detail
      </span>

      <VerticalSpacing size="lg" />

      {formState.errors?.root?.error?.message && (
        <ErrorMessage>{formState.errors.root.error.message}</ErrorMessage>
      )}

      <Button
        disabled={formState.isSubmitting}
        isLoading={formState.isSubmitting}
        name="Get your quote"
        type="submit"
      >
        Get your quote
      </Button>
    </form>
  );
}

Form.propTypes = {
  buildingName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  pricePlanId: PropTypes.number,
  pricePlanSize: PropTypes.number,
};
