import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import switchStyles from './SwitchButton.module.css';
import useToggleContext from '../../hooks/useToggleContext';
import defaultStyles from '../../styles/Toggle.module.css';

const SwitchButton = ({
  analyticsMetadata,
  disabled = false,
  label = null,
  ...props
}) => {
  const { on, toggle } = useToggleContext();

  const handleAnalytics = () => {
    analytics.track(
      'Switch changed',
      {
        selected: on,
        ...analyticsMetadata,
      },
      {
        sendPageProperties: true,
      },
    );
  };

  return (
    <label
      aria-label={props['aria-label'] || 'Toggle'}
      className={cn(switchStyles.SwitchButton, {
        [switchStyles['SwitchButton--disabled']]: disabled,
      })}
    >
      <b>{label}</b>
      <input
        checked={on}
        className={defaultStyles['Toggle-input']}
        disabled={disabled}
        onChange={() => {
          toggle();
          handleAnalytics();
        }}
        type="checkbox"
      />
      <button
        className={cn(
          switchStyles['SwitchButton-button'],
          defaultStyles['Toggle-button'],
          {
            [cn(
              switchStyles['SwitchButton-button--on'],
              defaultStyles['Toggle-button--on'],
            )]: on,
          },
        )}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();

          toggle();
          handleAnalytics();
        }}
        type="button"
        {...props}
      />
    </label>
  );
};

SwitchButton.propTypes = {
  analyticsMetadata: analyticsMetadataPropTypes,
  label: PropTypes.string,
};

export default SwitchButton;
