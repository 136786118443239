import { css } from '@emotion/css';

const hiddenStyle = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

// TODO: extend accessibility features as needed
// export { Hidden, Something, SomethingElse };
export default hiddenStyle;
