const photos = {
  BlueBeca: '/site/team/blob-style/blue-beca.png',
  BlueChai: '/site/team/blob-style/blue-chai.png',
  BlueCharlie: '/site/team/blob-style/blue-charlie.png',
  BlueGeorgina:
    '/site/team/blob-style/blue-georgina.png?w=100&h=100&auto=compress',

  BlueHenryb: '/site/team/blob-style/blue-henry-b.png',
  Chai: '/site/team/chai-jan19.jpg?w=100&h=100&auto=compress&fit=facearea&mask=https://hubble.imgix.net/brand/blob-white.png&fm=png&facepad=2',
  Wiley:
    '/site/team/wiley.jpg?w=100&h=100&auto=compress&fit=crop&mask=https://hubble.imgix.net/brand/blob-white.png&fm=png',
};
export default photos;
