import Image from 'next/image';

import { OutboundLink } from 'site-react/components/navigation';
import { UICard } from 'site-react/components/page';
import { Paragraph } from 'site-react/components/typography';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './TrustpilotCard.module.css';

const TrustpilotCard = ({ title, body, credit }) => {
  return (
    <UICard
      height="fill"
      style={{
        border: '1px solid var(--color-neutral-100)',
        borderRadius: '6px',
        padding: 'var(--space-md)',
      }}
    >
      <div className={styles['TrustpilotCard-logo']}>
        <OutboundLink
          href="https://uk.trustpilot.com/review/hubblehq.com"
          target="_blank"
        >
          <Image
            alt="Trustpilot rating of 4.8 stars"
            height={22}
            loader={imgixLoader}
            src={getImgixPathWithParameters(
              '/site/global/trustpilot-stars-1.svg',
              { auto: 'compress' },
            )}
            width={130}
          />
        </OutboundLink>
      </div>
      <div className={styles['TrustpilotCard-title']}>
        <Paragraph type="content3">
          {' '}
          <b> {title} </b>{' '}
        </Paragraph>
      </div>
      <div className={styles['TrustpilotCard-body']}>
        <Paragraph type="content3">{body}</Paragraph>
      </div>
      <div className={styles['TrustpilotCard-credit']}>
        <Paragraph type="content3">{credit}</Paragraph>
      </div>
    </UICard>
  );
};

export default TrustpilotCard;
