import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SelectorPills.module.css';

const SelectorPillItem = React.forwardRef(
  ({ children, id, style, ...props }, ref) => {
    return (
      <li
        className={styles['SelectorPills-buttonContainer']}
        style={{
          '--SelectorPillItem--border': style?.border
            ? style.border
            : '1px var(--color-neutral-500) solid',
          '--SelectorPillItem--borderRadius': style?.borderRadius
            ? style.borderRadius
            : 'calc(var(--SelectorPills-height) / 2)',
          '--SelectorPillItem--padding':
            'padding' in (style || {}) ? style.padding : '0 var(--space-md)',
        }}
      >
        <input id={id} ref={ref} {...props} />
        <label htmlFor={id}>{children}</label>
      </li>
    );
  },
);

SelectorPillItem.propTypes = {
  /**
   * Text to show inside the pill
   */
  children: PropTypes.node,

  /**
   * ID for the input. Required, so the label can reference it correctly.
   */
  id: PropTypes.string.isRequired,
};

function SelectorPillList({ children, horizontalScroll }) {
  return (
    <ul
      className={cn(styles['SelectorPills-list'], {
        [styles['SelectorPills-list--horizontalScroll']]: horizontalScroll,
      })}
    >
      {children}
    </ul>
  );
}

export default {
  Item: SelectorPillItem,
  List: SelectorPillList,
};
