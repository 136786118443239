import PropTypes from 'prop-types';
import React from 'react';

import AccountIconImg from './AccountIconImg';
import AccountIconLetter from './AccountIconLetter';

const AccountIcon = ({
  className = '',
  hover = false, // By default there is no hover effect if anyone hovers over it.
  imgSrc = '',
  link = null,
  name = '',
  onClick = null,
  size = null,
}) => {
  /**
   * Gets the first initial from the users name.
   *
   * @param {string} name - The users name to get the initial from
   */
  const getInitial = (name) => {
    return name.charAt(0);
  };

  const renderIcon = () => {
    if (!imgSrc) {
      // Account Letter
      return (
        <AccountIconLetter
          hover={hover}
          letter={getInitial(name)}
          size={size}
        />
      );
    }
    // Account Image
    return <AccountIconImg alt={name} hover={hover} size={size} src={imgSrc} />;
  };

  if (!link && !onClick) {
    return <span className={className}>{renderIcon()}</span>;
  } else if (onClick && !link) {
    return (
      <button className={className} onClick={onClick} type="button">
        {renderIcon()}
      </button>
    );
  } else {
    return (
      <a className={className} href={link} onClick={onClick}>
        {renderIcon()}
      </a>
    );
  }
};

AccountIcon.propTypes = {
  /**
   * Class name for icon wrapper.
   */
  className: PropTypes.string,
  /**
   * Enables/Disables allowing a hover effect on the icon,
   */
  hover: PropTypes.bool, // Enables/Disables allowing a hover effect on the icon.
  /**
   * Img src url for user icon
   */
  imgSrc: PropTypes.string,
  /**
   * Link if you click on the account icon
   */
  link: PropTypes.string,
  /**
   * Name of the user, required for the letter icon
   */
  name: PropTypes.string,
  /**
   * Click event for icon wrapper
   */
  onClick: PropTypes.func,
  /**
   * Pixel size for the Icon (square)
   */
  size: PropTypes.number,
};

export default AccountIcon;
