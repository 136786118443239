import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AlertToast } from 'site-react/components/page';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './AlertContext.module.css';

const AlertContext = createContext({});

const AlertContextProvider = ({ children = null }) => {
  const [alerts, setAlerts] = useState([]);

  const lastKey = useRef(0);

  const removeAlert = useCallback((key) => {
    setAlerts((alerts) => [
      ...alerts.filter(({ key: currentAlertKey }) => key !== currentAlertKey),
    ]);
  }, []);

  const addAlert = useCallback(
    (message, type) => {
      const key = lastKey.current + 1;

      setAlerts((alerts) => [
        ...alerts,
        {
          key,
          message,
          type,
        },
      ]);
      lastKey.current = key;

      return () => removeAlert(key);
    },
    [lastKey, removeAlert],
  );

  const addError = useCallback(
    (message) => {
      return addAlert(message, 'error');
    },
    [addAlert],
  );

  const addSuccess = useCallback(
    (message) => {
      return addAlert(message, 'success');
    },
    [addAlert],
  );

  const value = useMemo(
    () => ({
      addError,
      addSuccess,
    }),
    [addError, addSuccess],
  );

  return (
    <AlertContext.Provider value={value}>
      {alerts.length > 0 ? (
        <div className={styles.AlertContext}>
          {alerts.map(({ key, message, type }) => (
            <VerticalSpacing key={key} size="md">
              <AlertToast onClose={() => removeAlert(key)} type={type}>
                {message}
              </AlertToast>
            </VerticalSpacing>
          ))}
        </div>
      ) : null}
      {children}
    </AlertContext.Provider>
  );
};

AlertContextProvider.propTypes = {
  /**
   * All the other content to show. It's likely that the whole page should be
   * inside this component.
   */
  children: PropTypes.node,
};

export default AlertContextProvider;
export { AlertContext };
