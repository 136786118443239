import PropTypes from 'prop-types';
import React from 'react';

import { VerticalSpacing } from 'site-react/components/utility';

import styles from './Label.module.css';

/**
 * A label for a form element.
 *
 * NOTE: if htmlFor is unspecified, will automatically associate with its first
 * input-like child element. For multiple elements (like an InputGroup),
 * _always_ specify htmlFor. For associated elements which are not a child,
 * similarly, _always_ specify htmlFor.
 */
const Label = ({ children, htmlFor, labelText, labelType = '1' }) => (
  <label className={styles['Label']} htmlFor={htmlFor}>
    <span className={`u-typographyLabel${labelType}`}>{labelText}</span>
    <VerticalSpacing size="xs" />
    {children}
  </label>
);

Label.propTypes = {
  /**
   * Children to show.
   */
  children: PropTypes.node,

  /**
   * The label's for attribute. Needs to be called htmlFor, because for is a
   * reserved word in JavaScript.
   */
  htmlFor: PropTypes.string,

  /**
   * The text of the label.
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Label;
