import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useId, useMemo } from 'react';

import { UnstyledButton } from 'site-react/components/form';
import { NavBar } from 'site-react/components/navigation';
import { MaterialIcon } from 'site-react/components/typography';

import styles from './NavBarMenuItem.module.css';

const NavBarMenuItem = ({
  children,
  customLabelElement,
  forceOpenInHamburger,
  label,
  relative,
}) => {
  const { isMegaNav, onOpen, openIndicies } = useContext(NavBar.Context);
  const componentId = useId();
  const isOpen = openIndicies.includes(componentId);

  // On mobile, if forceOpenInHamburger is set, then we want to force the
  // contents of this menu to be open. That way, the items within the menu are
  // easily accessible when the hamburger is activated. This is mostly used for
  // our account menus.
  const overrideIsOpen = isMegaNav === false && forceOpenInHamburger;

  const contextValue = useMemo(
    () => ({ isOpen: isOpen || overrideIsOpen }),
    [isOpen, overrideIsOpen],
  );

  return (
    <NavBarMenuItem.Context.Provider value={contextValue}>
      <li
        aria-label={label}
        className={cn(styles.NavBarMenuItem, {
          [styles['NavBarMenuItem--relative']]: relative,
          [styles['NavBarMenuItem--open']]: isOpen,
        })}
      >
        <UnstyledButton
          analyticsMetadata={{
            location: 'navigation',
          }}
          attributes={{
            'aria-expanded': isOpen,
            'aria-haspopup': true,
            'data-testid': `${label} main nav item`,
          }}
          className={styles['NavBarMenuItem-button']}
          label={label}
          onClick={() => onOpen(componentId)}
          title={`${isOpen ? 'close' : 'open'} ${label} menu`}
        >
          {customLabelElement ?? label}

          {/* If we've forced the menu open, don't show the expand icon.
           * In this mode, the menu can't close, so we don't want the user to
           * think they can close it. */}
          {overrideIsOpen ? null : (
            <MaterialIcon
              aria-hidden
              className={styles['NavBarMenuItem-icon']}
              iconType="expand_more"
            />
          )}
        </UnstyledButton>
        {children}
      </li>
    </NavBarMenuItem.Context.Provider>
  );
};

NavBarMenuItem.Context = createContext({});

NavBarMenuItem.propTypes = {
  /**
   * Children, expecting inline styled elements
   */
  children: PropTypes.node,

  /**
   * A customised element to render inside the button, in place of the standard label.
   *
   * NOTE: for accessibility, even when using a customLabelElement, you **MUST** still provide a value for `label` that describes what this button does!
   */
  customLabelElement: PropTypes.node,

  /**
   * Display the contents of this menu when we're in hamburger-menu mode (at smaller breakpoints). Behaves normally when not shown as a hamburger menu.
   *
   * Used primarily for "account" menus, to provide easy access to those items.
   */
  forceOpenInHamburger: PropTypes.bool,

  /**
   * href of link, if provided alongside onClick, <a> tag emitted during SSR
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Label, to present at the head of this component
   */
  label: PropTypes.string.isRequired,

  /**
   * Should this item set relative positioning for its children?
   */
  relative: PropTypes.bool,
};

export default NavBarMenuItem;
