/* Standardised z-index values */

/* eslint-disable sort-keys */
export default {
  normal: 0,
  nav: 10,
  drawer: 20,
  cookieBanner: 25,
  modal: 30,
  toast: 40,
};
