import { Modal } from 'site-react/components/page';
import { TextWithIcon } from 'site-react/components/typography';

import styles from './USPContent.module.css';
import HqListingPriceGuaranteeModalContent from '../../../../../../HqListingPriceGuaranteeModalContent';

export default function USPContent() {
  return (
    <Modal
      modalName="Best price guarantee"
      renderTrigger={({ openModal }) => (
        <button
          className={styles['USPContent--trigger']}
          onClick={openModal}
          type="button"
        >
          <TextWithIcon
            contentType="content3"
            iconName="workspace_premium"
            iconPosition="left"
            iconSize="xl"
            text={
              <>
                <b>
                  <u>Best price guarantee</u>
                </b>
                <br />
                <span className={styles['USPContent-discountLabel']}>
                  15% average discount
                </span>
              </>
            }
          />
        </button>
      )}
    >
      <HqListingPriceGuaranteeModalContent />
    </Modal>
  );
}
