import { css } from '@emotion/css';
import React from 'react';

import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

import LogoGrid from '../../LogoGrid';

const contentWrapperStyle = css`
  align-items: start;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 0 var(--space-md);
  }
`;

const headingStyle = css`
  align-self: start;
`;

const listStyle = css`
  list-style: none;
  padding: 0;
`;

const OfficeFirstSignupContent = () => (
  <aside className={contentWrapperStyle}>
    <Heading level="2" type="title3">
      We’re trusted by 2,500+ of the world’s most exciting companies
    </Heading>

    <VerticalSpacing size="lg" />

    <LogoGrid />

    <VerticalSpacing size="lg" />
    <HorizontalRule />
    <VerticalSpacing size="lg" />

    <div className={headingStyle}>
      <Heading level="3" type="title3">
        Hubble gives you the power to
      </Heading>
    </div>
    <VerticalSpacing size="sm" />
    <ul className={listStyle}>
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Instantly search 5,000+ offices"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Save your searches and curate shortlists of your favourite spaces"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Understand, manage and share everything workplace related with your team"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Get a free personal workplace advisor"
      />
    </ul>
  </aside>
);

export default OfficeFirstSignupContent;
