import PropTypes from 'prop-types';
import { useState } from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Link } from 'site-react/components/navigation';
import {
  ErrorMessage,
  Heading,
  MaterialIcon,
  Paragraph,
  Price,
} from 'site-react/components/typography';
import { ImgixImage, Spinner } from 'site-react/components/utility';
import logError from 'site-react/helpers/logError';

import styles from './BasketItem.module.css';

function formatPrice(isPartTime, pricePlan) {
  if (isPartTime) {
    return (
      <span data-testid="part-time-price">
        from <Price amount={parseInt(pricePlan.partTimeOffPeakPrice, 10)} /> /
        <abbr title="month">mo</abbr>
      </span>
    );
  } else {
    return (
      <span data-testid="full-time-price">
        <abbr title="at">@</abbr>{' '}
        <Price amount={parseInt(pricePlan.fullTimeMonthlyPrice, 10)} /> /{' '}
        <abbr title="month">mo</abbr>
      </span>
    );
  }
}

const spaciousApi = new SpaciousApi();

export default function BasketItem({ item, setRecommendationList, token }) {
  const [error, setError] = useState(null);
  const [isRemovingFromBasket, setIsRemovingFromBasket] = useState(false);

  async function restoreRecommendationListItem() {
    setError(null);
    setIsRemovingFromBasket(true);

    try {
      await spaciousApi.createRecommendationsListItemRestore(token, item.id);

      setRecommendationList((prevRecommendationList) => {
        const updatedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((recommendationListItem) => {
            if (recommendationListItem.id === item.id) {
              return {
                ...recommendationListItem,
                status: 'pending',
              };
            }

            return recommendationListItem;
          }),
        };

        return updatedRecommendationList;
      });
    } catch (error) {
      logError(error);
      setError('Something went wrong. Please try again.');
    } finally {
      setIsRemovingFromBasket(false);
    }
  }

  if (isRemovingFromBasket) {
    return (
      <div className={styles['BasketItem-loading']}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className={styles.BasketItem}>
        <ImgixImage
          alt="Building image"
          className={styles['BasketItem-image']}
          height={80}
          imgixOptions={{
            dpr: 2,
            fit: 'crop',
            h: 80,
            q: 30,
            w: 80,
          }}
          src={item.building.images[0].img}
          width={80}
        />
        <div>
          <Heading type="title4">{item.building.name}</Heading>
          <Paragraph isMarginless>
            {item.pricePlan.desks} desk{item.pricePlan.desks !== 1 ? 's' : ''}{' '}
            {formatPrice(item.isPartTime, item.pricePlan)}{' '}
            <span className={styles['BasketItem-deemphesized']}>
              <abbr title="excluding">excl.</abbr> VAT
            </span>
          </Paragraph>
          <Link
            href={{
              pathname: `/office-space/${item.building.id}/${item.building.slug}/book-viewing`,
              query: {
                access: item.isPartTime ? 'partTime' : 'fullTime',
                option: item.pricePlan.id,
              },
            }}
            target="_blank"
          >
            Book a viewing online
          </Link>
        </div>
        <button
          className={styles['BasketItem-restore']}
          onClick={restoreRecommendationListItem}
          type="button"
        >
          <MaterialIcon iconType="delete" />
        </button>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

BasketItem.propTypes = {
  item: PropTypes.shape({
    advisorNotes: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    customerNotes: PropTypes.string,
    id: PropTypes.number.isRequired,
    isPartTime: PropTypes.bool,
    likedAt: PropTypes.string,
    pricePlanId: PropTypes.number.isRequired,
    recommendationList: PropTypes.number.isRequired,
    rejectedAt: PropTypes.string,
    status: PropTypes.oneOf([
      'liked',
      'pending',
      'viewing requested',
      'rejected',
    ]),
    updatedAt: PropTypes.string,
    viewingRequestedAt: PropTypes.string,
  }),

  token: PropTypes.string.isRequired,
};
