import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

const iframeStyle = css`
  width: 300px;
  height: 400px;

  @media (min-width: 543px) {
    width: 800px;
    height: 400px;
  }
`;

const wrapperStyle = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Tour360ContentPopup = ({ tourUrl }) => {
  return (
    <div>
      <div className={wrapperStyle}>
        <iframe
          allow="vr"
          allowFullScreen
          border="0"
          className={iframeStyle}
          frameBorder="0"
          scrolling="no"
          src={tourUrl}
          title="360° Tour"
        />
      </div>
    </div>
  );
};

Tour360ContentPopup.propTypes = {
  /**
   * The url for the 360 tour that is available in the building object
   */
  tourUrl: PropTypes.string.isRequired,
};

export default Tour360ContentPopup;
