import PropTypes from 'prop-types';

import { Link } from 'site-react/components/navigation';
import { ActionText, Paragraph } from 'site-react/components/typography';

const SignUpToLoginSwitcher = ({
  isModeSwitcherLink,
  modeSwitcherLinkHref,
  onActionTextClick,
}) => {
  return (
    <Paragraph isMarginless type="content3">
      Already have a Hubble account?{' '}
      {isModeSwitcherLink ? (
        <Link href={modeSwitcherLinkHref} isBold styleType="secondary">
          Log in here
        </Link>
      ) : (
        <ActionText
          contentType="content3"
          label="Redirect to login"
          onClick={onActionTextClick}
          styleType="secondary"
        >
          <u>
            <b>Log in here</b>
          </u>
        </ActionText>
      )}
      <br />
    </Paragraph>
  );
};

SignUpToLoginSwitcher.propTypes = {
  /**
   * Should the mode switcher work as a link to the /login page?
   *
   * If true, the mode switchers will act as a link. If false, the mode switcher will
   * change the view on the page without redirecting.
   */
  isModeSwitcherLink: PropTypes.bool,

  /**
   * Href to use if `isModeSwitcherLink` is true
   */
  modeSwitcherLinkHref: PropTypes.string.isRequired,

  /**
   * Handler to trigger on click if rendering an `ActionText` component
   */
  onActionTextClick: PropTypes.func.isRequired,
};

export default SignUpToLoginSwitcher;
