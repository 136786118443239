import styles from './CustomCheckbox.module.css';

export default function CustomCheckbox({
  checked,
  content,
  disabled = false,
  id,
  name,
  onChange,
}) {
  return (
    <label className={styles.CustomCheckbox}>
      <input
        aria-label={name}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <span className={styles['CustomCheckbox--checkmark']} />
      <b className={styles['CustomCheckbox--content']}>{content}</b>
    </label>
  );
}
