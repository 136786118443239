import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon, Paragraph } from 'site-react/components/typography';

import styles from './AlertToast.module.css';

const AlertToast = ({ children, onClose, type }) => (
  <div
    className={cn(styles['AlertToast'], {
      [styles['AlertToast--error']]: type === 'error',
      [styles['AlertToast--success']]: type === 'success',
    })}
    data-testid="AlertToast"
  >
    <MaterialIcon
      className={styles['AlertToast-icon']}
      iconType={{ error: 'error', success: 'check' }[type]}
    />

    <div aria-live="polite" className={styles['AlertToast-content']}>
      <Paragraph>{children}</Paragraph>
    </div>

    <button
      className={styles['AlertToast-closeButton']}
      data-testid="AlertToast-closeButton"
      onClick={onClose}
      type="button"
    >
      <MaterialIcon
        className={styles['AlertToast-closeIcon']}
        iconType="close"
      />
    </button>
  </div>
);

AlertToast.propTypes = {
  /**
   * Text to show inside the toast
   */
  children: PropTypes.node.isRequired,

  /**
   * Callback when the close button is clicked
   */
  onClose: PropTypes.func.isRequired,

  /**
   * The type of toast to show.
   *
   * N.B. in the future, could be extended to "warning" and "info", a la Bootstrap.
   */
  type: PropTypes.oneOf(['error', 'success']).isRequired,
};

export default AlertToast;
