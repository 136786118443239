import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useMemo, useState, useContext } from 'react';

import { Section } from 'site-react/components/page';
import {
  ModalNewContext,
  ModalNewTemplateBasic,
  ModalNewTemplateWithSidebar,
  ModalNewTemplateWithSidebarContent,
  ModalNewTemplateWithSidebarSidebar,
} from 'site-react/components/page/ModalNew';

import Content from './components/Content';
import Form from './components/Form';
import QuoteSuccessfullySubmitted from '../QuoteSuccessfullySubmitted';

const FullTimeQuoteModalPage = {
  Form: 'form',
  Success: 'success',
};

export default function FullTimeQuoteModal({
  buildingName,
  location,
  pricePlanPrice = null,
  pricePlanId = null,
  pricePlanSize = null,
  userEmail,
}) {
  const router = useRouter();

  const [page, setPage] = useState(FullTimeQuoteModalPage.Form);
  const { closeModal } = useContext(ModalNewContext);
  const [phoneNumber, setPhoneNumber] = useState(() => null);

  const viewingRequestUrl = useMemo(() => {
    if (!pricePlanId) {
      return router.asPath.includes('#office-details')
        ? router.asPath
        : router.asPath + '#office-details';
    } else {
      const [currentPathname] = router.asPath.split('?');

      return {
        pathname: `${currentPathname}/book-viewing`,
        query: { access: 'fullTime', option: pricePlanId },
      };
    }
  }, [pricePlanId, router.asPath]);

  if (page === FullTimeQuoteModalPage.Form) {
    return (
      <ModalNewTemplateWithSidebar>
        <ModalNewTemplateWithSidebarSidebar>
          <div data-testid="FullTimeQuoteModal-content">
            <Content />
          </div>
        </ModalNewTemplateWithSidebarSidebar>
        <ModalNewTemplateWithSidebarContent>
          <div>
            <Form
              buildingName={buildingName}
              location={location}
              onSuccess={(size) => {
                setPage(FullTimeQuoteModalPage.Success);

                if (size >= 15) {
                  setPhoneNumber({
                    display: '+44 (0)20 4600 4958',
                    href: '+442046004958',
                  });
                } else if (size >= 4) {
                  setPhoneNumber({
                    display: '+44 (0)20 4600 4957',
                    href: '+442046004957',
                  });
                }
              }}
              phoneNumber={phoneNumber}
              pricePlanId={pricePlanId}
              pricePlanPrice={pricePlanPrice}
              pricePlanSize={pricePlanSize}
              userEmail={userEmail}
            />
          </div>
        </ModalNewTemplateWithSidebarContent>
      </ModalNewTemplateWithSidebar>
    );
  }

  if (page === FullTimeQuoteModalPage.Success) {
    return (
      <ModalNewTemplateBasic>
        <Section verticalPadding="xl">
          <QuoteSuccessfullySubmitted
            closeModal={closeModal}
            ctaCopy={pricePlanId ? 'Book a viewing online' : 'See options'}
            phoneNumber={phoneNumber}
            viewingRequestUrl={viewingRequestUrl}
          />
        </Section>
      </ModalNewTemplateBasic>
    );
  }
}

FullTimeQuoteModal.propTypes = {
  buildingName: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  location: PropTypes.string.isRequired,
  pricePlanId: PropTypes.number,
  pricePlanPrice: PropTypes.number,
  pricePlanSize: PropTypes.number,
};
