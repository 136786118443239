import config from 'site-react/config';
import analytics from 'site-react/helpers/Analytics';
import getAppUrl from 'site-react/helpers/getAppUrl';
import getListingPageUrl from 'site-react/helpers/getListingPageUrl';

function handleLinkClick({ building, link, index }) {
  const url = new URL(link.url, getAppUrl(config));

  analytics.track('Listing Breadcrumb Clicked', {
    building_id: building.id,
    // Path the link is pointing to
    index, // Index of the link being clicked in the Breadcrumbs
    name: link.slug,
    // Slug of the building URL
    path: url.pathname,
  });
}

const productPrefix = {
  coworking: '/coworking/',
  hq: '/office-space-',
  'meeting-room': '/meeting-rooms/',
  'private-office': '/private-day-offices/',
};

export default function getBreadcrumbs({ building, productType = 'hq' }) {
  // Defaults to London. This should be rare.
  let anchorUrl = `${productPrefix[productType]}london`;
  let areas = [...building.areas];
  const reversedAreas = [...building.areas].reverse();
  // If there's a city in the area hierarchy, we (for now) discard all breadcrumb
  // info before it. So UK > London > Shoreditch becomes London > Shoreditch,
  // without UK.
  const anchorIndex = reversedAreas.findIndex((area) => area.isAnchor);
  const firstAnchorIndex = areas.findIndex((area) => area.isAnchor);

  if (anchorIndex !== -1) {
    anchorUrl = `${productPrefix[productType]}${reversedAreas[anchorIndex].slug}`;
  }

  if (firstAnchorIndex !== 0) {
    areas = areas.slice(firstAnchorIndex, areas.length);
  }

  const listingUrl = getListingPageUrl({
    buildingId: building.id,
    buildingSlug: building.slug,
    isPass: productType !== 'hq',
  });

  return {
    links: [
      ...areas.map((area) => ({
        label: area.name,
        slug: area.isAnchor ? `office-space-${area.slug}` : area.slug,
        url: area.isAnchor
          ? `${productPrefix[productType]}${area.slug}`
          : `${anchorUrl}/${area.slug}`,
      })),
      {
        label: building.name,
        slug: building.slug,
        url: listingUrl,
      },
    ],
    onLinkClicked: (link, index) => {
      handleLinkClick({
        building,
        index,
        link,
      });
    },
  };
}
