export default {
  favourited: {
    activeIcon:
      'https://hubble.imgix.net/map/markers/v2/favouritemarker--active.svg',
    defaultIcon:
      'https://hubble.imgix.net/map/markers/v2/favouritemarker--default.svg',
    highlightedIcon:
      'https://hubble.imgix.net/map/markers/v2/favouritemarker--hover.svg',
    hoverIcon:
      'https://hubble.imgix.net/map/markers/v2/favouritemarker--hover.svg',
  },
  standard: {
    activeIcon: 'https://hubble.imgix.net/map/markers/v2/marker--active.svg',
    defaultIcon: 'https://hubble.imgix.net/map/markers/v2/marker--default.svg',
    highlightedIcon:
      'https://hubble.imgix.net/map/markers/v2/marker--hover.svg',
    hoverIcon: 'https://hubble.imgix.net/map/markers/v2/marker--hover.svg',
  },
};
