// Json-ld schema for SEO
// Implementation: https://blog.haroen.me/json-ld-with-react

// Data used here, as of August 12, 2023:

// - Google rating is 4.8, based on 141 reviews
// - TrustPilot rating is 4.8, based on 123 reviews
//
//   SUM_OF_ALL_RATINGS / RATINGS_COUNT
// = (4.8*141 + 4.8*123) / (141 + 123)
// = (676.8 + 590.4) / 264
// = 4.8
// = 4.8 (ratingCount of 264)
const data = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  aggregateRating: {
    '@type': 'AggregateRating',
    bestRating: '5',
    ratingCount: '264',
    ratingValue: '4.8',
  },
  contactPoint: [
    {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      telephone: '+44-203-608-0215',
    },
  ],
  email: 'help@hubblehq.com',
  name: 'Hubble',
  sameAs: 'https://www.linkedin.com/company/hubblehq',
  url: 'https://hubblehq.com',
};

export default data;
