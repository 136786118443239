// ************* DEPRECATION NOTICE ***************
// We will be replacing this function with a new getImgixPathWithParameters
// function as we don't need to add the imgix domain name to the return string.
// That part will be done by next/image . For more context on this see
// next.config.js file.
//
// Even if it is deprecated one please do not remove it as we are referencing
// this at a lot of places in our code where we are not using next/image.
//
// NOTE:
// If you don't find any reference of this function feel free to remove it.
// After that please make sure that all the tests are passing before pushing
// your changes.
//
// Check  SC-8159
/**
 * @param {string} - Image src
 * @param {int} options.dpr - Device pixel ratio
 * @param {int} options.fit - How image is fit to the target size
 * @param {int} option.h - Resize height
 * @param {int} options.q - Quality percentage 1-100
 * @param {int} option.w - Resize width
 *
 * For docs on the parameters used, see:
 * * auto: https://docs.imgix.com/apis/url/auto
 * * dpr: https://docs.imgix.com/apis/url/pixel-density/dpr
 * * fit: https://docs.imgix.com/apis/url/size/fit
 * * h: https://docs.imgix.com/apis/url/size/h
 * * q: https://docs.imgix.com/apis/url/format/q
 * * w: https://docs.imgix.com/apis/url/size/w
 */
function imgixUrl(src, options) {
  const url = new URL(src);
  url.searchParams.set('auto', 'format,compress');

  // set the option url params
  Object.keys(options).forEach((key) =>
    url.searchParams.set(key, options[key]),
  );

  return url.toString();
}

export { imgixUrl };
export default { imgixUrl };
