import PropTypes from 'prop-types';

import { Link } from 'site-react/components/navigation';
import { ActionText, Paragraph } from 'site-react/components/typography';
import analytics from 'site-react/helpers/Analytics';

const LoginToSignupSwitcher = ({
  isModeSwitcherLink,
  modeSwitcherLinkHref,
  onActionTextClick,
}) => {
  return (
    <Paragraph isMarginless type="content3">
      Don’t have an account?{' '}
      {isModeSwitcherLink ? (
        <Link href={modeSwitcherLinkHref} isBold styleType="secondary">
          Sign up
        </Link>
      ) : (
        <ActionText
          contentType="content3"
          label="Redirect to signup"
          onClick={onActionTextClick}
          styleType="secondary"
        >
          <u>
            <b>Sign up</b>
          </u>
        </ActionText>
      )}
      <br />
      Forgot your password?{' '}
      <Link
        href="/password-reset"
        isBold
        onClick={() => {
          analytics.track('Reset your password clicked');
        }}
        styleType="secondary"
      >
        Reset password
      </Link>
    </Paragraph>
  );
};

LoginToSignupSwitcher.propTypes = {
  /**
   * Should the mode switcher work as a link to /signup or /login pages?
   *
   * If true, the mode switchers will act as a link. If false, the mode switcher will
   * change the view on the page without redirecting.
   */
  isModeSwitcherLink: PropTypes.bool,

  /**
   * Href to use if `isModeSwitcherLink` is true
   */
  modeSwitcherLinkHref: PropTypes.string.isRequired,

  /**
   * Handler to trigger on click if rendering an `ActionText` component
   */
  onActionTextClick: PropTypes.func.isRequired,
};

export default LoginToSignupSwitcher;
