import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import toggleStyles from './ToggleButton.module.css';
import useToggleContext from '../../hooks/useToggleContext';
import labelsPropTypes from '../../proptypes/labels.proptypes';
import defaultStyles from '../../styles/Toggle.module.css';

const ToggleButton = ({
  analyticsMetadata,
  disabled = false,
  labels,
  ...props
}) => {
  if (!labels) {
    throw new Error(
      'labels prop must be set in order to properly render ToggleButton value options.',
    );
  }
  const { on, toggle } = useToggleContext();

  const handleAnalytics = () => {
    analytics.track(
      'Switch changed',
      {
        selected: on ? labels[1] : labels[0],
        ...analyticsMetadata,
      },
      {
        sendPageProperties: true,
      },
    );
  };

  return (
    <div
      aria-label={props['aria-label'] || 'Toggle'}
      className={toggleStyles.ToggleButton}
    >
      <label
        className={cn(toggleStyles['ToggleButton-label'], {
          [toggleStyles['ToggleButton-label--active']]: !on && !disabled,
          [toggleStyles['ToggleButton-label--disabled']]: disabled,
        })}
      >
        <b>{labels[0]}</b>
        <input
          checked={!on}
          className={defaultStyles['Toggle-input']}
          disabled={disabled}
          onChange={(event) => {
            toggle(event.target.value);
            handleAnalytics();
          }}
          type="radio"
          value={labels[0]}
        />
      </label>
      <button
        className={cn(
          defaultStyles['Toggle-button'],
          toggleStyles['ToggleButton-button'],
          {
            [defaultStyles['Toggle-button--on']]: on,
          },
        )}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();

          toggle();
          handleAnalytics();
        }}
        type="button"
        {...props}
      />
      <label
        className={cn(toggleStyles['ToggleButton-label'], {
          [toggleStyles['ToggleButton-label--active']]: on && !disabled,
          [toggleStyles['ToggleButton-label--disabled']]: disabled,
        })}
      >
        <b>{labels[1]}</b>
        <input
          checked={on}
          className={defaultStyles['Toggle-input']}
          disabled={disabled}
          onChange={(event) => {
            toggle(event.target.value);
            handleAnalytics();
          }}
          type="radio"
          value={labels[1]}
        />
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  analyticsMetadata: analyticsMetadataPropTypes,
  disabled: PropTypes.bool,
  labels: labelsPropTypes,
};

export default ToggleButton;
