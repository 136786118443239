import PropTypes from 'prop-types';
import { useState, useContext } from 'react';

import { paperAirplane } from 'site-react/assets';
import { Button } from 'site-react/components/form';
import { ModalNewContext } from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';
import { Centered, VerticalSpacing } from 'site-react/components/utility';

import Form from './components/Form';

const ModalPage = {
  Form: 'form',
  Success: 'success',
};

export default function AdvisorUpdateRecommendationListFormModal({
  onSuccess = () => {},
  recommendationList,
}) {
  const { closeModal } = useContext(ModalNewContext);
  const [modalPage, setModalPage] = useState(ModalPage.Form);

  if (modalPage === ModalPage.Form) {
    return (
      <>
        <Heading level="1" type="title1">
          Update {recommendationList.name}
        </Heading>
        <VerticalSpacing size="md" />
        <Form
          onSuccess={(updatedRecommendationList) => {
            setModalPage(ModalPage.Success);
            onSuccess(updatedRecommendationList);
          }}
          recommendationList={recommendationList}
        />
      </>
    );
  }
  if (modalPage === ModalPage.Success) {
    return (
      <Centered>
        <img alt="" src={paperAirplane} />

        <VerticalSpacing size="lg" />

        <Heading level="1" type="title1">
          {recommendationList.name} has been updated
        </Heading>

        <VerticalSpacing size="lg" />

        <Button
          name="Back to Recommendations"
          onClick={closeModal}
          styleType="secondary"
          type="button"
        >
          Back to Recommendations
        </Button>
      </Centered>
    );
  }
}

AdvisorUpdateRecommendationListFormModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  recommendationList: PropTypes.shape({
    advisor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    requesterEmail: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};
