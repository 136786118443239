import cookieTypes from './cookieTypes';

const preferenceOptions = [
  {
    categories: ['Livechat'],
    desc: 'We use browser cookies that are necessary for the site to work as intended. For example, we store your website data collection.',
    isDefaultChecked: true,
    isRequired: true,
    name: cookieTypes.essential,
  },
  {
    categories: [
      'CRM',
      'Customer Success',
      'Deep Linking',
      'Helpdesk',
      'Performance Monitoring',
      'Personalization',
      'SMS & Push Notifications',
      'Security & Fraud',
    ],
    desc: 'To monitor the performance of our site and to enhance your browsing experience.',
    isDefaultChecked: false,
    isRequired: false,
    name: cookieTypes.functional,
  },
  {
    categories: [
      'A/B Testing',
      'Analytics',
      'Attribution',
      'Email',
      'Enrichment',
      'Heatmaps & Recordings',
      'Raw Data',
      'Realtime Dashboards',
      'Referrals',
      'Video',
    ],
    desc: 'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.',
    isDefaultChecked: false,
    isRequired: false,
    name: cookieTypes.marketingAndAnalytics,
  },
  {
    categories: [cookieTypes.advertising, 'Tag Managers'],
    desc: 'To personalize and measure the effectiveness of advertising on our site and other websites.',
    isDefaultChecked: false,
    isRequired: false,
    name: cookieTypes.advertising,
  },
];

export default preferenceOptions;
