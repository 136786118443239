import { css } from '@emotion/css';
import { default as NextLink } from 'next/link';
import React from 'react';

import analytics from 'site-react/helpers/Analytics';
import useMembership from 'site-react/hooks/useMembership';
import useUser from 'site-react/hooks/useUser';

const wrapperStyle = css`
  font: var(--font-style-content-3);

  background-color: var(--color-neutral-900);
  color: var(--color-white);
  display: inline-block;
  padding: calc(var(--space-base) * 3) var(--space-sm)
    calc(var(--space-base) * 3) var(--space-md);
  margin: 0;
  width: 100%;

  &:hover,
  &:focus {
    color: var(--color-neutral-50);
    text-decoration: none;
  }

  &:active {
    color: var(--color-neutral-100);
  }
`;

const fakeLinkStyle = css`
  font: var(--font-style-content-3);
  color: var(--color-brandprimary);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

const FreeTrialBanner = () => {
  const { user } = useUser();
  const { plan } = useMembership();

  if (!plan || !plan.abilities.includes('free-trial-banner')) {
    return null;
  }

  let contentText;
  if (plan.status === 'expired') {
    contentText = 'Your free trial has expired.';
  } else if (plan.freeTrialDaysRemaining === 0) {
    contentText = 'Free trial ends today.';
  } else if (plan.freeTrialDaysRemaining === 1) {
    contentText = 'Free trial ends in 1 day.';
  } else {
    contentText = `Free trial ends in ${plan.freeTrialDaysRemaining} days`;
  }

  if (user?.role === 'admin') {
    return (
      <NextLink
        href="/dashboard/pass"
        onClick={() => analytics.track('Upgrade Free Trial Banner Clicked')}
      >
        <span className={wrapperStyle}>
          <b>{contentText} </b>
          <span className={fakeLinkStyle}>Upgrade now</span>
        </span>
      </NextLink>
    );
  } else {
    return (
      <span className={wrapperStyle}>
        <b>{contentText}</b>
      </span>
    );
  }
};

export default FreeTrialBanner;
