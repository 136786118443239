import { css, cx } from '@emotion/css';
import { default as NextLink } from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Analytics from 'site-react/helpers/Analytics';

const linkStyle = css`
  font-size: var(--font-size-xs);
`;

const activeStyle = css`
  color: var(--color-brandprimary);
`;

const itemStyle = css`
  @media (min-width: 992px) {
    width: 100%;
  }
`;

const LocationItem = ({ href, label }) => {
  const pathname = usePathname();
  const isCurrent = Boolean(pathname?.startsWith(href));

  const handleClick = useCallback(() => {
    Analytics.track(
      'Link clicked',
      {
        href,
        label,
        location: 'navigation',
      },
      {
        sendPageProperties: true,
      },
    );
  }, [href, label]);

  return (
    <li className={itemStyle}>
      <NextLink
        className={cx(linkStyle, {
          [activeStyle]: isCurrent,
        })}
        href={href}
        onClick={handleClick}
      >
        {label}
      </NextLink>
    </li>
  );
};

LocationItem.propTypes = {
  /**
   * href of link
   */
  href: PropTypes.string.isRequired,
  /**
   * Label, to present at the head of this component
   */
  label: PropTypes.string.isRequired,
};

export default LocationItem;
