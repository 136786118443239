import cn from 'classnames';

import { TextWithIcon } from 'site-react/components/typography';

import styles from './MapButton.module.css';

export default function MapButton({ isOpen, onClick }) {
  return (
    <button
      className={cn(styles.MapButton, 'u-displayBelowMd')}
      onClick={onClick}
      type="button"
    >
      <b>
        <TextWithIcon
          iconName={isOpen ? 'list' : 'location_on'}
          iconPosition="left"
          text={isOpen ? 'List' : 'Map'}
        />
      </b>
    </button>
  );
}
