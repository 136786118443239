import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Centered.module.css';

/**
 * Centers text and other inline content
 */
export default function Centered({ at = 'all', children }) {
  return (
    <div
      className={cn({
        [styles['Centered--aboveLg']]: at === 'aboveLg',
        [styles.Centered]: at === 'all',
        [styles['Centered--belowLg']]: at === 'belowLg',
      })}
    >
      {children}
    </div>
  );
}

Centered.propTypes = {
  /**
   * At what breakpoints should this component center its content?
   */
  at: PropTypes.oneOf(['all', 'belowLg', 'aboveLg']),

  /**
   * The content to center
   */
  children: PropTypes.node.isRequired,
};
