import PropTypes from 'prop-types';

import { linkedin } from 'site-react/assets';
import { OutboundLink } from 'site-react/components/navigation';
import {
  ModalNewTemplateWithSidebar,
  ModalNewTemplateWithSidebarContent,
  ModalNewTemplateWithSidebarSidebar,
} from 'site-react/components/page/ModalNew';
import {
  Heading,
  ListItemWithIcon,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import {
  Asset,
  Centered,
  ImgixImage,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';
import {
  ADVISOR_GENERIC_IMAGE_SRC,
  ADVISOR_GENERIC_EMAIL,
  ADVISOR_GENERIC_PHONE_NO,
} from 'site-react/data/advisors';

import styles from './AdvisorModalContent.module.css';

export default function AdvisorModalContent({ advisor }) {
  return (
    <ModalNewTemplateWithSidebar>
      <ModalNewTemplateWithSidebarSidebar>
        <Centered>
          <Heading level="1" type="title3">
            {advisor &&
              'Your Hubble advisor has hand-picked the offices in this shortlist'}
            {!advisor &&
              'Our Hubble advisors have hand-picked the offices in this shortlist'}
          </Heading>

          {advisor && (
            <ImgixImage
              alt="Advisor"
              height={80}
              src={advisor.image}
              width={80}
            />
          )}
          {!advisor && (
            <ImgixImage
              alt="Advisor"
              height={80}
              src={ADVISOR_GENERIC_IMAGE_SRC}
              width={192}
            />
          )}
          {advisor && (
            <OutboundLink
              href={advisor.linkedin}
              rel="noopener noreferrer"
              styleType="secondary"
              target="_blank"
            >
              <div className={styles['AdvisorModalContent-linkedin']}>
                <b>{advisor.name}</b>
                <Asset
                  alt="LinkedIn"
                  height="16px"
                  sizingBehaviour="fixed"
                  src={linkedin}
                  width="16px"
                />
              </div>
            </OutboundLink>
          )}

          <VerticalSpacing size="xs" />

          <TextWithIcon
            iconName="mail"
            iconPosition="left"
            text={advisor ? advisor.email : ADVISOR_GENERIC_EMAIL}
          />

          <VerticalSpacing size="xs" />

          <TextWithIcon
            iconName="call"
            iconPosition="left"
            text={advisor ? advisor.tel : ADVISOR_GENERIC_PHONE_NO}
          />

          <VerticalSpacing size="md" />

          <Paragraph>
            Don’t hesitate to get in touch if you have any questions about these
            options, need further information or want me to schedule any
            viewings for you.
          </Paragraph>
        </Centered>
      </ModalNewTemplateWithSidebarSidebar>
      <ModalNewTemplateWithSidebarContent>
        <Heading level="2" type="title3">
          Hubble’s promise to you
        </Heading>

        <VerticalSpacing size="md" />

        <UnstyledList>
          <ListItemWithIcon
            iconColor="neutral-900"
            iconName="check_circle"
            text={
              <span className={styles['Content-listItemText']}>
                <b>Real listings</b> - no wasting time with unavailable, fake or
                outdated listings
              </span>
            }
          />
          <ListItemWithIcon
            iconColor="neutral-900"
            iconName="check_circle"
            text={
              <span className={styles['Content-listItemText']}>
                <b>Best price guarantee</b> - you won’t find a better deal
                anywhere else (even going direct)
              </span>
            }
          />
          <ListItemWithIcon
            iconColor="neutral-900"
            iconName="check_circle"
            text={
              <span className={styles['Content-listItemText']}>
                <b>Spam free zone</b> - rest easy knowing we won’t share your
                contact details with third parties. Ever
              </span>
            }
          />
          <ListItemWithIcon
            iconColor="neutral-900"
            iconName="check_circle"
            text={
              <span className={styles['Content-listItemText']}>
                <b>Free, friendly expertise</b> - get zero-pressure advice and
                help with negotiations at no cost to you
              </span>
            }
          />
        </UnstyledList>

        <VerticalSpacing size="md" />

        <ImgixImage
          alt="Trustpilot five star score"
          height={19}
          src="/site/homepage/trustpilot-black-letters.svg"
          width={208}
        />
      </ModalNewTemplateWithSidebarContent>
    </ModalNewTemplateWithSidebar>
  );
}

AdvisorModalContent.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
};
