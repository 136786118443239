import { Badges, Paragraph } from 'site-react/components/typography';

import styles from './HqListingTypeDescriptionModalContent.module.css';

const HqListingTypeDescriptionModalContent = ({ title, description }) => {
  return (
    <div className={styles['HqListingTypeDescriptionModalContent']}>
      <div className={styles['HqListingTypeDescriptionModalContent-heading']}>
        <Badges.Badge
          color={title === 'Offer' ? 'brandprimary' : 'white'}
          isBorderVariant
          label={title}
        />
      </div>
      <div>
        <Paragraph type="content1">{description}</Paragraph>
      </div>
    </div>
  );
};

export default HqListingTypeDescriptionModalContent;
