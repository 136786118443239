import { createContext, useCallback, useMemo } from 'react';

import useLocalStorage from 'site-react/hooks/useLocalStorage';

const PartTimeContext = createContext({
  calculatePrice: () => {},
  hasMetMinimumDayRequirement: false,
  partTimePreferences: {
    days: ['monday', 'friday'],
  },
  removePartTimePreferences: () => {},
  setPartTimePreferences: () => {},
});

const offPeakDays = ['monday', 'friday'];
const peakDays = ['tuesday', 'wednesday', 'thursday'];

function usePartTime() {
  const [
    partTimePreferencesOriginal,
    setPartTimePreferencesOriginal,
    removePartTimePreferences,
  ] = useLocalStorage('partTimePreferences', {
    days: ['monday', 'friday'],
  });

  const partTimePreferences = useMemo(() => {
    /**
     * We had an issue where we misconfigured the value of partTimePreferences so that you'd
     * end up with { days: { days: { ... days: [ ... ] } } } an unspecified amount of times.
     *
     * The way we're handling that is to check if the value of partTimePreferences.days is configured
     * as we would expect it to and if it isn't return some default value.
     *
     * This preserves the original value of partTimePreferences while also ensuring that we don't
     * accidentally break the app if the value is misconfigured.
     *
     * We can follow up to clean this up in a couple of weeks (CURRENT DATE 22/03/2024)
     */
    if (
      !partTimePreferencesOriginal?.days ||
      !Array.isArray(partTimePreferencesOriginal.days)
    ) {
      return { days: ['monday', 'friday'] };
    }

    // Remove any invalid days from preferences
    const validDays = [...offPeakDays, ...peakDays];
    return {
      days: partTimePreferencesOriginal.days.filter((day) =>
        validDays.includes(day),
      ),
    };
  }, [partTimePreferencesOriginal.days]);

  const hasMetMinimumDayRequirement = partTimePreferences?.days?.length >= 1;

  const calculatePrice = useCallback(
    ({ partTimePriceOffPeak, partTimePricePeak }) => {
      // Calculate the price, based on the user's preferences. If no preferences
      // are set, returns null.
      //
      // Basic algorithm is:
      // - Get number of off-peak days from preferences. Multiply by off-peak price.
      // - Get number of peak days from preferences. Multiply by peak price.
      // - Return sum of both.
      const numberPeakDays = partTimePreferences.days.filter((day) =>
        peakDays.includes(day),
      ).length;
      const numberOffPeakDays = partTimePreferences.days.filter((day) =>
        offPeakDays.includes(day),
      ).length;

      if (numberPeakDays + numberOffPeakDays > 0) {
        return (
          numberOffPeakDays * partTimePriceOffPeak +
          numberPeakDays * partTimePricePeak
        );
      }

      return null;
    },
    [partTimePreferences],
  );

  /**
   * A wrapper around setPartTimePreferences that allows for updating only valid days.
   * @param {Array} newPreferences
   * @returns {void}
   * @example
   * setPartTimePreferences({ days: ['monday', 'friday', 'saturday'] });
   * // partTimePreferences = ['monday', 'friday']
   */
  function setPartTimePreferences(newPreferences) {
    const validDays = [...offPeakDays, ...peakDays];

    // Reject any invalid day values
    const sanitisedPreferences = {
      days: newPreferences.days.filter((day) => validDays.includes(day)),
    };

    setPartTimePreferencesOriginal(sanitisedPreferences);
  }

  return {
    calculatePrice,
    hasMetMinimumDayRequirement,
    partTimePreferences,
    removePartTimePreferences,
    setPartTimePreferences,
  };
}

/**
 * Stores user preferences related to part time offices, such as days they would
 * like an office for. Provides methods for calculating prices based on those
 * preferences.
 */
export default function PartTimeContextProvider({ children }) {
  const partTime = usePartTime();

  return (
    <PartTimeContext.Provider value={partTime}>
      {children}
    </PartTimeContext.Provider>
  );
}
export { PartTimeContext };
