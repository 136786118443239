import PropTypes from 'prop-types';

export default PropTypes.exact({
  admin: PropTypes.bool,
  avatar: PropTypes.string,
  company: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  favourites: PropTypes.arrayOf(PropTypes.number),
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  hash: PropTypes.string,
  host: PropTypes.bool,
  icalToken: PropTypes.string,
  id: PropTypes.number.isRequired,
  is_active: PropTypes.bool,
  is_serviced_office_vr_ready: PropTypes.bool,
  isActive: PropTypes.bool,
  isServicedOfficeVrReady: PropTypes.bool,
  last_login: PropTypes.string,
  lastLogin: PropTypes.string,
  lastName: PropTypes.string,
  loggedInAs: PropTypes.any,
  name: PropTypes.string,
  organisation: PropTypes.exact({
    activatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.number,
    isBillingReferenceRequired: PropTypes.bool,
    memberDefaultSpendLimit: PropTypes.any,
    name: PropTypes.string,
    passActivatedAt: PropTypes.string,
    size: PropTypes.any,
  }),
  people: PropTypes.number,
  permissions: PropTypes.exact({
    bookEventSpace: PropTypes.string,
    bookMeetingRooms: PropTypes.string,
    bookPrivateOffices: PropTypes.string,
  }),
  phone_country_code: PropTypes.string,
  phone_number: PropTypes.string,
  phoneCountryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  regional_phone_number: PropTypes.string,
  regionalPhoneNumber: PropTypes.string,
  role: PropTypes.string,
  serviced_office_provider: PropTypes.bool,
  servicedOfficeProvider: PropTypes.bool,
  verifiedAt: PropTypes.string,
});
