export default {
  9752: {
    // For development purposes
    email: 'lizzie@hubblehq.com',
    id: 9752,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Lizzie Davison',
    tel: '07123456789',
  },
  35349: {
    // Charlie - imitates Henry Yiend
    email: 'henry.yiend@hubblehq.com',
    id: 35349,
    image: '/site/advisors/henry_y.png',
    linkedin: 'https://www.linkedin.com/in/henry-yiend-6461658b/',
    name: 'Henry Yiend',
    tel: '07894758908',
  },
  46774: {
    // For development purposes
    email: 'isabela@hubblehq.com',
    id: 46774,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Isabela Hodorog',
    tel: '07123456789',
  },
  47318: {
    // For development purposes
    email: 'simon@hubblehq.com',
    id: 47318,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Simon Andrews',
    tel: '07123456789',
  },
  47508: {
    // For development purposes
    email: 'ollie@hubblehq.com',
    id: 47508,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Ollie Crook',
    tel: '07123456789',
  },
  48793: {
    // For development purposes
    email: 'rick@hubblehq.com',
    id: 48793,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Rick Hartley',
    tel: '07123456789',
  },
  53195: {
    email: 'henry.yiend@hubblehq.com',
    id: 53195,
    image: '/site/advisors/henry_y.png',
    linkedin: 'https://www.linkedin.com/in/henry-yiend-6461658b/',
    name: 'Henry Yiend',
    tel: '07894758908',
  },
  72251: {
    email: 'gabriel@hubblehq.com',
    id: 72251,
    image: '/site/advisors/gabriel.png',
    linkedin: 'https://www.linkedin.com/in/gabriel-easterby-63b6b71b7/',
    name: 'Gabriel Easterby',
    tel: '07369206553',
  },
  77716: {
    email: 'bessie@hubblehq.com',
    id: 77716,
    image: '/site/advisors/bessie.png',
    linkedin: 'https://www.linkedin.com/in/bessie-griffiths-480b5a227/',
    name: 'Bessie Griffiths',
    tel: '07769185162',
  },
  82073: {
    email: 'kate@hubblehq.com',
    id: 82073,
    image: '/site/advisors/Kate.png',
    linkedin: 'https://www.linkedin.com/in/kate-mehigan-947722103/',
    name: 'Kate Mehigan',
    tel: '07447058277',
  },
  83073: {
    email: 'barney@hubblehq.com',
    id: 83073,
    image: '/site/advisors/barney.png',
    linkedin: 'https://www.linkedin.com/in/barney-wilson-a973679b/',
    name: 'Barney Wilson',
    tel: '07722762802',
  },
  92870: {
    email: 'claire.saunders@hubblehq.com',
    id: 92870,
    image: '/site/advisors/claire.png',
    linkedin: 'https://www.linkedin.com/in/claire-saunders-596b77a7/',
    name: 'Claire Saunders',
    tel: '07366285685',
  },
  94746: {
    // For development purposes
    email: 'arron.moore@hubblehq.com',
    id: 94746,
    image: '/site/advisors/pingu.jpg',
    linkedin: 'https://www.linkedin.com/',
    name: 'Arron Moore',
    tel: '07123456789',
  },
  96669: {
    email: 'fleur.sylvester@hubblehq.com',
    id: 96669,
    image: '/site/advisors/fleur.png',
    linkedin: 'https://www.linkedin.com/in/fleur-sylvester-32b707149/',
    name: 'Fleur Sylvester',
    tel: '07816149849',
  },
  100493: {
    email: 'max.montane-willis@hubblehq.com',
    id: 100493,
    image: '/site/advisors/max.png',
    linkedin: 'https://www.linkedin.com/in/max-montané-willis-253521214/',
    name: 'Max Montane-Willis',
    tel: '07507669714',
  },
};

export const ADVISOR_GENERIC_IMAGE_SRC = '/site/advisors/generic.png';
export const ADVISOR_GENERIC_EMAIL = 'advisors@hubblehq.com';
export const ADVISOR_GENERIC_PHONE_NO = '+44 20 3608 0215';
