import PropTypes from 'prop-types';
import { useContext } from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Link } from 'site-react/components/navigation';
import { UICard, UICardButton } from 'site-react/components/page';
import ModalNew, {
  ModalNewTemplateBasic,
} from 'site-react/components/page/ModalNew';
import {
  Heading,
  MaterialIcon,
  TextWithIcon,
  Paragraph,
} from 'site-react/components/typography';
import { ConfirmModal } from 'site-react/components/utility';
import config from 'site-react/config';
import { AlertContext } from 'site-react/data/core/AlertContext';
import useAdvisor from 'site-react/hooks/useAdvisor';

import styles from './AdvisorRecommendationList.module.css';
import AdvisorUpdateRecommendationListFormModal from '../../AdvisorUpdateRecommendationListFormModal';

export default function AdvisorRecommendationList({
  onDelete = () => {},
  onUpdate = () => {},
  recommendationList,
}) {
  const {
    enterBuildMode,
    exitBuildMode,
    recommendationList: activeRecommendationList,
  } = useAdvisor();

  const { addSuccess } = useContext(AlertContext);

  const recommendationLink = `${config.HUBBLE_URL}/recommendations/${recommendationList.token}`;

  const isRecommendationListActive =
    activeRecommendationList?.id === recommendationList.id;

  const uiCardProps = {
    boxShadow: 'subtle',
    height: 'shrink-wrap',
    style: {
      borderRadius: 'var(--space-xs)',
      padding: 'var(--space-sm)',
    },
    width: 'shrink-wrap',
  };

  function handleBuildClick(id) {
    if (isRecommendationListActive) {
      exitBuildMode();
    } else {
      enterBuildMode(id);

      window.open('/office-space-london', '_blank');
    }
  }

  function copyRecommendationListLink() {
    navigator.clipboard.writeText(recommendationLink);

    addSuccess('Link copied to clipboard');
  }

  async function deleteAdminRecommendationList(recommendationList) {
    const spacious = new SpaciousApi();
    await spacious.deleteAdminRecommendationList(recommendationList.id);
    onDelete(recommendationList);
  }

  return (
    <UICard
      style={{
        borderRadius: 'var(--space-xs)',
        ...(isRecommendationListActive && {
          border: '2px solid var(--color-green-200)',
        }),
      }}
    >
      <div className={styles.AdvisorRecommendationList}>
        <div>
          <Link
            href={recommendationLink}
            isUnderlined={false}
            styleType="secondary"
            target="_blank"
          >
            <Heading type="title1">{recommendationList.name}</Heading>
          </Link>
          <Paragraph>{recommendationList.requesterEmail}</Paragraph>
        </div>
        <div className={styles['AdvisorRecommendationList-actions']}>
          <UICardButton
            {...uiCardProps}
            height="fill"
            name="Build"
            onClick={() => {
              handleBuildClick(recommendationList.id);
            }}
            style={{
              ...uiCardProps.style,
              ...(isRecommendationListActive && {
                border: '1px solid var(--color-brandsecondary)',
              }),
            }}
            type="button"
          >
            <TextWithIcon
              contentType="content1"
              iconName="build"
              iconPosition="left"
              text={isRecommendationListActive ? <>Exit&nbsp;build</> : 'Build'}
            />
          </UICardButton>

          <ModalNew
            label="Edit recommendation list"
            renderTrigger={({ openModal }) => (
              <UICardButton {...uiCardProps} name="Edit" onClick={openModal}>
                <MaterialIcon iconType="edit" />
              </UICardButton>
            )}
          >
            <ModalNewTemplateBasic>
              <AdvisorUpdateRecommendationListFormModal
                onSuccess={onUpdate}
                recommendationList={recommendationList}
              />
            </ModalNewTemplateBasic>
          </ModalNew>

          <UICardButton
            {...uiCardProps}
            data-testid="copy-link"
            name="Copy link"
            onClick={copyRecommendationListLink}
            type="button"
          >
            <MaterialIcon iconType="content_copy" />
          </UICardButton>

          <ConfirmModal
            confirmLabel="Yes, delete this list"
            declineLabel="No, keep this list"
            onConfirm={() => {
              deleteAdminRecommendationList(recommendationList);
            }}
            prompt="This will permanently delete this Recommendation List. Are you sure?"
          >
            <UICardButton {...uiCardProps} name="Delete" type="button">
              <MaterialIcon iconType="delete" />
            </UICardButton>
          </ConfirmModal>
        </div>
      </div>
    </UICard>
  );
}
AdvisorRecommendationList.propTypes = {
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  recommendationList: PropTypes.shape({
    advisor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    requesterEmail: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};
