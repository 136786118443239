import PropTypes from 'prop-types';
import React from 'react';

import { Badges } from 'site-react/components/typography';

const OpenUntilLateBadge = ({ closingTime }) => (
  <Badges.Badge
    color="neutral-900"
    iconName="brightness_3"
    label={`open until ${closingTime.replace(/\s/g, '').replace(/:/g, '.')}`}
  />
);

OpenUntilLateBadge.propTypes = {
  closingTime: PropTypes.string.isRequired,
};

export default OpenUntilLateBadge;
