import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const MaterialIcon = ({ className = '', iconType = '', ...props }) => (
  <i {...props} className={cn('material-icons', className)}>
    {iconType}
  </i>
);

MaterialIcon.propTypes = {
  /**
   * Class names to apply on the icon. Can be used to stylize and re-color the icon.
   */
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),

  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconType: PropTypes.string,
};

MaterialIcon.Menu = (props) => <MaterialIcon {...props}>menu</MaterialIcon>;

export default MaterialIcon;
