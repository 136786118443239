import countryCodes from './country_codes.json';

/**
 * Gets a list of all the country codes.
 *
 * Each country will have the following keys:
 * - name: The name of the country for this code
 * - flag: An emoji representing the country's flag
 * - code: An ISO-3166 shortcode for the country, eg. GB for United Kingdom, or CA for Canada
 * - dial_code: The country code, prefixed with +, eg. +44 for United Kingdom, or +1 for Canada
 *
 * @returns [Object]
 */
export default function getCountryCodes() {
  return [...countryCodes];
}
