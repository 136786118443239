import config from 'site-react/config';

const getParsedUrl = (url) => {
  if (typeof window !== 'undefined' && window.location.href) {
    // When we're currently on a page, we can intuit relative URLs by basing
    // them on window.location
    return new URL(url, window.location.href);
  }
  return new URL(url);
};

export default function isValidHubbleUrl(url) {
  const parsedUrl = getParsedUrl(url);

  // If the current domain is the same as the HUBBLE_URL domain, then allow it.
  const hubbleUrl = new URL(config.HUBBLE_URL);
  if (
    parsedUrl.protocol === hubbleUrl.protocol &&
    parsedUrl.hostname === hubbleUrl.hostname &&
    parsedUrl.port === hubbleUrl.port
  ) {
    return true;
  }

  if (parsedUrl.protocol === 'https:') {
    return /(^|\.)hubblehq.(com|dev)$/.test(parsedUrl.hostname);
  } else {
    /**
     * End-to-end tests currently work over http and not https.
     * Until this is fixed we have to class dev environments over
     * http as valid.
     */
    if (parsedUrl.hostname === 'dev.hubblehq.dev') {
      return true;
    }
    return false;
  }
}
