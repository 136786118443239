/**
 * @param {string} - Image src
 * @param {int} options.dpr - Device pixel ratio
 * @param {int} options.fit - How image is fit to the target size
 * @param {int} option.h - Resize height
 * @param {int} options.q - Quality percentage 1-100
 * @param {int} option.w - Resize width
 *
 * For docs on the parameters used, see:
 * * auto: https://docs.imgix.com/apis/url/auto
 * * dpr: https://docs.imgix.com/apis/url/pixel-density/dpr
 * * fit: https://docs.imgix.com/apis/url/size/fit
 * * h: https://docs.imgix.com/apis/url/size/h
 * * q: https://docs.imgix.com/apis/url/format/q
 * * w: https://docs.imgix.com/apis/url/size/w
 */
const getImgixPathWithParameters = (path, options) => {
  const url = new URLSearchParams();
  url.set('auto', 'format,compress');

  Object.entries(options).forEach((option) => {
    const [key, value] = option;
    url.set(key, value);
  });

  return path + '?' + url.toString();
};

export default getImgixPathWithParameters;
