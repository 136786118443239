import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { meetingRoom } from 'site-react/assets';
import { InfoPanel, Modal } from 'site-react/components/page';
import {
  ActionText,
  Heading,
  Paragraph,
} from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

import MeetingRoomProductList from './MeetingRoomProductList';
import * as styles from './styles';
import ProductBookingDetailsWrapper from '../PassProducts/ProductBookingDetailsWrapper';
import ProductOverviewWrapper from '../PassProducts/ProductOverviewWrapper';

const MeetingRoomProducts = ({
  bookingResponseTime,
  building,
  products,
  isInstantBookAvailable,
}) => {
  const longestFacilitiesString = useMemo(() => {
    const productsWithFacilities = products.filter(
      (product) => product.facilities,
    );
    if (productsWithFacilities.length) {
      return productsWithFacilities.reduce((currentLongest, product) =>
        product.facilities.length > currentLongest.facilities.length
          ? product
          : currentLongest,
      ).facilities;
    } else {
      return null;
    }
  }, [products]);

  const isCapacityDuplicated = useMemo(
    () =>
      products.length !==
      new Set(products.map((product) => product.capacity)).size,
    [products],
  );

  return (
    <ProductOverviewWrapper
      description="Rooms to meet and present"
      imageSrc={meetingRoom}
      title="Meeting Rooms"
    >
      {longestFacilitiesString && (
        <>
          <VerticalSpacing size="md" />
          <HorizontalRule />
          <VerticalSpacing size="md" />
          <div className={styles.innerStyle}>
            <div className={styles.facilitiesStyle}>
              <div className={styles.truncatedTextStyle}>
                <Heading level="3" type="title4">
                  Available room facilities
                </Heading>
                <Paragraph type="content3">{longestFacilitiesString}</Paragraph>
              </div>
              <Modal
                modalName="View All Meeting Room Facilities"
                renderTrigger={({ openModal }) => (
                  <ActionText
                    contentType="content3"
                    label="View all"
                    onClick={openModal}
                    styleType="primary"
                  >
                    View all
                  </ActionText>
                )}
              >
                <div className={styles.facilitiesContentStyle}>
                  <Heading level="3" type="title3">
                    Meeting rooms in this building have the following facilities
                    available
                  </Heading>
                  <VerticalSpacing size="lg" />
                  <Paragraph type="content3">
                    {longestFacilitiesString}
                  </Paragraph>
                  <Paragraph type="content3">
                    <b>
                      Note: Not all of our meeting rooms have the same
                      facilities so make sure you let us know what you need when
                      booking.
                    </b>
                  </Paragraph>
                </div>
              </Modal>
            </div>
          </div>
        </>
      )}

      {isCapacityDuplicated && (
        <>
          <VerticalSpacing size="md" />

          <InfoPanel>
            <details className={styles.pricingQuestionStyle}>
              <summary className={styles.pricingQuestionContentStyle}>
                <span className={styles.pricingQuestionHeadingStyle}>
                  Why are some rooms of the same size priced differently?
                </span>
                <span
                  className={css`
                    font: var(--font-style-content-3);
                    padding-left: var(--space-lg);
                  `}
                >
                  <u>Details</u>
                </span>
                <span className={styles.expandIconStyle} />
              </summary>
              <VerticalSpacing size="sm" />

              <div
                className={css`
                  padding-left: var(--space-lg);
                `}
              >
                <Paragraph type="content3">
                  Cost can differ from room to room based on factors such as
                  natural light, view, position in the building, overall room
                  size (sqft) and sometimes facilities.
                </Paragraph>
                <Paragraph type="content3">
                  If you have any specific requests for your meeting room you
                  should let us know in the additional comments section when
                  booking.
                </Paragraph>
              </div>
            </details>
          </InfoPanel>
        </>
      )}

      <ProductBookingDetailsWrapper
        bookingResponseTime={bookingResponseTime}
        heading={isInstantBookAvailable ? 'Book a room' : 'Request to book'}
        showInstantBook={isInstantBookAvailable}
      >
        <MeetingRoomProductList
          building={building}
          isInstantBookAvailable={isInstantBookAvailable}
          products={products}
        />
      </ProductBookingDetailsWrapper>
    </ProductOverviewWrapper>
  );
};

export default MeetingRoomProducts;
