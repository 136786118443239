import { createContext } from 'react';

const Context = createContext({
  isLoggedIn: false,
  logout: () => {},
  parentUser: null,
  refreshUserData: () => {},
  updateUser: () => {},
  user: null,
});

const { Consumer, Provider } = Context;

export { Context, Consumer, Provider }; // JL: At some point we should remove the Consumer object from being exported
