export default function getBookingCTA(
  productType = 'coworking',
  isLoggedIn = true,
  isInstantlyBookable = false,
) {
  if (isLoggedIn) {
    if (productType === 'coworking' || isInstantlyBookable) return 'Book now';
  }
  return 'Book';
}
