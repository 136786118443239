export default {
  CUSTOMER_EVENTS_SEGMENT_TOKEN:
    process.env.NEXT_PUBLIC_CUSTOMER_EVENTS_SEGMENT_TOKEN,
  ENV: process.env.NEXT_PUBLIC_APP_ENV,
  GOOGLE_MAP_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  GOOGLE_MAP_RECOMMENDATION_MAP_ID:
    process.env.NEXT_PUBLIC_GOOGLE_MAP_RECOMMENDATION_MAP_ID,
  GTM_URL: process.env.NEXT_PUBLIC_GTM_URL,
  HTTP_USER_AGENT: process.env.NEXT_PUBLIC_HTTP_USER_AGENT,
  HUBBLE_API_PROXY: process.env.NEXT_PUBLIC_HUBBLE_API_PROXY,
  HUBBLE_API_TOKEN: process.env.NEXT_PUBLIC_HUBBLE_API_TOKEN,
  HUBBLE_API_URL: process.env.NEXT_PUBLIC_HUBBLE_API_URL,
  HUBBLE_URL: process.env.NEXT_PUBLIC_HUBBLE_URL,
  IMGIX_SUBDOMAIN: process.env.NEXT_PUBLIC_IMGIX_SUBDOMAIN,
  IS_STRIPE_INVOICE_CREATION_ENABLED: true,
  secrets: {
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_BUCKET: process.env.AWS_BUCKET,
    AWS_REGION: process.env.AWS_REGION,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    TREVOR_API_KEY: process.env.TREVOR_API_KEY,
  },
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SLACK_APP_CLIENT_ID: process.env.NEXT_PUBLIC_SLACK_APP_CLIENT_ID,
  STRIPE_PAYG_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_PAYG_PRICE_ID,
  STRIPE_PAYG_PRICE_ID_5: process.env.NEXT_PUBLIC_STRIPE_PAYG_PRICE_ID_5,
  STRIPE_PK: process.env.NEXT_PUBLIC_STRIPE_PK,
  STRIPE_SUBSCRIPTION_PRICE_ID:
    process.env.NEXT_PUBLIC_STRIPE_SUBSCRIPTION_PRICE_ID,
  STRIPE_TOP_UP_PRICE_ID: process.env.NEXT_PUBLIC_STRIPE_TOP_UP_PRICE_ID,
  STRIPE_TOP_UP_PRICE_ID_5: process.env.NEXT_PUBLIC_STRIPE_TOP_UP_PRICE_ID_5,
  TREVOR_HOST_BOOKINGS_REPORT_URL:
    process.env.NEXT_PUBLIC_TREVOR_HOST_BOOKINGS_REPORT_URL,
  TREVOR_HOST_INVENTORY_STATUS_REPORT_URL:
    process.env.NEXT_PUBLIC_TREVOR_HOST_INVENTORY_STATUS_REPORT_URL,
  TREVOR_HOST_REPORT_URL: process.env.NEXT_PUBLIC_TREVOR_HOST_REPORT_URL,
  TREVOR_USAGE_REPORT_URL: process.env.NEXT_PUBLIC_TREVOR_USAGE_REPORT_URL,
  USE_REVIEW_APP_URL: process.env.NEXT_PUBLIC_USE_REVIEW_APP_URL,
};
