import { HttpError, NetworkError } from '@hubble/request';

import logError from 'site-react/helpers/logError';

/**
 * Handle a login error. Gracefully determines a user-facing error message, depending on the severity of the error. For more severe errors, will log into Sentry so that a developer can investigate.
 *
 * @param {Error} error - an error, resulting from a login request
 * @returns a user-friendly error message that can be displayed
 * @private
 * @memberof module:login
 */
const handleLoginError = (error) => {
  if (error instanceof HttpError) {
    switch (error.statusCode) {
      case 400:
      case 401:
      case 403:
        return error.error;

      default:
        // Unexpected state. Log to Sentry, and show a generic message.
        logError(error);
        return `We were unable to log you in. Error code: ${error.statusCode}`;
    }
  } else if (error instanceof NetworkError) {
    return 'We were unable to log you in. Your network may be disconnected, or Hubble may be down.';
  } else {
    logError(error);
    return 'An error occurred, and we were unable to log you in.';
  }
};

export default handleLoginError;
