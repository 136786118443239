import cn from 'classnames';

import { Grid } from 'site-react/components/page';
import { TextWithIcon } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';
import { OfficeCardRecommendationListButton } from 'site-react/features/Recommendations';
import useAdvisor from 'site-react/hooks/useAdvisor';
import usePartTime from 'site-react/hooks/usePartTime';

import styles from './PrivateOfficePartTimeCard.module.css';
import OfficeCardAvailableFromLabel from '../OfficeCardAvailableFromLabel';
import OfficeCardButtonGroup from '../OfficeCardButtonGroup';
import OfficeCardCapacityHeading from '../OfficeCardCapacityHeading';
import OfficeCardFeaturesList from '../OfficeCardFeaturesList';
import OfficeCardHoppyLink from '../OfficeCardHoppyLink';
import OfficeCardStatsList from '../OfficeCardStatsList';
import OfficeCardTotalCost from '../OfficeCardTotalCost';

const PrivateOfficePartTimeCard = ({
  additionalCosts,
  availableFromText,
  accessHours,
  buildingName,
  canLeaveMonitor,
  capacity,
  cohort = 'control',
  enableHoppyLink,
  floor,
  isHighlighted,
  leavingEquipment,
  location,
  maxTerm,
  minTerm,
  partTimePriceOffPeak,
  partTimePricePeak,
  pricePlanId,
  renderedMarkdownPricePlanContent: { keyFeatures, notIncluded },
  sqft,
  userEmail,
}) => {
  const { recommendationList } = useAdvisor();
  const { calculatePrice, partTimePreferences } = usePartTime();

  const isCardExpandable =
    additionalCosts || keyFeatures || leavingEquipment || notIncluded;

  const WrapperElement = isCardExpandable ? 'details' : 'div';
  const ContentElement = isCardExpandable ? 'summary' : 'div';

  const totalPrice = calculatePrice({
    partTimePriceOffPeak,
    partTimePricePeak,
  });

  return (
    <div
      className={cn(styles['PrivateOfficePartTimeCard'], {
        [styles['PrivateOfficePartTimeCard--isHighlighted']]: isHighlighted,
      })}
      data-testid="private-office-with-part-time-card"
    >
      <Grid>
        <Grid.Item colSpan="9">
          <WrapperElement className={styles['PrivateOfficePartTimeCard-left']}>
            <ContentElement
              className={styles['PrivateOfficePartTimeCard-content']}
            >
              <div className={styles['PrivateOfficePartTineCard-badges']}>
                <OfficeCardAvailableFromLabel
                  availableFromText={availableFromText}
                />
                {recommendationList ? (
                  <OfficeCardRecommendationListButton
                    isPartTime
                    pricePlanId={pricePlanId}
                  />
                ) : null}
                {enableHoppyLink ? (
                  <OfficeCardHoppyLink pricePlanId={pricePlanId} />
                ) : null}
              </div>
              <VerticalSpacing size="sm" />
              <OfficeCardCapacityHeading capacity={capacity} />
              <VerticalSpacing size="sm" />
              <OfficeCardStatsList
                accessHours={accessHours}
                canLeaveMonitor={canLeaveMonitor}
                floor={floor}
                maxMonthsTerm={maxTerm}
                minMonthsTerm={minTerm}
                sqft={sqft}
              />
              <VerticalSpacing size="sm" />
              <div className={styles['horizontalRule-hideOnMobile']}>
                <HorizontalRule />
              </div>
              <VerticalSpacing size="md" />
              {isCardExpandable && (
                <span
                  className={styles['PrivateOfficePartTimeCard-expandIcon']}
                >
                  <u>See office details</u>
                </span>
              )}
            </ContentElement>
            <VerticalSpacing size="md" />
            <OfficeCardFeaturesList
              additionalCosts={additionalCosts}
              featuresExcluded={notIncluded}
              featuresIncluded={keyFeatures}
              leavingEquipment={leavingEquipment}
            />
          </WrapperElement>
        </Grid.Item>
        <Grid.Item colSpan="3" colStart="10">
          <div className={styles['PrivateOfficePartTimeCard-right']}>
            <div className={styles['PrivateOfficePartTimeCard-pricing']}>
              <div className={styles['PrivateOfficePartTimeCard-badge']}>
                <span>
                  <b>
                    {partTimePreferences.days.length > 1 ? (
                      <>{partTimePreferences.days.length}&nbsp;days</>
                    ) : (
                      <>{partTimePreferences.days.length}&nbsp;day</>
                    )}
                  </b>
                </span>
                {partTimePreferences.days
                  .sort((a, b) => {
                    const days = [
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                    ];
                    return days.indexOf(a) - days.indexOf(b);
                  })
                  .map((day) => (
                    <abbr key={day} title={day}>
                      {day.slice(0, 2)}
                    </abbr>
                  ))}
              </div>

              <VerticalSpacing size="md" />
              <OfficeCardTotalCost totalPrice={totalPrice} />
            </div>
            <VerticalSpacing size="md" />
            <OfficeCardButtonGroup
              buildingName={buildingName}
              cohort={cohort}
              location={location}
              officeAccess="partTime"
              pricePlanId={pricePlanId}
              pricePlanSize={capacity}
              userEmail={userEmail}
              uspContent={
                <TextWithIcon
                  contentType="content3"
                  iconName="workspace_premium"
                  iconPosition="left"
                  text={
                    <b>
                      <u>Best price guarantee</u>
                    </b>
                  }
                />
              }
            />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default PrivateOfficePartTimeCard;
