import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './AccountIconLetter.module.css';

const AccountIconLetter = ({ hover, letter, size }) => (
  <div
    className={cn(styles.AccountIconLetter, {
      [styles['AccountIconLetter--hover']]: hover,
    })}
    data-testid="account-icon"
    style={{
      '--AccountIconLetter-size': `${size + 4}px`,
    }}
    title="Your Account"
  >
    <span
      className={styles['AccountIconLetter-letter']}
      style={{
        '--AccountIconLetter-lineHeight': `${size + 3}px`,
      }}
    >
      {letter}
    </span>
  </div>
);

AccountIconLetter.propTypes = {
  /**
   * Should we show hover styles on this letter?
   */
  hover: PropTypes.bool.isRequired,

  /**
   * The letter to show
   */
  letter: PropTypes.string.isRequired,

  /**
   * Size of the letter/icon, in pixels
   */
  size: PropTypes.number.isRequired,
};

export default AccountIconLetter;
