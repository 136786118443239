import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import styles from './BuildingTable.module.css';

export default function BuildingTable({
  columnHeaders,
  rowContent,
  onMouseEnter,
  onMouseLeave,
  selectedColIndex,
}) {
  const tableRef = useRef(null);

  useEffect(() => {
    removeHighlight(selectedColIndex);
    if (selectedColIndex >= 0) {
      addHighlight(selectedColIndex);
    }
  }, [selectedColIndex]);

  function removeHighlight(colIndex) {
    const table = tableRef.current;
    if (table) {
      const cells = table.querySelectorAll(`.${styles['isHighlighted']}`);
      cells.forEach((cell) => cell.classList.remove(styles['isHighlighted']));
    }
  }

  function addHighlight(colIndex) {
    const table = tableRef.current;
    if (table) {
      const cells = table.querySelectorAll(
        `td:nth-child(${colIndex + 2}), th:nth-child(${colIndex + 2})`,
      );
      cells.forEach((cell) => cell.classList.add(styles['isHighlighted']));
    }
  }

  function handleMouseEnter(colIndex) {
    const table = tableRef.current;
    if (table) {
      onMouseEnter(table, colIndex);
    }
  }

  function handleMouseLeave(colIndex) {
    const table = tableRef.current;
    if (table) {
      onMouseLeave(table, colIndex);
    }
  }

  return (
    <table className={styles.BuildingTable} ref={tableRef}>
      <thead>
        <tr className={styles['BuildingTable-xAxisHeader']}>
          <th className={styles['BuildingTable-cornerHeaderItem']} />
          {columnHeaders.map((headerItem, index) => (
            <th
              className={styles['BuildingTable-xAxisHeaderItem']}
              key={headerItem}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {headerItem}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowContent.map((rowItem) => {
          return (
            <React.Fragment key={rowItem.header.id}>
              <tr
                className={cn(styles['BuildingTable-yAxisHeader'], {
                  [styles['BuildingTable-groupedRow']]: rowItem.subHeaders,
                })}
              >
                <th
                  className={cn(styles['BuildingTable-yAxisHeaderItem'], {
                    [styles['isString']]:
                      typeof rowItem.header.content === 'string',
                    [styles['BuildingTable-groupedHeaderItem']]:
                      rowItem?.subHeaders,
                  })}
                >
                  {rowItem.header.content}
                </th>
                {rowItem?.data?.map((cellData, index) => {
                  return (
                    <td
                      className={styles['BuildingTable-cell']}
                      key={cellData.id}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <p className={styles['BuildingTable-cellTitle']}>
                        {rowItem.header.content}
                      </p>
                      {cellData.content}
                    </td>
                  );
                })}
                {rowItem?.subHeaders?.[0]?.data?.map((subHeader, subIndex) => {
                  return (
                    <th
                      className={cn(
                        styles['BuildingTable-groupedHeaderItem'],
                        styles['BuildingTable-groupedHeaderWithSubheader'],
                      )}
                      key={subHeader.id}
                      onMouseEnter={() => handleMouseEnter(subIndex)}
                      onMouseLeave={() => handleMouseLeave(subIndex)}
                    />
                  );
                })}
              </tr>

              {rowItem.subHeaders &&
                rowItem.subHeaders.map((subHeader) => {
                  return (
                    <tr
                      className={cn(
                        styles['BuildingTable-yAxisHeader'],
                        styles['BuildingTable-groupedRow'],
                      )}
                      key={subHeader.header.id}
                    >
                      <th
                        className={cn(
                          styles['BuildingTable-yAxisHeaderItem'],
                          styles['BuildingTable-groupedHeaderItem'],
                          {
                            [styles['isString']]:
                              typeof subHeader.header.content === 'string',
                          },
                        )}
                      >
                        {subHeader.header.content}
                      </th>
                      {subHeader.data.map((subCellData, subIndex) => {
                        return (
                          <td
                            className={cn(
                              styles['BuildingTable-cell'],
                              styles['BuildingTable-groupedCell'],
                            )}
                            key={subCellData.id}
                            onMouseEnter={() => {
                              handleMouseEnter(subIndex);
                            }}
                            onMouseLeave={() => {
                              handleMouseLeave(subIndex);
                            }}
                          >
                            <p className={styles['BuildingTable-cellTitle']}>
                              {subHeader.header.content}
                            </p>
                            {subCellData.content}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

BuildingTable.propTypes = {
  /**
   * Content to display along the horizontal axis.
   */
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ).isRequired,

  /**
   * Content to display along the vertical axis. This includes y axis headers and
   * the data to display in the table.
   */
  rowContent: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
            .isRequired,
          id: PropTypes.string.isRequired,
        }),
      ),
      header: PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
          .isRequired,
        id: PropTypes.string.isRequired,
      }),
      subHeaders: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
                .isRequired,
              id: PropTypes.string.isRequired,
            }),
          ),
          header: PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
              .isRequired,
            id: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  ).isRequired,
};
