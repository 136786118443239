import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import styles from './MegaMenu.module.css';
import NavBarMenuItem from './NavBarMenuItem';

/**
 * A large menu, providing lots of real estate for multi-column content at large breakpoints.
 *
 * NOTE: you must _not_ set `relative` on the `NavBar.MenuItem` that triggers it, in order to get the positioning correct.
 */
const MegaMenu = ({ children }) => {
  const { isOpen } = useContext(NavBarMenuItem.Context);

  return (
    <div
      aria-hidden={!isOpen}
      className={cn(styles.MegaMenu, {
        [styles['MegaMenu--open']]: isOpen,
      })}
    >
      {children}
    </div>
  );
};

MegaMenu.propTypes = {
  /**
   * Children, expecting inline styled elements
   */
  children: PropTypes.node,
};

export default MegaMenu;
