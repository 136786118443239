import PropTypes from 'prop-types';

import { Link } from 'site-react/components/navigation';

export default function ViewOffices({ suffix }) {
  return (
    <Link href="#" styleType="secondary">
      𝑥 desks{suffix && ` ${suffix}`}
    </Link>
  );
}

ViewOffices.propTypes = {
  suffix: PropTypes.string,
};
