import { Button } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import { Section } from 'site-react/components/page';
import ModalNew from 'site-react/components/page/ModalNew';
import usePartTime from 'site-react/hooks/usePartTime';
import {
  BuildingPropTypes,
  PricePlanPropTypes,
  UserPropTypes,
} from 'site-react/proptypes';

import PricePerMonth from './components/PricePerMonth';
import ViewOffices from './components/ViewOffices';
import styles from './ViewingRequestBanner.module.css';
import FullTimeQuoteModal from '../FullTimeQuoteModal';
import PartTimeQuoteModal from '../PartTimeQuoteModal';

export default function ViewingRequestBanner({ building, pricePlan, user }) {
  const { calculatePrice } = usePartTime();

  const QuoteModal =
    pricePlan?.typeOfPricePlan === 'part-time-office'
      ? PartTimeQuoteModal
      : FullTimeQuoteModal;

  let price;

  if (!pricePlan) {
    const prices = building.pricePlans
      .map((pricePlan) => {
        if (pricePlan.typeOfPricePlan === 'part-time-office') {
          return calculatePrice(pricePlan);
        }

        return pricePlan.fullTimePrice;
      })
      .sort((a, b) => a - b);

    price = prices[0];
  } else {
    price =
      pricePlan?.typeOfPricePlan === 'part-time-office'
        ? calculatePrice(pricePlan)
        : pricePlan.price;
  }

  return (
    <div className={styles.ViewingRequestBanner}>
      <Section>
        <div className={styles['ViewingRequestBanner-content']}>
          <div className={styles['ViewingRequestBanner-officeDetails']}>
            <ViewOffices suffix={!pricePlan ? ' from' : null} />
            <PricePerMonth price={price} />
          </div>
          <div className={styles['ViewingRequestBanner-actions']}>
            <ModalNew
              id="get-a-quote"
              label="Get a detailed quote"
              renderTrigger={({ openModal }) => (
                <Button
                  isCompact
                  name="Enquire"
                  onClick={openModal}
                  styleType="secondary"
                  type="button"
                >
                  Enquire
                </Button>
              )}
            >
              <QuoteModal
                buildingName={building.name}
                location={building.areas.at(-1).name}
                pricePlanId={pricePlan?.id}
                pricePlanSize={pricePlan?.capacity}
                userEmail={user?.email}
              />
            </ModalNew>

            <LinkAsButton href="#office-details" isCompact name="View Offices">
              View Offices
            </LinkAsButton>
          </div>
        </div>
      </Section>
    </div>
  );
}

ViewingRequestBanner.propTypes = {
  building: BuildingPropTypes.isRequired,
  pricePlan: PricePlanPropTypes,
  user: UserPropTypes,
};
