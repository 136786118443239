import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ErrorMessage, MaterialIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './TextInput.module.css';
import Label from '../Label';

const TextInput = React.forwardRef(
  (
    {
      boxShadow = null,
      errorHasHTML = false,
      errorText = null,
      iconType = null,
      isValidationManaged = false,
      labelText = '',
      labelType = '1',
      placeholder = '',
      setValue = () => {},
      status = null,
      testId = null,
      ...otherInputProps
    },
    ref,
  ) => {
    const [isReadyToValidate, setIsReadyToValidate] = useState(false);

    const inputElement = (
      <>
        {iconType && (
          <MaterialIcon
            className={cn({
              [styles['TextInput-icon']]: !labelText,
              [styles['TextInput-icon--withLabel']]: labelText,
            })}
            iconType={iconType}
          />
        )}
        <input
          className={cn(styles['TextInput-input'], {
            [styles['TextInput-input--withIcon']]: iconType,
            [styles['TextInput-input--invalid']]:
              status === 'error' && (isReadyToValidate || isValidationManaged),
          })}
          data-testid={testId}
          onBlur={() => !isValidationManaged && setIsReadyToValidate(true)}
          onChange={(event) => {
            if (!isValidationManaged) {
              setIsReadyToValidate(false);
              setValue(event.target.value);
            }
          }}
          placeholder={placeholder}
          ref={ref}
          style={{
            '--TextInput-boxShadow': boxShadow
              ? `var(--shadow-${boxShadow})`
              : 'none',
          }}
          {...otherInputProps}
        />
      </>
    );

    return (
      /* Inside a <div />, so that we can use TextInput inside a flexbox (like in InputGroup) */
      <div className={styles.TextInput}>
        {labelText && (
          <Label labelText={labelText} labelType={labelType}>
            {inputElement}
          </Label>
        )}
        {!labelText && inputElement}
        {status === 'error' &&
          errorText !== null &&
          (isReadyToValidate || isValidationManaged) && (
            <>
              <VerticalSpacing size="sm" />
              <ErrorMessage hasHTML={errorHasHTML} isMarginless scrollIntoView>
                {errorText}
              </ErrorMessage>
            </>
          )}
      </div>
    );
  },
);

export default TextInput;

TextInput.propTypes = {
  /**
   * If set, will apply a box shadow to the input field.
   */
  boxShadow: PropTypes.string,

  /**
   * Whether or not the error message contains HTML (as opposed to a simple string)
   */
  errorHasHTML: PropTypes.bool,

  /**
   * Message to show if the status is set to 'error'.
   */
  errorText: PropTypes.string,

  /**
   * This is a string which corresponds to a Material Icon. When set it will be
   * displayed alongside the placeholder.
   */
  iconType: PropTypes.string,

  /**
   * Is validation being mangaged by a higher level component?
   *
   * This is the preferred way to handle validation on Form components.
   */
  isValidationManaged: PropTypes.bool,

  /**
   * What to display above the input field.
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Text displayed in the input field when there is no value.
   */
  placeholder: PropTypes.string,

  /**
   * Callback to pass the entered value back to whichever parent
   * component is using the Input. This component does not manage
   * its own internal value. You must do that from the parent.
   *
   * If you are validating using React Hook Form, you don't need to set this!
   */
  setValue: PropTypes.func,

  /**
   * Used to dynamically display error messages.
   */
  status: PropTypes.oneOf(['error', 'success', null]),

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,
};
