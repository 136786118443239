import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

import config from 'site-react/config';
import { defaultIntegrations } from 'site-react/data/analytics/integrations';

import useSegmentDestinations from './useSegementDestinations';
import loadLegacyAnalytics from '../loadLegacyAnalytics';

const cookieName = 'hbl_cookie_prefs_v1';
const cookieExpires = 365;

const useManageCookiePreferences = (availablePreferences) => {
  const defaultPreferences = availablePreferences.filter(
    (option) => option.isRequired,
  );
  const [preferences, setPreferences] = useState(defaultPreferences);
  const [hasSetPreferences, setHasSetPreferences] = useState(false);
  const [hasLoadedPreferences, setHasLoadedPreferences] = useState(false);
  const destinations = useSegmentDestinations(
    config.CUSTOMER_EVENTS_SEGMENT_TOKEN,
  );

  function setPreferenceCookie(newPreferences) {
    const domain = window.location.hostname;
    let cookieDomain;
    if (domain.includes('hubblehq.dev')) {
      cookieDomain = 'hubblehq.dev';
    } else if (domain.includes('hubblehq.com')) {
      cookieDomain = 'hubblehq.com';
    } else {
      // Running on a non-Hubble domain (like a preview branch deployment). Let
      // the browser choose the domain where the cookie should be set.
      cookieDomain = null;
    }

    // Keep what we are storing smol
    const preferenceNameList = newPreferences.reduce((acc, curr) => {
      acc.push(curr.name);
      return acc;
    }, []);

    Cookies.set(cookieName, JSON.stringify(preferenceNameList), {
      domain: cookieDomain,
      expires: cookieExpires,
    });
  }

  function updatePreferences(newPreferences) {
    if (hasSetPreferences) {
      // if the user is updating their preferences
      // reload the page as we can't reload the
      // analytics after they've been loaded already
      window.location.reload();
    }

    setHasSetPreferences(true);
    setPreferences(newPreferences);
    setPreferenceCookie(newPreferences);
    const integrations = { ...defaultIntegrations };
    const allowedDestinationCategories = preferences.reduce((acc, curr) => {
      acc.push(...curr.categories);
      return acc;
    }, []);

    for (const destination of destinations) {
      const isEnabled = allowedDestinationCategories.includes(
        destination.category,
      );
      integrations[destination.id] = isEnabled;
    }
    loadLegacyAnalytics(destinations, newPreferences);
  }

  function acceptAll() {
    updatePreferences(availablePreferences);
  }

  function declineAll() {
    updatePreferences(defaultPreferences);
  }

  function getPreferences() {
    const preferenceNameString = Cookies.get(cookieName);

    if (!preferenceNameString) {
      setHasLoadedPreferences(true);
      return;
    }

    const preferenceNameList = JSON.parse(preferenceNameString);
    const cookiePreferences = availablePreferences.filter((available) =>
      preferenceNameList.includes(available.name),
    );

    setHasSetPreferences(true);
    setPreferences(cookiePreferences);
    setHasLoadedPreferences(true);
  }

  useEffect(() => {
    getPreferences();
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    acceptAll,
    declineAll,
    hasLoadedPreferences,
    hasSetPreferences,
    preferences,
    setPreferences: updatePreferences,
  };
};

export default useManageCookiePreferences;
