import animation from './animation';
import breakpoints from './breakpoints';
import colors from './colors';
import font from './font';
import grid from './grid';
import shadow from './shadow';
import spacing from './spacing';
import {
  contentText3,
  largeLabel,
  lineHeight,
  smallLabel,
  titleText3,
  titleText4,
} from './typography';
import * as utilities from './utilities';
import zIndex from './zIndex';

const theme = {
  animation,
  breakpoints,
  colors,
  contentText3,
  font,
  grid,
  largeLabel,
  lineHeight,
  shadow,
  smallLabel,
  spacing,
  titleText3,
  titleText4,
  utilities,
  zIndex,
};

export default theme;
