import { css } from '@emotion/css';
import Image from 'next/image';

import { UICardLink } from 'site-react/components/page';
import { Paragraph, TextWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import theme from 'site-react/theme';

const innerCardStyle = css`
  display: flex;
  flex-direction: row;
`;

const imageWrapperStyle = css`
  flex: 0 0 124px;
  position: relative;
`;

const innerContentStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--space-md);
`;

const ctaAlignStyle = css`
  > span {
    align-items: center;
    display: flex;
  }
`;

const FeaturedCard = ({
  ctaContent,
  description,
  href,
  heading,
  imageAlt,
  imageSrc,
  linkType = 'a',
}) => (
  <UICardLink
    href={href}
    linkType={linkType}
    name={heading}
    style={{
      border: 'none',
      padding: 0,
    }}
  >
    <div className={innerCardStyle}>
      <div className={imageWrapperStyle}>
        <Image
          alt={imageAlt}
          fill
          loader={imgixLoader}
          src={getImgixPathWithParameters(imageSrc, {})}
          style={{
            objectFit: 'cover',
            objectPosition: 'left',
          }}
        />
      </div>
      <div className={innerContentStyle}>
        <span
          className={css`
            ${theme.smallLabel}
          `}
        >
          {heading}
        </span>
        <VerticalSpacing size="xs" />
        <Paragraph isMarginless type="content2">
          <b>{description}</b>
        </Paragraph>
        <VerticalSpacing size="xs" />
        <div className={ctaAlignStyle}>
          <TextWithIcon
            color="brandPrimary"
            contentType="content1"
            iconName="chevron_right"
            iconPosition="right"
            iconSize="lg"
            text={<strong style={{ fontSize: '14px' }}>{ctaContent}</strong>}
          />
        </div>
      </div>
    </div>
  </UICardLink>
);

export default FeaturedCard;
