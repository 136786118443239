// The disabled Prop accepts:

// A single date i.e. disabled={new Date(2022, 4, 18)}
// A single matcher i.e. disabled={{ from: new Date(2022, 4, 18), to: new Date(2022, 4, 29) }}
// An array of dates i.e. disabled={[new Date(2022, 5, 10), new Date(2022, 5, 12)]}
// An array of matchers i.e. disabled={[{ from: new Date(2022, 4, 18), to: new Date(2022, 4, 29)}, {dayOfWeek: [0, 6]}]}
// An array of dates and matchers i.e. disabled={[new Date(2022, 5, 10), new Date(2022, 5, 12), { from: new Date(2022, 4, 18), to: new Date(2022, 4, 29)}, {dayOfWeek: [0, 6]}]}

const ARRAY_OF_DATES_OR_MATCHERS = (props, propName, componentName) => {
  // Check if the prop is an array
  if (!Array.isArray(props.disabled)) {
    return new Error(
      `Expected ${propName} to be an array of dates and/or matchers. Instead got ${typeof props.disabled}`,
    );
  }

  // Validate the array elements to check if every element in the array is either a Date instance or object
  if (
    !props.disabled.every(
      (value) => value instanceof Date || typeof value === 'object',
    )
  ) {
    return new Error(
      `Expected ${propName} to be an array of dates or matchers.`,
    );
  }

  return null;
};

const DATE_OR_MATCHER = (props, propName, componentName) => {
  if (
    !(props[propName] instanceof Date || typeof props[propName] === 'object')
  ) {
    return new Error(`Expected ${propName} to be a date or matcher.`);
  }

  return null;
};

const validateDisabledProps = (props, propName, componentName) => {
  if (props[propName] === undefined) {
    return null;
  }
  if (Array.isArray(props[propName])) {
    return ARRAY_OF_DATES_OR_MATCHERS(props, propName, componentName);
  } else {
    return DATE_OR_MATCHER(props, propName, componentName);
  }
};

export default validateDisabledProps;
