import { css } from '@emotion/css';

/**
 * A collection of commonly-used utility styles.
 *
 * This is a place to unify some of our more utilitarian styles that don't
 * work well as components, or are meant to be composed with other style
 * definitions.
 *
 * It is _not_ a place to put frequently-used design elements; those should
 * be implemented as components inside ../core.
 */

/*
 * By definition, this file doesn't have a default export - it's a collection.
 */
/* eslint-disable import/prefer-default-export */

/**
 * Visually hidden. Don't _show_ the content, but allow screen readers and
 * other assistive tech read it.
 *
 * See: https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
export const visuallyHidden = css`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
`;
