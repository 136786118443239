import PropTypes from 'prop-types';
import React from 'react';

import formatPrice from './formatPrice';

const Price = ({
  amount,
  currency = 'GBP',
  locale = undefined,
  precision = 0,
}) => {
  const isNumeric = !Number.isNaN(amount) && Number.isFinite(amount);

  return isNumeric && <>{formatPrice(amount, currency, locale, precision)}</>;
};

Price.currencies = ['GBP'];
Price.locales = [undefined, 'en-GB'];

Price.propTypes = {
  /**
   * The amount to be displayed as a price.
   */
  amount: PropTypes.number.isRequired,

  /**
   * An ISO 4217 currency code.
   * https://en.wikipedia.org/wiki/ISO_4217#Active_codes
   */
  currency: PropTypes.oneOf(Price.currencies),

  /**
   * An BCP 47 language tag, or `undefined` to use host default.
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
   */
  locale: PropTypes.oneOf(Price.locales),

  /**
   * An integer representing the number of decimal places to display.
   */
  precision: PropTypes.number,
};

export default Price;
