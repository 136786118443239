import { usePathname, useSearchParams } from 'next/navigation';

import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';

function useLinkWithNextParam(href, isLogout = false) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  let nextUrl;
  if (pathname?.startsWith('/dashboard') && isLogout) {
    // When we're on the dashboard, we want to redirect to the homepage after logout
    nextUrl = '/';
  } else if (pathname === '/' && !isLogout) {
    // When we're on the homepage, we want to redirect to the dashboard after login
    nextUrl = '/dashboard';
  } else {
    nextUrl = pathname;
    if (searchParams?.toString()) {
      nextUrl += `?${searchParams.toString()}`;
    }
  }

  const newUrl = new URL(href, getAppUrl(config));

  if (nextUrl) {
    newUrl.searchParams.set('next', nextUrl);
  }

  return `${newUrl.pathname}${newUrl.search}`;
}

export default useLinkWithNextParam;
