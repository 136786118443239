import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import styles from './Radio.module.css';

const Radio = forwardRef(
  (
    {
      className,
      id,
      helperText,
      label,
      name,
      onChange,
      value,
      ...otherRadioProps
    },
    ref,
  ) => {
    return (
      <div className={className}>
        <input
          className={styles['Radio']}
          id={id}
          name={name}
          onChange={onChange}
          ref={ref}
          type="radio"
          value={value}
          {...otherRadioProps}
        />
        <label htmlFor={id}>
          {label}
          {helperText && (
            <div className={styles['Radio-helperText']}>{helperText}</div>
          )}
        </label>
      </div>
    );
  },
);

Radio.propTypes = {
  /**
   * Unique identifier for the button
   */
  id: PropTypes.string.isRequired,

  /**
   * String to display next to the button
   */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,

  /**
   * Identifier to connect a group of radio buttons together
   */
  name: PropTypes.string.isRequired,

  /**
   * Function to emit changes to a parent component.
   *
   * Users of this component must implement an onChange handler to
   * assign which button is currently checked.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Value of radio button
   */
  value: PropTypes.string.isRequired,
};

export default Radio;
