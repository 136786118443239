import { useEffect, useRef } from 'react';

const useEffectAfterMount = (callback, dependencies) => {
  const justMounted = useRef(true);
  useEffect(() => {
    if (!justMounted.current) {
      return callback();
    }
    justMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useEffectAfterMount;
