/* eslint-disable sort-keys */
const DAY = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};
/* eslint-enable sort-keys */

class BusinessDayHelper {
  constructor({
    businessDays = [DAY.MON, DAY.TUE, DAY.WED, DAY.THU, DAY.FRI],
    holidayMatchers = [],
  } = {}) {
    this.businessDays = businessDays;
    this.holidayMatchers = holidayMatchers;
  }

  /**
   * Checks if Luxon DateTime instance is a holiday by checking against all holiday matchers.
   * @param {DateTime} datetime
   * @returns {boolean}
   */
  isHoliday(datetime, ...args) {
    const isDayAnyHoliday = this.holidayMatchers.some((holidayMatcher) => {
      return holidayMatcher(datetime, ...args);
    });

    return isDayAnyHoliday;
  }

  /**
   * Checks if Luxon DateTime instance is a business day.
   * @param {DateTime} datetime
   * @returns {boolean}
   */
  isBusinessDay(datetime) {
    return this.businessDays.includes(datetime.weekday);
  }

  /**
   * Adds business days to an existing Luxon DateTime instance.
   * @param {DateTime} datetime
   * @param {number} days - The number of business days to add.
   * @returns {DateTime}
   */
  plusBusinessDays(datetime, days) {
    let dt = datetime;

    if (!dt.isValid) {
      return dt;
    }

    const isNegative = days < 0;

    let businessDaysLeftToAdd = isNegative
      ? Math.round(-1 * days)
      : Math.round(days);

    const oneDayByDirection = isNegative ? -1 : 1;

    while (businessDaysLeftToAdd > 0) {
      dt = dt.plus({ days: oneDayByDirection });

      if (this.isBusinessDay(dt) && !this.isHoliday(dt)) {
        businessDaysLeftToAdd--;
      }
    }

    return dt;
  }

  plusBusinessMinutes(datetime, minutes) {
    const startHour = 8;
    const startMinute = 30;
    const endHour = 17;
    const endMinute = 0;

    let dt = datetime;

    if (!dt.isValid) {
      return dt;
    }

    let date = datetime;
    let remainingMinutes = minutes;

    const nextBusinessDayStart = (datetime) => {
      do {
        datetime = datetime
          .plus({ days: 1 })
          .set({ hour: startHour, minute: startMinute });
      } while (!this.isBusinessDay(datetime) || this.isHoliday(datetime));
      return datetime;
    };

    if (!this.isBusinessDay(date) || this.isHoliday(date)) {
      date = nextBusinessDayStart(date);
    }

    while (remainingMinutes > 0) {
      // Set business start and end times for the current day
      const businessStart = date.set({ hour: startHour, minute: startMinute });
      const businessEnd = date.set({ hour: endHour, minute: endMinute });

      // If the current date is outside business hours, move to the next business day
      if (date < businessStart) {
        date = businessStart;
      } else if (date >= businessEnd) {
        date = nextBusinessDayStart(date);
        continue;
      }

      const minutesLeftToday = businessEnd.diff(date, 'minutes').minutes;

      if (remainingMinutes <= minutesLeftToday) {
        return date.plus({ minutes: remainingMinutes });
      } else {
        remainingMinutes -= minutesLeftToday;
        date = nextBusinessDayStart(date);
      }
    }
    return date;
  }

  /**
   * Subtracts business days to an existing Luxon DateTime instance.
   * @param {DateTime} datetime
   * @param {number} days - The number of business days to subtract.
   * @returns {DateTime}
   */
  minusBusinessDays(datetime, days) {
    return this.plusBusinessDays(datetime, -days);
  }

  /**
   * Gets the next business day from a Luxon DateTime instance.
   * @param {DateTime} datetime
   * @returns {DateTime}
   */
  nextBusinessDay(datetime) {
    return this.plusBusinessDays(datetime, 1);
  }
}

export default BusinessDayHelper;
export { DAY };
