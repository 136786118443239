import cn from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { BackButton, Breadcrumbs } from 'site-react/components/navigation';
import {
  Heading,
  RuledHeading,
  TextWithIcon,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import FloatingNavigationAnchor from './components/FloatingNavigationAnchor';
import HqProductOffering from './components/HqProductOffering';
import ImageCarousel from './components/ImageCarousel';
import PassBadge from './components/PassBadge';
import PassFacilities from './components/PassFacilities';
import Location from './components/PassLocation';
import PassProducts from './components/PassProducts';
import Summary from './components/PassSummary';
import getBreadcrumbs from './helpers/getBreadcrumbs';
import styles from './PassListing.module.css';
import { PassRenderedMarkdownContentPropTypes } from './proptypes';
import OpenUntilLateBadge from '../PassSearch/components/OpenUntilLateBadge/OpenUntilLateBadge';

const ArrivalInstructions = ({ renderedMarkdownContent }) => {
  if (renderedMarkdownContent.arrivalInstructions) {
    return (
      <div className={styles['PassListing-content']}>
        <FloatingNavigationAnchor id="arrival-instructions" />
        <div id="arrival-instructions-container">
          <RuledHeading level="2">Arrival Instructions</RuledHeading>
          <VerticalSpacing size="lg" />

          <div
            dangerouslySetInnerHTML={{
              __html: renderedMarkdownContent.arrivalInstructions,
            }}
          />

          {renderedMarkdownContent.accessHours ? (
            <>
              <VerticalSpacing size="xl" />
              <Heading level="3" type="title4">
                Access Hours
              </Heading>
              <VerticalSpacing size="md" />

              <div
                dangerouslySetInnerHTML={{
                  __html: renderedMarkdownContent.accessHours,
                }}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else if (renderedMarkdownContent.howToFindUs) {
    return (
      <div className={styles['PassListing-content']}>
        <FloatingNavigationAnchor id="arrival-instructions" />
        <div id="arrival-instructions-container">
          <RuledHeading level="2">Arrival Instructions</RuledHeading>
          <VerticalSpacing size="md" />

          <div
            dangerouslySetInnerHTML={{
              __html: renderedMarkdownContent.howToFindUs,
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default function PassListing({
  building,
  closingTime,
  isLateStay,
  productType = 'coworking',
  renderedMarkdownContent,
}) {
  const router = useRouter();
  let option = router.query.option ?? 'coworking';

  if (option.includes('meeting-room')) {
    option = 'meeting-room';
  }

  const breadcrumbProps = getBreadcrumbs({ building, productType: option });

  const orderOfProducts = ['coworking', 'private-office', 'meeting-room'];

  // Set 'productType' passed from query to be first in list of products
  orderOfProducts.sort(
    (a, b) => (productType === a && -1) || (productType === b && 1) || 0,
  );

  const hasOnDemandListingImages = building.onDemandProduct?.images?.length > 0;

  const hasFacilities =
    building.onDemandProduct?.includedPassFacilities?.length > 0 ||
    building.onDemandProduct?.extraPassFacilities?.length > 0;

  const previousSearchPage = useMemo(() => {
    if (typeof window === 'undefined') {
      return null;
    }

    const previousSearchPage = sessionStorage.getItem('previousSearchPage');
    // We remove this immediately as keeping it could cause issues with other open listing pages.
    if (previousSearchPage) {
      sessionStorage.removeItem('previousSearchPage');
    }
    return previousSearchPage;
  }, []);

  function goBackToSearch() {
    if (previousSearchPage) {
      router.push(previousSearchPage);
    } else {
      const parentArea =
        breadcrumbProps.links[breadcrumbProps.links.length - 2];
      router.push(parentArea.url);
    }
  }

  return (
    <>
      <BackButton
        contentType="content1"
        data-analytics-tag="back_to_search"
        label="Back to search results"
        onBackButtonClick={goBackToSearch}
      />
      <div
        className={cn(
          styles['PassListing-content'],
          styles['PassListing-imageCarousel'],
        )}
      >
        <FloatingNavigationAnchor id="image-gallery" />
        <div id="image-gallery-container">
          <ImageCarousel
            building={
              hasOnDemandListingImages ? building.onDemandProduct : building
            }
            hideAccessories
          />
        </div>
        {isLateStay && (
          <div className={styles['PassListing-badge']}>
            <OpenUntilLateBadge closingTime={closingTime} />
          </div>
        )}
        <VerticalSpacing size="lg" />
      </div>
      <Breadcrumbs {...breadcrumbProps} />
      <VerticalSpacing size="lg" />
      <PassBadge />
      <Heading level="1" type="title1">
        {building.name}
      </Heading>
      {building.city?.name ? (
        <TextWithIcon
          color="neutral900"
          contentType="content2"
          iconName="location_on"
          iconPosition="left"
          iconSize="md"
          text={building.city.name}
        />
      ) : null}
      <VerticalSpacing size="lg" />
      <div className={styles['PassListing-content']}>
        <FloatingNavigationAnchor id="location" />
        <div id="location-container">
          <Location
            address={building.address}
            buildingId={building.id}
            buildingName={building.name}
            isPass
            landmarks={building.landmarks}
            lat={building.lat}
            lng={building.lng}
            postcode={building.postcode}
          />
        </div>
      </div>
      <VerticalSpacing size="lg" />
      <div className={styles['PassListing-content']}>
        <FloatingNavigationAnchor id="products" />
        <div id="products-container">
          <PassProducts building={building} orderOfProducts={orderOfProducts} />
        </div>
      </div>
      <VerticalSpacing size="lg" />
      {hasFacilities ? (
        <div className={styles['PassListing-content']}>
          <FloatingNavigationAnchor id="facilities" />
          <div id="facilities-container">
            <PassFacilities building={building} />
          </div>
        </div>
      ) : undefined}
      <VerticalSpacing size="lg" />

      <ArrivalInstructions renderedMarkdownContent={renderedMarkdownContent} />
      <VerticalSpacing size="xl" />
      {renderedMarkdownContent.overview ? (
        <div className={styles['PassListing-content']}>
          <FloatingNavigationAnchor id="summary" />
          <div id="summary-container">
            <Summary renderedMarkdownContent={renderedMarkdownContent} />
          </div>
        </div>
      ) : undefined}
      {building.isAvailableOnHq ? (
        <HqProductOffering
          buildingId={building.id}
          buildingSlug={building.slug}
        />
      ) : null}
      <VerticalSpacing size="xl" />
    </>
  );
}

PassListing.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    buildingType: PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      longDescription: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    landmarks: PropTypes.arrayOf(
      PropTypes.shape({
        distance: PropTypes.number.isRequired,
        landmark: PropTypes.shape({
          images: PropTypes.arrayOf(
            PropTypes.shape({
              img: PropTypes.string.isRequired,
            }),
          ),
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    specialOffer: PropTypes.string,
  }).isRequired,

  /**
   * The time the building closes (end of access hours for Pass)
   */
  closingTime: PropTypes.string,

  /**
   * Whether or not access hours go beyond 6pm.
   */
  isLateStay: PropTypes.bool,

  productType: PropTypes.oneOf(['coworking', 'private-office', 'meeting-room']),

  renderedMarkdownContent: PassRenderedMarkdownContentPropTypes.isRequired,
};
