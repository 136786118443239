import Head from 'next/head';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React from 'react';

import config from 'site-react/config';
import defaultMeta from 'site-react/content/meta/default';
import getAppUrl from 'site-react/helpers/getAppUrl';
import { imgixUrl } from 'site-react/helpers/imgix';
import removeHTML from 'site-react/helpers/removeHTML';

function getMeta(props) {
  const { img, lastPage, page, pathname } = props;

  const url = new URL(pathname, getAppUrl(config));

  const canonicalUrl = new URL(
    props.canonicalUrl || url.pathname,
    getAppUrl(config),
  );
  if (!props.canonicalUrl && props.page && props.page > 1) {
    canonicalUrl.searchParams.set('page', props.page);
  }

  let nextUrl;
  let prevUrl;
  if (page && lastPage) {
    if (lastPage > page) {
      nextUrl = new URL(url.pathname, getAppUrl(config));
      nextUrl.searchParams.set('page', page + 1);
    }

    if (page > 1) {
      prevUrl = new URL(url.pathname, getAppUrl(config));

      // We don't want the `page` parameter to be set on the previous page if
      // the previous page is the first page.
      if (page > 2) {
        prevUrl.searchParams.set('page', page - 1);
      }
    }
  }

  const isImgix = img.startsWith('https://hubble.imgix.net');

  return {
    ...props,

    canonicalUrl: canonicalUrl.toString(),
    fb: {
      ...defaultMeta.fb,
      ...props.fb,
    },
    nextUrl: nextUrl && nextUrl.toString(),
    og: {
      ...defaultMeta.og,
      ...props.og,
      image: isImgix ? imgixUrl(img, { h: 630, w: 1200 }) : img,
      url: url.toString(),
    },
    prevUrl: prevUrl && prevUrl.toString(),
    twitter: {
      ...defaultMeta.twitter,
      ...props.twitter,
      imageSrc: isImgix ? imgixUrl(img, { h: 600, w: 1120 }) : img,
    },
    url: url.toString(),
  };
}

export default function Meta(props) {
  const mergedProps = Object.assign({}, defaultMeta, props);
  const pathname = usePathname();
  const meta = getMeta({ ...mergedProps, pathname });

  return (
    <Head>
      <link href={meta.canonicalUrl} rel="canonical" />
      {meta.nextUrl && <link href={meta.nextUrl} rel="next" />}
      {meta.prevUrl && <link href={meta.prevUrl} rel="prev" />}
      <meta content={removeHTML(meta.description)} name="description" />
      <meta
        content={meta.index ? `${meta.index}, follow` : 'follow'}
        name="robots"
      />
      <meta content={meta.twitter.card} name="twitter:card" />
      <meta content={meta.twitter.creator} name="twitter:creator" />
      <meta content={removeHTML(meta.description)} name="twitter:description" />
      <meta content={meta.twitter.imageSrc} name="twitter:image:src" />
      <meta content={meta.twitter.site} name="twitter:site" />
      <meta
        content={removeHTML(meta.title) + removeHTML(meta.titleSuffix)}
        name="twitter:title"
      />
      <meta content={meta.fb.admins} property="fb:admins" />
      <meta content={meta.fb.appId} property="fb:app_id" />
      <meta content={removeHTML(meta.description)} property="og:description" />
      <meta content={meta.og.image} property="og:image" />
      <meta content={meta.og.locale} property="og:locale" />
      <meta content={meta.og.siteName} property="og:site_name" />
      <meta
        content={removeHTML(meta.title) + removeHTML(meta.titleSuffix)}
        property="og:title"
      />
      <meta content={meta.og.type} property="og:type" />
      <meta content={meta.og.url} property="og:url" />
    </Head>
  );
}

Meta.propTypes = {
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  fb: PropTypes.shape({
    admins: PropTypes.string,
    appId: PropTypes.string,
  }),
  img: PropTypes.string,
  index: PropTypes.string,
  lastPage: PropTypes.number,
  og: PropTypes.shape({
    image: PropTypes.string,
    locale: PropTypes.string,
    siteName: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  page: PropTypes.number,
  router: PropTypes.shape({
    asPath: PropTypes.string,
  }),
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
  twitter: PropTypes.shape({
    card: PropTypes.string,
    creator: PropTypes.string,
    imageSrc: PropTypes.string,
    site: PropTypes.string,
  }),
};
