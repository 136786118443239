import {
  Heading,
  ListItemWithIcon,
  Paragraph,
} from 'site-react/components/typography';
import {
  ImgixImage,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';

export default function Content() {
  return (
    <>
      <Heading level="2" type="title1">
        Get a detailed quote
      </Heading>
      <VerticalSpacing size="md" />
      <Paragraph>
        Our expert advisors will get back to you with a detailed breakdown for
        this office
      </Paragraph>

      <VerticalSpacing size="xl" />

      <Heading type="content2">
        <b>Our promise to you</b>
      </Heading>

      <VerticalSpacing size="md" />

      <UnstyledList>
        <ListItemWithIcon
          iconName="check_circle"
          text={
            <>
              <b>Best price guarantee</b> - we can achieve 15% discount on
              average
              <VerticalSpacing size="md" />
            </>
          }
        />
        <ListItemWithIcon
          iconName="check_circle"
          text={
            <>
              <b>100% real listing</b> - we don’t list fake, unavailable or
              outdated offices
              <VerticalSpacing size="md" />
            </>
          }
        />
        <ListItemWithIcon
          iconName="check_circle"
          text={
            <>
              <b>Spam free zone</b> - we won’t share your contact details with
              third parties, ever
            </>
          }
        />
      </UnstyledList>
      <VerticalSpacing size="xl" />
      <ImgixImage
        alt="Trustpilot five star score"
        height={25}
        src="/site/homepage/trustpilot-black-letters.svg"
        width={289}
      />
    </>
  );
}
