import PropTypes from 'prop-types';

import states from 'site-react/data/booking/states.json';

export default PropTypes.exact({
  attendees: PropTypes.arrayOf(
    PropTypes.exact({
      booking: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
      userId: PropTypes.number,
    }),
  ),
  billingReferenceCode: PropTypes.string,
  billingReferenceDescription: PropTypes.string,
  billingReferenceId: PropTypes.string,
  bookingDate: PropTypes.string.isRequired,
  bookingEndTime: PropTypes.string.isRequired,
  bookingStartTime: PropTypes.string.isRequired,
  buildingId: PropTypes.number.isRequired,
  buildingTimeZone: PropTypes.string,
  cancelledAt: PropTypes.string,
  confirmedAt: PropTypes.string,
  createdAt: PropTypes.string,
  declinedAt: PropTypes.string,
  failureReason: PropTypes.string,
  financeAccount: PropTypes.number,
  grossPrice: PropTypes.string,
  grossPriceInCurrency: PropTypes.string,
  headCount: PropTypes.number,
  id: PropTypes.number,
  isAutoDeclined: PropTypes.bool,
  isPastBooking: PropTypes.bool,
  isPrivate: PropTypes.bool,
  meetingRoomPriceId: PropTypes.number,
  organiserId: PropTypes.number,
  overturnedAt: PropTypes.string,
  postVisitFeedbackSentAt: PropTypes.string,
  pricePerHour: PropTypes.number,
  productId: PropTypes.number,
  productType: PropTypes.string,
  refundedAt: PropTypes.string,
  requestedFacilities: PropTypes.string,
  state: PropTypes.oneOf(Object.values(states)),
  stripeCheckoutSessionId: PropTypes.string,
  stripePaymentIntentId: PropTypes.string,
  summaryTodaySentAt: PropTypes.string,
  summaryTomorrowSentAt: PropTypes.string,
  totalHostPayment: PropTypes.any,
  totalListPrice: PropTypes.any,
  unitPriceType: PropTypes.string,
  updatedAt: PropTypes.string,
});
