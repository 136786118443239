/**
 * DEPRECATION NOTICE
 *
 * We are moving our styling tooling to CSS modules. This file will be removed
 * in the future.
 *
 * spacing.css is the source of truth for all spacing style variables.
 */

/*
 * These values are intended for use in spacing and layout,
 * so typically for things like MARGINS and PADDING.
 *
 * DO NOT use these for font-sizes --> use theme.font instead.
 *
 * Spacing values should always be a multiple of 4.
 * In order to achieve a value that is not explicitly listed,
 * do some math using the available variables.
 * This can help to achieve consistency in spatial relationships.
 *
 * NB. No cheating! "4 x 3.75" doesn't count as a multiple of 4. Use whole numbers.
 *
 *Example 1:
 * margin: ${theme.spacing.md}px ${theme.spacing.lg}px; // margin: 16px 24px
 *
 * Example 2:
 * const myComponentSpacing = theme.spacing.base * 12;
 * margin: ${myComponentSpacing}px; // margin: 48px
 *
 * Example 3:
 * margin: ${theme.spacing.base * 15}px 0 0; // margin: 60px 0 0
 */

/* eslint-disable sort-keys */
export default {
  base: 4,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 40,
  xxxl: 80,

  // 44x44 is the recommended minimum iOS touch target size
  minimumTargetSize: 44,
};
