import debounce from 'lodash.debounce';

class GlobalEvents {
  /**
   * Takes an object of events and will attach the handlers to the
   * correct global event.
   * - Use capture changes the order in which events are read, view the link below for more details.
   * - By default applys a 0ms debounce, ie. no debounce
   *
   * @example
   * // Example events object
   * {
   *  keyup: {
   *    handler: (e) => console.log('key up', e),
   *    useCapture: true,
   *    debounce: 300
   *  },
   *  click: (e) => console.log('clicked', e),
   *  focus: {
   *    handler: (e) => console.log('fish cat', e),
   *  }
   * }
   *
   *
   * @param {Object} events - An object of events
   * @class GlobalEvents
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener Use Capture}
   */
  constructor(events) {
    this.events = events;
    this.listeners = [];
    this.debounceDefault = 0;
  }

  /**
   * Hooks in all event listeners
   *
   * @memberof GlobalEvents
   */
  listen() {
    const { events } = this;

    Object.keys(events).forEach((event) =>
      this.addListener(event, events[event]),
    );
  }

  /**
   * Add a global event listener
   *
   * @param {any} eventKey - The string value of the event i.e. 'keyup'
   * @param {any} eventHandler - The event handler, either a function or an object as shown above.
   * @memberof GlobalEvents
   */
  addListener(eventKey, eventHandler) {
    const event = {
      debounce: Object.prototype.hasOwnProperty.call(eventHandler, 'debounce')
        ? eventHandler.debounce
        : this.debounceDefault,
      handler: eventHandler.handler ? eventHandler.handler : eventHandler,
      useCapture: eventHandler.useCapture ? eventHandler.useCapture : false,
    };
    const handler = event.debounce
      ? debounce(event.handler, event.debounce)
      : event.handler;

    document.addEventListener(eventKey, handler, event.useCapture);
    this.listeners.push({
      event: eventKey,
      handler,
      useCapture: event.useCapture,
    });
  }

  /**
   * Removes all event listeners, useCapture required to remove listener
   *
   * @memberof GlobalEvents
   */
  remove() {
    this.listeners.forEach(({ event, handler, useCapture }) => {
      document.removeEventListener(event, handler, useCapture);
    });
  }
}

export default GlobalEvents;
