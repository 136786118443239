import EventEmitter from 'events';

import FeatureFlagsApi from 'site-react/api/FeatureFlagsApi';
import config from 'site-react/config';
import logError from 'site-react/helpers/logError';

import defaultFlags from './defaultFlags';

const LOCAL_STORAGE_KEY = `hubblehq-local-flags-${config.ENV}`;
const CHANGE_EVENT = Symbol('change');

/**
 * Maintains a key/value store for checking feature flags, as well as an event
 * emitter to detect when the store changes.
 *
 * If you're using feature flags from within our bundle - ie. you're working in
 * React - you should almost always use the FeatureFlags context. If you need to
 * use this underlying instance for any reason, you should use the default
 * export from this file, which is a singleton of the `FeatureFlags` class.
 *
 * If you're using feature flags from an external library, you can push new
 * flags using `window.hubbleFeatureFlags.push()`. See {@link FeatureFlags#push}
 * for more information.
 *
 * NOTE: when using `window.hubbleFeatureFlags`, we only currently support
 * `push()`. While `addListener`, `removeListener`, and `getFlags` may be
 * available once our bundle has finished loading, it is unsafe to assume that
 * they will be present when an external library loads.
 */
class FeatureFlags {
  /**
   * Create a new FeatureFlags instance.
   *
   * If the `window.hubbleFeatureFlags` variable exists, and it's an array, its
   * values will overwrite - in order - the default flags. This is meant to
   * support external libraries, like an A/B testing framework, which may load
   * before our bundle does.
   *
   * NOTE: in practice, we should always use the singleton, rather than
   * constructing a new `FeatureFlags` instance. This will allow us to maintain
   * a single source of truth, even when external libraries change feature flags
   * on the `window.hubbleFeatureFlags` instance.
   */
  constructor() {
    this._emitter = new EventEmitter();
    let defaultFlagsCleaned = Object.fromEntries(
      Object.entries(defaultFlags).map(([key, obj]) => [key, obj.value]),
    );

    let flags = {};
    if (
      typeof window !== 'undefined' &&
      window.hubbleFeatureFlags &&
      Array.isArray(window.hubbleFeatureFlags)
    ) {
      flags = Object.assign(flags, ...window.hubbleFeatureFlags);
    }

    // Freeze the object. This enforces that any changes to `flags` _must_ go
    // through `push()`, by throwing an exception if we try to run
    // `this.getFlags().someFlag = false`. This is important to maintain our
    // event emitter functionality - if we can change flags without firing a
    // change event, then the integrity of our data may get compromised.
    this._defaultFlags = Object.freeze(defaultFlagsCleaned);
    this._remoteFlags = Object.freeze({});
    this._pageFlags = Object.freeze(flags);
    this._localFlags = Object.freeze({});
  }

  /**
   * Gets feature flags from our servers.
   */
  async updateRemoteFlags() {
    try {
      const api = new FeatureFlagsApi();
      const { body: flags } = await api.getFeatureFlags();

      // Let's get meta. If the remote tells us flags are on, use them. Otherwise, ignore them.
      if (
        flags.find((flag) => flag.key === 'enableRemoteFlags')?.defaultValue
      ) {
        const remoteFlags = {};

        for (const flag of flags) {
          remoteFlags[flag.key] = flag.defaultValue;
        }

        this._remoteFlags = Object.freeze(remoteFlags);
      } else {
        this._remoteFlags = Object.freeze({});
      }

      this._emitter.emit(CHANGE_EVENT, flags);
    } catch (error) {
      // We don't have any appropriate UI to show feature flag errors. This functionality
      // should be completely transparent to the user—if it failed, they wouldn't be able
      // to do anything, so we shouldn't even tell them. So, instead of presenting an error
      // message, simply log the error to the console and send it into Sentry.
      logError(error);
    }
  }

  /**
   * Get the feature flags key/value store.
   *
   * NOTE: the object returned from this function is immutable. If you want to
   * change the value of a flag, see {@link FeatureFlags#push}.
   *
   * @returns an immutable object containing flag names as keys, and their values.
   */
  getFlags() {
    return Object.freeze({
      // The order of inheritance here is important.
      ...this._defaultFlags, // Starts from the JSON file
      ...this._remoteFlags, // Which can be overwritten by our server
      ...this._pageFlags, // Which can be overwritten by window.hubbleFeatureFlags (A/B tests)
      ...this._localFlags, // Which can be overwritten by local storage
    });
  }

  /**
   * Get the description of a flag
   *
   * @param {string} flagName
   * @returns {string}
   */
  getDescription(flagName) {
    return defaultFlags[flagName]?.description;
  }

  /**
   * Initialize any local flags in local storage.
   *
   * This is done separately from normal flags, to better hook into React's
   * lifecycle. We want local flags to init _after_ the first render, to make
   * sure that we don't get differences between the server and client on page
   * load. Then, re-render with any new flags, if necessary, by triggering
   * a change event.
   *
   * Think of loading local flags as a side effect that happens on page load,
   * rather than local flags being something that are present immediately.
   */
  initLocalFlags() {
    if (typeof window !== 'undefined' && 'localStorage' in window) {
      const flagsFromStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (flagsFromStorage !== null) {
        this._localFlags = Object.freeze(JSON.parse(flagsFromStorage));
        this._emitter.emit(CHANGE_EVENT, this._localFlags);
      }

      // This event fires when local storage changes _from another window_ (or
      // tab). It does _not_ fire if local storage is changed from the _current_
      // tab.
      // Reference: https://stackoverflow.com/questions/50767241/observe-localstorage-changes-in-js
      // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
      //
      // Having this event handler allows us to keep one tab open to a page we're
      // working on, another tab open to the flags configuration, and not need
      // to constantly refresh the page we're working on.
      window.addEventListener('storage', (event) => {
        // `key` will be null if local storage gets cleared. WHen that happens,
        // we want to clear the local flags, as well.
        if (event.key === null || event.key === LOCAL_STORAGE_KEY) {
          const flagsFromStorage =
            window.localStorage.getItem(LOCAL_STORAGE_KEY);
          if (flagsFromStorage !== null) {
            this._localFlags = Object.freeze(JSON.parse(flagsFromStorage));
          } else {
            this._localFlags = Object.freeze({});
          }
          this._emitter.emit(CHANGE_EVENT, this._localFlags);
        }
      });
    }
  }

  /**
   * Update one or more flags.
   *
   * This method is named `push`, so that on page load,
   * `window.hubbleFeatureFlags` can be initialized as an array, and be
   * immediately useful to external libraries, like an A/B testing framework.
   * The idea is that, at any point on our page - whether our bundle has loaded
   * or not - we can always call
   * `window.hubbleFeatureFlags.push({ flag: value })`.
   *
   * A call to `push` will fire the event emitter, so any added listeners get
   * called.
   *
   * @param {object} flags the new flags to push. Must be an object with keys
   * and values. Will overwrite any previous values.
   *
   * @example
   * // Set a single flag
   * featureFlags.push({ myFlag: true })
   *
   * @example
   * // Set multiple flags at once
   * featureFlags.push({ myFlag: false, mySecondFlag: 'purple' })
   */
  push(flags) {
    // Freeze the object. This enforces that any changes to `flags` _must_ go
    // through `push()`, by throwing an exception if we try to run
    // `this.getFlags().someFlag = false`. This is important to maintain our
    // event emitter functionality - if we can change flags without firing a
    // change event, then the integrity of our data may get compromised.
    this._pageFlags = Object.freeze({
      ...this._pageFlags,
      ...flags,
    });

    this._emitter.emit(CHANGE_EVENT, flags);
  }

  /**
   * Set some flags on a user-level. This allows for users to opt into certain
   * features.
   * @param {object} localFlags new user-level flags to persist
   */
  pushLocalFlags(localFlags) {
    // Freeze the object. This enforces that any changes to `localFlags` _must_
    // go through `pushLocalFlags()`, by throwing an exception if we try to run
    // `this.getFlags().someFlag = false`. This is important to maintain our
    // event emitter functionality - if we can change flags without firing a
    // change event, then the integrity of our data may get compromised.
    this._localFlags = Object.freeze({
      ...this._localFlags,
      ...localFlags,
    });

    if (typeof window !== 'undefined' && 'localStorage' in window) {
      try {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(this._localFlags),
        );
      } catch (error) {
        // We can't always access local storage - in some scenarios, browsers
        // will just block it - particularly if local storage runs out of space.
        // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
        //
        // This is generally an expected exception. Since this is a developer-
        // focused feature, it's not worth creating noise in Sentry, so just log
        // it into the console.
        // eslint-disable-next-line no-console
        console.error(
          'Unable to save user’s feature flags to local storage',
          error,
        );
      }
    }

    this._emitter.emit(CHANGE_EVENT, localFlags);
  }

  /**
   * Add a change listener. On change, listeners will be called with the flags
   * that have changed.
   *
   * @param {function} listener a function that should be called any time the
   * underlying key/value store updates
   *
   * @example
   * // Detect a change in a particular flag
   * hubbleFeatureFlags.addListener(changedFlags => {
   *   if (typeof changedFlags.myCoolFeature !== 'undefined') {
   *     console.log(
   *       `The value of myCoolFeature has changed to ${
   *         changedFlags.myCoolFeature
   *       }.`
   *     );
   *   }
   * });
   *
   * @example
   * // Update a React component when flags change.
   * const [flags, setFlags] = useState(featureFlags.getFlags());
   *
   * useEffect(() => {
   *   const listener = () => {
   *     setFlags(featureFlags.getFlags());
   *   }
   *   featureFlags.addListener(listener);
   *
   *   return () => featureFlags.removeListener(listener);
   * });
   */
  addListener(listener) {
    this._emitter.addListener(CHANGE_EVENT, listener);
  }

  /**
   * Removes a listener that was previously added with
   * {@link FeatureFlags#addListener}.
   *
   * @param {function} listener a function that was previously added using
   * {@link FeatureFlags#addListener}
   */
  removeListener(listener) {
    this._emitter.removeListener(CHANGE_EVENT, listener);
  }
}

const featureFlagsInstance = new FeatureFlags();

if (typeof window !== 'undefined') {
  window.hubbleFeatureFlags = featureFlagsInstance;
}

export default featureFlagsInstance;
export { FeatureFlags };
