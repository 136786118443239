import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledList } from 'site-react/components/utility';

import styles from './SimpleMenuList.module.css';

export default function SimpleMenuList({ children }) {
  return (
    <UnstyledList className={styles.SimpleMenuList}>{children}</UnstyledList>
  );
}

SimpleMenuList.propTypes = {
  /**
   * Children (must be `li`s) to show
   */
  children: PropTypes.node,
};
