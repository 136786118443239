import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Section.module.css';
/**
 * `Section` is used to compose components into vertical page layouts.
 */
const Section = ({
  breakpoint = 'lg',
  children = null,
  horizontalPadding = 'md',
  id = null,
  isFullWidth = false,
  verticalPadding = null,
  isLeftAligned,
}) => {
  return (
    <div
      className={cn(
        styles['Section'],
        styles[`Section--${breakpoint}Breakpoint`],
        {
          [styles['Section--maxWidth']]: !isFullWidth,
          [styles['Section--leftAligned']]: isLeftAligned,
        },
      )}
      id={id}
      style={{
        '--Section--horizontalPadding': `var(--space-${horizontalPadding})`,
        '--Section--verticalPadding': verticalPadding
          ? `var(--space-${verticalPadding})`
          : '0',
      }}
    >
      {children}
    </div>
  );
};

Section.propTypes = {
  /**
   * Large width breakpoint to use
   */
  breakpoint: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  /**
   * Content to display in the Section.
   */
  children: PropTypes.node,

  /**
   * The amount of padding to apply equally to left & right of the Section.
   */
  horizontalPadding: PropTypes.oneOf(['md', 'lg', 'xl', 'xxl']),

  /**
   * ID to allow for # navigation
   */
  id: PropTypes.string,

  /**
   * Should the width of this Section be unconstrained (filling the viewport)?
   */
  isFullWidth: PropTypes.bool,

  /**
   * Should left align at large breakpoint
   */
  isLeftAligned: PropTypes.bool,

  /**
   * The amount of spacing to apply equally to top & bottom of the Section.
   */
  verticalPadding: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
    'xxxl',
    'xxxxl',
  ]),
};

export default Section;
