/**
 * A function to format months(given integer) into years and months.
 * @example: formatTimeInYearsMonths.sort(10);  // 10 months
 * @example: formatTimeInYearsMonths.sort(18);  // 1 year 6 months
 *
 * @param {Number} 10 - an integer that indicates number of months
 */
export default function formatTimeInYearsMonths(monthsTerm) {
  if (monthsTerm === null) return null;
  if (monthsTerm === 0) return 'None';

  const timeLabel = (text, amount) => (amount > 1 ? `${text}s` : text);

  if (monthsTerm < 12) return `${monthsTerm}mo`;

  let years = monthsTerm / 12;
  if (monthsTerm % 12 === 0) return `${years}${timeLabel('yr', years)}`;

  years = Math.floor(monthsTerm / 12);
  const months = monthsTerm % 12;
  return `${years}${timeLabel('yr', years)} ${months}mo`;
}
