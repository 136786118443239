import {
  Heading,
  MaterialIcon,
  Paragraph,
} from 'site-react/components/typography';

import styles from './HqListingPriceGuaranteeModalContent.module.css';

const HqListingPriceGuaranteeModalContent = () => {
  return (
    <div className={styles['HqListingPriceGuaranteeModalContent']}>
      <div className={styles['HqListingPriceGuaranteeModalContent--heading']}>
        <MaterialIcon iconType="workspace_premium" size="lg" />
        <Heading level="1" type="title1">
          Best price guarantee
        </Heading>
      </div>

      <Paragraph type="content1">
        Our team of expert advisors know the best prices on the market, and can{' '}
        <b>achieve on average 15% discount</b> on your office cost.
      </Paragraph>

      <Paragraph type="content1">
        We don’t add on on any commission to the price you pay, so you can rest
        assured that you’ll get the best possible price on the market.
      </Paragraph>
    </div>
  );
};

export default HqListingPriceGuaranteeModalContent;
