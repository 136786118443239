const formatPrice = (
  amount,
  currency = 'GBP',
  locale = 'en-GB',
  precision = 0,
) => {
  return amount.toLocaleString(locale, {
    currency: currency,
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
    style: 'currency',
  });
};

export default formatPrice;
