import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';

import SwitchButton from './components/SwitchButton';
import ToggleContext from './context/ToggleContext';
import useEffectAfterMount from './hooks/useEffectAfterMount';

/**
 * Toggle style component designed to switch between opposing boolean values.
 */
const Switch = ({
  defaultValue = false,
  disabled = false,
  label = null,
  onToggle = () => {},
}) => {
  const [on, setOn] = useState(() => defaultValue);

  const toggle = useCallback(() => setOn((previousOn) => !previousOn), []);

  useEffectAfterMount(() => {
    onToggle(on);
  }, [on]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ on, toggle }), [on]);

  return (
    <ToggleContext.Provider value={value}>
      <div>
        <SwitchButton disabled={disabled} label={label} />
      </div>
    </ToggleContext.Provider>
  );
};

Switch.propTypes = {
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onToggle: PropTypes.func,
};

export default Switch;
