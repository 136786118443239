import cn from 'classnames';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Analytics from 'site-react/helpers/Analytics';

import styles from './NavBarLinkItem.module.css';

const NavBarLinkItem = ({ className, customLabelElement, href, label }) => {
  const pathname = usePathname();

  const isCurrent = pathname === href;

  const handleClick = useCallback(() => {
    Analytics.track(
      'Link clicked',
      {
        href,
        label,
        location: 'navigation',
      },
      {
        sendPageProperties: true,
      },
    );
  }, [href, label]);

  return (
    <li
      aria-label={label}
      className={cn(
        styles.NavBarLinkItem,
        {
          [styles['NavBarLinkItem--current']]: isCurrent,
        },
        className,
      )}
    >
      <NextLink
        className={styles['NavBarLinkItem-link']}
        data-testid={`${label} main nav item`}
        href={href}
        onClick={handleClick}
      >
        {customLabelElement ?? label}
      </NextLink>
    </li>
  );
};

NavBarLinkItem.propTypes = {
  /**
   * Optional extra class to assign to the <li> element
   */
  className: PropTypes.string,

  /**
   * A customised element to render inside the button, in place of the standard label.
   *
   * NOTE: for accessibility, even when using a customLabelElement, you **MUST** still provide a value for `label` that describes what this button does!
   */
  customLabelElement: PropTypes.node,

  /**
   * The href for this link
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Label to show on the nav item
   */
  label: PropTypes.string.isRequired,
};

export default NavBarLinkItem;
