import cn from 'classnames';
import { usePathname, useSearchParams } from 'next/navigation';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { NavigationLogo } from 'site-react/components/navigation';
import { GlobalEvents } from 'site-react/helpers/Events';
import useMediaQuery from 'site-react/hooks/useMediaQuery';
import theme from 'site-react/theme';

import MegaMenu from './components/MegaMenu';
import NavBarLinkItem from './components/NavBarLinkItem';
import NavBarMenuItem from './components/NavBarMenuItem';
import NavBarSpacerItem from './components/NavBarSpacerItem';
import NavigationList from './components/NavigationList';
import SimpleMenu from './components/SimpleMenu';
import SimpleMenuList from './components/SimpleMenuList';
import styles from './NavBar.module.css';

const NavBar = ({
  children,
  darkThemeAnimated = false,
  isLogoTextDisplayed = false,
  isUserLoading = false,
  accessory = null,
}) => {
  const ref = useRef(null);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openIndicies, setOpenIndicies] = useState([]);

  const isMegaNav = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);

  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    if (!darkThemeAnimated) {
      // We aren't animating, so we don't need to track stickiness
      return;
    }

    const handleScroll = (e) => {
      const newStuckValue = window.scrollY > 10; // Start the animation at 10px

      if (isStuck !== newStuckValue) {
        setIsStuck(newStuckValue);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [darkThemeAnimated, isStuck]);

  // when switching breakpoints
  useEffect(() => {
    if (isMegaNav) {
      // keep last opened menu open
      setOpenIndicies((state) => {
        const lastIndex = state.length - 1;
        if (lastIndex === -1) {
          return [];
        }
        return [state[lastIndex]];
      });
    }
  }, [isMegaNav, setOpenIndicies]);

  useEffect(() => {
    // Close all menus when the route changes
    setOpenIndicies((prevIndicies) =>
      prevIndicies.length > 0 ? [] : prevIndicies,
    );
  }, [pathname, searchParams]);

  // Listen out for clicks anywhere on the page
  const onGlobalClick = useCallback(
    (event) => {
      // if they originate outside of our component
      if (!ref.current?.contains(event.target)) {
        setOpenIndicies([]); // close all menus
      }
    },
    [setOpenIndicies, ref],
  );

  useEffect(() => {
    if (openIndicies.length === 0) {
      // Only watch global click events if the nav is open
      return;
    }

    const globalEvents = new GlobalEvents({
      click: onGlobalClick,
    });

    globalEvents.listen();

    return () => globalEvents.remove();
  }, [onGlobalClick, openIndicies]);

  const onOpen = useCallback(
    (idx) =>
      setOpenIndicies((state) => {
        if (state.includes(idx)) {
          return state.filter((v) => v !== idx); // remove from list
        } else if (isMegaNav) {
          return [idx]; // only open this one
        } else {
          return [...state, idx]; // open alongside others
        }
      }),
    [isMegaNav],
  );

  return (
    <header
      className={cn(styles.NavBar, {
        [styles['NavBar--open']]: isMenuOpen,
        [styles['NavBar--darkThemeAnimated']]: darkThemeAnimated,
        [styles['NavBar--darkThemeAnimatedScrolledStyle']]:
          darkThemeAnimated && isStuck,
      })}
      ref={ref}
      role="banner"
    >
      <div className={styles['NavBar-logo']}>
        <NavigationLogo isLogoTextDisplayed={isLogoTextDisplayed} />
      </div>

      {accessory ? (
        <div className={styles['NavBar-accessory']}>{accessory}</div>
      ) : undefined}

      <NavBar.Context.Provider
        value={{
          isMegaNav,
          onOpen,
          openIndicies,
        }}
      >
        <NavigationList
          isMegaNav={isMegaNav}
          isMenuOpen={isMenuOpen}
          isUserLoading={isUserLoading}
          onMenuClick={() => setIsMenuOpen((state) => !state)}
        >
          {children}
        </NavigationList>
      </NavBar.Context.Provider>
    </header>
  );
};

NavBar.Context = createContext({});

NavBar.propTypes = {
  /**
   * An accessory to display in available NavBar whitespace.
   */
  accessory: PropTypes.node,

  /**
   * Primary navigation items to show
   */
  children: PropTypes.node,

  /**
   * Whether it changes color on scroll
   */
  darkThemeAnimated: PropTypes.bool,

  /**
   * Whether to display text with our navigation logo
   */
  isLogoTextDisplayed: PropTypes.bool,

  /**
   * Whether to hide nav items because the user is still loading
   */
  isUserLoading: PropTypes.bool,
};

// NavBar item components, used to add items at the top level of the nav
NavBar.LinkItem = NavBarLinkItem;
NavBar.MenuItem = NavBarMenuItem;
NavBar.SpacerItem = NavBarSpacerItem;

// NavBar menu components, to be used with `NavBar.MenuItem` to create menu content
NavBar.MegaMenu = MegaMenu;
NavBar.SimpleMenu = SimpleMenu;
NavBar.SimpleMenu.List = SimpleMenuList;

export default NavBar;
