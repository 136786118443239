class ProductPriceFormatter {
  priceTypes = Object.freeze({
    credits: 'credits',
    currency: 'currency',
  });

  grossPriceConfig = Object.freeze({
    credits: 'grossPrice',
    currency: 'grossPriceInCurrency',
  });

  priceTypeConfig = Object.freeze({
    credits: 'price',
    currency: 'pricePaygInCurrency',
  });

  meetingRoomPriceConfig = Object.freeze({
    perDay: {
      credits: 'pricePerDay',
      currency: 'pricePerDayPaygInCurrency',
    },
    perHour: {
      credits: 'pricePerHour',
      currency: 'pricePerHourPaygInCurrency',
    },
  });

  /**
   * Given a number this class will return that price value formatted to a desired price type.
   *
   * @param {string} locale - the locale to use for the currency e.g. "en-GB". Find more via: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
   * @param {string} currencyFormat - the currency to use in currency formatting e.g. "GBP". Find more via: https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
   */
  constructor(locale, currencyFormat) {
    this.currencyFormatter = new Intl.NumberFormat(locale, {
      currency: currencyFormat,
      maximumFractionDigits: 0,
      style: 'currency',
    });
  }

  /**
   * Get the formatted version of a product's price.
   *
   * @param {number} price - The price to format.
   * @param {string} priceType - The price type to retrieve for the product e.g. "credits".
   *
   * @returns A formatted price string.
   */
  getFormattedPrice(price, priceType) {
    this.checkForInvalidPriceType(priceType);

    let formattedPrice;

    if (priceType === this.priceTypes.currency) {
      formattedPrice = this.getProductPriceInCurrency(price);
    } else {
      formattedPrice = this.getProductPriceInCredits(price);
    }

    return formattedPrice;
  }

  getProductPriceInCurrency(price) {
    return this.currencyFormatter.format(price);
  }

  getProductPriceInCredits(price) {
    return `${price} credit${price !== 1 ? 's' : ''}`;
  }

  checkForInvalidPriceType(priceType) {
    const acceptedPriceTypes = Object.values(this.priceTypes);

    if (!acceptedPriceTypes.includes(priceType)) {
      throw new Error(
        `Invalid price type: ${priceType}. Accepted price types are: ${acceptedPriceTypes.join(
          ', ',
        )}`,
      );
    }
  }
}

export default ProductPriceFormatter;
