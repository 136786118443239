import PropTypes from 'prop-types';

import styles from './CustomCheckbox.module.css';

export default function CustomCheckbox({
  checked,
  content,
  disabled = false,
  id,
  name,
  onChange,
}) {
  return (
    <label className={styles.CustomCheckbox}>
      <input
        aria-label={name}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <span className={styles['CustomCheckbox--checkmark']} />
      <b className={styles['CustomCheckbox--content']}>{content}</b>
    </label>
  );
}

CustomCheckbox.propTypes = {
  /**
   * If true, the checkbox is checked.
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Display value within the checkbox. There isn't much room so it should be
   * short.
   */
  content: PropTypes.string.isRequired,
  /**
   * If true, the checkbox is disabled and can't be interacted with.
   */
  disabled: PropTypes.bool,
  /**
   * Unique id for the checkbox.
   */
  id: PropTypes.string.isRequired,
  /**
   * The name of the checkbox. Also used as the aria-label which is useful if
   * passing content that wouldn't make sense as an aria-label. For instance,
   * an abbreviation.
   */
  name: PropTypes.string.isRequired,
  /**
   * Function to call when the checkbox is changed.
   */
  onChange: PropTypes.func.isRequired,
};
