import { useEffect, useState } from 'react';

import colors from 'site-react/theme/colors';

import { addMapListeners, removeMapListeners } from './mapListeners';
import styles from './mapStyles';

/**
 * uses the google maps api to create a map, and binds it to the passed in
 * element.
 *
 * @param {*} element - the element to bind the map to
 * @param {Object} GoogleMap - the google map api
 * @param {Object} InitialMapSettings - the settings to launch the map with.
 * see here for more info: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 * @param {Object} isFittingBoundsRef - is the fitBounds method currently running? This is a ref - use `isFittingBoundsRef.current` to get its value
 * @param {Boolean} isSearchable - a toggle to determine whether we want searches to be triggered by moving the map.
 * @param {Function} setActiveId - callback for setting the active id highlighted on the map. controls nanocard, active pin.
 */
const useMap = ({
  element,
  GoogleMap,
  initialMapSettings = {
    center: { lat: 51.509865, lng: -0.118092 },
    zoom: 14,
  },
  isFittingBoundsRef = () => {},
  isSearchable = false,
  setActiveId = () => {},
  mapId = null,
}) => {
  const [map, setMap] = useState(null);
  const [mapQuery, setMapQuery] = useState();

  const { center, gestureHandling, zoom } = initialMapSettings;
  let lat;
  let lng;
  if (center) {
    lat = center.lat;
    lng = center.lng;
  }
  useEffect(() => {
    if (!(GoogleMap && element)) {
      // No cleanup required, since we didn't do anything!
      // This is here to ensure the hook returns consistently.
      return;
    }
    setMap(
      new GoogleMap.Map(element, {
        backgroundColor: colors.neutral50,
        center: { lat: lat, lng: lng },
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: gestureHandling || 'greedy',
        mapId: mapId,
        styles,
        zoom: zoom,
        zoomControl: true,
        zoomControlOptions: {
          position: GoogleMap.ControlPosition.TOP_RIGHT,
        },
      }),
    );
  }, [
    lat,
    lng,
    element,
    gestureHandling,
    GoogleMap,
    isSearchable,
    mapId,
    zoom,
  ]);

  useEffect(() => {
    if (!map) return () => {};

    const listeners = addMapListeners(
      isFittingBoundsRef,
      isSearchable,
      map,
      setActiveId,
      setMapQuery,
    );
    // this returns adds a cleanup function, which will be fired when the state changes.
    return () => {
      removeMapListeners(GoogleMap, listeners);
    };
  }, [GoogleMap, isFittingBoundsRef, isSearchable, map, setActiveId]);

  return { map, mapQuery, setMapQuery };
};

export default useMap;
