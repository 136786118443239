import { yupResolver } from '@hookform/resolvers/yup';
import { HttpError, NetworkError } from '@hubble/request';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Button, TextArea } from 'site-react/components/form';
import { Grid } from 'site-react/components/page';
import { ModalNewContext } from 'site-react/components/page/ModalNew';
import {
  ActionText,
  ErrorMessage,
  Heading,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import logError from 'site-react/helpers/logError';

const spaciousApi = new SpaciousApi();

const validationSchema = Yup.object().shape({
  advisorNotes: Yup.string(),
});

export default function AdvisorNote({
  buildingName,
  onSuccess,
  recommendationListId,
  recommendationListItem,
}) {
  const { closeModal } = useContext(ModalNewContext);
  const { formState, handleSubmit, register, setError, setValue } = useForm({
    defaultValues: {
      advisorNotes: recommendationListItem.advisorNotes,
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit(data) {
    try {
      const requiredPayload = {
        isPartTime: recommendationListItem.isPartTime,
        pricePlanId: recommendationListItem.pricePlanId,
      };
      await spaciousApi.updateAdminRecommendationListItem(
        recommendationListId,
        recommendationListItem.id,
        {
          ...requiredPayload,
          advisorNotes: data.advisorNotes,
        },
      );

      onSuccess(data.advisorNotes);
      closeModal();
    } catch (error) {
      if (error instanceof HttpError) {
        if (error.statusCode === 400) {
          if (Object.keys(error.body).includes('advisorNotes')) {
            setError('advisorNotes', {
              message: error.body.advisorNotes[0],
              type: 'custom',
            });
          }
        }
        setError('root.serverError', {
          message: 'Something went wrong. Please try again.',
        });
      } else if (error instanceof NetworkError) {
        setError('root.serverError', {
          message: 'Something went wrong. Please try again.',
        });
      } else {
        setError('root.serverError', {
          message: 'Something went wrong. Please try again.',
        });
        logError(error);
      }
    }
  }

  return (
    <>
      <Heading level="1" type="title4">
        {buildingName}
      </Heading>

      <VerticalSpacing size="md" />

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid gridGapY="sm">
          <Grid.Item colSpan="12">
            <TextArea
              errorText={formState.errors?.advisorNotes?.message}
              labelText={`${recommendationListItem.advisorNotes ? 'Edit' : 'Add a'} note for your customer`}
              placeholder="Leave an optional note"
              status={formState.errors?.advisorNotes?.message && 'error'}
              {...register('advisorNotes')}
            />
          </Grid.Item>

          {formState.errors.root?.serverError?.message ? (
            <Grid.Item colSpan="12">
              <ErrorMessage isMarginless>
                {formState.errors.root.serverError.message}
              </ErrorMessage>
            </Grid.Item>
          ) : null}

          {recommendationListItem.advisorNotes && (
            <Grid.Item align="center" colSpan="6" justify="start">
              <ActionText
                contentType="content2"
                disabled={formState.isSubmitting}
                label="Clear"
                onClick={() => {
                  setValue('advisorNotes', '');
                  handleSubmit(onSubmit)();
                }}
              >
                Clear
              </ActionText>
            </Grid.Item>
          )}
          <Grid.Item
            align="center"
            colSpan={recommendationListItem.advisorNotes ? '6' : '12'}
            justify="end"
          >
            <Button
              disabled={formState.isSubmitting}
              isCompact
              isLoading={formState.isSubmitting}
              name={recommendationListItem.advisorNotes ? 'Save' : 'Add note'}
              type="submit"
            >
              {recommendationListItem.advisorNotes ? 'Save' : 'Add note'}
            </Button>
          </Grid.Item>
        </Grid>
      </form>
    </>
  );
}

AdvisorNote.propTypes = {
  recommendationListId: PropTypes.number.isRequired,
  recommendationListItem: PropTypes.shape({}).isRequired,
};
