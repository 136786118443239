import { useContext, useState } from 'react';

import { Button } from 'site-react/components/form';
import ModalNew, {
  ModalNewContext,
  ModalNewTemplateBasic,
} from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';

import styles from './ConfirmModal.module.css';

const ConfirmModalContent = ({
  confirmLabel,
  declineLabel,
  prompt,
  onConfirm,
  onDecline,
}) => {
  const { closeModal } = useContext(ModalNewContext);
  const _onDecline = () => {
    if (onDecline) {
      onDecline();
    }
    closeModal();
  };

  const _onConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    closeModal();
  };

  return (
    <ModalNewTemplateBasic>
      <div className={styles['ConfirmModalContent-title']}>
        <Heading isCentered level="3" type="title3">
          {prompt}
        </Heading>
      </div>
      <div className={styles['ConfirmModalContent-buttonWrapper']}>
        <Button name="modal-confirm" onClick={_onConfirm} type="button">
          {confirmLabel}
        </Button>
        <Button
          name="modal-decline"
          onClick={_onDecline}
          styleType="secondary"
          type="button"
        >
          {declineLabel}
        </Button>
      </div>
    </ModalNewTemplateBasic>
  );
};

const ConfirmModal = ({
  confirmLabel,
  declineLabel,
  prompt,
  onConfirm,
  onDecline,
  children,
}) => {
  const [opened, setOpened] = useState(false);

  const _openModal = (openModal) => {
    setOpened(true);
    openModal();
  };

  return (
    <ModalNew
      label="Confirmation Modal"
      renderTrigger={({ openModal }) => {
        return <div onClick={() => _openModal(openModal)}> {children} </div>;
      }}
    >
      {/* 
      Only render the content once the modal has been opened // this allows
      the ConfirmModalContent component to get the ModalContext // after the
      modal has been opened.
      */}
      {opened && (
        <ConfirmModalContent
          confirmLabel={confirmLabel}
          declineLabel={declineLabel}
          onConfirm={onConfirm}
          onDecline={onDecline}
          prompt={prompt}
        />
      )}
    </ModalNew>
  );
};

export default ConfirmModal;
