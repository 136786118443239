import getCountryCodes from './getCountryCodes';

/**
 * Gets a list of "pinned" country codes. These are country codes that would
 * normally be shown at a top of a list, as they are the most common country
 * codes used by our customers.
 *
 * Each country will have the following keys:
 * - name: The name of the country for this code
 * - flag: An emoji representing the country's flag
 * - code: An ISO-3166 shortcode for the country, eg. GB for United Kingdom, or CA for Canada
 * - dial_code: The country code, prefixed with +, eg. +44 for United Kingdom, or +1 for Canada
 *
 * @returns [Object]
 */
export default function getPinnedCountryCodes() {
  const countryCodes = getCountryCodes();
  const pinnedCountries = ['GB', 'NL'];

  const pinnedCountryCodes = countryCodes.filter((country) =>
    pinnedCountries.includes(country.code),
  );

  return pinnedCountryCodes.sort((a, b) => {
    if (a.code === 'GB') return -1; // Move a (+44) to the front
    if (b.code === 'GB') return 1; // Move b (+44) to the front
    return 0; // Leave their order unchanged
  });
}
