import { LinkAsButton } from 'site-react/components/navigation';
import { ModalNewTemplateBasic } from 'site-react/components/page/ModalNew';
import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './ArchivedListingRedirectModal.module.css';

const ArchivedListingRedirectModal = () => (
  <ModalNewTemplateBasic>
    <div className={styles.ArchivedListingRedirectModal}>
      <Heading isCentered type="title1">
        Sorry, there are no available offices in this building right now
      </Heading>
      <VerticalSpacing size="sm" />
      <Paragraph>
        We cover 100% of the market so there are plenty more offices like this
        one that could be your perfect match.
      </Paragraph>

      <LinkAsButton
        href="/full-time-office-enquiry"
        name="Go to full time office enquiry"
      >
        View similar offices
      </LinkAsButton>
    </div>
  </ModalNewTemplateBasic>
);

export default ArchivedListingRedirectModal;
