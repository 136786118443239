import debounce from 'lodash.debounce';
import Router from 'next/router';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PassApi from 'site-react/api/PassApi';
import { paymentTypes } from 'site-react/features/Listing';
import runWithRetry from 'site-react/helpers/runWithRetry';
import useUser from 'site-react/hooks/useUser';

const MembershipContext = createContext();

function MembershipProvider(props) {
  const passTopUpPricePerCredit = 5;

  const [account, setAccount] = useState(null);
  const [accountError, setAccountError] = useState(null);
  const [isAccountLoading, setIsAccountLoading] = useState(true);

  const [plan, setPlan] = useState(() => null);
  const [planError, setPlanError] = useState(null);
  const [isPlanLoading, setIsPlanLoading] = useState(true);
  const { isLoggedIn } = useUser();

  /**
   * Make a request to get the plan data associated with the user's Organisation (if any).
   *
   * @param {*} silent - If true, we don't set a loading state or wipe out the cached plan. This
   *                     allows us to quietly update the plan data when a user navigates back to
   *                     the Pass booking page without throwing them back into a loading state.
   *                     This means we always have the most up to date version of the user's Organisation's
   *                     plan without disrupting their flow when knowing the most recent version is important.
   */
  const refreshPlan = useCallback(({ silent } = { silent: false }) => {
    const api = new PassApi();
    if (!silent) {
      setIsPlanLoading(true);
      setPlan(null);
    }
    setPlanError(null);

    (async function () {
      try {
        const response = await runWithRetry(() => api.getPlan());
        setPlan(response.body);
      } catch (exception) {
        if (exception.message) {
          setPlanError(exception.message);
        } else if (exception.error) {
          setPlanError(exception.error);
        } else {
          setPlanError('Something went wrong getting your plan.');
        }
      }

      setIsPlanLoading(false);
    })();
  }, []);

  const refreshAccount = useCallback(() => {
    const api = new PassApi();
    setIsAccountLoading(true);
    setAccountError(null);
    setAccount(null);

    (async function () {
      try {
        const response = await runWithRetry(() => api.getFinanceAccount());
        setAccount(response.body);
      } catch (exception) {
        if (exception.error) {
          setAccountError(exception.error);
        } else if (exception.message) {
          setAccountError(exception.message);
        } else {
          setAccountError('Something went wrong getting your account balance.');
        }
      }

      setIsAccountLoading(false);
    })();
  }, []);

  useEffect(() => {
    const debouncedRefreshPlan = debounce(() => {
      if (isLoggedIn) {
        refreshPlan({ silent: true });
      }
    }, 500);

    Router.events.on('routeChangeStart', debouncedRefreshPlan);

    return () => Router.events.off('routeChangeStart', debouncedRefreshPlan);
  }, [refreshPlan, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      refreshPlan();
    }
  }, [refreshPlan, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      refreshAccount();
    }
  }, [refreshAccount, isLoggedIn]);

  const hasMembership = useMemo(() => {
    if (plan) {
      if (
        plan?.paymentType === paymentTypes.CREDITS ||
        plan?.paymentType === paymentTypes.CREDITS_LEGACY
      ) {
        // Currently we can determine if a user belongs to an organisation with a membership if the payment type on their plan is either credits or credits legacy.
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [plan]);

  const value = useMemo(
    () => ({
      account,
      accountError,
      hasMembership,
      isAccountLoading,
      isPlanLoading,
      passTopUpPricePerCredit,
      plan,
      planError,
      refreshAccount,
      refreshPlan,
    }),
    [
      account,
      accountError,
      hasMembership,
      isAccountLoading,
      isPlanLoading,
      passTopUpPricePerCredit,
      plan,
      planError,
      refreshAccount,
      refreshPlan,
    ],
  );

  return <MembershipContext.Provider value={value} {...props} />;
}

export { MembershipContext };
export default MembershipProvider;
