import PropTypes from 'prop-types';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './Arrow.module.css';

const arrowIconConfig = {
  next: 'chevron_right',
  previous: 'chevron_left',
};

export default function Arrow({ direction, onClick, ...props }) {
  return (
    <button className={styles.Arrow} onClick={onClick} type="button" {...props}>
      <MaterialIcon iconType={arrowIconConfig[direction]} />
    </button>
  );
}

Arrow.propTypes = {
  direction: PropTypes.oneOf(['next', 'previous']).isRequired,
};
