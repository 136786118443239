import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from './Link.module.css';

/**
 * Hubble's Link component.
 *
 * This component does 2 main things:
 * * Provides a limited set of Hubble-approved link styles
 * * Encourages the use of next/link for in-app links for faster navigation
 *
 * You may find the following links useful to explain the available props further:
 * * https://nextjs.org/docs/api-reference/next/link
 * * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
 * For most props, prop type descriptions have been copied verbatim from those
 * sources.
 */
export default function Link({
  analyticsMetadata = {},
  children,
  download = undefined,
  href,
  hrefLang = undefined,
  referralSource = undefined,
  isAppLink = true,
  isBold = false,
  isUnderlined = true,
  ping = undefined,
  prefetch = undefined,
  rel = undefined,
  replace = undefined,
  scroll = undefined,
  styleType = 'primary',
  target = undefined,
  testId = undefined,
  type = undefined,
}) {
  // trackLink isn't a reversible call. We need the current version of
  // analyticsMetadata to be available to the first version of trackLink; a ref
  // gives us a mutable `.current` value that can _always_ hold the latest
  // value of analyticsMetadata
  const analyticsMetadataRef = useRef(analyticsMetadata);
  const linkElement = useRef(null);

  useEffect(() => {
    analyticsMetadataRef.current = { href, ...analyticsMetadata };
  }, [analyticsMetadata, href]);

  useEffect(() => {
    // For non-next/link links, we need to use a special tracking method, which
    // delays navigation for a moment while we fire off our events.
    if (!isAppLink && linkElement.current !== null) {
      let analyticsMessage = 'Link clicked';
      if (
        typeof referralSource !== 'undefined' &&
        referralSource === 'attemptAddToShortlist'
      ) {
        analyticsMessage = 'Shortlist list clicked after Signup';
      }
      analytics.trackLink(
        linkElement.current,
        analyticsMessage,
        () => analyticsMetadataRef.current,
        { sendPageProperties: true },
      );
    }
  }, [analyticsMetadataRef, isAppLink, linkElement, referralSource]);

  const linkProps = {
    className: cn(styles.Link, {
      [styles['Link--bold']]: isBold,
      [styles['Link--label']]: styleType === 'label',
      [styles['Link--onDark']]: styleType === 'onDark',
      [styles['Link--secondary']]: styleType === 'secondary',
      [styles['Link--noDecoration']]: !isUnderlined,
    }),
    download,
    href,
    hrefLang,
    ping,
    rel,
    target,
    type,
  };

  if (isAppLink) {
    /**
     * Pick out the `href` prop so it doesn't get directly passed into the child <a> tag, since the <Link> component already takes care of this.
     * This prevents bugs where if a href is defined as an object, it outputs a href in the format of "/some/path/[object Object]", preventing users from opening a link in a new tab.
     */
    const { href, ...appLinkProps } = linkProps;

    return (
      <NextLink
        {...appLinkProps}
        data-testid={testId}
        href={href}
        onClick={() => {
          // NOTE: this handles analytics for next/link-based `Link`s. Other,
          // non-isAppLink links will use the `trackLink` method above.
          analytics.track('Link clicked', analyticsMetadataRef.current, {
            sendPageProperties: true,
          });
        }}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
      >
        {children}
      </NextLink>
    );
  }

  return (
    <a {...linkProps} data-testid={testId} ref={linkElement}>
      {children}
    </a>
  );
}

Link.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Content to show inside the Link.
   */
  children: PropTypes.node.isRequired,

  /**
   * Prompts the user to save the linked URL instead of navigating to it. Pass
   * a string to explicitly set a filename and enable download mode; pass `true`
   * to enable download mode, but let the browser decide the filename.
   */
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /**
   * Where this link leads. Or, if you're using an app link, the route to link
   * to (ie. the path within Next's `pages` folder for the page you're linking
   * to)
   */
  href: PropTypes.oneOfType([
    PropTypes.string, // https://nextjs.org/docs/api-reference/next/link#with-url-object
    PropTypes.shape({}),
  ]).isRequired,

  /**
   * Hints at the human language of the linked URL. No built-in functionality.
   *  Allowed values are the same as the global lang attribute.
   */
  hrefLang: PropTypes.string,

  /**
   * Is this an app link?
   *
   * App links should be used for pages that exist in the current app. Clicking
   * an app link won't reload the page; instead, it will simply download the
   * new scripts that are needed to load the next page.
   *
   * App links are much faster than regular links, and should be used any time
   * we're linking to a page within the current app.
   *
   * See also: https://nextjs.org/docs/api-reference/next/link
   */
  isAppLink: PropTypes.bool,

  /**
   * Should this link be boldened?
   */
  isBold: PropTypes.bool,

  /**
   * Should this link be underlined?
   */
  isUnderlined: PropTypes.bool,

  /**
   * A space-separated list of URLs. When the link is followed, the browser will
   *  send POST requests with the body PING to the URLs. Typically for tracking.
   */
  ping: PropTypes.string,

  /**
   * Prefetch the page in the background. Defaults to true. App link only.
   */
  prefetch: PropTypes.bool,

  /**
   * The relationship of the linked URL as space-separated link types.
   */
  rel: PropTypes.string,

  /**
   * Replace the current history state instead of adding a new url into the
   * stack. Defaults to false. App link only.
   */
  replace: PropTypes.bool,

  /**
   * Scroll to the top of the page after a navigation. Defaults to true. App
   * link only.
   */
  scroll: PropTypes.bool,

  /**
   * Style of the link to render
   */
  styleType: PropTypes.oneOf(['label', 'onDark', 'primary', 'secondary']),

  /**
   * Where to display the linked URL, as the name for a browsing context (a tab,
   *  window, or <iframe>).
   */
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,

  /**
   * Hints at the linked URL’s format with a MIME type. No built-in functionality.
   */
  type: PropTypes.string,
};
