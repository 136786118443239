import { DOMParser } from 'xmldom';

// NOTE: Using DOMParser (via xmldom on the server, and the native browser
// class on the client - see server/next.config.js), as it's a more reliable way
// of stripping HTML tags than, eg. regex, and doesn't leave us open to terrible
// security vulnerabilities
const removeHTML = (htmlString) => {
  if (typeof htmlString !== 'string') {
    return null;
  }

  /**
   * I’m wrapping the html string in a div so I can guarantee a root element.
   * This makes the data more predictable simplifying the text extraction.
   */
  const parser = new DOMParser().parseFromString(
    `<div>${htmlString}</div>`,
    'text/html',
  );

  return parser.documentElement.textContent;
};

export default removeHTML;
