import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Surface.module.css';

const darkBackgrounds = ['neutral-900'];

const Surface = ({ backgroundColor = null, children = null }) => {
  const defaultBackgroundColor = backgroundColor?.default || backgroundColor;
  return (
    <div
      className={cn(styles.Surface, {
        [styles['Surface--textOnDark']]: darkBackgrounds.includes(
          defaultBackgroundColor,
        ),
        // sm modifiers
        [styles['Surface--smColor']]: backgroundColor?.sm,
        [styles['Surface--textOnDarkSm']]: darkBackgrounds.includes(
          backgroundColor?.sm,
        ),
        [styles['Surface--textOnLightSm']]:
          backgroundColor?.sm && !darkBackgrounds.includes(backgroundColor?.sm),
        // md modifiers
        [styles['Surface--mdColor']]: backgroundColor?.md,
        [styles['Surface--textOnDarkMd']]: darkBackgrounds.includes(
          backgroundColor?.md,
        ),
        [styles['Surface--textOnLightMd']]:
          backgroundColor?.md && !darkBackgrounds.includes(backgroundColor?.md),
        // lg modifiers
        [styles['Surface--lgColor']]: backgroundColor?.lg,
        [styles['Surface--textOnDarkLg']]: darkBackgrounds.includes(
          backgroundColor?.lg,
        ),
        [styles['Surface--textOnLightLg']]:
          backgroundColor?.lg && !darkBackgrounds.includes(backgroundColor?.lg),
        // xl modifiers
        [styles['Surface--xlColor']]: backgroundColor?.xl,
        [styles['Surface--textOnDarkXl']]: darkBackgrounds.includes(
          backgroundColor?.xl,
        ),
        [styles['Surface--textOnLightXl']]:
          backgroundColor?.xl && !darkBackgrounds.includes(backgroundColor?.xl),
        // xxl modifiers
        [styles['Surface--xxlColor']]: backgroundColor?.xxl,
        [styles['Surface--textOnDarkXxl']]: darkBackgrounds.includes(
          backgroundColor?.xxl,
        ),
        [styles['Surface--textOnLightXxl']]:
          backgroundColor?.xxl &&
          !darkBackgrounds.includes(backgroundColor?.xxl),
        // xxxl modifiers
        [styles['Surface--xxxlColor']]: backgroundColor?.xxxl,
        [styles['Surface--textOnDarkXxxl']]: darkBackgrounds.includes(
          backgroundColor?.xxxl,
        ),
        [styles['Surface--textOnLightXxxl']]:
          backgroundColor?.xxxl &&
          !darkBackgrounds.includes(backgroundColor?.xxxl),
      })}
      style={{
        '--Surface-defaultColor':
          typeof backgroundColor === 'string'
            ? `var(--color-${backgroundColor})`
            : `var(--color-${backgroundColor.default})`,
        ...(backgroundColor?.sm && {
          '--Surface-smColor': `var(--color-${backgroundColor.sm})`,
        }),
        ...(backgroundColor?.md && {
          '--Surface-mdColor': `var(--color-${backgroundColor.md})`,
        }),
        ...(backgroundColor?.lg && {
          '--Surface-lgColor': `var(--color-${backgroundColor.lg})`,
        }),
        ...(backgroundColor?.xl && {
          '--Surface-xlColor': `var(--color-${backgroundColor.xl})`,
        }),
        ...(backgroundColor?.xxl && {
          '--Surface-xxlColor': `var(--color-${backgroundColor.xxl})`,
        }),
        ...(backgroundColor?.xxxl && {
          '--Surface-xxxlColor': `var(--color-${backgroundColor.xxxl})`,
        }),
      }}
    >
      {children}
    </div>
  );
};

const backgroundColorOptions = [
  'brandsecondary',
  'brandsecondary-light',
  'neutral-900',
  'gradient-bluepink',
  'gradient-pinkblue',
  'gradient-blue',
];

Surface.propTypes = {
  /**
   * The background color to use. May influence the color of text and links within children.
   *
   * If value is a string this color will be applied across all breakpoints.
   *
   * If value is an object the default color will be applied up to the first breakpoint, at which point
   * we'll use that color up to the next breakpoint, and so on.
   */
  backgroundColor: PropTypes.oneOfType([
    PropTypes.oneOf(backgroundColorOptions),
    /* eslint-disable sort-keys */
    PropTypes.shape({
      default: PropTypes.oneOf(backgroundColorOptions).isRequired,
      sm: PropTypes.oneOf(backgroundColorOptions),
      md: PropTypes.oneOf(backgroundColorOptions),
      lg: PropTypes.oneOf(backgroundColorOptions),
      xl: PropTypes.oneOf(backgroundColorOptions),
      xxl: PropTypes.oneOf(backgroundColorOptions),
      xxxl: PropTypes.oneOf(backgroundColorOptions),
    }),
    /* eslint-enable sort-keys */
  ]).isRequired,

  /**
   * The children to show.
   */
  children: PropTypes.node,
};

export default Surface;
