import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from '../Link/Link.module.css';

/**
 * An outbound link, ie. one that leads to a domain that isn't hosted in this project.
 * If you are linking to something within this project, please use next/link.
 * Adds link tracking, with a slight delay to increase the likelihood of our
 * tracking events reaching Amplitude/Google Analytics/Segment.
 */
const OutboundLink = ({
  analyticsMetadata = {},
  children,
  href,
  isBold = false,
  isUnderlined = true,
  styleType = 'primary',
  ...props
}) => {
  // trackLink isn't a reversible call. We need the current version of
  // analyticsMetadata to be available to the first version of trackLink; a ref
  // gives us a mutable `.current` value that can _always_ hold the latest
  // value of analyticsMetadata
  const analyticsMetadataRef = useRef(analyticsMetadata);
  const linkElement = useRef(null);

  useEffect(() => {
    analyticsMetadataRef.current = { href, ...analyticsMetadata };
  }, [analyticsMetadata, href]);

  useEffect(() => {
    if (linkElement.current !== null) {
      /**
       * Tracks a link. Recommended only when we want to track an _outbound_ link,
       * ie. a link to Typeform. Internal links to other Hubble pages shouldn't use
       * this, as it adds a delay on link clicks and degrades the user experience.
       *
       * The analytics event only fires once a tracked link is clicked.
       *
       * See also: https://segment.com/docs/sources/website/analytics.js/#track-link
       */
      analytics.trackLink(
        linkElement.current,
        'Outbound link clicked',
        () => analyticsMetadataRef.current,
        { sendPageProperties: true },
      );
    }
  }, [analyticsMetadataRef, linkElement]);

  return (
    // OutboundLink is meant to be a normal <a> element, with analytics. Since the
    // actual children are defined elsewhere, our eslint config gets tripped up.
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className={cn(styles.Link, {
        [styles['Link--bold']]: isBold,
        [styles['Link--label']]: styleType === 'label',
        [styles['Link--onDark']]: styleType === 'onDark',
        [styles['Link--secondary']]: styleType === 'secondary',
        [styles['Link--noDecoration']]: !isUnderlined,
      })}
      href={href}
      ref={linkElement}
      {...props}
    >
      {children}
    </a>
  );
};

OutboundLink.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Content to display in the link
   */
  children: PropTypes.node.isRequired,

  /**
   * Destination for this link
   */
  href: PropTypes.string.isRequired,

  /**
   * Should this link be boldened?
   */
  isBold: PropTypes.bool,

  /**
   * Should this link be underlined?
   */
  isUnderlined: PropTypes.bool,

  /**
   * Style of the link to render
   */
  styleType: PropTypes.oneOf(['label', 'onDark', 'primary', 'secondary']),
};

export default OutboundLink;
