/**
 * Function to convert booking response times from milliseconds to hours, rounded up to whole hours.
 *
 * @param {number|undefined} ms Booking response time in milliseconds
 * @param {number} maxHours Maximum hour cutoff to allow, returning null if it's surpassed.
 * @returns {number|null} Rounded booking response hours. Null if converted hours are above the maximum threshold.
 */
const getConvertedBookingResponseTime = (ms, maxHours) => {
  if (!ms && ms !== 0) return null;

  const hours = Math.ceil(ms / 3600000);

  if (hours > maxHours) return null;

  return hours;
};

export default getConvertedBookingResponseTime;
