import PropTypes from 'prop-types';
import React from 'react';

import { Modal, UICard } from 'site-react/components/page';
import { Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './HqListingUspCard.module.css';

const HqListingUspCard = ({ description, modalContent, title }) => (
  <aside className={styles.HqListingUspCard}>
    <Modal
      modalName="hq-listing-usp-card-modal"
      renderTrigger={({ openModal }) => {
        return (
          <button
            className={styles['HqListingUspCard-modalTrigger']}
            onClick={() => {
              if (modalContent) openModal();
            }}
          >
            <UICard
              height="fill"
              style={{
                border: `1px solid var(--color-neutral-100)`,
                borderRadius: '6px',
                padding: `var(--space-md)`,
              }}
            >
              <div className={styles['HqListingUspCard-title']}>{title}</div>
              <VerticalSpacing size="sm" />
              <Paragraph type="content3">{description}</Paragraph>
            </UICard>
          </button>
        );
      }}
    >
      {modalContent}
    </Modal>
  </aside>
);

HqListingUspCard.propTypes = {
  description: PropTypes.node.isRequired,
  modalContent: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default HqListingUspCard;
