import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './CloseButton.module.css';

const CloseButton = ({ color = 'neutral-500', onClick = () => {} }) => (
  <MaterialIcon
    className={styles.CloseButton}
    data-testid="CloseButton"
    iconType="close"
    onClick={onClick}
    role="button"
    style={{
      '--CloseButton-color': `var(--color-${color})`,
    }}
  />
);

CloseButton.propTypes = {
  /**
   * The color of the MaterialIcon cross component
   */
  color: PropTypes.string,
  /**
   * What we want to do after the close button has been clicked
   */
  onClick: PropTypes.func,
};

export default CloseButton;
