import PropTypes from 'prop-types';

/**
 * Common props that we use to represent analyticsMetadata across the site.
 */
export default PropTypes.shape({
  /**
   * The name of an area
   */
  area: PropTypes.string,

  /**
   * An ID for a building
   */
  buildingId: PropTypes.number,

  /**
   * The name of a city
   */
  city: PropTypes.string,

  /**
   * Used for Viewing Requests. The date of a VR.
   */
  date: PropTypes.string,

  /**
   * The location of this button, eg. office-showcase, sidebar
   */
  location: PropTypes.string,

  /**
   * A price plan ID
   */
  pricePlanId: PropTypes.number,

  /**
   * The currently-selected price plan for a Viewing Request
   */
  selectedPricePlan: PropTypes.shape({
    priceFormattedByMonth: PropTypes.string,
    type: PropTypes.string,
  }),

  /**
   * Used for Viewing Requests. The time of a VR.
   */
  time: PropTypes.string,
});
