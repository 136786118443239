import PropTypes from 'prop-types';

import { Price } from 'site-react/components/typography';

import styles from './PricePerMonth.module.css';

export default function PricePerMonth({ price }) {
  return (
    <span>
      <span className={styles['PricePerMonth-amount']}>
        <Price amount={price} />
      </span>{' '}
      / <abbr title="month">mo</abbr>
    </span>
  );
}

PricePerMonth.propTypes = {
  price: PropTypes.number.isRequired,
};
