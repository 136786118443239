import React from 'react';

import styles from './Placeholder.module.css';

/**
 * A temporary component to highlight missing or incomplete content and assets.
 */
export default function Placeholder({ children, ElementType = 'div' }) {
  return <ElementType className={styles.Placeholder}>{children}</ElementType>;
}
