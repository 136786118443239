import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import PersonaApi from 'site-react/api/PersonaApi';
import logError from 'site-react/helpers/logError';
import useLocalStorage from 'site-react/hooks/useLocalStorage';
import useRequest from 'site-react/hooks/useRequest';
import useUser from 'site-react/hooks/useUser';

import { Provider } from './context';

const PersonaProvider = ({ children = null }) => {
  const { isLoggedIn, logoutTarget } = useUser();

  const [localPersona, setLocalPersona, removeLocalPersona] = useLocalStorage(
    'persona',
    {},
  );

  useEffect(() => {
    const handleLogout = () => {
      removeLocalPersona();
    };

    logoutTarget.addEventListener('logout', handleLogout);

    return () => {
      logoutTarget.removeEventListener('logout', handleLogout);
    };
  }, [logoutTarget, removeLocalPersona]);

  // dbPersona is User's persona object saved on server
  const [dbPersona, setDbPersona] = useState({});

  const { body: getPersonaBody, exception: getPersonaException } = useRequest(
    PersonaApi,
    'getPersona',
    null,
    isLoggedIn,
  );

  useEffect(() => {
    setDbPersona(getPersonaBody);
  }, [getPersonaBody]);

  useEffect(() => {
    if (getPersonaException) {
      logError(getPersonaException);
    }
  }, [getPersonaException]);

  const updatePersonaOnServer = useCallback(async (personaData) => {
    const api = new PersonaApi();
    try {
      const { body: newPersona } = await api.updatePersona(personaData);
      setDbPersona(newPersona);
    } catch (error) {
      logError(error);
      return false;
    }
    return true;
  }, []);

  const updatePersonaLocal = useCallback(
    async (personaData) => {
      setLocalPersona(personaData);
      return true;
    },
    [setLocalPersona],
  );

  /**
   * Persisting localstorage persona to server only if localStorage
   * persona exists but persona on server does not
   */
  const persistLocalPersonaToServer = useCallback(async () => {
    const api = new PersonaApi();
    let personaFromApi;
    try {
      const { body } = await api.getPersona();
      personaFromApi = body;
    } catch (error) {
      logError(error);
    }
    if (Object.keys(localPersona).length !== 0 && !personaFromApi) {
      return updatePersonaOnServer(localPersona);
    }
    return false;
  }, [localPersona, updatePersonaOnServer]);

  return isLoggedIn ? (
    <Provider
      value={{
        hasPersona: Boolean(dbPersona && Object.keys(dbPersona).length),
        persistPersona: persistLocalPersonaToServer,
        persona: dbPersona,
        updatePersona: updatePersonaOnServer,
      }}
    >
      {children}
    </Provider>
  ) : (
    <Provider
      value={{
        hasPersona: Boolean(localPersona && Object.keys(localPersona).length),
        persistPersona: persistLocalPersonaToServer,
        persona: localPersona,
        updatePersona: updatePersonaLocal,
      }}
    >
      {children}
    </Provider>
  );
};

PersonaProvider.propTypes = {
  /**
   * Child components.
   */
  children: PropTypes.node,
};

export default PersonaProvider;
