// TODO: This file is not currently unit tested.
// Implementing these tests is complex, and at the time,
// was deemed not worth the effort. (Agreed by: Simon, Stella)
import { useState, useEffect, useRef } from 'react';

const useMapApi = (isScriptReady) => {
  const mapRef = useRef(null);
  const [GoogleMap, setGoogleMap] = useState(null);

  useEffect(() => {
    if (isScriptReady) {
      setGoogleMap(window.google.maps);
    }
  }, [isScriptReady]);

  return {
    GoogleMap,
    mapRef,
  };
};

export default useMapApi;
