import Image from 'next/image';

import { Grid, HubbleLogo, Section, Surface } from 'site-react/components/page';
import { HorizontalRule } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import JsonLD from 'site-react/helpers/jsonLD';

import LegalLinks from './components/LegalLinks';
import SearchWorkspacesForm from './components/SearchWorkspacesForm';
import UsefulLinks from './components/UsefulLinks';
import styles from './Footer.module.css';
import data from './schemaData';

export default function Footer() {
  return (
    <footer>
      <Surface backgroundColor="neutral-900">
        <Section verticalPadding="xl">
          <Grid>
            <Grid.Item align="end" colSpan="6">
              <JsonLD data={data} />
              <div className={styles['Footer-logo']}>
                <HubbleLogo size={48} />
                <Image
                  alt="Trustpilot rating of 4.8 stars"
                  height={48}
                  loader={imgixLoader}
                  src={getImgixPathWithParameters(
                    '/site/homepage/trustpilot-white-letters.svg',
                    { auto: 'compress' },
                  )}
                  width={260}
                />
              </div>
            </Grid.Item>

            <Grid.Item align="end" colSpan="6">
              <SearchWorkspacesForm />
            </Grid.Item>
          </Grid>
        </Section>

        <Section verticalPadding="xl">
          <HorizontalRule />
        </Section>

        <Section verticalPadding="xl">
          <Grid>
            <Grid.Item colSpan="12">
              <UsefulLinks />
            </Grid.Item>
          </Grid>
        </Section>

        <Section verticalPadding="xl">
          <HorizontalRule />
        </Section>

        <Section verticalPadding="xl">
          <Grid>
            <Grid.Item colSpan="12">
              <LegalLinks />
            </Grid.Item>
          </Grid>
        </Section>
      </Surface>
    </footer>
  );
}
