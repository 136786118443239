import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';

import styles from './ActionText.module.css';

/**
 * We very often want to display text that looks like our link styling but with
 * some kind of onClick handler passed rather than behaving like a link with a redirect.
 * For instance, something with the appearance of a link might open
 * a Modal or change the view on a page without actually redirecting.
 *
 * This component renders something with the styling of a link with the behaviour of a button.
 */
const ActionText = ({
  analyticsMetadata = {},
  children: text,
  contentType = 'content1',
  isEnabled = true,
  isUnderlined = false,
  label = '',
  onClick = () => {},
  styleType = 'primary',
  ...props
}) => {
  const sanitisedProps = { ...props };

  if (props['data-testid']) {
    sanitisedProps.testId = props['data-testid'];
    delete sanitisedProps['data-testid'];
  }
  return (
    <UnstyledButton
      analyticsMetadata={analyticsMetadata}
      className={cn(
        styles.ActionText,
        styles[`ActionText--${contentType}`],
        styles[`ActionText--${styleType}`],
        {
          [styles['ActionText--underlined']]: isUnderlined,
        },
      )}
      isEnabled={isEnabled}
      label={label}
      onClick={onClick}
      type="button"
      {...sanitisedProps}
    >
      {text}
    </UnstyledButton>
  );
};

ActionText.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: PropTypes.shape({}),

  /**
   * Used as `text` in component. Component to embed into the UnstyledButton
   */
  children: PropTypes.node,

  /**
   * Typography rules to apply to the content.
   */
  contentType: PropTypes.oneOf(['content1', 'content2', 'content3', 'title1']),

  /**
   * Is this button enabled? Propagates as <button>'s `disabled` attribute.
   */
  isEnabled: PropTypes.bool,

  /**
   * Should this button be underlined?
   */
  isUnderlined: PropTypes.bool,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string.isRequired,

  /**
   * Callback when this button activates.
   */
  onClick: PropTypes.func,

  /**
   * The pre-defined style of ActionText.
   */
  styleType: PropTypes.oneOf(['primary', 'secondary', 'cancel']),
};

export default ActionText;
