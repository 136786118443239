import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './AdvisorPhoto.module.css';

const photoWidth = {
  large: 96,
  medium: 64,
  small: 40,
};

const AdvisorPhoto = ({ advisorUrl, photoSize = 'small' }) => (
  <Image
    alt="Hubble Advisor"
    className={styles[`AdvisorPhoto--${photoSize}`]}
    height={photoWidth[photoSize]}
    loader={imgixLoader}
    src={getImgixPathWithParameters(advisorUrl, {
      q: 30,
      w: photoWidth[photoSize],
    })}
    width={photoWidth[photoSize]}
  />
);

AdvisorPhoto.propTypes = {
  /**
   * URL link to the image.
   */
  advisorUrl: PropTypes.string.isRequired,
  /**
   * Determins the size of the advisor photos
   */
  photoSize: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default AdvisorPhoto;
