/**
 * Given a config object, returns the base URL (as a string) for this app.
 *
 * `config` is passed in as an argument, rather than being imported directly,
 * so that this can be used with a server-side or client-side config.
 *
 * @param {*} config
 */
export default function getAppUrl(config) {
  return config.HUBBLE_URL;
}
