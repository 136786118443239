import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import styles from './Pagination.module.css';

const Ellipsis = () => (
  <span className={styles['Pagination-ellipsis']}>&#8230;</span>
);

/**
 * https://www.a11ymatters.com/pattern/pagination/
 */
const Pagination = ({ lastPage, page, pathname, query = {} }) => {
  const paginationLinks = useMemo(() => {
    const firstPages = [1, 2];
    const lastPages = [lastPage - 1, lastPage];
    const middlePages = [page - 1, page, page + 1];

    const mergePageArrays = [...firstPages, ...middlePages, ...lastPages];
    const uniquePageNumbers = Array.from(new Set(mergePageArrays));
    const removeInvalidPageNumbers = uniquePageNumbers
      .filter((page) => page > 0)
      .filter((page) => page <= lastPage)
      .sort((a, b) => a - b);

    return removeInvalidPageNumbers;
  }, [lastPage, page]);

  return (
    <nav aria-label="Pagination Navigation" role="navigation">
      <ul className={styles['Pagination-list']}>
        {paginationLinks.map((pageNumber, index, pageNumbers) => {
          const isCurrentPage = pageNumber === page;

          const isBrokenByEllipsis =
            pageNumber > 1 && pageNumber - 1 !== pageNumbers[index - 1];

          const paginatedQuery = {
            ...query,

            // Only add page query param if it's not the first page
            ...(pageNumber !== 1 ? { page: pageNumber } : null),
          };

          // If page number is 1, explicitly remove it from the URL
          if (pageNumber === 1) {
            delete paginatedQuery.page;
          }

          return (
            <li className={styles['Pagination-item']} key={pageNumber}>
              {isBrokenByEllipsis && <Ellipsis />}
              <NextLink
                aria-current={isCurrentPage}
                aria-label={
                  isCurrentPage
                    ? `Current Page, Page ${pageNumber}`
                    : `Go to Page ${pageNumber}`
                }
                className={cn(styles['Pagination-link'], {
                  [styles['Pagination-currentPage']]: isCurrentPage,
                })}
                href={{
                  pathname,
                  query: paginatedQuery,
                }}
              >
                {pageNumber}
              </NextLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  /**
   * Based on the total buildings, what do we expect the last page number to be?
   */
  lastPage: PropTypes.number.isRequired,

  /**
   * What page of results we are currently looking at
   */
  page: PropTypes.number.isRequired,

  /**
   * The path to link new pages to before query params.
   */
  pathname: PropTypes.string.isRequired,

  /**
   * We use this to preserve the existing query parameters when changing the page
   */
  query: PropTypes.shape(),
};

export default Pagination;
