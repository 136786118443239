import { useState, useEffect } from 'react';

function useBrowserProcess() {
  const [isBrowserProcess, setIsBrowserProcess] = useState(false);

  useEffect(() => {
    setIsBrowserProcess(process.browser);
  }, []);

  return {
    isBrowserProcess,
  };
}

export default useBrowserProcess;
