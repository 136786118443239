import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';

const getListingPageUrl = ({
  buildingId,
  buildingSlug = '',
  queryParams = {},
  isPass = false,
  isPartTime = false,
  pricePlanId = false,
}) => {
  const relativeUrlPath = `${
    isPass ? '/pass' : ''
  }/office-space/${buildingId}/${buildingSlug}`;
  const params = new URLSearchParams();

  if (isPartTime) {
    params.append('access', OfficeAccessOptions.PartTime);
  }

  if (pricePlanId) {
    params.append('option', pricePlanId);
  }

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (typeof value !== 'undefined') {
      params.append(key, value);
    }
  });

  return (
    relativeUrlPath +
    (params.toString().length > 0 ? `?${params.toString()}` : '')
  );
};

export default getListingPageUrl;
