import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Paragraph.module.css';

/**
 * Use `Paragraph` to typeset text content in paragraphs.
 */
const Paragraph = ({
  children = null,
  isCentered = false,
  isDeEmphasized = false,
  isMarginless = false,
  isMeasured = false,
  type = 'content2',
}) => {
  return (
    <p
      className={cn(styles.Paragraph, styles[`Paragraph--${type}`], {
        [styles['Paragraph--deemphasized']]: isDeEmphasized,
        [styles['Paragraph--centered']]: isCentered,
        [styles['Paragraph--marginless']]: isMarginless,
        [styles['Paragraph--measured']]: isMeasured,
      })}
    >
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  /**
   * Text to show in the Paragraph
   */
  children: PropTypes.node,

  /**
   * Should the Paragraph be centered?
   */
  isCentered: PropTypes.bool,

  /**
   * Whether the text should be De-emphasized (It changes color of text to neutral500 when de-emphasized, otherwise it inherits the text-color)
   */
  isDeEmphasized: PropTypes.bool,

  /**
   * Should this paragraph always render without any margins?
   *
   * By default, Paragraph has a bottom margin unless it's a `last-child`. With
   * this set to true, the bottom margin is _always_ 0, which can make it easier
   * to control space with `VerticalSpacing`.
   */
  isMarginless: PropTypes.bool,

  /**
   * Should the Paragraph be width restricted to a typographic measure?
   */
  isMeasured: PropTypes.bool,

  /**
   * Style variant of the Paragraph.
   */
  type: PropTypes.oneOf(['content1', 'content2', 'content3']),
};

export default Paragraph;
