export default function formatTerm(pricePlan) {
  if (pricePlan.fullTimeMinTerm && pricePlan.fullTimeMaxTerm) {
    return (
      <>
        <b>Min</b> {pricePlan.fullTimeMinTerm}{' '}
        {pricePlan.fullTimeMinTerm === '1' ? 'month' : 'months'} - <b>Max</b>{' '}
        {pricePlan.fullTimeMaxTerm}{' '}
        {pricePlan.fullTimeMaxTerm === '1' ? 'month' : 'months'}
      </>
    );
  } else if (pricePlan.fullTimeMinTerm) {
    if (pricePlan.fullTimeMinTerm === '1') {
      return 'Monthly rolling';
    } else {
      return (
        <>
          <b>Min</b> {pricePlan.fullTimeMinTerm} months
        </>
      );
    }
  } else {
    return 'N/A';
  }
}
