import { useEffect, useState } from 'react';

import getDataLayer, { gtag } from 'site-react/helpers/dataLayer';
import useUser from 'site-react/hooks/useUser';

import cookieTypes from '../cookieTypes';

function consentFromPreferences(preferences) {
  const advertisingConsent = preferences.some(
    (preference) => preference.name === cookieTypes.advertising,
  )
    ? 'granted'
    : 'denied';
  const essentialConsent = preferences.some(
    (preference) => preference.name === cookieTypes.essential,
  )
    ? 'granted'
    : 'denied';
  const functionalConsent = preferences.some(
    (preference) => preference.name === cookieTypes.functional,
  )
    ? 'granted'
    : 'denied';
  const marketingAndAnalyticsConsent = preferences.some(
    (preference) => preference.name === cookieTypes.marketingAndAnalytics,
  )
    ? 'granted'
    : 'denied';

  return {
    ad_personalization: advertisingConsent,
    ad_storage: advertisingConsent,
    ad_user_data: advertisingConsent,
    analytics_storage: marketingAndAnalyticsConsent,
    functionality_storage: functionalConsent,
    personalization_storage: marketingAndAnalyticsConsent,
    security_storage: essentialConsent,
  };
}

const getAnonymousIdFromEmail = async (email) => {
  // Cribbed from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
  const emailUint8 = new TextEncoder().encode(email); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', emailUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  return hashHex;
};

async function getUserData(user, parentUser) {
  return {
    // If there's a parent user, then this is a ghost login
    is_ghost_login: !!parentUser,

    // If the user's logged in, then we can generate an ID for them
    user_id: user?.email ? await getAnonymousIdFromEmail(user.email) : null,
  };
}

/**
 * Adds the user to the data layer on page load.
 *
 * This does some data layer manipulation to ensure that the user data is available
 * for all subsequent events. It should only be used before GTM has loaded. If
 * GTM has already loaded, then use `addUserToDataLayer` instead.
 *
 * Using after GTM has already loaded may result in the user data not being available.
 *
 * @param {*} user
 * @param {*} parentUser
 */
async function addInitialUserToDataLayer(user, parentUser) {
  const dataLayer = getDataLayer();
  const data = await getUserData(user, parentUser);

  if (Array.isArray(dataLayer)) {
    // If the data layer is an array, then we can manipulate it to put the user
    // data as the very first value. This is important because it means the user
    // data will be available for all subsequent events.
    dataLayer.unshift(data);
  }
}

/**
 * Adds the user to the data layer after GTM has already loaded.
 *
 * If GTM hasn't loaded yet, then use `addInitialUserToDataLayer` instead. It
 * will ensure that the user data is available for all events throughout a
 * session.
 *
 * @param {*} user
 * @param {*} parentUser
 */
async function addUpdatedUserToDataLayer(user, parentUser) {
  const dataLayer = getDataLayer();
  const data = await getUserData(user, parentUser);

  dataLayer.push({
    event: 'user_update',
    ...data,
  });
}

export default function useTagManager(allowLoading, preferences) {
  const [isReady, setIsReady] = useState(false);
  const { isUserLoading, parentUser, user } = useUser();

  // Used so we can track if the user has changed. If we've already loaded GTM,
  // and user != lastUser, then the user has changed mid-session—such as by
  // logging in or signing up.
  //
  // We can't use isUserLoading for this, because useUser won't change
  // isUserLoading during a user refresh.
  const [lastUser, setLastUser] = useState(user);

  useEffect(() => {
    if (allowLoading) {
      // Add consent to the data layer as soon as we have it
      gtag('consent', 'update', consentFromPreferences(preferences));
    }
  }, [allowLoading, preferences]);

  useEffect(() => {
    // If we're not allowed to load, or we've already loaded, then we don't need
    // to do anything
    //
    // If the user hasn't loaded yet, then we aren't ready to load GTM, because
    // we don't know if we have a useable user ID yet
    if (!allowLoading || isReady || isUserLoading) {
      return;
    }

    (async () => {
      await addInitialUserToDataLayer(user, parentUser);
      setLastUser(user);
      setIsReady(true);
    })();
  }, [allowLoading, isReady, isUserLoading, parentUser, user]);

  useEffect(() => {
    if (!allowLoading || !isReady) {
      // This effect is only relevant when GTM is already loaded, but its user
      // data is out of date. If GTM isn't loaded, then we don't need to do
      // anything.
      return;
    }

    if (lastUser?.email !== user?.email) {
      // If we're here, it means that the user has changed _after_ isReady was
      // flipped to true.
      //
      // That means the user changed mid-session, likely as the result of a
      // signup or login. We need to update the user data in the data layer.
      (async () => {
        await addUpdatedUserToDataLayer(user, parentUser);
        setLastUser(user);
      })();
    }
  }, [allowLoading, isReady, lastUser, parentUser, user]);

  return { isReady };
}
