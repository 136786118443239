import PropTypes from 'prop-types';

import CalloutAdvisorContent from './components/CalloutAdvisorContent';
import CalloutGenericAdvisorContent from './components/CalloutGenericAdvisorContent';
export default function Callout({
  advisor,
  isLoggedInUserAdvisor = false,
  isRecommendationListExpanded = false,
  recommendationListName,
}) {
  if (advisor) {
    return (
      <CalloutAdvisorContent
        advisor={advisor}
        heading={
          isLoggedInUserAdvisor
            ? recommendationListName
            : 'Your office recommendations'
        }
        isRecommendationListExpanded={isRecommendationListExpanded}
      />
    );
  } else {
    return (
      <CalloutGenericAdvisorContent
        heading={
          isLoggedInUserAdvisor
            ? recommendationListName
            : 'Your office recommendations'
        }
        isRecommendationListExpanded={isRecommendationListExpanded}
      />
    );
  }
}

Callout.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  isLoggedInUserAdvisor: PropTypes.bool,
  isRecommendationListExpanded: PropTypes.bool,
  recommendationListName: PropTypes.string.isRequired,
};
