import { imgixUrl } from 'site-react/helpers/imgix';

/**
 * @param {string} src
 * @param {array} widthList
 * Transforms an image src string (typically an imgix url) into a string,
 * which is formatted appropriate for a srcset attribute.
 *
 * @example output
 * "http://www.google.com/image.jpg?auto=format,compress&w=543&q=30&dpr=1 543w,
 * http://www.google.com/image.jpg?auto=format,compress&w=543&q=30&dpr=2 1086w,
 * http://www.google.com/image.jpg?auto=format,compress&w=768&q=30&dpr=1 768w,
 * http://www.google.com/image.jpg?auto=format,compress&w=768&q=30&dpr=2 1536w,
 * http://www.google.com/image.jpg?auto=format,compress&w=992&q=30&dpr=1 992w,
 * http://www.google.com/image.jpg?auto=format,compress&w=992&q=30&dpr=2 1984w,
 * http://www.google.com/image.jpg?auto=format,compress&w=1200&q=30&dpr=1 1200w,
 * http://www.google.com/image.jpg?auto=format,compress&w=1200&q=30&dpr=2 2400w"
 */
const getSrcSet = (src, widthList) => {
  return widthList
    .map(
      (width) =>
        `${imgixUrl(src, {
          dpr: 1,
          q: 30,
          w: width,
        })} ${width}w, ${imgixUrl(src, {
          dpr: 2,
          q: 30,
          w: width,
        })} ${width * 2}w`,
    )
    .join(', ');
};

export default getSrcSet;
