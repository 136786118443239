import { css } from '@emotion/css';
import React from 'react';

import {
  Heading,
  ListItemWithIcon,
  Paragraph,
} from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

const contentWrapperStyle = css`
  align-items: start;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 0 var(--space-md);
  }
`;

const headingStyle = css`
  align-self: start;
`;

const listStyle = css`
  list-style: none;
  padding: 0;
`;

const PassContent = () => {
  return (
    <aside className={contentWrapperStyle}>
      <Heading level="2" type="title3">
        One membership, thousands of workspaces
      </Heading>

      <VerticalSpacing size="lg" />

      <Paragraph type="content1">
        Hubble on-demand membership gives teams access to a network of amazing
        coworking spaces to work, meet in-person and collaborate.
      </Paragraph>

      <VerticalSpacing size="md" />

      <img
        alt="Trustpilot rating of 4.8 stars"
        src="https://hubble.imgix.net/site/homepage/trustpilot-black-letters.svg?auto=compress"
      />

      <VerticalSpacing size="lg" />

      <HorizontalRule />

      <VerticalSpacing size="lg" />

      <div className={headingStyle}>
        <Heading level="3" type="title3">
          Getting started is easy
        </Heading>
      </div>
      <VerticalSpacing size="sm" />
      <ol className={listStyle}>
        <ListItemWithIcon
          contentType="content1"
          heading="Create a free account"
          iconColor="green-200"
          iconName="check_circle"
          text="No credit card required"
        />
        <ListItemWithIcon
          contentType="content1"
          heading="Choose a flexible plan"
          iconColor="green-200"
          iconName="check_circle"
          text="Buy credits that your team can use on workspace bookings."
        />
        <ListItemWithIcon
          contentType="content1"
          heading="Invite your team"
          iconColor="green-200"
          iconName="check_circle"
          text="Share a link with your team to get them set up"
        />
        <ListItemWithIcon
          contentType="content1"
          heading="Start booking"
          iconColor="green-200"
          iconName="check_circle"
          text="Choose from 1000s of workspaces worldwide"
        />
      </ol>
    </aside>
  );
};

export default PassContent;
