import HubbleBaseApi from '../HubbleBaseApi';

class PersonaApi extends HubbleBaseApi {
  /**
   * Get an object containing the spacious user's persona
   *
   */
  async getPersona() {
    return this.get(`persona`);
  }

  /**
   * Update the spacious user's persona
   *
   * @param {Object} personaData the user's persona
   */
  async updatePersona(personaData) {
    return this.put(`persona`, personaData);
  }
}

export default PersonaApi;
