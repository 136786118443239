import styles from './OfficeCardAvailableFromLabel.module.css';

const OfficeCardAvailableFromLabel = ({ availableFromText }) => {
  return (
    <span className={(styles['available-from'], styles['u-typographyLabel2'])}>
      {' '}
      {availableFromText}{' '}
    </span>
  );
};

export default OfficeCardAvailableFromLabel;
