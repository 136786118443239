import PropTypes from 'prop-types';
import React from 'react';

import {
  Heading,
  Paragraph,
  RuledHeading,
} from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';

import styles from './Highlights.module.css';

const Highlights = ({ highlights }) => (
  <>
    <RuledHeading level="2">Highlights</RuledHeading>
    <VerticalSpacing size="lg" />
    <UnstyledList aria-label="Highlights" className={styles.Highlights}>
      {highlights.map(({ description, id, title }) => (
        <li className={styles['Highlights-highlight']} key={id}>
          <Heading level="3" type="title3">
            {title}
          </Heading>
          <VerticalSpacing size="sm" />
          <Paragraph>{description}</Paragraph>
        </li>
      ))}
    </UnstyledList>
  </>
);

Highlights.propTypes = {
  /**
   * Highlights stored against a building
   */
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      building: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Highlights;
