import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @typedef RequestHookOutputs
 * @param {Object[]} body - the response from the api call.
 * @param {string|null} error - a user-friendly error message, or null if no errors were encountered
 * @param {Boolean} isLoading - true if a request is in progress; false otherwise
 * @param {Function} reload - triggers a fresh fetch from the API
 */

/**
 * Hook for api calls.
 *
 * @param {class} ApiClass - the class containing the api method we wish to use
 * @param {string} methodName - the name of the method we wish to use from the api class
 * @param {Object} payload - the payload for the api method
 * @param {Boolean} ready - false if we are not yet ready to make the api call (might be expensive, show misinformation)
 * @returns {RequestHookOutputs}
 */
const useRequest = (ApiClass, methodName, payload, ready = true) => {
  const [body, setBody] = useState({});
  const [exception, setException] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [needsLoad, setNeedsLoad] = useState(ready);
  const api = useMemo(() => {
    return new ApiClass();
  }, [ApiClass]);

  const reload = useCallback(() => {
    setNeedsLoad(true);
  }, []);

  useEffect(() => {
    if (ready) {
      setNeedsLoad(true);
    }
    // We want to re-trigger the request if:
    // * we have marked that the request is ready to send, AND
    //   * we have updated the payload OR
    //   * the method name has changed
  }, [methodName, payload, ready]);

  useEffect(() => {
    if (needsLoad) {
      setException(null);
      setIsLoading(true);
      (async () => {
        try {
          const resp = await api[methodName](payload);
          if (resp.body) {
            setBody(resp.body);
          }
        } catch (apiException) {
          setException(apiException);
          setBody({});
        }
        setIsLoading(false);
        setNeedsLoad(false);
      })();
    } else {
      setIsLoading(false);
    }
  }, [api, methodName, needsLoad, payload, ready]);
  return {
    body,
    exception,
    isLoading,
    reload,
  };
};

export default useRequest;
