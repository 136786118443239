import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from 'site-react/components/styles/button.module.css';
import { Spinner } from 'site-react/components/utility';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

const Button = React.forwardRef(
  (
    {
      analyticsMetadata,
      isBlock,
      isCompact,
      isLoading = false,
      children = 'Submit',
      name,
      onClick = () => {},
      styleAtSmall,
      styleType = 'primary',
      type,
      ...otherButtonProps
    },
    ref,
  ) => {
    if (otherButtonProps.hasOwnProperty('className')) {
      throw new Error(
        'You should not override or extend the built in styling of this component. If you need something more bespoke you should implement it as a bespoke component.',
      );
    }

    if (!type) {
      throw new Error(
        'Browsers do not have a consistent default value for the "type" attribute so we do not assign a default for the Button component. You must explicitly set a type when using the Button component',
      );
    }

    const onClickWithAnalytics = (...args) => {
      analytics.track(
        'Button clicked',
        {
          label: name,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
      onClick(...args);
    };

    const buttonClassName = cn(styles['Button'], {
      /* eslint-disable sort-keys */
      // other modifiers
      [styles['Button--block']]: isBlock,
      [styles['Button--compact']]: isCompact,

      // `styleType`s
      [styles['Button--primary']]: styleType === 'primary',
      [styles['Button--secondary']]: styleType === 'secondary',
      [styles['Button--secondaryWhite']]: styleType === 'secondaryWhite',
      [styles['Button--white']]: styleType === 'white',

      // `styleAtSmall`s
      [styles['Button--smallFullWidth']]: styleAtSmall === 'fullWidth',
      [styles['Button--smallIconOnly']]: styleAtSmall === 'iconOnly',
      /* eslint-enable sort-keys */
    });

    return (
      <button
        aria-label={name}
        className={buttonClassName}
        onClick={onClickWithAnalytics}
        ref={ref}
        type={type}
        {...otherButtonProps}
      >
        {isLoading && (
          <div className={styles['Button-spinnerWrapper']}>
            <Spinner size="small" />
          </div>
        )}
        <span className={cn(isLoading && styles['Button-spinner--hidden'])}>
          {children}
        </span>
      </button>
    );
  },
);

Button.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Content to be displayed within the button.
   */
  children: PropTypes.node,

  /**
   * Should this button always fill its container, regardless of styleAtSmall?
   */
  isBlock: PropTypes.bool,

  /**
   * Show a slightly-less-padded button. Preferred in places where we have less
   * real estate to play with.
   */
  isCompact: PropTypes.bool,

  /**
   * Is this button showing a loading state?
   */
  isLoading: PropTypes.bool,

  /**
   * Label to be sent with analytics event
   */
  name: PropTypes.string.isRequired,

  /**
   * Callback when this button activates.
   */
  onClick: PropTypes.func,

  /**
   * With the exception of `onClick`, any attribute native to the <button> tag
   * can be passed as a prop and directly spread as an attribute on the component.
   *
   * For instance, if you need to pass a datatest-id, do so like this:
   * ```
   * <Button onClick={clickHandler} data-testid="my-button">Click Me</Button>
   * ```
   */
  otherButtonProps: PropTypes.any,

  /**
   * The style of this button, specifically for small viewports. Defaults to
   * fullWidth for historical reasons.
   */
  styleAtSmall: PropTypes.oneOf(['auto', 'fullWidth', 'iconOnly']),

  /**
   * The pre-defined style of this button.
   */
  styleType: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryWhite',
    'white',
  ]),

  /**
   * Different browsers may use different default types for the <button> element so this _must_ be set when using Button.
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit']).isRequired,
};

export default Button;
