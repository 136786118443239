/**
 * DEPRECATION NOTICE
 *
 * We are moving our styling tooling to CSS modules. This file will be removed
 * in the future.
 *
 * viewport.css is the source of truth for all viewport style variables.
 */

/*
 * These values should typically be implemented as "XX-and-up",
 * using "min-width", following mobile-first principles.
 *
 *Example:
 * @media (min-width: ${theme.breakpoints.md}px) {
 *
 */

/* eslint-disable sort-keys */
export default {
  xs: 0,
  sm: 543,
  md: 768,
  legacySmListing: 840,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1680,
};
