import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

const UnstyledButton = React.forwardRef(
  (
    {
      analyticsMetadata = {},
      attributes = {},
      children = null,
      className = '',
      elementType = 'button',
      form = null,
      href = null,
      isEnabled = true,
      label,
      onClick = () => {},
      rel = null,
      target = null,
      testId = null,
      title = '',
      type = 'submit',
    },
    ref,
  ) => {
    // setup analytics
    const onClickWithAnalytics = (...args) => {
      analytics.track(
        'Button clicked',
        {
          label,
          ...(href && { href }),
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
      onClick(...args);
    };

    if (elementType === 'button') {
      attributes = {
        ...attributes,
        disabled: !isEnabled,
        form,
        type,
      };

      return (
        <button
          className={className}
          data-testid={testId}
          onClick={onClickWithAnalytics}
          ref={ref}
          title={title}
          {...attributes}
        >
          {children}
        </button>
      );
    } else if (elementType === 'OutboundLink') {
      attributes = {
        ...attributes,
        href,
        rel,
        target,
      };

      return (
        <OutboundLink
          className={className}
          data-testid={testId}
          onClick={onClickWithAnalytics}
          ref={ref}
          title={title}
          {...attributes}
        >
          {children}
        </OutboundLink>
      );
    } else {
      attributes = {
        ...attributes,
        rel,
        target,
      };

      return (
        <NextLink
          className={className}
          data-testid={testId}
          href={href}
          onClick={onClickWithAnalytics}
          ref={ref}
          title={title}
          {...attributes}
        >
          {children}
        </NextLink>
      );
    }
  },
);

// With `forwardRef`, this component would show in render trees as `<ForwardRef />`.
// Setting a displayName overrides this behaviour.
UnstyledButton.displayName = 'UnstyledButton';

UnstyledButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Any HTML attributes to be added to the final element
   */
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  ),
  /**
   * Component to embed into the vanilla unstyled-button
   */
  children: PropTypes.node,

  /**
   * Styles to apply on this button.
   */
  className: PropTypes.string,

  /**
   * The type of the Button.: a for links, button for actions, OutboundLink for an a that leads off-site.
   */
  elementType: PropTypes.oneOf(['a', 'button', 'OutboundLink']),

  /**
   * An ID of a form to associate, if this is a `<button />` and the form isn't an ancestor.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Attributes
   */
  form: PropTypes.string,

  /**
   * href attribute. Only applies to <a> elementTypes.
   */
  href: PropTypes.string,

  /**
   * Is this button enabled? Propagates as <button>'s `disabled` attribute.
   * Only applies to <button> elements.
   */
  isEnabled: PropTypes.bool,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string.isRequired,

  /**
   * Callback when this button activates.
   */
  onClick: PropTypes.func,

  /**
   * rel attribute. Only applies to <a> elements.
   */
  rel: PropTypes.string,

  /**
   * target attribute. Only applies to <a> elements.
   */
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,

  /**
   * The button's title. Note: this is used as the tooltip, not as the label.
   */
  title: PropTypes.string,

  /**
   * Default behaviour of the button. Only applies to <button> elements.
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};

export default UnstyledButton;
