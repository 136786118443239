import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './../UICard.module.css';

const UICard = ({
  backgroundColor = 'white',
  boxShadow = null,
  children,
  height = 'shrink-wrap',
  stripEdgeColor = 'brandprimary',
  style = null,
  variant = 'default',
  // NOTE: defaults to "fill", but if "default" is used then we don't apply any width-based styles.
  width = 'fill',
}) => {
  const hasStyleWithPadding =
    'padding' in (style || {}) && !(typeof style.padding === 'object');
  const hasStyleWithBreakpointsPadding =
    'padding' in (style || {}) && typeof style.padding === 'object';
  return (
    <div
      className={cn(styles['UICard'], {
        [styles['UICard--stripEdge']]: variant === 'strip-edge',
        [styles['UICard--fullHeight']]: height === 'fill',
        [styles['UICard--fullWidth']]: width === 'fill',
        [styles['UICard--shrinkWrapHeight']]: height === 'shrink-wrap',
        [styles['UICard--shrinkWrapWidth']]: width === 'shrink-wrap',
        [styles['UICard--border']]: !!style?.border,
        [styles['UICard--borderRadius']]: !!style?.borderRadius,
        [styles['UICard--padding']]: hasStyleWithPadding,
        [styles['UICard--responsivePadding']]: hasStyleWithBreakpointsPadding,
        [styles['UICard--responsivePaddingSm']]:
          hasStyleWithBreakpointsPadding && style.padding?.sm,
        [styles['UICard--responsivePaddingMd']]:
          hasStyleWithBreakpointsPadding && style.padding?.md,
        [styles['UICard--responsivePaddingLg']]:
          hasStyleWithBreakpointsPadding && style.padding?.lg,
        [styles['UICard--responsivePaddingXl']]:
          hasStyleWithBreakpointsPadding && style.padding?.xl,
        [styles['UICard--responsivePaddingXxl']]:
          hasStyleWithBreakpointsPadding && style.padding?.xxl,
        [styles['UICard--responsivePaddingXxxl']]:
          hasStyleWithBreakpointsPadding && style.padding?.xxxl,
      })}
      style={{
        '--UICard--backgroundColor': `var(--color-${backgroundColor})`,
        '--UICard--border': style?.border ? style.border : 'none',
        '--UICard--borderRadius': style?.borderRadius
          ? style.borderRadius
          : 'none',
        '--UICard--boxShadow': boxShadow
          ? `var(--shadow-${boxShadow})`
          : 'none',
        '--UICard--minWidth': style?.minWidth ? style.minWidth : 'none',
        '--UICard--padding':
          'padding' in (style || {}) && !(typeof style.padding === 'object')
            ? style.padding
            : 'none',
        ...(style?.padding?.default && {
          '--UICard--paddingDefault': style.padding.default,
        }),
        ...(style?.padding?.sm && { '--UICard--paddingSm': style.padding.sm }),
        ...(style?.padding?.md && { '--UICard--paddingMd': style.padding.md }),
        ...(style?.padding?.lg && { '--UICard--paddingLg': style.padding.lg }),
        ...(style?.padding?.xl && { '--UICard--paddingXl': style.padding.xl }),
        ...(style?.padding?.xxl && {
          '--UICard--paddingXxl': style.padding.xxl,
        }),
        ...(style?.padding?.xxxl && {
          '--UICard--paddingXxxl': style.padding.xxxl,
        }),
        '--UICard--stripEdgeColor': `var(--color-${stripEdgeColor})`,
      }}
    >
      {children}
    </div>
  );
};

UICard.propTypes = {
  /** Which of our colors we would like to use as a background
   * "backgroundColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  backgroundColor: PropTypes.string,

  /** Which of our shadows we would like to use
   * "boxShadow" should be a `--shadow-{substring}` substring of the shadow name in theme/shadow.css
   */
  boxShadow: PropTypes.string,

  /** Content to show in the UICard */
  children: PropTypes.node.isRequired,

  /** Do we want this UICard to fill the height of its container or shrink wrap to its contents? */
  height: PropTypes.oneOf(['fill', 'shrink-wrap']),

  /** Only applicable if variant is "strip-edge"
   * "stripEdgeColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  stripEdgeColor: PropTypes.string,

  /** Optionally override some styling */
  style: PropTypes.shape({
    /** Any valid border CSS value will work */
    border: PropTypes.string,
    /** Any border-radius value */
    borderRadius: PropTypes.string,
    /** Any valid width shorthand CSS value will work */
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Any valid padding shorthand CSS value will work */
    padding: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      /* eslint-disable sort-keys */
      PropTypes.shape({
        default: PropTypes.string.isRequired,
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
        xl: PropTypes.string,
        xxl: PropTypes.string,
        xxxl: PropTypes.string,
      }),
      /* eslint-enable sort-keys */
    ]),
  }),

  /** Which style card do we want to render? */
  variant: PropTypes.oneOf(['default', 'strip-edge']),

  /** Do we want this UICard to fill the width of its container, shrink wrap to its contents, or follow the default? */
  width: PropTypes.oneOf(['default', 'fill', 'shrink-wrap']),
};

export default UICard;
