import cn from 'classnames';

import { Button } from 'site-react/components/form';
import { HubbleLogo } from 'site-react/components/page';
import ModalNew from 'site-react/components/page/ModalNew';
import {
  Heading,
  MaterialIcon,
  Paragraph,
} from 'site-react/components/typography';
import { ImgixImage } from 'site-react/components/utility';
import { ADVISOR_GENERIC_IMAGE_SRC } from 'site-react/data/advisors';

import componentStyles from './CalloutGenericAdvisorContent.module.css';
import calloutStyles from '../../Callout.module.css';
import AdvisorModalContent from '../AdvisorModalContent';

const ADVISOR_GENERIC_IMAGE_HEIGHT = 50;
const ADVISOR_GENERIC_IMAGE_WIDTH = 128;
const styles = { ...calloutStyles, ...componentStyles };

const CalloutGenericAdvisorContent = ({
  isRecommendationListExpanded,
  heading,
}) => {
  const advisorImageProps = {
    alt: 'Advisor',
    height: ADVISOR_GENERIC_IMAGE_HEIGHT,
    src: ADVISOR_GENERIC_IMAGE_SRC,
    width: ADVISOR_GENERIC_IMAGE_WIDTH,
  };

  return (
    <>
      <div
        className={cn(styles.CalloutGenericAdvisorContent, {
          [styles['isRecommendationListExpanded']]:
            isRecommendationListExpanded,
        })}
      >
        <div
          className={cn(styles['CalloutGenericAdvisorContent-heading'], {
            [styles['isRecommendationListExpanded']]:
              isRecommendationListExpanded,
          })}
          data-testid="generic-user-recommendations-dashboard-callout"
        >
          <HubbleLogo isLink={false} isLogoTextDisplayed size={32} />

          <div>
            <Heading level="1" type="title3">
              {heading}
            </Heading>
            <div
              className={
                styles['CalloutGenericAdvisorContent-headingParagraph']
              }
            >
              <Paragraph isMarginless>Hand picked by our advisors</Paragraph>
            </div>
          </div>
        </div>

        <div
          className={cn(styles['CalloutGenericAdvisorContent-advisorCta'], {
            [styles['isRecommendationListExpanded']]:
              isRecommendationListExpanded,
          })}
        >
          <div
            className={cn(
              styles['CalloutGenericAdvisorContent-imageWrapper'],
              styles['u-hideBelowMd'],
            )}
          >
            <ImgixImage {...advisorImageProps} />
          </div>

          <div className={styles['u-hideBelowMd']}>
            <ModalNew
              label="Contact generic advisor"
              renderTrigger={({ openModal }) => (
                <Button
                  isCompact
                  name="Contact advisor"
                  onClick={openModal}
                  styleType="secondary"
                  type="button"
                >
                  Contact us
                </Button>
              )}
            >
              <AdvisorModalContent advisor={undefined} />
            </ModalNew>
          </div>
        </div>
      </div>

      <div
        className={cn(styles['Callout-advisorImage'], styles['u-hideAboveMd'])}
      >
        <ModalNew
          label="Contact advisor"
          renderTrigger={({ openModal }) => (
            <button
              className={styles['Callout-advisorImageContent']}
              onClick={openModal}
              type="button"
            >
              <ImgixImage {...advisorImageProps} />
              <MaterialIcon
                className={styles['Callout-icon']}
                iconType="chat_bubble"
              />
            </button>
          )}
        >
          <AdvisorModalContent advisor={undefined} />
        </ModalNew>
      </div>
    </>
  );
};

export default CalloutGenericAdvisorContent;
