import debounce from 'lodash.debounce';
import { useEffect } from 'react';

const useMapPosition = ({
  GoogleMap,
  isAreaSearch,
  isSearchable,
  map,
  results,
  searchQuery,
  setIsFittingBounds,
  setToggleStatus,
  shape,
}) => {
  useEffect(() => {
    if (!GoogleMap || !map) {
      return () => {};
    }
    const bounds = new GoogleMap.LatLngBounds();

    const fitBounds = () => {
      if (bounds.isEmpty()) return;

      setTimeout(() => {
        setIsFittingBounds(true);
        // fit new bounds, but don't zoom all the way in (e.g. to a single marker)
        map.setOptions({ maxZoom: 18 });

        map.fitBounds(bounds, 0);
      });
      GoogleMap.event.addListenerOnce(
        map,
        'idle',
        debounce(() => {
          // restore full zoom control to the user
          map.setOptions({ maxZoom: undefined });
          setIsFittingBounds(false);
        }, 200),
      );
    };

    if (!isSearchable) {
      results.forEach((result) => {
        const [lng, lat] = result.buildingLocation.coordinates;
        bounds.extend(new GoogleMap.LatLng(lat, lng));
      });
      fitBounds();

      return () => {};
    }

    /**
     * If there is a geoshape and search results  come from a named area search, fit the
     * map window to the coordinate points of the geoshape so we see the full area
     * regardless of how many pins are inside, otherwise we get inconsistent zoom
     * levels when viewing the map at different times depending on inventory levels.
     *
     * If the search is based on a coordinate parameter, fit the map window to cover that
     * instead.
     */

    if (isAreaSearch && shape?.geometry) {
      if (shape.geometry.type === 'Point') {
        const [lng, lat] = shape.geometry.coordinates;

        bounds.extend(new GoogleMap.LatLng(lat, lng));
      } else if (shape.geometry.type === 'MultiPolygon') {
        shape.geometry.coordinates.forEach((coordinates) => {
          coordinates[0].forEach((shapePoint) => {
            const [lng, lat] = shapePoint;
            bounds.extend(new GoogleMap.LatLng(lat, lng));
          });
        });
      } else if (shape.geometry.coordinates[0]) {
        shape.geometry.coordinates[0].forEach((shapePoint) => {
          const [lng, lat] = shapePoint;
          bounds.extend(new GoogleMap.LatLng(lat, lng));
        });
      }
      fitBounds();
    } else if (searchQuery?.coordinates) {
      // coordinates are an array of singular lat or lng values.
      // Package them up into lat lng pairs and then use pair to extend bounds
      for (let i = 0; i < searchQuery.coordinates.length; i += 2) {
        const [lng, lat] = [
          parseFloat(searchQuery.coordinates[i], 10),
          parseFloat(searchQuery.coordinates[i + 1], 10),
        ];

        bounds.extend(new GoogleMap.LatLng(lat, lng));
      }
      fitBounds();
    }
  }, [
    GoogleMap,
    isAreaSearch,
    isSearchable,
    map,
    results,
    searchQuery,
    setIsFittingBounds,
    setToggleStatus,
    shape,
  ]);
};

export default useMapPosition;
