import cn from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import { ModalNewContext } from './context/ModalNewContext';
import styles from './ModalNew.module.css';

const MODAL_STATES = {
  close: 'Closed',
  open: 'Opened',
};

/**
 * Problems to resolve (please delete from list when resolved):
 * - Fade in animation doesn't work on backdrop pseudoclass.
 */
const Modal = ({
  analyticsMetadata = {},
  children,
  isFullScreen = false,
  isLocked = false,
  isOpenOnRender = false,
  label,
  onCloseCallback = () => {},
  onOpenCallback = () => {},
  renderTrigger,
  ...props
}) => {
  if (props?.open) {
    throw new Error(
      'The Modal component should be opened using the .show() or .showModal() methods - not by passing an `open` attribute. If a <dialog> is opened using the `open` attribute, it will be non-modal.',
    );
  }

  const [isRenderable, setIsRenderable] = useState(false);
  useEffect(() => {
    // This is to prevent the modal from rendering on the server, where document
    // is not defined.
    //
    // Using an effect in this way is a common pattern for ensuring that code
    // that relies on the DOM only runs in the browser, without causing
    // hydration mismatches.
    setIsRenderable(true);
  }, []);

  // We only want to render the children of the modal when it is open. Otherwise:
  // - we can get conflicts between element IDs
  // - we're spending CPU cycles rendering things that aren't visible
  const [renderChildren, setRenderChildren] = useState(false);

  const dialog = useRef(null);

  const handleAnalytics = useCallback(
    (modalState) => {
      analytics.track(
        `Modal ${modalState}`,
        {
          label,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
    },
    [analyticsMetadata, label],
  );

  const handleOpenModal = useCallback(() => {
    setRenderChildren(true);
    handleAnalytics(MODAL_STATES.open);

    document.body.classList.add(['u-preventScroll']);

    if (!dialog.current?.open) {
      dialog.current?.showModal();
    }

    onOpenCallback();
  }, [handleAnalytics, onOpenCallback]);

  useEffect(() => {
    if (isOpenOnRender) {
      handleOpenModal();
    }
  }, [handleOpenModal, isOpenOnRender]);

  const handleCloseModal = useCallback(() => {
    if (dialog.current?.open) {
      dialog.current?.close();
    }
  }, []);

  const handleCloseDialog = () => {
    setRenderChildren(false);
    handleAnalytics(MODAL_STATES.close);

    document.body.classList.remove('u-preventScroll');
    onCloseCallback();
  };

  // This allows us to use certain functions
  // or values anywhere within a modal without
  // prop drilling.
  const contextValue = useMemo(() => {
    return {
      closeModal: handleCloseModal,
      isLocked,
    };
  }, [handleCloseModal, isLocked]);

  return (
    <>
      {renderTrigger({
        openModal: handleOpenModal,
      })}
      {isRenderable
        ? createPortal(
            <dialog
              className={cn(styles.Modal, {
                [styles['Modal--fullScreen']]: isFullScreen,
                [styles['Modal--popOut']]: !isFullScreen,
              })}
              onClick={(event) => {
                event.stopPropagation();
                if (!isLocked && event.target.localName === 'dialog') {
                  handleCloseModal();
                }
              }}
              onClose={handleCloseDialog}
              ref={dialog}
              {...props}
            >
              {renderChildren ? (
                <ModalNewContext.Provider value={contextValue}>
                  {children}
                </ModalNewContext.Provider>
              ) : null}
            </dialog>,
            document.body,
          )
        : null}
    </>
  );
};

Modal.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * The content to show inside this modal.
   *
   * If this is a function, on render it will be called with a
   * `closeModal` argument.
   *
   * Example:
   * ```
   * <Modal>Content goes here</Modal>
   * ```
   * ```
   * <Modal>
   *   {({ closeModal }) => (
   *     <div>
   *       Content goes here
   *       <button onClick={closeModal}>Close modal</button>
   *     </div>
   *   )}
   * </Modal>
   * ```
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

  /**
   * Should the modal be sized to the full width and height of the screen?
   */
  isFullScreen: PropTypes.bool,

  /**
   * If true the user cannot manually exit the modal?
   */
  isLocked: PropTypes.bool,

  /**
   * If true the modal is opened automatically on render?
   */
  isOpenOnRender: PropTypes.bool,

  /**
   * Label to be sent with analytics event
   */
  label: PropTypes.string.isRequired,

  /**
   * Optional function to be called when the modal is closed.
   */
  onCloseCallback: PropTypes.func,

  /**
   * Optional function to be called when the modal is closed.
   */
  onOpenCallback: PropTypes.func,

  /**
   * Render prop for the trigger. A function, which returns a component that
   * gets rendered in place. The function will be called with an openModal
   * argument, which can be called to open the associated modal.
   *   *
   * Example:
   * ```
   * <Modal renderTrigger={
   *   ({ openModal }) => <button onClick={openModal}>Open modal</button>
   * } />`
   * ```
   */
  renderTrigger: PropTypes.func.isRequired,
};

export default Modal;
