export const mockFacilitiesList = [
  {
    group: 'business',
    icon: '',
    id: 2,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Furniture',
    order: 1,
    slug: 'furniture',
  },
  {
    group: 'business',
    icon: '',
    id: 8,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Breakout Space',
    order: 1,
    slug: 'breakout-space',
  },
  {
    group: 'business',
    icon: '',
    id: 11,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Mailing Address',
    order: 1,
    slug: 'mailing-address',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 3,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Lockers',
    order: 1,
    slug: 'lockers',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 5,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Meeting Rooms',
    order: 1,
    slug: 'meeting-rooms',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 10,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Event Space',
    order: 1,
    slug: 'event-space',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 16,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Childcare',
    order: 1,
    slug: 'childcare',
  },
  {
    group: 'business',
    icon: '',
    id: 17,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Cleaning',
    order: 1,
    slug: 'cleaning',
  },
  {
    group: 'business',
    icon: '',
    id: 21,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Inventory Storage',
    order: 1,
    slug: 'inventory-storage',
  },
  {
    group: 'business',
    icon: '',
    id: 25,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Reception',
    order: 1,
    slug: 'reception',
  },
  {
    group: 'business',
    icon: '',
    id: 26,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Secure Access',
    order: 1,
    slug: 'secure-access',
  },
  {
    group: 'business',
    icon: '',
    id: 27,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Utilities',
    order: 1,
    slug: 'utilities',
  },
  {
    group: 'business',
    icon: '',
    id: 28,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Trading Address',
    order: 1,
    slug: 'trading-address',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 14,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Beer & Wine',
    order: 1,
    slug: 'beer-n-wine',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 19,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Fruit & Snacks',
    order: 1,
    slug: 'fruit-n-snacks',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 29,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Events & Talks',
    order: 1,
    slug: 'events-n-talks',
  },
  {
    group: 'business',
    icon: '',
    id: 1,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Wifi',
    order: 1,
    slug: 'wifi',
  },
  {
    group: 'business',
    icon: '',
    id: 6,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: '24 hr Access',
    order: 1,
    slug: '24hr-access',
  },
  {
    group: 'pleasure',
    icon: '',
    id: 30,
    isAvailableOnHq: true,
    isAvailableOnPass: false,
    name: 'Roof Terrace',
    order: 1,
    slug: 'roof-terrace',
  },
  {
    group: 'pleasure',
    icon: 'coffee',
    id: 18,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Coffee & Tea',
    order: 1,
    slug: 'coffee-n-tea',
  },
  {
    group: 'pleasure',
    icon: 'nature_people',
    id: 31,
    isAvailableOnHq: false,
    isAvailableOnPass: true,
    name: 'Outdoor Space',
    order: 2,
    slug: 'outdoor-space',
  },
  {
    group: 'pleasure',
    icon: 'fitness_center',
    id: 20,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Gym',
    order: 3,
    slug: 'gym',
  },
  {
    group: 'business',
    icon: 'contact_phone',
    id: 23,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Phone Booths',
    order: 4,
    slug: 'phone-booths',
  },
  {
    group: 'pleasure',
    icon: 'pedal_bike',
    id: 9,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Bike Storage',
    order: 5,
    slug: 'bike-storage',
  },
  {
    group: 'pleasure',
    icon: 'shower',
    id: 4,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Showers',
    order: 6,
    slug: 'showers',
  },
  {
    group: 'pleasure',
    icon: 'pets',
    id: 13,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Pets Allowed',
    order: 7,
    slug: 'pets-allowed',
  },
  {
    group: 'business',
    icon: 'accessible',
    id: 12,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Disabled Access',
    order: 8,
    slug: 'disabled-access',
  },
  {
    group: 'pleasure',
    icon: 'countertops',
    id: 7,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Kitchen',
    order: 9,
    slug: 'kitchen',
  },
  {
    group: 'pleasure',
    icon: 'local_parking',
    id: 22,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Parking',
    order: 10,
    slug: 'parking',
  },
  {
    group: 'business',
    icon: 'print',
    id: 24,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Printing',
    order: 11,
    slug: 'printing',
  },
  {
    group: 'pleasure',
    icon: 'restaurant',
    id: 15,
    isAvailableOnHq: true,
    isAvailableOnPass: true,
    name: 'Cafe',
    order: 12,
    slug: 'cafe',
  },
  {
    group: 'pleasure',
    icon: 'local_bar',
    id: 32,
    isAvailableOnHq: false,
    isAvailableOnPass: true,
    name: 'Bar',
    order: 13,
    slug: 'bar',
  },
];
