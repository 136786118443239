import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  coworkingProduct,
  eventSpace,
  hotFixedDeskProduct,
  hqOfficeProduct,
  meetingRoom,
  privateDayOfficeProduct,
} from 'site-react/assets';
import { Grid, Section } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import useLocalStorage from 'site-react/hooks/useLocalStorage';
import useUser from 'site-react/hooks/useUser';

import CardItem from './CardItem';

const onDemandCardWrapperStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-md);

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const rentLongerCardWrapperStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-md);
`;

const borderStyle = css`
  @media (min-width: 992px) {
    border-right: 1px solid var(--color-neutral-100);
    padding-right: var(--space-md);
  }
`;

export default function SearchMenuContent({ activeWorkspace = null }) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const [isPartTimeSearchFormCompleted] = useLocalStorage(
    'partTimeSearchFormCompleted',
  );

  const [isFullTimeSearchFormCompleted] = useLocalStorage(
    'fullTimeSearchFormCompleted',
  );

  const { isLoggedIn, user } = useUser();

  const hasPass = Boolean(user?.organisation);
  const isAdmin = user?.role === 'admin';

  const memberNotAdmin = hasPass && !isAdmin;

  const passLink = '/pass/go';
  const externalFormPrefix = 'https://search.hubblehq.com';

  const urlPassLondonMeetingRoom = isLoggedIn
    ? `${passLink}?productType=meeting-room`
    : '/meeting-rooms/london';
  const urlPassLondonPrivateOffice = `${passLink}?productType=private-office`;
  const urlPassLondonCoworking = `${passLink}?productType=coworking`;
  const urlPassEventSpace = `${externalFormPrefix}/events/`;

  const fullTimeEnquiryFormUrl = '/full-time-office-enquiry?source=navBar';
  const ptoEnquiryFormUrl = '/part-time-office-enquiry?source=navBar';

  return (
    <Section verticalPadding="lg">
      <Grid>
        <Grid.Item colSpan="8">
          <div className={borderStyle}>
            <Heading level="3" type="title4">
              Book on-demand
            </Heading>
            <VerticalSpacing size="md" />
            <UnstyledList className={onDemandCardWrapperStyle}>
              <CardItem
                description="To meet and present"
                href={urlPassLondonMeetingRoom}
                iconAlt=""
                iconSrc={meetingRoom}
                isActive={activeWorkspace === 'pass-meeting-room'}
                label="Meeting rooms"
              />
              <CardItem
                description="Desks in a shared workspace"
                href={urlPassLondonCoworking}
                iconAlt=""
                iconSrc={coworkingProduct}
                isActive={activeWorkspace === 'pass-coworking'}
                label="Coworking spaces"
              />
              <CardItem
                description="For conferences and get-togethers"
                href={urlPassEventSpace}
                iconAlt=""
                iconSrc={eventSpace}
                isActive={activeWorkspace === 'event-space'}
                label="Event space"
                linkType="OutboundLink"
                target="_blank"
              />
              <CardItem
                description="A room with desks and chairs"
                href={urlPassLondonPrivateOffice}
                iconAlt=""
                iconSrc={privateDayOfficeProduct}
                isActive={activeWorkspace === 'pass-private-office'}
                label="Private day offices"
              />
            </UnstyledList>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="4">
          <Heading level="3" type="title4">
            Rent longer-term
          </Heading>{' '}
          <VerticalSpacing size="md" />
          <UnstyledList className={rentLongerCardWrapperStyle}>
            <CardItem
              description="Entire offices to rent"
              disabled={memberNotAdmin}
              href={
                // Only switch the URL after page load, to avoid hydration errors
                isLoaded && isFullTimeSearchFormCompleted
                  ? '/office-space-london'
                  : fullTimeEnquiryFormUrl
              }
              iconAlt=""
              iconSrc={hqOfficeProduct}
              isActive={activeWorkspace === 'hq-private-offices'}
              label={
                memberNotAdmin
                  ? 'Full-time offices (admins)'
                  : 'Full-time offices'
              }
            />
            <CardItem
              description="Your own office 1-3 days every&nbsp;week"
              disabled={memberNotAdmin}
              href={
                // Only switch the URL after page load, to avoid hydration errors
                isLoaded && isPartTimeSearchFormCompleted === true
                  ? '/part-time-offices/london'
                  : ptoEnquiryFormUrl
              }
              iconAlt=""
              iconSrc={hotFixedDeskProduct}
              isActive={activeWorkspace === 'part-time-offices'}
              label={
                memberNotAdmin
                  ? 'Part-time offices (admins)'
                  : 'Part-time offices'
              }
            />
          </UnstyledList>
        </Grid.Item>
      </Grid>
    </Section>
  );
}

SearchMenuContent.propTypes = {
  /**
   * Which workspace nav item should have active styling applied, if any
   */
  activeWorkspace: PropTypes.oneOf([
    'hq-private-offices',
    'part-time-offices',
    'pass-coworking',
    'pass-meeting-room',
    'pass-private-office',
    'event-space',
    null,
  ]),
};
