import { useContext } from 'react';

import { AdvisorContext } from 'site-react/data/core/AdvisorContext';

function useAdvisor() {
  const context = useContext(AdvisorContext);
  if (!context) {
    throw new Error('useAdvisor must be used within a AdvisorProvider');
  }
  return context;
}

export default useAdvisor;
