/**
░░░░░░░WKkxdx0N░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░WN0xdoc:oK░░░░░░░░░░░░░░░░░░░░░░░░
░░WX0O00Oxxkxc;dN░░░░░░░░░░░░░░░░░░░░░░░
░░Xxdox00kxdoc;oN░░░░░░░░░░░░░░░░░░░░░░░
░░Nkok0Kklcc:;:0░░░░░░░░░░░░░░░░░░░░░░░░
░░░WNNOxddoc;lKW░░░░░░░░░░░░░░░░░░░░░░░░
░░░░NOkkdoc;,okkxddddddxOKXW░░WNNNNW░░░░
░░░W0xxxdl::;;;;::::;;;;;;cok00OO000X░░░
░░░WOdddolcc::c:llolc:,;,,;;,,;codkk0W░░
░░░░Kolllccc:cc:cldxxc,,;;;:;,'.;odkK░░░
░░░░W0l:::clc:cclkOdl;';:::::;'.'ox0W░░░
░░░░░░Xkl:ldl;;cokOkdc,;;::::;,'':ON░░░░
░░░░░░░░Nkdodl::dkOO0kc,,,;;;;;,.:K░░░░░
░░░░░░░░░WXkoll::clodo:'''',;;;:oKW░░░░░
░░░░░░░░░░░WN0kolcc:::::;,:lok0XW░░░░░░░
░░░░░░░░░░░░░░W0lcoollllcxKNW░░░░░░░░░░░
░░░░░░░░░░░░░░░Nd;lk0xlloK░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░Ndl0WW0ldKW░░░░░░░░░░░░░░
░░░░░░░░░N0O0KKkcdNNXdlK░░░░░░░░░░░░░░░░
░░░░░░░░W0dlclxkxkkdlcoKN░░░░░░░░░░░░░░░
░░░░░░░░░░NOOK0xddoccok0XW░░░░░░░░░░░░░░
░░░░░░░░░░░░░Nkxxod0XN░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░NXWKkX░░░░░░░░░░░░░░░░░░░░░
 *
 * DEPRECATION NOTICE
 *
 * We have created a new Modal component under components/page/ModalNew.
 * The hope is that this new way of implementing a Modal is simpler to reason about and more
 * semantically correct as it leverages the dialog element which handles a lot of fiddly behaviour
 * like focus trapping, scroll locking, background effects and key events out of the box.
 * If you need to implement a Modal or are currently working on a task that involves maintenance of a page
 * using this component please consider migrating to the new Modal component pattern.
 */
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';
import { GlobalEvents, keyEventsHandler } from 'site-react/helpers/Events';
import hiddenStyle from 'site-react/theme/accessibility';

import Portal from './components/Portal';
import styles from './Modal.module.css';
import ModalBackground from './ModalBackground';
import ModalCloseButton from './ModalCloseButton';
import ModalCloseIcon from './ModalCloseIcon';
import ModalContainer from './ModalContainer';

/**
 * Adds a class to disable page scrolling
 * when a modal is open.
 * @param {Boolean} isModalVisible Is a modal visible?
 */
const setAbilityToScroll = (isModalVisible) => {
  if (isModalVisible) {
    document.body.classList.add('body--overlayed');
  } else {
    document.body.classList.remove('body--overlayed');
  }
};

const ModalUnmanaged = ({
  analyticsMetadata = {},
  centerContent = false,
  children = null,
  deprecatedCloseAnalytics = null,
  deprecatedOpenAnalytics = null,
  describedBy = '',
  id,
  isClosable = true,
  isFullScreen = false,
  isPositionTop = false,
  isReducedWidth,
  label = '',
  labelledBy = '',
  modalName,
  onCloseModal = null,
  unsetBackground = false,
  visible = false,
}) => {
  /**
   * ModalUnmanaged is a reusable component which will generate an empty box with a
   * close button, unless it is passed a child node containing content.
   * It is strongly advised to pass in props for label, labelledBy & describedBy
   * for accessibility purposes: see link for further details on implementation.
   *
   * @param {any} props
   * {@link https://www.marcozehe.de/2015/02/05/advanced-aria-tip-2-accessible-modal-dialogs/ Label, LabelledBy, DescribedBy }
   * @memberof ModalUnmanaged
   */
  const [isVisible, setIsVisible] = useState(visible);

  /**
   * Update the state so it knows the modal should be hidden,
   * refocus the browser to whatever had focus before the modal loaded,
   * pass a message to any analytic handlers
   */
  const closeModal = () => {
    analytics.track(
      deprecatedCloseAnalytics ? deprecatedCloseAnalytics : 'Modal Closed',
      {
        label: modalName,
        ...analyticsMetadata, //If we send any analytics metadata, this will only be visible through Segment
      },
      {
        sendPageProperties: true,
      },
    );

    if (!isClosable) {
      return;
    }
    setIsVisible(false);
    setAbilityToScroll(false);

    if (onCloseModal) {
      onCloseModal();
    }
  };

  const [globalEvents] = useState(
    new GlobalEvents({
      keyup: {
        debounce: false,
        handler: keyEventsHandler({
          Escape: closeModal,
        }),
        useCapture: true,
      },
    }),
  );

  const modalRef = useRef();

  /**
   * Adds global event hooks and sets up the focus-related aspects of state.
   * We also manually set the initial focus, because it's too early to get it
   * directly from state.
   */
  useEffect(() => {
    globalEvents.listen();
    setAbilityToScroll(isVisible);

    /**
     * Removes global event listeners
     */
    return () => {
      setAbilityToScroll(false); // ensure we unlock document scrolling
      globalEvents.remove();
    };
  }, [globalEvents, isVisible, modalRef]);

  /**
   * Sends analytics when the modal is open
   */
  useEffect(() => {
    if (isVisible) {
      analytics.track(
        deprecatedOpenAnalytics ? deprecatedOpenAnalytics : 'Modal Opened',
        {
          label: modalName,
          ...analyticsMetadata, //If we send any analytics metadata, this will only be visible through Segment
        },
        {
          sendPageProperties: true,
        },
      );
    }
  }, [analyticsMetadata, deprecatedOpenAnalytics, isVisible, modalName]);

  const background = isFullScreen ? null : (
    <ModalBackground
      onClickCallback={closeModal}
      unsetBackground={unsetBackground}
    />
  );

  /**
   * Render the modal only if the state declares it should be visible,
   * otherwise return null
   */
  if (isVisible) {
    return (
      <Portal selector="#modal">
        <FocusTrap
          focusTrapOptions={{ tabbableOptions: { displayCheck: 'none' } }}
        >
          <div className={styles.ModalUnmanaged}>
            {background}
            <ModalContainer
              aria-describedby={describedBy}
              aria-labelledby={labelledBy}
              centerContent={centerContent}
              id={id}
              isFullScreen={isFullScreen}
              isPositionTop={isPositionTop}
              isReducedWidth={isReducedWidth}
              role="dialog"
              setModalRef={modalRef}
            >
              {isClosable && (
                <>
                  <div className={hiddenStyle} id={labelledBy}>
                    {label}
                  </div>
                  <ModalCloseButton
                    aria-label="Close"
                    isFullScreen={isFullScreen}
                    onClickCallback={closeModal}
                  >
                    <ModalCloseIcon />
                  </ModalCloseButton>
                </>
              )}
              {children}
            </ModalContainer>
          </div>
        </FocusTrap>
      </Portal>
    );
  } else {
    return null;
  }
};

ModalUnmanaged.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,
  centerContent: PropTypes.bool,
  children: PropTypes.node,
  /**
   * The deprecated analytics were introduced to support bespoke analytics
   * that were already in existence at the time. This are not meant to be used
   * for custom analytics. We have analytics being sent for all modals using
   * analyticsMetadata and any extra property should be attached there.
   */
  deprecatedCloseAnalytics: PropTypes.string,
  deprecatedOpenAnalytics: PropTypes.string,
  describedBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  isClosable: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isPositionTop: PropTypes.bool,
  label: PropTypes.string,
  labelledBy: PropTypes.string,
  /**
   * This is the name of the Event Label analytic for a specific modal instance
   * It's required because we can't see the metadata in Google Analytics
   */
  modalName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  unsetBackground: PropTypes.bool,
  visible: PropTypes.bool,
};

export default ModalUnmanaged;
