import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Heading.module.css';

const typeVarMapping = {
  content1: 'var(--font-style-content-1)',
  content2: 'var(--font-style-content-2)',
  content3: 'var(--font-style-content-3)',
  hero1: 'var(--font-style-hero-1)',
  hero2: 'var(--font-style-hero-2)',
  title1: 'var(--font-style-title-1)',
  title2: 'var(--font-style-title-2)',
  title3: 'var(--font-style-title-3)',
  title4: 'var(--font-style-title-4)',
};

const Heading = ({
  children = null,
  color = null,
  isCentered = false,
  isUnderlined = false,
  level = null,
  type,
}) => {
  const ElementType = level ? `h${level}` : `span`;

  return (
    <ElementType
      className={cn(styles.Heading, {
        [styles['Heading--centered']]: isCentered,
        [styles['Heading--underlined']]: isUnderlined,
        [styles['Heading--color']]: color,
        [styles['Heading--smType']]: type?.sm,
        [styles['Heading--mdType']]: type?.md,
        [styles['Heading--lgType']]: type?.lg,
        [styles['Heading--xlType']]: type?.xl,
        [styles['Heading--xxlType']]: type?.xxl,
        [styles['Heading--xxxlType']]: type?.xxxl,
      })}
      style={{
        ...(color && { '--Heading-color': `var(--color-${color})` }),
        '--Heading-defaultType':
          typeof type === 'string'
            ? typeVarMapping[type]
            : typeVarMapping[type.default],
        ...(type?.sm && {
          '--Heading-smType': typeVarMapping[type.sm],
        }),
        ...(type?.md && {
          '--Heading-mdType': typeVarMapping[type.md],
        }),
        ...(type?.lg && {
          '--Heading-lgType': typeVarMapping[type.lg],
        }),
        ...(type?.xl && {
          '--Heading-xlType': typeVarMapping[type.xl],
        }),
        ...(type?.xxl && {
          '--Heading-xxlType': typeVarMapping[type.xxl],
        }),
        ...(type?.xxxl && {
          '--Heading-xxxlType': typeVarMapping[type.xxxl],
        }),
      }}
    >
      {children}
    </ElementType>
  );
};

const typeOptions = [
  'content1',
  'content2',
  'content3',
  'hero1',
  'hero2',
  'title1',
  'title2',
  'title3',
  'title4',
];

Heading.propTypes = {
  /**
   * Content to show in the heading.
   */
  children: PropTypes.node,

  /**
   * Color of the heading
   */
  color: PropTypes.string,

  /**
   * Should this heading be centered?
   */
  isCentered: PropTypes.bool,

  /**
   * Should this heading be underlined?
   */
  isUnderlined: PropTypes.bool,

  /**
   * The heading level. Level '1' gives an h1, '2' an h2, etc. etc.
   */
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),

  /**
   * The type of heading. Corresponds to our theming variables.
   *
   * If value is a string this type will be applied across all breakpoints.
   *
   * If value is an object the default type will be applied up to the first breakpoint, at which point
   * we'll use that type up to the next breakpoint, and so on.
   */
  type: PropTypes.oneOfType([
    PropTypes.oneOf(typeOptions),
    /* eslint-disable sort-keys */
    PropTypes.shape({
      default: PropTypes.oneOf(typeOptions).isRequired,
      sm: PropTypes.oneOf(typeOptions),
      md: PropTypes.oneOf(typeOptions),
      lg: PropTypes.oneOf(typeOptions),
      xl: PropTypes.oneOf(typeOptions),
      xxl: PropTypes.oneOf(typeOptions),
      xxxl: PropTypes.oneOf(typeOptions),
    }),
    /* eslint-enable sort-keys */
  ]).isRequired,
};

export default Heading;
