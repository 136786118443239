import { css, cx, keyframes } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import { BottomAccessoryButton } from 'site-react/features/ImageCarousel';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';
import theme from 'site-react/theme';

const buttonHeight = theme.spacing.xxl;
const buttonRadius = buttonHeight / 2;

const buttonBaseStyle = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  grid-area: shortlist-button;
  justify-content: center;
  color: var(--color-neutral-900);

  &:hover,
  &:focus {
    color: var(--color-brandprimary);
  }
  &:active {
    color: var(--color-brandprimary-hover);
  }
`;

const saveButtonStyle = css`
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-100);
  border-radius: ${buttonRadius}px;
  box-shadow: var(--shadow-resting);
  color: var(--color-neutral-900);
  ${buttonBaseStyle}

  line-height: 0;
  padding: 0;
  text-align: center;
`;

const saveButtonLabelStyle = css`
  font: var(--font-style-content-3);
  color: 'currentColor';
  display: inline;
  padding-left: var(--space-xs);
`;

const saveAndCompareButtonStyle = css`
  ${buttonBaseStyle}
  display: flex;
  align-items: center;
`;

const saveAndCompareButtonLabelStyle = css`
  font: var(--font-style-content-2);
  font-family: var(--font-family);
  margin-left: 4px;
  text-decoration: underline;
`;

const buttonIconOnlyStyle = css`
  height: ${buttonHeight}px;
  width: ${buttonHeight}px;
`;

const buttonIconLabelStyle = css`
  font: unset;
  height: ${buttonHeight}px;
  padding: 0 var(--space-md);
`;

const buttonIconLabelNavStyle = css`
  padding: 0;
`;

const iconBaseStyle = css`
  vertical-align: middle;
`;

const loadingAnimation = keyframes`
  0% {
    transform: scale(0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const loadingButtonStyle = css`
  background-color: var(--color-white);
  height: ${buttonHeight}px;
  padding: 0;
  width: ${buttonHeight}px;

  &:hover {
    background-color: var(--color-white);
  }
`;

const loadingStyles = css`
  animation: ${loadingAnimation} 0.75s infinite ease-in-out;
  background-color: var(--color-neutral-300);
  border-radius: ${buttonRadius}px;
  height: ${buttonHeight}px;
  width: ${buttonHeight}px;
`;

const shortlistedIconStyle = css`
  color: var(--color-brandprimary);
  &:hover,
  &:focus {
    color: var(--color-brandprimary-hover);
  }

  &:active {
    color: var(--color-brandprimary-active);
  }
`;

const ShortlistButton = ({
  analyticsMetadata = {},
  isActivated = false,
  isEnabled = true,
  isLoading = false,
  label = null,
  onClick = () => {},
  testId = 'ShortlistButton',
  variant = 'default',
}) => {
  const onFavouriteClick = (...args) => {
    analytics.track(
      'Favourite button clicked',
      {
        ...analyticsMetadata,
        isFavouriting: !isActivated,
      },
      {
        sendPageProperties: true,
      },
    );
    onClick(...args);
  };

  return variant === 'carousel' ? (
    <BottomAccessoryButton
      enabledIconColor={isActivated ? 'brandPrimary' : 'neutral900'}
      iconType={isActivated ? 'favorite' : 'favorite_border'}
      isLoading={isLoading}
      label={isActivated ? 'Saved' : label}
      onClick={onFavouriteClick}
      title={
        isActivated
          ? 'Remove office from shortlists'
          : 'Add office to shortlists!'
      }
    />
  ) : (
    <button
      className={cx(
        buttonBaseStyle,
        variant === 'nav' ? saveAndCompareButtonStyle : saveButtonStyle,
        label && !isLoading ? buttonIconLabelStyle : buttonIconOnlyStyle,
        {
          [buttonIconLabelNavStyle]: variant === 'nav',
          [loadingButtonStyle]: isLoading,
        },
      )}
      data-testid={testId}
      disabled={!isEnabled}
      onClick={onFavouriteClick}
      title={
        isActivated
          ? 'Remove office from shortlists'
          : 'Add office to shortlists!'
      }
      type="button"
    >
      {isLoading && <div className={loadingStyles} />}
      {!isLoading && (
        <>
          <MaterialIcon
            className={cx(iconBaseStyle, isActivated && shortlistedIconStyle)}
            iconType={isActivated ? 'favorite' : 'favorite_border'}
          />
          {label && (
            <span
              className={
                variant === 'nav'
                  ? saveAndCompareButtonLabelStyle
                  : saveButtonLabelStyle
              }
            >
              {isActivated ? 'Saved' : label}
            </span>
          )}
        </>
      )}
    </button>
  );
};

ShortlistButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,
  /**
   * Has this item been shortlisted?
   */
  isActivated: PropTypes.bool,

  /**
   * Is this button enabled?
   */
  isEnabled: PropTypes.bool,

  /**
   * Is the state currently changing?
   */
  isLoading: PropTypes.bool,

  /**
   * Label to appear next to the shortlist button
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Callback when the button is clicked.
   */
  onClick: PropTypes.func,

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,

  /**
   * Optional variant type to style differently based on where it's being used
   */
  variant: PropTypes.oneOf(['default', 'carousel', 'nav']),
};

export default ShortlistButton;
