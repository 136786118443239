import HubbleBaseApi from '../HubbleBaseApi';

class FeatureFlagsApi extends HubbleBaseApi {
  async getFeatureFlags(opts = {}) {
    return this.get('feature-flags', undefined, opts);
  }

  async getFeatureFlagByKey(key) {
    return this.get(`feature-flags/${key}`);
  }
}

export default FeatureFlagsApi;
