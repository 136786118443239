import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import HamburgerButton from './HamburgerButton';
import styles from './NavigationList.module.css';

const NavigationList = ({
  children,
  isMegaNav,
  isMenuOpen,
  isUserLoading,
  onMenuClick,
}) => {
  return (
    <nav
      aria-label="Main Navigation"
      className={cn(styles.NavigationList, {
        [styles['NavigationList--loading']]: isUserLoading,
        [styles['NavigationList--open']]: isMenuOpen,
      })}
    >
      <HamburgerButton isOpen={isMenuOpen} onClick={onMenuClick} />
      <ul
        aria-hidden={!isMenuOpen && !isMegaNav}
        aria-label="Main Navigation Items"
        className={styles['NavigationList-list']}
        data-testid="Navigation"
      >
        {children}
      </ul>
    </nav>
  );
};

NavigationList.propTypes = {
  /**
   * Children, expecting inline styled elements
   */
  children: PropTypes.node.isRequired,
  /**
   * Is Menu Open
   */
  isMenuOpen: PropTypes.bool,
  /**
   * On Menu Click, callback
   */
  onMenuClick: PropTypes.func.isRequired,
};

export default NavigationList;
