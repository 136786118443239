import PropTypes from 'prop-types';

export default PropTypes.exact({
  building: PropTypes.number.isRequired,
  capacity: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  facilities: PropTypes.string,
  id: PropTypes.number.isRequired,
  isInstantlyBookable: PropTypes.bool.isRequired,
  pricePerDay: PropTypes.number,
  pricePerDayInCurrency: PropTypes.number,
  pricePerDayPayg: PropTypes.number,
  pricePerDayPaygInCurrency: PropTypes.number,
  pricePerHalfDay: PropTypes.number,
  pricePerHalfDayInCurrency: PropTypes.number,
  pricePerHalfDayPayg: PropTypes.number,
  pricePerHalfDayPaygInCurrency: PropTypes.number,
  pricePerHour: PropTypes.number,
  pricePerHourInCurrency: PropTypes.number,
  pricePerHourPayg: PropTypes.number,
  pricePerHourPaygInCurrency: PropTypes.number,
  productType: PropTypes.oneOf(['coworking', 'meeting-room', 'private-office'])
    .isRequired,
  quantity: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['active', 'archived', 'inactive']).isRequired,
  title: PropTypes.string.isRequired,
});
