import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { createRef, useCallback, useState } from 'react';

import analytics from 'site-react/helpers/Analytics';
import logError from 'site-react/helpers/logError';

import { OfficeAccessOptions, Provider } from './context';

function ViewingRequestProvider({
  children = null,
  initialPricePlanId = null,
  pricePlans = [],
}) {
  const router = useRouter();

  // If access is specified in the URL, use that value. Otherwise, default to
  // full time.
  let officeAccess = router.query?.access ?? OfficeAccessOptions.FullTime;
  if (!Object.values(OfficeAccessOptions).includes(officeAccess)) {
    // An invalid access option was specified, so default to full time.
    officeAccess = OfficeAccessOptions.FullTime;
  }

  const [pricePlan, setPricePlan] = useState(
    getInitialPricePlan(pricePlans, initialPricePlanId),
  );

  const setOfficeAccess = useCallback(
    (value) => {
      // The `access` query parameter is the single source of truth. So, to set
      // the office access value, we'll update the URL.

      const newQuery = { ...router.query };

      // To keep URLs clean, only add the `access` parameter for part time. Full
      // time URLs just won't have access specified at all.
      if (value === OfficeAccessOptions.FullTime) {
        delete newQuery.access;
      } else {
        newQuery.access = value;
      }

      // Using replace rather than push. That way, changing the office access
      // won't overwhelm the user's back button.
      router.replace(
        {
          // Only updating the querystring. All other properties (like pathname)
          // are preserved
          query: newQuery,
        },

        // Setting this to null means Next will use a default value.
        null,

        // Shallow routing prevents doing a round trip to the server. Without
        // this, changing the radio buttons is super duper slow.
        { shallow: true },
      );
    },
    [router],
  );

  const viewingRequestRef = createRef();
  function setNewOfficeAccess(value) {
    if (!Object.values(OfficeAccessOptions).includes(value)) {
      logError(
        `Invalid value '${value}' for officeAccess. Valid options are 'fullTime' and 'partTime'.`,
      );
    }

    setOfficeAccess(value);

    if (
      value === OfficeAccessOptions.PartTime &&
      !pricePlan?.isAvailablePartTime
    ) {
      const defaultPartTimePricePlan = pricePlans.find(
        (pricePlan) => pricePlan.isAvailablePartTime,
      );
      setPricePlan(defaultPartTimePricePlan);
    }
  }

  function getInitialPricePlan(pricePlans, initialPricePlanId) {
    let pricePlan;

    if (initialPricePlanId && pricePlans.length) {
      pricePlan = pricePlans.find((plan) => plan.id === initialPricePlanId);
    }

    if (officeAccess === OfficeAccessOptions.PartTime) {
      pricePlans = pricePlans.filter(
        (pricePlan) => pricePlan.isAvailablePartTime,
      );
    }

    // If initialPricePlanId is null, _or_ it doesn't exist in the pricePlans
    // array, we'll end up in this conditional
    if (!pricePlan) {
      pricePlan = pricePlans.length > 0 ? { ...pricePlans[0] } : null;
    }

    return pricePlan;
  }

  function setNewPricePlan(id) {
    const newPricePlan = pricePlans.find((pricePlan) => {
      return pricePlan.id === id;
    });

    if (newPricePlan) {
      const { building } = newPricePlan;
      setPricePlan(newPricePlan);
      analytics.track('Office Selected', {
        building,
        button_location: 'sidebar-dropdown',
        office: newPricePlan.id,
      });
    }
  }

  return (
    <Provider
      value={{
        officeAccess,
        pricePlan,
        setOfficeAccess: setNewOfficeAccess,
        setPricePlan: setNewPricePlan,
        viewingRequestRef,
      }}
    >
      {children}
    </Provider>
  );
}

ViewingRequestProvider.propTypes = {
  /**
   * Children to render inside this provider
   */
  children: PropTypes.node,

  /**
   * The initial price plan. Probably comes from the URL; ignored after first render.
   */
  initialPricePlanId: PropTypes.number,

  /**
   * All the pricePlans available on the page
   */
  pricePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
};

export default ViewingRequestProvider;
