import cn from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Link } from 'site-react/components/navigation';
import ModalNew, {
  ModalNewTemplateBasic,
} from 'site-react/components/page/ModalNew';
import { MaterialIcon } from 'site-react/components/typography';
import { ConfirmModal, ImgixImage } from 'site-react/components/utility';
import { AlertContext } from 'site-react/data/core/AlertContext';
import ImageCarousel, {
  ImageCarouselItem,
  getSrcSet,
} from 'site-react/features/ImageCarousel';
import logError from 'site-react/helpers/logError';
import useUser from 'site-react/hooks/useUser';
import theme from 'site-react/theme';

import Actions from './components/Actions';
import AdvisorNote from './components/AdvisorNote';
import UserNote from './components/UserNote';
import styles from './TableHeader.module.css';

const imageWidths = [
  theme.breakpoints.sm,
  theme.breakpoints.md,
  theme.breakpoints.lg,
  theme.breakpoints.xl,
];

const spaciousApi = new SpaciousApi();

export default function TableHeader({
  advisor,
  recommendationList,
  recommendationListItem,
  setRecommendationList,
  likedRecommendationListItems,
  viewingRequestedItems,
}) {
  const { user } = useUser();

  const isLoggedInUserAdvisor = advisor ? user?.id === advisor?.id : false;

  const { addError } = useContext(AlertContext);

  const carouselItems = recommendationListItem.building.images.map((item) => ({
    alt: item.caption,
    id: item.id,
    sizes: `(min-width: ${theme.breakpoints.md}px) 656px, 100vw`,
    src: item.img,
    srcSet: getSrcSet(item.img, imageWidths),
  }));

  async function likeRecommendationListItem() {
    setRecommendationList((prevRecommendationList) => {
      const updatedRecommendationList = {
        ...prevRecommendationList,
        items: prevRecommendationList.items.map((item) => {
          if (item.id === recommendationListItem.id) {
            return {
              ...item,
              likedAt: DateTime.utc().toISO(),
              status: 'liked',
            };
          }

          return item;
        }),
      };

      return updatedRecommendationList;
    });

    try {
      await spaciousApi.createRecommendationsListItemLike(
        recommendationList.token,
        recommendationListItem.id,
      );
    } catch (error) {
      setRecommendationList((prevRecommendationList) => {
        const revertedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((item) => {
            if (item.id === recommendationListItem.id) {
              return {
                ...item,
                likedAt: recommendationListItem.likedAt,
                status: recommendationListItem.status,
              };
            }

            return item;
          }),
        };

        return revertedRecommendationList;
      });

      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  async function rejectRecommendationListItem() {
    setRecommendationList((prevRecommendationList) => {
      const updatedRecommendationList = {
        ...prevRecommendationList,
        items: prevRecommendationList.items.map((item) => {
          if (item.id === recommendationListItem.id) {
            return {
              ...item,
              rejectedAt: DateTime.utc().toISO(),
              status: 'rejected',
            };
          }

          return item;
        }),
      };

      return updatedRecommendationList;
    });

    try {
      await spaciousApi.createRecommendationsListItemReject(
        recommendationList.token,
        recommendationListItem.id,
      );
    } catch (error) {
      setRecommendationList((prevRecommendationList) => {
        const revertedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((item) => {
            if (item.id === recommendationListItem.id) {
              return {
                ...item,
                rejectedAt: recommendationListItem.rejectedAt,
                status: recommendationListItem.status,
              };
            }

            return item;
          }),
        };

        return revertedRecommendationList;
      });

      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  async function restoreRecommendationListItem() {
    setRecommendationList((prevRecommendationList) => {
      const updatedRecommendationList = {
        ...prevRecommendationList,
        items: prevRecommendationList.items.map((item) => {
          if (item.id === recommendationListItem.id) {
            return {
              ...item,
              status: 'pending',
            };
          }

          return item;
        }),
      };

      return updatedRecommendationList;
    });

    try {
      await spaciousApi.createRecommendationsListItemRestore(
        recommendationList.token,
        recommendationListItem.id,
      );
    } catch (error) {
      setRecommendationList((prevRecommendationList) => {
        const revertedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((item) => {
            if (item.id === recommendationListItem.id) {
              return {
                ...item,
                status: recommendationListItem.status,
              };
            }

            return item;
          }),
        };

        return revertedRecommendationList;
      });

      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  async function deleteRecommendationListItem() {
    try {
      await spaciousApi.deleteAdminRecommendationListItem(
        recommendationList.id,
        recommendationListItem.id,
      );

      setRecommendationList((prevRecommendationList) => {
        const updatedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.filter(
            (item) => item.id !== recommendationListItem.id,
          ),
        };

        return updatedRecommendationList;
      });
    } catch (error) {
      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  return (
    <>
      <div className={styles['TableHeader-detail']}>
        <span className={styles['TableHeader-imgContainer']}>
          <ModalNew
            isFullScreen
            label="Building image carousel"
            renderTrigger={({ openModal }) => {
              return (
                <ImgixImage
                  alt="View building slideshow"
                  className={styles['TableHeader-img']}
                  height={80}
                  imgixOptions={{
                    dpr: 2,
                    fit: 'crop',
                    h: 80,
                    q: 30,
                    w: 80,
                  }}
                  onClick={openModal}
                  src={recommendationListItem.building.images[0].img}
                  width={80}
                />
              );
            }}
          >
            <ModalNewTemplateBasic>
              <ImageCarousel
                desktopRatio="2:1"
                fullScreen={true}
                mobileRatio="2:1"
                overrideAspectRatio={true}
                trackingId={recommendationListItem.building.id}
              >
                {carouselItems.map((item) => (
                  <ImageCarouselItem
                    key={`rec-carousel-item-${item.id}`}
                    {...item}
                  />
                ))}
              </ImageCarousel>
            </ModalNewTemplateBasic>
          </ModalNew>

          <span className={styles['TableHeader-imgCount']}>
            {recommendationListItem.building.images.length}
          </span>
        </span>
        <div className={styles['TableHeader-linkContainer']}>
          <div>
            <ModalNew
              label="Notes"
              renderTrigger={({ openModal }) => (
                <button
                  className={styles['TableHeader-notes']}
                  disabled={
                    !isLoggedInUserAdvisor &&
                    !recommendationListItem.advisorNotes
                  }
                  onClick={openModal}
                  type="button"
                >
                  Notes{' '}
                  <span
                    className={cn(styles['TableHeader-notesCount'], {
                      [styles['is-disabled']]:
                        !recommendationListItem.advisorNotes,
                    })}
                  >
                    {recommendationListItem.advisorNotes ? '1' : '0'}
                  </span>
                </button>
              )}
            >
              <ModalNewTemplateBasic>
                {isLoggedInUserAdvisor ? (
                  <AdvisorNote
                    buildingName={recommendationListItem.building.name}
                    onSuccess={(note) => {
                      setRecommendationList((prevRecommendationList) => {
                        const updatedRecommendationList = {
                          ...prevRecommendationList,
                          items: prevRecommendationList.items.map((item) => {
                            if (item.id === recommendationListItem.id) {
                              return {
                                ...item,
                                advisorNotes: note,
                              };
                            }

                            return item;
                          }),
                        };

                        return updatedRecommendationList;
                      });
                    }}
                    recommendationListId={recommendationList.id}
                    recommendationListItem={recommendationListItem}
                  />
                ) : (
                  <UserNote
                    advisor={advisor}
                    buildingName={recommendationListItem.building.name}
                    note={recommendationListItem.advisorNotes}
                  />
                )}
              </ModalNewTemplateBasic>
            </ModalNew>
          </div>

          <span className={styles['TableHeader-buildingName']}>
            <Link
              href={{
                pathname: `/office-space/${recommendationListItem.building.id}/${recommendationListItem.building.slug}`,
                query: {
                  [recommendationListItem.isPartTime
                    ? 'access'
                    : 'officeAccess']: recommendationListItem.isPartTime
                    ? 'partTime'
                    : 'fullTime',
                  option: recommendationListItem.pricePlan.id,
                },
              }}
              styleType="secondary"
              target="_blank"
            >
              {recommendationListItem.building.name}
            </Link>
          </span>
        </div>
        {isLoggedInUserAdvisor && (
          <div className={styles['TableHeader-delete']}>
            <ConfirmModal
              confirmLabel="Yes, delete this item"
              declineLabel="No, keep this item"
              onConfirm={deleteRecommendationListItem}
              prompt={`This will permanently delete ${recommendationListItem.building.name} from this Recommendation List. Are you sure?`}
            >
              <button data-testid="delete-recommendation" type="button">
                <MaterialIcon iconType="delete" />
              </button>
            </ConfirmModal>
          </div>
        )}
      </div>
      <Actions
        advisor={advisor}
        handleLike={likeRecommendationListItem}
        handleReject={rejectRecommendationListItem}
        handleRestore={restoreRecommendationListItem}
        likedRecommendationListItems={likedRecommendationListItems}
        recommendationListItem={recommendationListItem}
        setRecommendationList={setRecommendationList}
        token={recommendationList.token}
        viewingRequestedItems={viewingRequestedItems}
      />
    </>
  );
}

TableHeader.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  likedRecommendationListItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),
  recommendationList: PropTypes.shape({
    advisor: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    requesterEmail: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  recommendationListItem: PropTypes.object.isRequired,
  setRecommendationList: PropTypes.func.isRequired,
  viewingRequestedItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),
};
