import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './Content.module.css';
import Form from '../Form';

const Content = ({ building, handleAnalytics = () => {} }) => (
  <div className={styles.Content}>
    <VerticalSpacing size="md">
      <Heading
        id="dialog-request-floor-plan-description"
        level="1"
        type="title1"
      >
        Download PDF
      </Heading>
    </VerticalSpacing>
    <VerticalSpacing size="sm">
      <Form building={building} handleAnalytics={handleAnalytics} />
    </VerticalSpacing>
  </div>
);

Content.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pricePlans: PropTypes.arrayOf(
      PropTypes.shape({
        desksAvailable: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    slug: PropTypes.string,
  }).isRequired,

  /**
   * Handling the analytics function.
   */
  handleAnalytics: PropTypes.func,
};

export default Content;
