import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './Summary.module.css';
import {
  HQRenderedMarkdownContentPropTypes,
  PassRenderedMarkdownContentPropTypes,
} from '../../proptypes';

const Summary = ({ hashtags = [], renderedMarkdownContent }) => {
  // Use the snippet if there is one, otherwise use the full overview
  const firstContent =
    renderedMarkdownContent.overviewSnippet ?? renderedMarkdownContent.overview;

  // If there's no snippet, then there's no remaining content.
  const remainingContent = renderedMarkdownContent.overviewRemaining ?? '';

  const hashtagsEl = hashtags.length ? (
    <ul aria-label="Hashtags" className={styles['Summary-hashtags']}>
      {hashtags.map(({ tag }) => (
        <li key={tag}>{`#${tag}`}</li>
      ))}
    </ul>
  ) : null;

  return (
    <div className={styles.Summary}>
      <Heading level="2" type="title3">
        About this building
      </Heading>
      <VerticalSpacing size="md" />

      <div dangerouslySetInnerHTML={{ __html: firstContent }} />

      {remainingContent.trim() || hashtagsEl ? (
        <details className={styles['Summary-details']}>
          {remainingContent.trim() ? (
            <div dangerouslySetInnerHTML={{ __html: remainingContent }} />
          ) : null}

          {hashtagsEl ? hashtagsEl : null}

          {/* CSS is used to add a " more" or " less" suffix here, depending on whether the details are closed or open. */}
          <summary className={styles['Summary-summary']}>Read</summary>
        </details>
      ) : null}
    </div>
  );
};

Summary.propTypes = {
  hashtags: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      tag: PropTypes.string,
    }),
  ),
  renderedMarkdownContent: PropTypes.oneOfType([
    HQRenderedMarkdownContentPropTypes,
    PassRenderedMarkdownContentPropTypes,
  ]).isRequired,
};

export default Summary;
