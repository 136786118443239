import React from 'react';

import { Link } from 'site-react/components/navigation';
import { Paragraph, RuledHeading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getListingPageUrl from 'site-react/helpers/getListingPageUrl';

const PassProductOffering = ({ buildingId, buildingSlug }) => {
  const passListingLink = getListingPageUrl({
    buildingId: buildingId,
    buildingSlug: buildingSlug,
    isPass: true,
  });

  return (
    <>
      <RuledHeading level="2">Need a workspace for a day?</RuledHeading>
      <VerticalSpacing size="md" />
      <Paragraph>
        This building also has offices, meeting rooms and coworking desks to
        book on-demand.
      </Paragraph>
      <Link href={passListingLink}> View on-demand workspaces</Link>
    </>
  );
};

export default PassProductOffering;
