import PropTypes from 'prop-types';

export default PropTypes.exact({
  archivedAt: PropTypes.string,
  availableFrom: PropTypes.string,
  building: PropTypes.number,
  buildingDetail: PropTypes.shape({
    address: PropTypes.string,
    archivedAt: PropTypes.string,
    buildingType: PropTypes.string,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
  }),
  capacity: PropTypes.number,
  createdAt: PropTypes.string,
  deletedAt: PropTypes.string,
  desksAvailable: PropTypes.number,
  floor: PropTypes.arrayOf(PropTypes.any),
  formattedPrice: PropTypes.string,
  hoursPerWeek: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  id: PropTypes.number,
  keyFeatures: PropTypes.string,
  maxMonthsTerm: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  minMonthsTerm: PropTypes.number,
  notIncluded: PropTypes.string,
  partTimePriceOffPeak: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  partTimePricePeak: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  price: PropTypes.number,
  priceAutoUpdated: PropTypes.bool,
  pricePerPerson: PropTypes.number,
  publishedAt: PropTypes.string,
  ranking: PropTypes.number,
  reasonUnpublished: PropTypes.string,
  seatingConfig: PropTypes.string,
  sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  status: PropTypes.string,
  updatedAt: PropTypes.string,
  url: PropTypes.string,
});
