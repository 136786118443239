import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Heading } from 'site-react/components/typography';
import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';

import DefaultContent from './components/DefaultContent';
import LayoutWrapper from './components/LayoutWrapper';
import LoginForm from './components/LoginForm';
import LoginToSignupSwitcher from './components/LoginToSignupSwitcher';
import SignupForm from './components/SignupForm';
import SignUpToLoginSwitcher from './components/SignUpToLoginSwitcher';

const Signup = ({
  children,
  errors = null,
  forcedEmail = null,
  initialOrganisation = null,
  isAbleToRedirect = false,
  isBookingInProgress = false,
  isHostSignup = false,
  isModeSwitcherLink = false,
  isOrganisationInvite = false,
  isSignupByDefault = false,
  layoutStyle = 'page',
  loginTitle = 'Welcome back! Log in',
  onLogin = () => {},
  onSignup = () => {},
  referralSource = null,
  returnUrl = null,
  signupTitle = 'Create your Hubble account',
}) => {
  const [isSignUp, setIsSignUp] = useState(() => isSignupByDefault);

  /**
   * The return urls are used to switch between login and signup states,
   * the props are added to them to persist url state between switches.
   */
  const loginReturnUrl = new URL('/login', getAppUrl(config));
  const signupReturnUrl = new URL('/signup', getAppUrl(config));

  if (returnUrl) {
    loginReturnUrl.searchParams.set('next', returnUrl);
    signupReturnUrl.searchParams.set('next', returnUrl);
  }

  if (isHostSignup) {
    loginReturnUrl.searchParams.set('host', 'true');
    signupReturnUrl.searchParams.set('host', 'true');
  }

  if (referralSource) {
    loginReturnUrl.searchParams.set('referralSource', referralSource);
    signupReturnUrl.searchParams.set('referralSource', referralSource);
  }

  /*
   * Some usages of this component pass in a single node as the children.
   * Others pass in an array of nodes where those nodes can all be undefined because they are conditionally rendered.
   * Check that the children prop is either
   * a) an array with at least one truthy value, or
   * b) not an array (is a single node)
   */
  let content = <DefaultContent />;
  if (children) {
    if (Array.isArray(children) && children.some(Boolean)) {
      content = children;
    } else if (!Array.isArray(children)) {
      content = children;
    }
  }

  return (
    <LayoutWrapper
      content={content}
      isBookingInProgress={isBookingInProgress}
      isSignUp={isSignUp}
      layoutStyle={layoutStyle}
      loginHeading={
        <Heading level="1" type="title1">
          {loginTitle}
        </Heading>
      }
      signupHeading={
        <Heading level="1" type="title1">
          {signupTitle}
        </Heading>
      }
    >
      {isSignUp ? (
        <SignupForm
          existingErrors={errors}
          forcedEmail={forcedEmail}
          initialOrganisation={initialOrganisation}
          isAbleToRedirect={isAbleToRedirect}
          isHostSignup={isHostSignup}
          isOrganisationInvite={isOrganisationInvite}
          onSignup={onSignup}
          referralSource={referralSource}
          returnUrl={returnUrl}
        >
          {layoutStyle === 'page' && (
            <Heading level="1" type="title1">
              {signupTitle}
            </Heading>
          )}
          <SignUpToLoginSwitcher
            isModeSwitcherLink={isModeSwitcherLink}
            modeSwitcherLinkHref={loginReturnUrl.toString()}
            onActionTextClick={() =>
              setIsSignUp((currentIsSignUp) => !currentIsSignUp)
            }
          />
        </SignupForm>
      ) : (
        <LoginForm
          existingErrors={errors}
          forcedEmail={forcedEmail}
          isAbleToRedirect={isAbleToRedirect}
          onLogin={onLogin}
          returnUrl={returnUrl}
        >
          {layoutStyle === 'page' && (
            <Heading level="1" type="title1">
              {loginTitle}
            </Heading>
          )}
          <LoginToSignupSwitcher
            isModeSwitcherLink={isModeSwitcherLink}
            modeSwitcherLinkHref={signupReturnUrl.toString()}
            onActionTextClick={() =>
              setIsSignUp((currentIsSignUp) => !currentIsSignUp)
            }
          />
        </LoginForm>
      )}
    </LayoutWrapper>
  );
};

Signup.propTypes = {
  /**
   * Extra content to explain the purpose of logging in/signing up. Good to use for
   * added context when used as part of a user journey for a specific experience, such
   * as Pass.
   *
   * Can be used to, for example, explain that by logging in, you'll be joining
   * an organisation.
   */
  children: PropTypes.node,

  /**
   * Non-validation errors, such as errors from a social auth service.
   */
  errors: PropTypes.arrayOf(PropTypes.string),

  /**
   * Hard-set the value of the email field. Useful for invitation acceptance.
   */
  forcedEmail: PropTypes.string,

  /**
   * Is this login able to redirect upon completion?
   *
   * If a login form is embedded in a page with custom onLogin/onSignup handlers,
   * this should probably be false. Generally, it will only be true on a page
   * that is dedicated to logging in or signing up.
   */
  isAbleToRedirect: PropTypes.bool,

  /**
   * Is this signup for a host user?
   *
   * When a host signs up we ask for a phone number as extra information
   * so we can contact them with any concerns regarding their listing, this
   * is required and is not a field on normal signup.
   */
  isHostSignup: PropTypes.bool,

  /**
   * Should the mode switcher work as a link to /signup or /login pages?
   *
   * If true, the mode switchers will act as a link. If false, the mode switcher will
   * change the view on the page without redirecting.
   */
  isModeSwitcherLink: PropTypes.bool,

  /**
   * Is this signup a part of the Organisation Invite flow?
   *
   * If true, we shouldn't display an Organisation field or send that property in
   * the signup payload as this will be populated after accepting the
   * Organisation Invite after signup.
   */
  isOrganisationInvite: PropTypes.bool,

  /**
   * Is this a sign up form on render?
   */
  isSignupByDefault: PropTypes.bool,

  /**
   * Define the style for this component depending on where it's being implemented.
   *
   * Defaults to `page`
   */
  layoutStyle: PropTypes.oneOf(['page', 'modal']),

  /**
   * Custom title to be used above the login form.
   */
  loginTitle: PropTypes.string,

  /**
   * Callback when a login completes
   */
  onLogin: PropTypes.func,

  /**
   * Callback when a signup completes
   */
  onSignup: PropTypes.func,

  /**
   * The intake source of this user (e.g. pass)
   */
  referralSource: PropTypes.string,

  /**
   * If set, the URL to return to once the user is logged in.
   */
  returnUrl: PropTypes.string,

  /**
   * Custom title to be used above the signup form.
   */
  signupTitle: PropTypes.string,
};

export default Signup;
