import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { blob1, blob2, blob3 } from 'site-react/assets';
import { Heading } from 'site-react/components/typography';

import styles from './ContentWithBlob.module.css';
const blobs = {
  blob1,
  blob2,
  blob3,
};

const ContentWithBlob = ({
  blobImg = 'blob1',
  content,
  stagger = null,
  textColor = 'white',
  title,
}) => (
  <div
    className={cn(styles['ContentWithBlob'])}
    style={{
      '--ContentWithBlob--backgroundImage': `url(${blobs[blobImg]})`,
      '--ContentWithBlob--color': `var(--color-${textColor})`,
      '--ContentWithBlob--stagger': `var(--space-${stagger})`,
    }}
  >
    <Heading color={textColor} level="3" type="hero2">
      {title}
      <span className={cn(styles['ContentWithBlob-blobLabel'])}>{content}</span>
    </Heading>
  </div>
);

ContentWithBlob.propTypes = {
  /**
   * The background image to use in this blob
   */
  blobImg: PropTypes.oneOf(['blob1', 'blob2', 'blob3']),

  /**
   * Additional detail to add to the title.
   */
  content: PropTypes.string,

  /**
   * Size of margin-top to be applied to Blob.
   */
  stagger: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl']),

  /**
   * The color of the text to pass to Heading and Paragraph components.
   */
  textColor: PropTypes.oneOf(['neutral900', 'white']),

  /**
   * The main point of what we're detailing in this blob.
   */
  title: PropTypes.node.isRequired,
};

export default ContentWithBlob;
