import ShortlistButton from 'site-react/features/ShortlistButton';
import useFavouriteToggle from 'site-react/hooks/useFavouriteToggle';

import styles from './PrivateOfficeCardShortlistButton.module.css';

const PrivateOfficeCardShortlistButton = ({ pricePlanId, isPartTime }) => {
  const { isShortlisted, onShortlistClick } = useFavouriteToggle(
    pricePlanId,
    isPartTime,
  );

  return (
    <ShortlistButton
      isActivated={isShortlisted}
      isEnabled={!!pricePlanId}
      label={
        <>
          Save
          <span
            className={styles['PrivateOfficeCardShortlistButton-showOnLarge']}
          >
            {' '}
            for later
          </span>
        </>
      }
      onClick={onShortlistClick}
      variant="nav"
    />
  );
};

export default PrivateOfficeCardShortlistButton;
