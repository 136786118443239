import Cookies from 'js-cookie';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';

import config from 'site-react/config';

import useCookieManagePreferences from './hooks/useManageCookiePreferences';
import useSegmentDestinations from './hooks/useSegementDestinations';
import useTagManager from './hooks/useTagManager';
import loadLegacyAnalytics from './loadLegacyAnalytics';
import preferenceOptions from './preferenceOptions';

const CookiePreferencesContext = createContext();
function CookiePreferencesProvider(props) {
  const destinations = useSegmentDestinations(
    config.CUSTOMER_EVENTS_SEGMENT_TOKEN,
  );

  const {
    preferences,
    setPreferences,
    acceptAll,
    declineAll,
    hasSetPreferences,
  } = useCookieManagePreferences(preferenceOptions);

  const { isReady: isGtmLoadable } = useTagManager(
    hasSetPreferences,
    preferences,
  );

  useEffect(() => {
    if (hasSetPreferences) {
      loadLegacyAnalytics(destinations, preferences);
    }
  }, [hasSetPreferences, destinations, preferences]);

  /**
   * Set a cookie based on the user's functional preferences.
   * If a user has accepted functional cookies we set the cookie with a 365 day expiry.
   * If a user has not accepted functional cookies we set a session cookie.
   */
  const setCookieBasedOnFunctionalPreference = useCallback(
    (cookieName, cookieValue, cookieDetails) => {
      const hasAcceptedFunctionalCookies = !!preferences.find(
        (pref) => pref.name === 'Functional',
      );

      if (cookieDetails?.expires) {
        // Remove ability to add a long term cookie if user has not accepted functional cookies
        delete cookieDetails.expires;
      }

      const cookieDetailsWithExpiry = {
        ...cookieDetails,
        ...(hasAcceptedFunctionalCookies ? { expires: 365 } : {}),
      };

      Cookies.set(cookieName, cookieValue, cookieDetailsWithExpiry);
    },
    [preferences],
  );

  const value = useMemo(
    () => ({
      acceptAll,
      availablePreferences: preferenceOptions,
      declineAll,
      hasSetPreferences,
      isGtmLoadable,
      preferences,
      setCookieBasedOnFunctionalPreference,
      setPreferences,
    }),
    [
      acceptAll,
      declineAll,
      isGtmLoadable,
      preferences,
      setCookieBasedOnFunctionalPreference,
      setPreferences,
      hasSetPreferences,
    ],
  );

  return <CookiePreferencesContext.Provider value={value} {...props} />;
}

export { CookiePreferencesContext };
export default CookiePreferencesProvider;
