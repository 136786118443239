import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import usePartTime from 'site-react/hooks/usePartTime';

import { DEFAULT_ACCESS, DEFAULT_DAYS } from '../data';

const days = [
  { label: 'Mo', name: 'monday' },
  { label: 'Tu', name: 'tuesday' },
  { label: 'We', name: 'wednesday' },
  { label: 'Th', name: 'thursday' },
  { label: 'Fr', name: 'friday' },
];

/**
 * Ensure that the days parameter is an array of valid days
 *
 * @param {Array|String} valueFromParams
 * @param {Array} valueFromLocalStorage
 * @returns {Array} An array of valid days
 * @example
 * sanitiseDaysParam('monday', undefined) // ['monday']
 * sanitiseDaysParam('shmoosday', undefined) // DEFAULT_DAYS
 * sanitiseDaysParam(['monday', 'tuesday'], undefined) // ['monday', 'tuesday']
 * sanitiseDaysParam(['tuesday', 'shmoosday'], undefined) // ['tuesday']
 * sanitiseDaysParam(undefined, ['monday', 'tuesday']) // ['monday', 'tuesday']
 * sanitiseDaysParam(undefined, undefined) // DEFAULT_DAYS
 */
function sanitiseDaysParam(valueFromParams, valueFromLocalStorage) {
  const validDays = days.map((day) => day.name);

  if (Array.isArray(valueFromParams)) {
    return valueFromParams.filter((day) => validDays.includes(day));
  }

  if (typeof valueFromParams === 'string') {
    if (validDays.includes(valueFromParams)) {
      return [valueFromParams];
    }
  }

  if (valueFromLocalStorage) {
    return valueFromLocalStorage;
  }

  return DEFAULT_DAYS;
}

export default function usePricePlanFilters({ pricePlans }) {
  const router = useRouter();
  const { partTimePreferences, setPartTimePreferences } = usePartTime();

  const [searchState, dispatchSearchState] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    {
      access: Array.isArray(router.query.access)
        ? router.query.access
        : typeof router.query.access === 'string'
          ? [router.query.access]
          : DEFAULT_ACCESS,
      days: sanitiseDaysParam(router.query.days, partTimePreferences?.days),
      peopleMin: router.query.peopleMin,
    },
  );

  useEffect(() => {
    if (
      !isEqual(
        [...searchState.days].sort(),
        [...partTimePreferences.days].sort(),
      )
    ) {
      setPartTimePreferences({ days: searchState.days });
    }
  }, [partTimePreferences.days, searchState.days, setPartTimePreferences]);

  const submitParams = useCallback(() => {
    const newParams = {
      ...router.query,
      ...searchState,
    };

    if (!newParams.access) {
      newParams.access = DEFAULT_ACCESS;
    } else if (
      !newParams.access.includes('partTime') ||
      isEqual([...DEFAULT_DAYS].sort(), [...newParams.days].sort())
    ) {
      delete newParams.days;
    }

    if (isEqual([...DEFAULT_ACCESS].sort(), [...newParams.access].sort())) {
      delete newParams.access;
    }

    if (!newParams.peopleMin) {
      delete newParams.peopleMin;
    }

    if (newParams.option) {
      delete newParams.option;
    }

    router.replace({ query: newParams }, undefined, { scroll: false });
  }, [router, searchState]);

  const [matches, other] = useMemo(() => {
    const typeOfPricePlanConfig = {
      fullTime: 'full-time-office',
      partTime: 'part-time-office',
    };
    const typeOfPricePlanSelected = searchState.access.map(
      (state) => typeOfPricePlanConfig[state],
    );

    const { matches: newMatches, other: newOther } = pricePlans.reduce(
      (acc, pricePlan) => {
        if (
          pricePlan.status === 'published' &&
          typeOfPricePlanSelected.includes(pricePlan.typeOfPricePlan) &&
          pricePlan.capacity >=
            (!isNaN(searchState.peopleMin) ? searchState.peopleMin : 0)
        ) {
          if (pricePlan.isHighlighted) {
            acc.matches.unshift(pricePlan);
          } else {
            acc.matches.push(pricePlan);
          }
        } else {
          if (pricePlan.isHighlighted) {
            acc.other.unshift(pricePlan);
          } else {
            acc.other.push(pricePlan);
          }
        }
        return acc;
      },
      { matches: [], other: [] },
    );

    return [newMatches, newOther];
  }, [pricePlans, searchState.peopleMin, searchState.access]);

  return {
    dispatchSearchState,
    matches,
    other,
    searchState,
    submitParams,
  };
}
