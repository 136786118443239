import breakpoints from 'site-react/theme/breakpoints';

/**
 * Analytics handles most basic analytics events required.
 *
 * @class Analytics
 */
class Analytics {
  constructor() {
    this.pageProps = {};
  }

  /**
   * Gets the Segment window.analytics object
   * Also fails silently in a test.
   *
   * @param {string} errorMsg
   */
  static getAnalytics() {
    // If not got window or analytics
    if (typeof window === 'undefined' || !window.analytics) {
      // you can log an error if you are trying to track something before window is available
      return null;
    }
    return window.analytics;
  }

  alias(userId, previousId, options, callback) {
    const analytics = Analytics.getAnalytics();
    if (!analytics) return;
    analytics.alias(userId, previousId, options, callback);
  }

  /**
   * If analytics object is present in window, send message to segment
   *
   * N.B. Page properties will only be sent if `options.sendPageProperties` is `true`. It defaults to `false`, but this behaviour is likely to change in the future.
   *
   * @param {string} message - meaningful description of user action
   * @param {Object} [payload] - wrap any required information to send in an object literal
   * @param {Object} [options] - options to set on Segment for this track
   * @param {Function} [callback] - a function that is executed after a short timeout, giving the browser time to make outbound requests first.
   * @see {@link https://segment.com/docs/sources/website/analytics.js/#track}
   *
   * For all analytics functions to work they have to be in componentDidMount or after in the lifecycle.
   */
  track(
    message,
    payload,
    { sendPageProperties, ...options } = { sendPageProperties: false },
    callback,
  ) {
    const analytics = Analytics.getAnalytics();
    if (!analytics) return;
    // Always send the current url with analytics
    const url = window.location.href;
    analytics.track(
      message,
      {
        url,
        ...(sendPageProperties && this.getPageProperties()),
        ...payload,
      },
      options,
      callback,
    );
  }

  /**
   * Tracks a link. Recommended only when we want to track an _outbound_ link,
   * ie. a link to Typeform. Internal links to other Hubble pages shouldn't use
   * this, as it adds a delay on link clicks and degrades the user experience.
   *
   * The analytics event only fires once a tracked link is clicked.
   *
   * See also: https://segment.com/docs/sources/website/analytics.js/#track-link
   *
   * @param {Element} element - the element to add a track on
   * @param {string} message - meaningful description of user action
   * @param {Object|Function} [payload] - wrap any required information to send in an object literal
   * @param {Object} [options] - options to set on Segment for this track
   */
  trackLink(
    element,
    message,
    payload,
    { sendPageProperties, ...options } = { sendPageProperties: false },
  ) {
    const analytics = Analytics.getAnalytics();
    if (!analytics) return;
    // Always send the current url with analytics
    const url = window.location.href;

    const payloadCallback = () => ({
      url,
      ...(sendPageProperties && this.getPageProperties()),
      ...(typeof payload === 'function' ? payload() : payload),
    });

    analytics.trackLink(
      element,
      message,
      typeof payload === 'function' ? payloadCallback : payloadCallback(),
      options,
    );
  }

  // We widely use this function as a class method, so in order to avoid
  // breaking those, we'll keep it as a class method.
  // eslint-disable-next-line class-methods-use-this
  identify(userId, traits, options, callback) {
    const analytics = Analytics.getAnalytics();
    if (!analytics) return;
    if (userId) {
      analytics.identify(userId, traits, options, callback);
    } else {
      analytics.identify(traits, options, callback);
    }
  }

  /**
   * The Group method associates an identified user with a Hubble
   * organization.
   *
   * This is useful for tools like Intercom and Pendo, as it ties the user
   * to a group of other users.
   *
   * NOTE: all parameters other than `groupId` are optional; `traits` is,
   * however, strongly encouraged.
   *
   * This docblock was copied from Segment's documentation, and lightly edited
   * for Hubble's use case. You can view the original documentation at
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group
   *
   * @param {int} groupId the Group ID to associate with the current user.
   * @param {object} [traits] a dictionary of traits for the group. Example traits for a group include name, createdAt, and employees.
   * @param {object} [options] a dictionary of options. For example, enable or disable specific destinations for the call. Note: If you do not pass a properties object, pass an empty object (like ‘{}’) before options
   * @param {function} [callback] a function that runs after a short timeout, giving the browser time to make outbound requests first.
   */
  group(groupId, traits, options, callback) {
    const analytics = Analytics.getAnalytics();
    if (!analytics) return;
    analytics.group(groupId, traits, options, callback);
  }

  // We widely use this function as a class method, so in order to avoid
  // breaking those, we'll keep it as a class method.
  // eslint-disable-next-line class-methods-use-this
  pageTrack(name, category, properties, options, callback) {
    const props = properties || {};
    const opts = options || {};

    if (document.referrer) {
      props.referrer = document.referrer;
    }

    if (!opts.Intercom) {
      opts.Intercom = {};
    }
    if (!('hideDefaultLauncher' in opts.Intercom)) {
      opts.Intercom.hideDefaultLauncher = window.innerWidth < breakpoints.md;
    }

    const analytics = Analytics.getAnalytics();
    if (!analytics) return;

    analytics.page(name, category, props, opts, callback);
  }

  /**
   * Resets analytics
   * TODO: Restart Intercom!!!
   *
   * @see {@link https://segment.com/docs/sources/website/analytics.js/#reset-logout}
   */
  // We widely use this function as a class method, so in order to avoid
  // breaking those, we'll keep it as a class method.
  // eslint-disable-next-line class-methods-use-this
  reset() {
    const errorMsg = 'You cannot reset analytics before window is available';
    const analytics = Analytics.getAnalytics(errorMsg);
    if (!analytics) return;

    analytics.reset();
  }

  /**
   * Gets the current page properties.
   *
   * Usage of this should be exceedinly rare; page properties are normally handled by `track()`.
   */
  getPageProperties() {
    return {
      ...(typeof window !== 'undefined' && { page_url: window.location.href }),
      ...(typeof document !== 'undefined' &&
        document.title && { page_title: document.title }),

      ...this.pageProps,
    };
  }

  /**
   * @typedef PageProperties
   * @property {number} page_budget the value of the current page; usually identifies the price of what is represented by `page_id`.
   * @property {string} page_category the type of the page. Must be one of `static`, `listing`, `user-profile`, `search`, or `blog`.
   * @property {string} page_city the name of the city associated with the current page
   * @property {string} page_city_area the name of a region within a city, associated with the current page
   * @property {number} page_id an ID which, when combined with the category and type, describes something identifiable. For example, on a listing page, `page_id` is a price plan ID.
   * @property {number} page_quantity the quantity of things the current page represents. For example, on a listing page, this would represent the number of desks.
   * @property {string} page_title the title of the current page. Defaults to `document.title`; only set when a different value is needed.
   * @property {string} page_type the type of the current page; eg. `favourites` or `about-us`
   * @property {string} page_url the URL for the page - ideally, canonical. Will default to `window.location.href`; only set when a different value is needed.
   *
   * @typedef PagePropertiesOptions
   * @property {bool} merge should the props be merged into the existing props?
   *
   * Sets the page properties. These properties will be sent with every
   * analytics event track that follows, until `resetPageProperties` is called.
   * @param {PageProperties} props the page properties to set
   * @param {PagePropertiesOptions} opts options for setting properties
   */
  setPageProperties(props, { merge } = { merge: false }) {
    this.pageProps = {
      ...(merge && this.pageProps),
      ...props,
    };
  }
}

export default new Analytics();
