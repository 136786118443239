import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useMediaQuery from 'site-react/hooks/useMediaQuery';
import useScrollSpy from 'site-react/hooks/useScrollSpy';
import theme from 'site-react/theme';

import styles from './PageNavigation.module.css';
import PageNavigationItem from './PageNavigationItem';

const scrollspyRootMargin = `-${
  theme.spacing.base * 55
}px 0px 0px 0px`; /* 220px; small header + small page nav */
const scrollspyRootMarginLg = `-${
  theme.spacing.base * 63
}px 0px 0px 0px`; /* 252px; larger header + larger page nav */

const PageNavigation = ({
  accessory = null,
  ariaLabel,
  isScrollTracked = false,
  isWidthConstrained = true,
  navItems,
}) => {
  const [intersectingId, setIntersectingId] = useState(
    isScrollTracked && navItems[0]?.id ? `${navItems[0].id}-container` : '',
  );

  let observables = [];
  if (typeof document !== 'undefined') {
    const documentNavItems = navItems.map((navItem) =>
      document.querySelector(`#${navItem.id}-container`),
    );
    observables = documentNavItems.filter((item) => item !== null);
  }

  const isViewportLg = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const { currentElement } = useScrollSpy({
    observables,
    rootMargin: isViewportLg ? scrollspyRootMarginLg : scrollspyRootMargin,
  });

  useEffect(() => {
    if (isScrollTracked) {
      setIntersectingId(currentElement?.id);
    }
  }, [currentElement, isScrollTracked]);

  return (
    <div className={styles.PageNavigation}>
      <nav
        aria-label={ariaLabel}
        className={cn(styles['PageNavigation-nav'], {
          [styles['isWidthConstrained']]: isWidthConstrained,
        })}
      >
        <ul className={styles['PageNavigation-items']}>
          {navItems.map((item) => (
            <PageNavigationItem
              href={item.href}
              isActive={
                item.isActive || intersectingId === `${item.id}-container`
              }
              isOutbound={item.isOutbound}
              key={item.id ?? item.title}
              title={item.title}
            />
          ))}
        </ul>
        {accessory && (
          <div className={styles['PageNavigation-accessory']}>{accessory}</div>
        )}
      </nav>
    </div>
  );
};

PageNavigation.propTypes = {
  /**
   * An accessory. A small component that is displayed within the nav, but
   * separated from the navigation items.
   *
   * Only shown at medium breakpoints and above, due to limited screen real
   * estate.
   *
   * Example: a shortlist button
   */
  accessory: PropTypes.node,

  /**
   * Used by screen-readers to identify the purpose of the navigation bar.
   * This is especially useful as basically all our pages have multiple navs.
   * Without this, it can be confusing for user's with screen readers to
   * know the purposes of different nav sections on a page.
   */
  ariaLabel: PropTypes.string.isRequired,

  /**
   * Should scroll position be tracked?
   */
  isScrollTracked: PropTypes.bool,

  /**
   * Should there be a maximum width?
   */
  isWidthConstrained: PropTypes.bool,

  /**
   * Array of nav item data objects.
   */
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      id: PropTypes.string,
      isActive: PropTypes.bool,
      isOutbound: PropTypes.bool,
      title: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default PageNavigation;
