import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';

import styles from './HamburgerButton.module.css';

const HamburgerButton = ({ isOpen, onClick }) => {
  return (
    <UnstyledButton
      attributes={{
        'aria-expanded': isOpen,
        'aria-haspopup': true,
      }}
      className={cn(styles.HamburgerButton, {
        [styles['HamburgerButton--open']]: isOpen,
      })}
      label="main navigation menu"
      onClick={onClick}
      title={`${isOpen ? 'Open' : 'Close'} main navigation menu`}
    >
      <span />
      <span />
      <span />
    </UnstyledButton>
  );
};

HamburgerButton.propTypes = {
  /**
   * Is Open
   */
  isOpen: PropTypes.bool,

  /**
   * On Click, callback
   */
  onClick: PropTypes.func.isRequired,
};

export default HamburgerButton;
