/**
░░░░░░░WKkxdx0N░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░WN0xdoc:oK░░░░░░░░░░░░░░░░░░░░░░░░
░░WX0O00Oxxkxc;dN░░░░░░░░░░░░░░░░░░░░░░░
░░Xxdox00kxdoc;oN░░░░░░░░░░░░░░░░░░░░░░░
░░Nkok0Kklcc:;:0░░░░░░░░░░░░░░░░░░░░░░░░
░░░WNNOxddoc;lKW░░░░░░░░░░░░░░░░░░░░░░░░
░░░░NOkkdoc;,okkxddddddxOKXW░░WNNNNW░░░░
░░░W0xxxdl::;;;;::::;;;;;;cok00OO000X░░░
░░░WOdddolcc::c:llolc:,;,,;;,,;codkk0W░░
░░░░Kolllccc:cc:cldxxc,,;;;:;,'.;odkK░░░
░░░░W0l:::clc:cclkOdl;';:::::;'.'ox0W░░░
░░░░░░Xkl:ldl;;cokOkdc,;;::::;,'':ON░░░░
░░░░░░░░Nkdodl::dkOO0kc,,,;;;;;,.:K░░░░░
░░░░░░░░░WXkoll::clodo:'''',;;;:oKW░░░░░
░░░░░░░░░░░WN0kolcc:::::;,:lok0XW░░░░░░░
░░░░░░░░░░░░░░W0lcoollllcxKNW░░░░░░░░░░░
░░░░░░░░░░░░░░░Nd;lk0xlloK░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░Ndl0WW0ldKW░░░░░░░░░░░░░░
░░░░░░░░░N0O0KKkcdNNXdlK░░░░░░░░░░░░░░░░
░░░░░░░░W0dlclxkxkkdlcoKN░░░░░░░░░░░░░░░
░░░░░░░░░░NOOK0xddoccok0XW░░░░░░░░░░░░░░
░░░░░░░░░░░░░Nkxxod0XN░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░NXWKkX░░░░░░░░░░░░░░░░░░░░░
 *
 * DEPRECATION NOTICE
 *
 * We have created a new Modal component under components/page/ModalNew.
 * The hope is that this new way of implementing a Modal is simpler to reason about and more
 * semantically correct as it leverages the dialog element which handles a lot of fiddly behaviour
 * like focus trapping, scroll locking, background effects and key events out of the box.
 * If you need to implement a Modal or are currently working on a task that involves maintenance of a page
 * using this component please consider migrating to the new Modal component pattern.
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ModalUnmanaged from './ModalUnmanaged';

const Modal = ({
  children,
  isOpenOnRender = false,
  modalName,
  onCloseModal = () => {},
  isReducedWidth,
  renderTrigger,
  ...otherModalProps
}) => {
  const [isOpen, setIsOpen] = useState(isOpenOnRender);

  const handleCloseModal = (...args) => {
    setIsOpen(false);
    onCloseModal(...args);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {renderTrigger({ openModal: handleOpenModal })}
      {isOpen ? (
        <ModalUnmanaged
          {...otherModalProps}
          isReducedWidth={isReducedWidth}
          modalName={modalName}
          onCloseModal={handleCloseModal}
          visible
        >
          {/* If children is a function, invoke it. Otherwise, render it. */}
          {typeof children === 'function'
            ? children({ closeModal: handleCloseModal })
            : children}
        </ModalUnmanaged>
      ) : null}
    </>
  );
};

Modal.propTypes = {
  /**
   * The content to show inside this modal.
   *
   * If this is a function, on render it will be called with a
   * `closeModal` argument.
   *
   * Example:
   * ```
   * <Modal>Content goes here</Modal>
   * ```
   * ```
   * <Modal>
   *   {({ closeModal }) => (
   *     <div>
   *       Content goes here
   *       <button onClick={closeModal}>Close modal</button>
   *     </div>
   *   )}
   * </Modal>
   * ```
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

  /**
   * Should the modal open automatically when first rendered?
   */
  isOpenOnRender: PropTypes.bool,

  /**
   * This is the name of the Event Label analytic for a specific modal instance
   * It's required because we can't see the metadata in Google Analytics
   */
  modalName: PropTypes.string.isRequired,

  /**
   * Callback when the modal is closed.
   *
   * Entirely optional. Modal handles opening and closing modals for you,
   * but this prop is available to handle things like analytics.
   */
  onCloseModal: PropTypes.func,

  /**
   * Render prop for the trigger. A function, which returns a component that
   * gets rendered in place. The function will be called with an openModal
   * argument, which can be called to open the associated modal.
   *
   * NOTE: This component also accepts all the props that Modal accepts. It
   * essentially adds state management, in order to simplify the opening and
   * closing of modals.
   *
   * Example:
   * ```
   * <Modal renderTrigger={
   *   ({ openModal }) => <button onClick={openModal}>Open modal</button>
   * } />`
   * ```
   */
  renderTrigger: PropTypes.func.isRequired,
};

export default Modal;
