import useMembership from 'site-react/hooks/useMembership';
import useUser from 'site-react/hooks/useUser';

export const priceTypes = {
  credits: 'credits',
  currency: 'currency',
};

const usePriceType = () => {
  const { plan } = useMembership();
  const { isLoggedIn } = useUser();
  let priceType = priceTypes.currency;

  if (!isLoggedIn) {
    priceType = priceTypes.currency;
  } else if (plan?.abilities?.includes('pays-in-credits')) {
    priceType = priceTypes.credits;
  }
  return priceType;
};

export default usePriceType;
