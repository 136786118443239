import PropTypes from 'prop-types';

export default PropTypes.exact({
  building: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  maxDeskCapacity: PropTypes.number.isRequired,
  minDeskCapacity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  priceInCurrency: PropTypes.number.isRequired,
  pricePayg: PropTypes.number.isRequired,
  pricePaygInCurrency: PropTypes.number.isRequired,
  productType: PropTypes.oneOf(['coworking', 'meeting-room', 'private-office']),
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
});
