import colors from 'site-react/theme/colors';

const styles = [
  {
    elementType: 'labels.text',
    featureType: 'poi',
    stylers: [
      {
        color: colors.white,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'poi',
    stylers: [
      {
        color: colors.neutral500,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi',
    stylers: [
      {
        color: colors.neutral500,
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [
      {
        color: colors.neutral200,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: colors.neutral500,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi',
    stylers: [
      {
        color: colors.neutral500,
      },
    ],
  },
];

export default styles;
