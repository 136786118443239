import AuthApi from 'site-react/api/AuthApi';
import authService from 'site-react/auth/AuthService';

/**
 * @typedef SignupInputs
 * @property {string} email - the email to use for signing up
 * @property {string} firstName - the first name to use for signing up
 * @property {string} lastName - the last name to use for signing up
 * @property {string} password - the password to use for signing up
 * @property {string} referralSource - the intake source of this user (e.g. pass)
 * @memberof module:login
 */

/**
 * Attempt a signup using the authentication API.
 *
 * @param {SignupInputs} payload - the inputs required for signing up
 * @throws when the underlying `request` throws - see {@link module:request}
 * @returns {module:request.ValidResponse}
 * @private
 * @memberof module:login
 */
const attemptSignup = async ({
  email,
  firstName,
  lastName,
  organisation,
  password,
  regionalPhoneNumber,
  referralSource,
}) => {
  const api = new AuthApi();
  const response = await api.signup({
    email,
    firstName,
    lastName,
    ...(organisation && { organisation }),
    password,
    referralSource: referralSource ?? '',
    regionalPhoneNumber,
  });

  // TODO: remove this when signup() moves inside the AuthService.
  // See EN-1232
  await authService.refresh();

  return response;
};

export default attemptSignup;
