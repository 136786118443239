import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

import { Button, Select } from 'site-react/components/form';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './SearchWorkspacesForm.module.css';

/* eslint-disable sort-keys */
export const searchRoutes = {
  'full-time': {
    displayValue: 'Full-time office',
    href: '/office-space-london',
  },
  'part-time': {
    displayValue: 'Part-time office - 1-3 days per week',
    href: '/part-time-offices/london',
  },
  'private-day-office': {
    displayValue: 'Day office',
    href: '/private-day-offices/london',
  },
  'meeting-room': {
    displayValue: 'Meeting room',
    href: '/meeting-rooms/london',
  },
  coworking: {
    displayValue: 'Coworking desks',
    href: '/coworking/london',
  },
  'event-space': {
    displayValue: 'Event space',
    href: 'https://search.hubblehq.com/events/',
    target: '_blank',
  },
};
/* eslint-enable sort-keys */

export default function SearchWorkspacesForm() {
  const router = useRouter();

  const handleSubmit = useCallback(
    function (event) {
      event.preventDefault();

      const data = new FormData(event.target);

      const workspaceValue = data.get('workspaces');
      const workspaceRoute = searchRoutes[workspaceValue];

      if (workspaceRoute.target) {
        // useRouter doesn't support target="_blank" so we need to use window.open
        window.open(workspaceRoute.href, workspaceRoute.target);
      } else {
        router.push(workspaceRoute.href);
      }
    },
    [router],
  );

  return (
    // Note that we use an API action here, which will serve as a fallback for
    // users (like Googlebot) who don't have JavaScript enabled.
    //
    // Google has been trying to index by submitting this form. We want it to
    // index our pages, so if it's choosing to follow this path, that's
    // something to encourage. But we need to support it without JavaScript,
    // because Google was _trying_ to submit this form to the current page,
    // which wouldn't work.
    <form action="/api/workspace-redirect" noValidate onSubmit={handleSubmit}>
      <label
        aria-label="Find a workspace"
        className={styles['SearchWorkspacesForm-label']}
        htmlFor="workspaces"
      >
        <Heading type="title3">Find a workspace</Heading>
      </label>
      <VerticalSpacing size="md" />
      <div className={styles['SearchWorkspacesForm-content']}>
        <Select data-testid="workspaces" id="workspaces" name="workspaces">
          {Object.entries(searchRoutes).map(([key, value]) => (
            <option data-testid={key} key={key} value={key}>
              {value.displayValue}
            </option>
          ))}
        </Select>
        <div>
          <Button
            analyticsMetadata={{
              location: 'footer',
            }}
            name="Search"
            styleAtSmall="fullWidth"
            type="submit"
          >
            Search
          </Button>
        </div>
      </div>
    </form>
  );
}
