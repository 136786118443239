/**
 * Runs a function, `fn`, up to `retries` times, waiting for
 * `waitTimeBasis * currentTryNumber` before each re-attempt.
 *
 * This function does _not_ use exponential backoff, because it didn't work well
 * for the use case it was designed for. Rather, it will simply multiply the
 * `waitTimeBasis` by the current attempt number. That means for a value of 300,
 * for each retry, the time would be:
 * * between attempt 1 and 2: 300ms (300 * 1)
 * * between attempt 2 and 3: 600ms (300 * 2)
 * * between attempt 3 and 4: 900ms (300 * 3)
 * and so on.
 *
 * @param {Function} fn the function to run (repeatedly if necessary)
 * @param {Number} retries how many attempts should be made to run `fn`
 * @param {Number} waitTimeBasis the time, in ms, to use as the basis for the backoff strategy.
 */
export default async function runWithRetry(
  fn,
  retries = 5,
  waitTimeBasis = 300,
) {
  // Loop until we're out of tries.
  // NOTE: this is 1-indexed, because it makes the math easier!
  for (let tries = 1; tries <= retries; tries++) {
    try {
      return await fn();
    } catch (error) {
      if (tries === retries) {
        // We've reached the maximum number of attempts. Time to give up.
        throw error;
      } else {
        // Calculates the time to wait for, in ms
        const waitFor = waitTimeBasis * tries;

        // await a promise that will resolve in `waitFor` ms.
        await new Promise((resolve) => setTimeout(resolve, waitFor));
        // Now, the loop will restart
      }
    }
  }
}
