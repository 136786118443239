import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import theme from 'site-react/theme';

import Arrow from './components/Arrow';
import {
  IMAGES_SCROLLED_PER_CLICK,
  NO_OF_COLUMNS_BY_BREAKPOINT,
} from './ImageCarousel.data';
import styles from './ImageCarousel.module.css';

/**
 * Image Carousel which, given a list of images, will display them:
 * - In a 2 column grid on sm viewports. Overflow is scrollable with snap scrolling.
 * - In a 4 column grid on md viewports. Overflow is hidden and accessible using
 *   navigation buttons.
 * - In a 5 column grid on lg+ viewports. Overflow behaves the same as md viewports.
 */
export default function ImageCarousel({ images }) {
  function getBreakpoint() {
    if (typeof window === 'undefined') {
      return 'sm';
    }

    if (window.innerWidth >= theme.breakpoints.lg) {
      return 'lg';
    } else if (window.innerWidth >= theme.breakpoints.md) {
      return 'md';
    } else {
      return 'sm';
    }
  }

  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint());

  const scrollContainerRef = useRef(null);
  const [snapToIndex, setSnapToIndex] = useState(() => 0);

  useEffect(() => {
    function handleResize() {
      setBreakpoint(getBreakpoint());
      setSnapToIndex(0);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handlePreviousButtonDisabledState(isDisabled) {
    const previousButton = document.querySelector('[aria-label="Previous"]');
    if (previousButton.disabled !== isDisabled) {
      previousButton.disabled = isDisabled;
    }
  }

  function handleNextButtonDisabledState(isDisabled) {
    const nextButton = document.querySelector('[aria-label="Next"]');
    if (nextButton.disabled !== isDisabled) {
      nextButton.disabled = isDisabled;
    }
  }

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (snapToIndex === 0) {
      handlePreviousButtonDisabledState(true);
      scrollContainer.scrollLeft = 0;
      return;
    } else {
      handlePreviousButtonDisabledState(false);
    }

    const imageWidthWithGap =
      scrollContainer.firstElementChild.offsetWidth + theme.spacing.md;

    const finalScrollToPosition =
      images.length - NO_OF_COLUMNS_BY_BREAKPOINT[breakpoint];

    const finalLeftPosition = imageWidthWithGap * finalScrollToPosition;

    const targetItem = scrollContainer.children[snapToIndex];

    if (snapToIndex === finalScrollToPosition) {
      scrollContainer.scrollLeft = finalLeftPosition;
      handleNextButtonDisabledState(true);
      return;
    } else {
      handleNextButtonDisabledState(false);
    }

    if (scrollContainer && targetItem) {
      scrollContainer.scrollLeft = imageWidthWithGap * snapToIndex;
    }
  }, [breakpoint, images.length, snapToIndex]);

  function handleSnapTo(index) {
    setSnapToIndex(index);
  }

  return (
    <div className={styles.ImageCarousel}>
      <div className={styles['ImageCarousel-arrowWrapper']}>
        <Arrow
          aria-label="Previous"
          direction="previous"
          disabled={snapToIndex === 0}
          onClick={() =>
            handleSnapTo((previousScrollPosition) => {
              const nextScrollPosition =
                previousScrollPosition - IMAGES_SCROLLED_PER_CLICK;

              if (nextScrollPosition < 0) {
                return 0;
              } else {
                return nextScrollPosition;
              }
            })
          }
        />
      </div>
      <div
        className={styles['ImageCarousel-images']}
        id="image-carousel"
        ref={scrollContainerRef}
      >
        {images.map((image) => (
          <figure className={styles['ImageCarousel-figure']} key={image.alt}>
            <img
              alt={image.alt}
              className={cn(styles['ImageCarousel-image'], {
                [styles['ImageCarousel-image--withCustomWidth']]: image.width,
              })}
              loading="lazy"
              src={image.src}
              style={{
                '--Image-width': `${image.width}px`,
              }}
            />
          </figure>
        ))}
      </div>
      <div className={styles['ImageCarousel-arrowWrapper']}>
        <Arrow
          aria-label="Next"
          direction="next"
          onClick={() => {
            handleSnapTo((previousScrollPosition) => {
              const nextScrollPosition =
                previousScrollPosition + IMAGES_SCROLLED_PER_CLICK;

              const finalSnapTo =
                images.length - NO_OF_COLUMNS_BY_BREAKPOINT[breakpoint];

              if (nextScrollPosition > finalSnapTo) {
                return finalSnapTo;
              } else {
                return nextScrollPosition;
              }
            });
          }}
        />
      </div>
    </div>
  );
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      width: PropTypes.number,
    }),
  ).isRequired,
};
