import React, { useContext } from 'react';

import CloseButton from './../../components/CloseButton';
import styles from './ModalNewTemplateBasic.module.css';
import { ModalNewContext } from '../../context/ModalNewContext';

/**
 * !!! Important !!!
 *
 * # Do not make it more complex
 * This is a very basic modal template, a white box with a close
 * button. If it isn't simple anymore its defeated the point.
 *
 * # Copy & paste is an excellent option.
 * If you need something similar but different then copy&paste it
 * and use it either as a custom modal view or create a template.
 */
const ModalNewTemplateBasic = ({ children }) => {
  const { closeModal, isLocked } = useContext(ModalNewContext);
  return (
    <div className={styles['ModalNewTemplateBasic']}>
      {!isLocked && <CloseButton onClickCallback={closeModal} />}
      {children}
    </div>
  );
};

export { ModalNewTemplateBasic };
