import request, { HttpError } from '@hubble/request';
import Cookies from 'js-cookie';

import config from 'site-react/config';

class HubbleBaseApi {
  /**
   * Checks if the request was rejected due to an XSRF token expiration.
   *
   * Sometimes the XSRF token expire, and we send POST requests before they have
   * had a chance to get updated through a GET request.
   *
   * To avoid failing calls when this happens, we refresh the token and try
   * the request again. If the second try fails, then an error should show.
   *
   * @param {module:request.HttpError} response an object with an error key
   * @returns `true` if data represents a request with an invalid XSRF token; `false` otherwise.
   */
  static isExpiredXsrfResponse(response) {
    if (response.error === 'XSRF token invalid') {
      return true;
    }
    return false;
  }

  /**
   * Ensures that the request options contain the latest XSRF token.
   */
  static addXsrfToken(requestOpts) {
    return {
      ...requestOpts,

      // Use the provided headers, if any, _but_ ensure that the X-XSRF-TOKEN
      // header matches the latest value in the cookie.
      headers: {
        ...(requestOpts.headers || {}),
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
      },
    };
  }

  static addLoggedInAs(requestOpts, overrideLoginAs = false) {
    if (overrideLoginAs) return requestOpts;

    return {
      ...requestOpts,

      // By default all requests should send the use logged in as header
      headers: {
        ...(requestOpts.headers || {}),
        'X-Use-Logged-In-As': 'true',
      },
    };
  }

  static addUserAgent(requestOpts, userAgent) {
    return {
      ...requestOpts,

      // By default all requests should send the use logged in as header
      headers: {
        ...(requestOpts.headers || {}),
        'User-Agent': userAgent,
      },
    };
  }

  constructor() {
    this.apiToken = config.HUBBLE_API_TOKEN;

    if (config.HUBBLE_API_PROXY === '1' && typeof window !== 'undefined') {
      // When we're running off-domain, we use an API proxy. You'll find this in
      // /src/pages/api/proxy.
      //
      // We only do this to overcome CORS restrictions and cross-origin cookies.
      // Those issues only exist in the browser; when server-side rendering, we
      // use the normal HUBBLE_API_URL.
      this.apiUrl = new URL(
        '/api/proxy/',
        new URL(window.location.href).origin,
      ).toString();
    } else {
      this.apiUrl = config.HUBBLE_API_URL;
    }

    if (typeof window === 'undefined') {
      this.userAgent = config.HTTP_USER_AGENT;
    }
  }

  /**
   * Converts an endpoint into a fully-qualified Hubble API URL, with an
   * environment-safe API key and hostname.
   *
   * @param {*} endpoint the endpoint to use; no leading slash
   * @returns a fully-qualified Hubble API URL
   */
  getUrl(endpoint) {
    const token = `${endpoint.includes('?') ? '&' : '?'}api_key=${
      this.apiToken
    }`;

    return `${this.apiUrl}${endpoint}${token}`;
  }

  /**
   * Refresh the XSRF token.
   *
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async refreshXsrfToken() {
    return this.get('ok', undefined, { skipXsrfRetry: true });
  }

  /**
   * Make a request to a Hubble endpoint.
   *
   * In addition to prefixing the endpoint with the API URL for the current
   * environment, `request()` will also add the API key to the end of the URL,
   * and add an XSRF header.
   *
   * If the Hubble server rejects the XSRF token, `request()` will refresh the
   * token and try one more time. If the second attempt fails, for any reason,
   * `request()` will return the second response.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {string} [method='GET'] the method to use
   * @param {Object|String} [body=undefined] the payload to send
   * @param {*} [opts={}] options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch. Also accepts a `skipXsrfRetry` property, which will prevent this function from retrying failed requests resulting from expired XSRF tokens.
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async request(endpoint, method = 'GET', body = undefined, opts = {}) {
    let { skipXsrfRetry, overrideLoginAs, ...requestOpts } = opts;
    const url = this.getUrl(endpoint);

    skipXsrfRetry = skipXsrfRetry || false;

    // Add Login As headers
    requestOpts = HubbleBaseApi.addLoggedInAs(requestOpts, overrideLoginAs);

    // Add User Agent
    if (this.userAgent) {
      requestOpts = HubbleBaseApi.addUserAgent(requestOpts, this.userAgent);
    }

    // Add XSRF token
    requestOpts = HubbleBaseApi.addXsrfToken({
      credentials: 'include',
      ...requestOpts,
    });

    let response;

    const args = [url, method, body, requestOpts];

    if (skipXsrfRetry) {
      response = await request(...args);
    } else {
      response = await this.requestWithXsrfRetry(...args);
    }

    return response;
  }

  /**
   * A wrapper around `request()` (the base function, not the member of this class by the same name) which will retry a request once if an expired XSRF token is deteched.
   *
   * Accepts exactly the same arguments as request().
   *
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async requestWithXsrfRetry(url, method, body, requestOpts) {
    let response;

    try {
      response = await request(url, method, body, requestOpts);
    } catch (error) {
      if (
        error instanceof HttpError &&
        HubbleBaseApi.isExpiredXsrfResponse(error)
      ) {
        // Refresh the XSRF token, and try again.
        await this.refreshXsrfToken();

        // N.B. if this throws again, it will throw to the caller - we don't
        // catch it here.
        response = await request(
          url,
          method,
          body,
          HubbleBaseApi.addXsrfToken(requestOpts), // Get the updated token into the headers
        );
      } else {
        // We don't know how to handle this error here - throw it to the caller
        // to take care of.
        throw error;
      }
    }

    return response;
  }

  // Response types

  /**
   * Completes a GET request to a Hubble endpoint. Uses
   * `HubbleBaseApi.request()` under the hood.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {*} opts options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async get(endpoint, body, opts = {}) {
    return this.request(endpoint, 'GET', body, opts);
  }

  /**
   * Completes a PATCH request to a Hubble endpoint. Uses
   * `HubbleBaseApi.request()` under the hood.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {Object|String} body the payload to send. Objects will be run through `JSON.stringify` automatically.
   * @param {*} opts options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}
   */
  async patch(endpoint, body, opts = {}) {
    return this.request(endpoint, 'PATCH', body, opts);
  }

  /**
   * Completes a POST request to a Hubble endpoint. Uses
   * `HubbleBaseApi.request()` under the hood.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {Object|String} body the payload to send. Objects will be run through `JSON.stringify` automatically.
   * @param {*} opts options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async post(endpoint, body, opts = {}) {
    return this.request(endpoint, 'POST', body, opts);
  }

  /**
   * Completes a PUT request to a Hubble endpoint. Uses
   * `HubbleBaseApi.request()` under the hood.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {Object|String} body the payload to send. Objects will be run through `JSON.stringify` automatically.
   * @param {*} opts options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async put(endpoint, body, opts = {}) {
    return this.request(endpoint, 'PUT', body, opts);
  }

  /**
   * Completes a DELETE request to a Hubble endpoint. Uses
   * `HubbleBaseApi.request()` under the hood.
   *
   * @param {string} endpoint the endpoint to request from. No leading slash.
   * @param {Object|String} body the payload to send. Objects will be run through `JSON.stringify` automatically.
   * @param {*} opts options. See `fetch()` init param: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
   * @throws When the core `request()` method throws (see @hubble/request), this method will throw as well, unless it's an invalid XSRF token (which we can usually recover from automatically).
   *
   * When this method throws, it is likely to throw either a {@link module:request.NetworkError} or {@link module:request.HttpError}
   * @return a promise, resolving to a {@link module:request.ValidResponse}.
   */
  async delete(endpoint, body, opts = {}) {
    return this.request(endpoint, 'DELETE', body, opts);
  }
}

export default HubbleBaseApi;
