import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

class AuthApi extends HubbleBaseApi {
  async createConfirmReset(payload) {
    return this.post('confirm-reset', payload, { overrideLoginAs: true });
  }

  async createResetRequest(payload) {
    return this.post('reset-request', payload, { overrideLoginAs: true });
  }

  // Deprecated - Use signup instead
  async createUser(payload) {
    return this.post('users', payload, { overrideLoginAs: true });
  }

  async signup(payload) {
    return this.post('auth/signup', payload, {
      overrideLoginAs: true,
    });
  }

  async getAuth() {
    return this.get('auth', {}, { overrideLoginAs: true });
  }

  async getUser(opts) {
    return this.get('auth/user', {}, { overrideLoginAs: true, ...opts });
  }

  async ghostLogin(payload) {
    return this.post('auth/login/ghost', payload, {
      overrideLoginAs: true,
    });
  }

  async login(payload) {
    return this.post('auth/login', payload, {
      overrideLoginAs: true,
    });
  }

  async logout() {
    return this.post('auth/logout', {}, { overrideLoginAs: true });
  }

  async updateUser(payload) {
    return this.put('users', payload);
  }
}

export default AuthApi;
