import { useEffect } from 'react';

import config from 'site-react/config';

const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAP_API_KEY}`;

const useGoogleMapsScript = (setIsScriptReady) => {
  useEffect(() => {
    const onLoad = () => {
      setIsScriptReady(true);
    };
    if (
      typeof window !== 'undefined' &&
      !document.querySelectorAll(`[src="${scriptSrc}"]`).length
    ) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      const headScript = document.querySelector('script');
      headScript.parentNode.insertBefore(script, headScript);
      script.addEventListener('load', onLoad);
    } else if (
      typeof window !== 'undefined' &&
      document.querySelectorAll(`[src="${scriptSrc}"]`).length
    ) {
      // If we hide the map and show it again, this component's
      // state will revert to default but the script will still
      // be on the document. This block prevents duplicate script
      // tags
      setIsScriptReady(true);
    } else {
      setIsScriptReady(false);
    }
  }, [setIsScriptReady]);
};

export default useGoogleMapsScript;
