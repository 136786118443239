import { useContext } from 'react';

import ToggleContext from '../../context/ToggleContext';

const useToggleContext = () => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error(
      'Toggle components cannot be rendered outside the Toggle component',
    );
  }
  return context;
};

export default useToggleContext;
