import { yupResolver } from '@hookform/resolvers/yup';
import { HttpError } from '@hubble/request';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Button, TextInput } from 'site-react/components/form';
import { ErrorMessage, TextWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import logError from 'site-react/helpers/logError';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('You must enter a name'),
  requesterEmail: Yup.string()
    .email('You must enter a valid email address')
    .required('You must enter an email'),
});

const spaciousApi = new SpaciousApi();

export default function Form({ onSuccess }) {
  const { formState, handleSubmit, register, setError } = useForm({
    defaultValues: {
      name: null,
      requesterEmail: null,
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit(data) {
    try {
      const response = await spaciousApi.createAdminRecommendationList(data);
      onSuccess(response.body);
    } catch (error) {
      if (error instanceof HttpError) {
        const failedFields = Object.keys(error.body || {});

        if (
          failedFields.length &&
          (failedFields.some((field) => validationSchema.fields[field]) ||
            failedFields.includes('nonFieldErrors'))
        ) {
          failedFields.forEach((field) => {
            if (validationSchema.fields[field]) {
              setError(field, {
                message: error.body[field][0],
              });
            } else if (field === 'nonFieldErrors') {
              setError('root.error', {
                message: error.body.nonFieldErrors,
              });
            }
          });
        } else {
          logError(error);
          setError('root.error', {
            message: 'An error occurred. Please try again.',
          });
        }
      } else {
        logError(error);
        setError('root.error', {
          message: 'An error occurred. Please try again.',
        });
      }
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        errorText={formState.errors.name?.message}
        isValidationManaged
        labelText="List Name"
        placeholder="e.g. Hubble’s Recommendation List"
        required
        status={formState.errors?.name?.message && 'error'}
        {...register('name')}
      />

      <VerticalSpacing size="md" />

      <TextInput
        errorText={formState.errors?.requesterEmail?.message}
        isValidationManaged
        labelText="Customer Email"
        placeholder="e.g. name@companyname.com"
        required
        status={formState.errors.requesterEmail ? 'error' : undefined}
        type="email"
        {...register('requesterEmail')}
      />

      <VerticalSpacing size="md" />

      {formState.errors?.root?.error?.message && (
        <ErrorMessage>{formState.errors.root.error.message}</ErrorMessage>
      )}

      <Button
        data-testid="button"
        disabled={formState.isSubmitting}
        isLoading={formState.isSubmitting}
        name="Create List"
        type="submit"
      >
        <TextWithIcon
          contentType="content2"
          iconName="add"
          iconPosition="left"
          iconSize="md"
          text="Create List"
        />
      </Button>
    </form>
  );
}

Form.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
