import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from 'site-react/components/page';
import Map from 'site-react/features/Map';

import styles from './MiniMap.module.css';

const MiniMap = ({ lat, lng }) => {
  const results = [
    {
      buildingLocation: {
        coordinates: [lng, lat],
      },
      id: 1,
    },
  ];

  return (
    <Grid>
      <Grid.Item colSpan="12">
        <div className={styles.MiniMap}>
          <Map gestureHandling="cooperative" results={results} />
        </div>
      </Grid.Item>
    </Grid>
  );
};

MiniMap.propTypes = {
  /**
   * Latitude of the pin
   */
  lat: PropTypes.number.isRequired,

  /**
   * Longitude of the pin
   */
  lng: PropTypes.number.isRequired,
};

export default MiniMap;
