import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './UnstyledList.module.css';

const UnstyledList = ({
  children,
  className,
  type: ListType = 'ul',
  ...otherUnstyledListProps
}) => (
  <ListType
    className={cn(styles['UnstyledList'], className)}
    {...otherUnstyledListProps}
  >
    {children}
  </ListType>
);

UnstyledList.propTypes = {
  /**
   * Children, expecting inline styled elements
   */
  children: PropTypes.node,
  /**
   * className to apply to the ul element
   */
  className: PropTypes.string,
  /**
   * any attribute native to the <ul> tag
   * can be passed as a prop and directly spread as an attribute on the component.
   */
  otherUnstyledListProps: PropTypes.any,
  /**
   * type of list to render
   */
  type: PropTypes.oneOf(['ul', 'ol']),
};

export default UnstyledList;
