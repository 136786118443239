import PropTypes from 'prop-types';
import React from 'react';

const SvgHubbleLogoText = ({ title = null, ...props }) => (
  <svg viewBox="0 0 1165 285" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{title}</title>
    <path
      d="M270.84 86.14C248 26.19 177.82-.2 117.77 0 50.93.22 11.9 39.92 2.14 105.6-7 167.21 12.91 230.3 65.69 264.29c58.17 37.46 104.61 20.5 162.83-28.95 41.96-35.63 62.25-96.94 42.32-149.2zM169.13 217a2.462 2.462 0 0 1-2.44 2.44h-12.24c-1.34 0-2.445-1.1-2.45-2.44v-54.1a2.451 2.451 0 0 0-2.44-2.44H98a2.451 2.451 0 0 0-2.44 2.44v54.6c0 1.34-1.1 2.445-2.44 2.45H80.89a2.462 2.462 0 0 1-2.44-2.45V67.14c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45v73.74c0 1.34 1.1 2.445 2.44 2.45h68.68a2.462 2.462 0 0 1 2.44 2.45zm28.64.47c0 1.34-1.1 2.445-2.44 2.45h-12.24a2.462 2.462 0 0 1-2.44-2.45v-84.22a2.462 2.462 0 0 0-2.44-2.44h-68.83a2.462 2.462 0 0 1-2.44-2.44v-12.24a2.451 2.451 0 0 1 2.44-2.44h68.82a2.462 2.462 0 0 0 2.44-2.45v-44.1c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45z"
      fill="currentColor"
    />
    <path
      d="M93.12 219.95H80.89a2.462 2.462 0 0 1-2.44-2.45V67.14c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45v73.74c0 1.34 1.1 2.445 2.44 2.45h68.68a2.462 2.462 0 0 1 2.44 2.45l.01 71.22a2.462 2.462 0 0 1-2.44 2.44h-12.24c-1.34 0-2.445-1.1-2.45-2.44v-54.1a2.452 2.452 0 0 0-2.44-2.44H98a2.452 2.452 0 0 0-2.44 2.44v54.6a2.462 2.462 0 0 1-2.44 2.45zm102.21-.03h-12.24a2.462 2.462 0 0 1-2.44-2.45v-84.22a2.462 2.462 0 0 0-2.44-2.44h-68.83a2.462 2.462 0 0 1-2.44-2.44v-12.24a2.451 2.451 0 0 1 2.44-2.44h68.82a2.462 2.462 0 0 0 2.44-2.45v-44.1c0-1.34 1.1-2.445 2.44-2.45h12.23a2.462 2.462 0 0 1 2.44 2.45l.02 150.33a2.462 2.462 0 0 1-2.44 2.45z"
      fill="#FFF"
    />
    <path
      d="M484.8,64.8h-18.9c-1.9,0-3.4,1.5-3.4,3.4V126c0,1.9-1.5,3.4-3.4,3.4h-70.3c-1.9,0-3.4-1.5-3.4-3.4V68.2 c0-1.9-1.5-3.4-3.4-3.4h-18.9c-1.9,0-3.4,1.5-3.4,3.4v149.2c0,1.9,1.5,3.4,3.4,3.4H382c1.9,0,3.4-1.5,3.4-3.4v-60.2 c0-1.9,1.5-3.4,3.4-3.4h70.3c1.9,0,3.4,1.5,3.4,3.4v60.2c0,1.9,1.5,3.4,3.4,3.4h18.9c1.9,0,3.4-1.5,3.4-3.4V68.2 C488.2,66.4,486.7,64.8,484.8,64.8L484.8,64.8z M605.6,111h-17.4c-1.9,0-3.4,1.5-3.4,3.4V165c0,23.5-11.2,36-26.8,36 c-14.1,0-22-10.8-22-28.3v-58.3c0-1.9-1.5-3.4-3.4-3.4h-17.3c-1.9,0-3.4,1.5-3.4,3.4v62.3c0,28.1,15.6,46.4,40.9,46.4 c14.1,0,25.7-6.1,32.1-17.1v11.5c0,1.9,1.5,3.4,3.4,3.4h17.4c1.9,0,3.4-1.5,3.4-3.4V114.4C609,112.5,607.4,111,605.6,111z  M692.2,108.8c-15.8,0-29.2,8.1-35.4,16.9V59.5c0-1.9-1.5-3.4-3.4-3.4h-17.4c-1.9,0-3.4,1.5-3.4,3.4v158c0,1.9,1.5,3.4,3.4,3.4h17.4 c1.9,0,3.4-1.5,3.4-3.4v-11.3c6.1,8.8,19.6,16.9,35.4,16.9c30.1,0,53-25.7,53-57.1S722.3,108.8,692.2,108.8z M687.9,201.1 c-19.6,0-33-15.4-33-35.1s13.4-35.2,33-35.2c19.3,0,32.7,15.4,32.7,35.2S707.2,201.1,687.9,201.1z M820.6,108.8 c-15.8,0-29.2,8.1-35.4,16.9V59.5c0-1.9-1.5-3.4-3.4-3.4h-17.4c-1.9,0-3.4,1.5-3.4,3.4v158c0,1.9,1.5,3.4,3.4,3.4h17.4 c1.9,0,3.4-1.5,3.4-3.4v-11.3c6.2,8.8,19.5,16.9,35.4,16.9c30.1,0,53-25.7,53-57.1S850.7,108.8,820.6,108.8z M816.2,201.1 c-19.6,0-33-15.4-33-35.1s13.4-35.2,33-35.2c19.3,0,32.7,15.4,32.7,35.2S835.5,201.1,816.2,201.1z M910.1,56h-17.4 c-1.9,0-3.4,1.5-3.4,3.4v158c0,1.9,1.5,3.4,3.4,3.4h17.4c1.9,0,3.4-1.5,3.4-3.4v-158C913.5,57.5,912,56,910.1,56z M1039.8,163.3 c0-35.4-24.2-54.5-53-54.5c-33.4,0-57.4,24.9-57.3,57.2c0.1,33,28.5,58.6,61.5,56.9c19.6-1,34.6-9.5,44.1-22.6 c0.4-0.6,0.6-1.3,0.6-2c0-1.1-0.5-2.1-1.4-2.8l-12.9-9.2c-1.5-1.1-3.6-0.7-4.7,0.8c-6,8.2-15.7,13.9-29.6,13.9 c-18.2,0-32.7-11.9-33.8-29.9h81.5c2.7,0,5-2.2,5-4.9C1039.8,165.2,1039.8,164.3,1039.8,163.3L1039.8,163.3z M954.4,153.6 c3.3-16.3,17.6-24.4,31.6-24.4s26.8,8.6,29.4,24.4H954.4z"
      fill="currentColor"
    />
  </svg>
);

SvgHubbleLogoText.propTypes = {
  /**
   * The title of this logo. Used primarily for accessibility purposes.
   */
  title: PropTypes.string,
};

export default SvgHubbleLogoText;
