import AuthApi from 'site-react/api/AuthApi';
import authService from 'site-react/auth/AuthService';

/**
 * @typedef LoginInputs
 * @property {string} email - the email to use for this login
 * @property {string} password - the password to use for this login
 * @memberof module:login
 */

/**
 * Attempt a login using the authentication API.
 *
 * @param {LoginInputs} payload - the inputs required for a login
 * @throws when the underlying `request` throws - see {@link module:request}
 * @returns {module:request.ValidResponse}
 * @private
 * @memberof module:login
 */
const attemptLogin = async ({ email, password }) => {
  const api = new AuthApi();
  const response = await api.login({ email, password });

  // TODO: remove this when login() moves inside the AuthService.
  // See EN-1232
  await authService.refresh();

  return response;
};

export default attemptLogin;
