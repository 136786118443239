export const colors = {
  blue50: '#A4FDFF',
  blue100: '#4BF4FF',
  blue100Rgb: '75, 244, 255',
  blue200: '#33E5F0',
  blue300: '#00D4DF',
  blue400: '#00A4B0',
  blue500: '#00838F',
  blue600: undefined,
  blue700: undefined,
  blue800: undefined,
  blue900: undefined,
  bluePale: '#DBFDFF',

  green50: '#D0FFF6',
  green100: '#76FDE7',
  green200: '#00F2C4',
  green300: '#00CDA1',
  green400: '#00A980',
  green500: '#008761',
  green600: '#219653',
  green700: undefined,
  green800: undefined,
  green900: undefined,

  magenta50: '#F7F2F6',
  magenta100: '#E6D9E5',
  magenta200: '#D4C1D3',
  magenta300: '#C3A9C1',
  magenta400: '#A17A9F',
  magenta500: '#90638E',
  magenta600: '#804D7D',
  magenta700: '#6F366D',
  magenta800: '#4C0649',
  magenta900: undefined,

  pink50: '#FFF2F7',
  pink100: '#FFD9E9',
  pink200: '#FFBAD7',
  pink300: '#FF99C3',
  pink400: '#FF377A',
  pink400Rgb: '255, 55, 122',
  pink500: '#E70F67',
  pink600: '#D20057',
  pink700: '#BC0047',
  pink800: undefined,
  pink900: undefined,

  purple50: '#F9F1FF',
  purple100: '#EED7FF',
  purple200: '#E4C6FF',
  purple300: '#D0A3FF',
  purple400: '#B06CFF',
  purple500: '#9842FF',
  purple600: '#8927FF',
  purple700: '#7200FF',
  purple800: undefined,
  purple900: undefined,

  red50: '#FFEAF1',
  red100: undefined,
  red200: undefined,
  red300: undefined,
  red400: undefined,
  red500: '#EE000A',
  red500Rgb: '238, 0, 10',
  red600: '#D70000',
  red700: '#C00000',
  red800: undefined,
  red900: undefined,
};

/* eslint-disable sort-keys */
export default {
  brandPrimary: colors.pink400, // #FF377A
  brandPrimaryHover: colors.pink500, // #E70F67
  brandPrimaryActive: colors.pink600, // #D20057
  brandPrimaryRgb: colors.pink400Rgb,

  brandSecondary: colors.blue100, // #4BF4FF
  brandSecondaryHover: colors.blue200, // #33E5F0
  brandSecondaryActive: colors.blue300, // #00D4DF
  brandSecondaryRgb: colors.blue100Rgb,
  brandSecondaryLight: colors.blue50, // #A4FDFF
  brandSecondaryBackground: colors.bluePale, // #DBFDFF

  error: colors.red500, // #EE000A
  errorHover: colors.red600, // #D70000
  errorActive: colors.red700, // #C00000
  errorRgb: colors.red500Rgb,
  success: colors.green500, // #008761

  ghostLogin: colors.green200, // #00F2C4

  neutral25: '#F9F9F9',
  neutral50: '#F2F3F5',
  neutral100: '#E2E5EA',
  neutral200: '#D2D8DF',
  neutral300: '#A9B2BB',
  neutral400: '#8B93A4',
  neutral500: '#6E768D',
  neutral600: '#606882',
  neutral700: '#525a77',
  neutral800: '#454c6d',
  neutral900: '#050A3A',
  neutral900Rgb: '5, 10, 58',

  black: '#000000',
  blackRgb: '0, 0, 0',
  white: '#FFFFFF',
  whiteRgb: '255, 255, 255',

  transparent: 'transparent',

  blue100: colors.blue100, // #4BF4FF
  green200: colors.green200, // #00F2C4
  green600: colors.green600, // #219653

  gradientBlue: '#EEFBFE',
  gradientPink: '#FEEFF8',
  gradientPinkBlue: `linear-gradient(#FEEFF8, #EEFBFE)`,
  gradientBluePink: `linear-gradient(#EEFBFE, #FEEFF8)`,
};
