const ARRAY_OF_TWO_STRINGS = (props, propName, componentName) => {
  if (!Array.isArray(props.labels)) {
    return new Error(
      `Expected ${propName} to be an array of two strings. Instead got ${typeof props.labels}`,
    );
  }

  if (props.labels.length !== 2) {
    return new Error(
      `Expected ${propName} to be an array of exactly 2 strings. `,
    );
  }

  if (!props.labels.every((value) => typeof value === 'string')) {
    return new Error(`Expected ${propName} to be an array of two strings.`);
  }

  return null;
};

export default ARRAY_OF_TWO_STRINGS;
