import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'site-react/components/navigation';
import { ErrorMessage } from 'site-react/components/typography';

import paymentTypes from '../../helpers/paymentTypes';

const CreditWarning = ({ isUserAdmin, paymentType }) => {
  if (isUserAdmin) {
    return (
      <ErrorMessage>
        {paymentType === paymentTypes.CREDITS ? (
          <>
            You don’t have enough credits in your account to make this booking.{' '}
            <Link href="/dashboard/pass">
              You can top up your account here.
            </Link>{' '}
          </>
        ) : (
          <>
            You don’t have enough credits in your account to make this booking.
            You can either{' '}
            <Link href="/dashboard/pass">top up your account</Link> or pay the
            full amount below.
          </>
        )}
      </ErrorMessage>
    );
  } else {
    return (
      <ErrorMessage>
        {paymentType === paymentTypes.CREDITS ? (
          <>
            You don’t have enough credits in your account to make this booking.
            Please contact your admin to add credits to your account.
          </>
        ) : (
          <>
            You don’t have enough credits in your account to make this booking.
            Please contact your admin to add credits to your account, or pay the
            full amount below.
          </>
        )}
      </ErrorMessage>
    );
  }
};

CreditWarning.propTypes = {
  isUserAdmin: PropTypes.bool.isRequired,
  paymentType: PropTypes.oneOf([
    paymentTypes.CREDITS,
    paymentTypes.CREDITS_LEGACY,
    paymentTypes.CURRENCY,
  ]),
};

export default CreditWarning;
