import HubbleBaseApi from '../HubbleBaseApi';

class LeadsApi extends HubbleBaseApi {
  /**
   * Create lead in spacious
   *
   * @param {Object} payload - the lead payload
   */
  async createLead(payload) {
    return this.post('leads', payload);
  }
}

export default LeadsApi;
