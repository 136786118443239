import { HttpError, NetworkError } from '@hubble/request';

import ShortlistApi from 'site-react/api/ShortlistApi';
import AuthService from 'site-react/auth/AuthService';
import logError from 'site-react/helpers/logError';

const handleError = (error, addError) => {
  if (error instanceof NetworkError) {
    addError(
      'Your network looks like it’s disconnected, so we couldn’t remove that from your favourites.',
    );
  } else if (error instanceof HttpError) {
    switch (error.statusCode) {
      case 401:
        // The user may have logged out in another tab.
        addError(
          'It looks like you’ve been logged out, so we couldn’t remove that from your favourites.',
        );
        break;

      default:
        addError(
          'Something went wrong, and we couldn’t remove that from your favourites. Please try again later.',
        );
        logError(error);
    }
  }
};

const attemptRemoveFromShortlist = async (shortlistId, addError) => {
  const api = new ShortlistApi();

  try {
    await api.createUnfavourite(shortlistId);
  } catch (error) {
    handleError(error, addError);
    return false;
  }

  // Force a refresh of the user - this makes sure that local storage is up
  // to date.
  AuthService.refresh();
  return true;
};

export default attemptRemoveFromShortlist;
