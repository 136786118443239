import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'site-react/components/page';
import { BottomAccessoryButton } from 'site-react/features/ImageCarousel';
import analytics from 'site-react/helpers/Analytics';

import Content from './components/Content';

const handleAnalytics = (message, leadValue, building = {}, user) => {
  const { buildingType, id, city } = building;
  // this function is used to send data to segment not hubspot
  if (message === 'onSubmit') {
    analytics.track('Download PDF Form Submitted', {
      building: id,
      buildingType: buildingType ? buildingType.key : undefined,
      city: city ? city.slug : undefined,
      location: 'download-pdf-popup',
      people: (user && user.people) || 1,
      value: leadValue,
    });
  }
};

const DownloadPDFRequest = ({ building, triggerComponent }) => (
  <Modal
    deprecatedCloseAnalytics="Download PDF Popup Dismissed"
    deprecatedOpenAnalytics="Download PDF Popup Opened"
    describedBy="dialog-download-listing-pdf-description"
    id="dialog-download-listing-pdf"
    label="Form to request a PDF copy of the current listing page"
    labelledBy="dialog-download-listing-pdf-title"
    modalName="Download PDF"
    renderTrigger={({ openModal }) =>
      triggerComponent ? (
        <div onClick={openModal}>{triggerComponent}</div>
      ) : (
        /**
         * This version of the Download PDF button can be removed once we have
         * permanently relocated Download PDF to the new sharing dropdown
         */
        <BottomAccessoryButton
          iconType="file_download"
          label="Download PDF"
          onClick={openModal}
          testId="DownloadPDFButton-button"
        />
      )
    }
  >
    <Content building={building} handleAnalytics={handleAnalytics} />
  </Modal>
);

DownloadPDFRequest.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pricePlans: PropTypes.arrayOf(
      PropTypes.shape({
        desksAvailable: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    slug: PropTypes.string,
  }).isRequired,

  /**
   * The component or node to display, which will be wrapped in an onClick
   */
  triggerComponent: PropTypes.node,
};

export default DownloadPDFRequest;
