import { useContext } from 'react';

import { ModalNewContext } from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import Form from './components/Form';

export default function AdvisorCreateRecommendationListFormModal({
  onSuccess = () => {},
}) {
  const { closeModal } = useContext(ModalNewContext);

  return (
    <>
      <Heading level="1" type="title1">
        Create a new list
      </Heading>
      <VerticalSpacing size="md" />
      <Form
        onSuccess={(newRecList) => {
          onSuccess(newRecList);
          closeModal();
        }}
      />
    </>
  );
}
