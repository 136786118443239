import PropTypes from 'prop-types';

export default PropTypes.exact({
  ancestors: PropTypes.arrayOf(
    PropTypes.exact({
      areaType: PropTypes.string,
      id: PropTypes.number,
      isAnchor: PropTypes.bool,
      iso: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  areaType: PropTypes.string,
  geoData: PropTypes.exact({
    geometry: PropTypes.exact({
      coordinates: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.number, PropTypes.number),
        ),
      ),
      type: PropTypes.string,
    }),
    properties: PropTypes.exact({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  geoJson: PropTypes.exact({
    crs: PropTypes.exact({
      properties: PropTypes.exact({
        name: PropTypes.string,
      }),
      type: PropTypes.string,
    }),
    features: PropTypes.arrayOf(
      PropTypes.exact({
        geometry: PropTypes.exact({
          coordinates: PropTypes.arrayOf(
            PropTypes.arrayOf(
              PropTypes.arrayOf(
                PropTypes.arrayOf(PropTypes.number, PropTypes.number),
              ),
            ),
          ),
          type: PropTypes.string,
        }),
        id: PropTypes.number,
        properties: PropTypes.exact({
          areaType: PropTypes.number,
          createdAt: PropTypes.string,
          geoData: PropTypes.exact({
            geometry: PropTypes.exact({
              coordinates: PropTypes.arrayOf(
                PropTypes.arrayOf(
                  PropTypes.arrayOf(PropTypes.number, PropTypes.number),
                ),
              ),
              type: PropTypes.string,
            }),
            properties: PropTypes.exact({
              color: PropTypes.string,
              name: PropTypes.string,
            }),
            type: PropTypes.string,
          }),
          hasLandingPage: PropTypes.bool,
          isAnchor: PropTypes.bool,
          isHblShape: PropTypes.bool,
          iso: PropTypes.string,
          isSitemapIndexable: PropTypes.bool,
          isVisibleInAreaParentDropdown: PropTypes.bool,
          isVisibleInListingsDropdown: PropTypes.bool,
          level: PropTypes.number,
          lft: PropTypes.number,
          name: PropTypes.string,
          oldId: PropTypes.string,
          parent: PropTypes.number,
          pk: PropTypes.string,
          rght: PropTypes.number,
          slug: PropTypes.string,
          sourceUrl: PropTypes.string,
          treeId: PropTypes.number,
          updatedAt: PropTypes.string,
        }),
        type: PropTypes.string,
      }),
    ),
    type: PropTypes.string,
  }),
  hasLandingPage: PropTypes.bool,
  hasLandingPageOverride: PropTypes.string,
  id: PropTypes.number,
  isAnchor: PropTypes.bool,
  iso: PropTypes.string,
  isSitemapIndexable: PropTypes.bool,
  meta: PropTypes.exact({
    area: PropTypes.number,
    coverImg: PropTypes.any,
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    guide: PropTypes.string,
    id: PropTypes.number,
    postcodeRegex: PropTypes.any,
    timeZone: PropTypes.any,
    unitOfMeasure: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  name: PropTypes.string,
  oldId: PropTypes.string,
  parent: PropTypes.number,
  parentInfo: PropTypes.exact({
    id: PropTypes.number,
    iso: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.any,
    slug: PropTypes.string,
  }),
  slug: PropTypes.string,
  stats: PropTypes.exact({
    area: PropTypes.number.isRequired,
    averagePricePerFullTimeOfficeDesk: PropTypes.number,
    buildingFacilities: PropTypes.object,
    coworkingAveragePrice: PropTypes.number,
    coworkingTotal: PropTypes.number,
    fullTimeOfficesTotal: PropTypes.number,
    id: PropTypes.number.isRequired,
    meetingRoomsAveragePrice: PropTypes.number,
    meetingRoomsTotal: PropTypes.number,
    onDemandFacilities: PropTypes.object,
    partTimeOfficesTotal: PropTypes.number,
    privateDayOfficesTotal: PropTypes.number,
    topFacilitiesCsv: PropTypes.string,
  }).isRequired,
});
