import { useEffect, useRef } from 'react';

/**
 * Returns previous value of the state parameter passed in.
 *
 * @param {*} newValue - A value that you want to track the previous state of.
 * For simplicity, this method only returns the previous value rather than validating
 * any change in status. You will have to do that yourself wherever you have used this component.
 */
const usePrevious = (newValue) => {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = newValue;
  }, [newValue]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
