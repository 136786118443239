import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './AccountIconImg.module.css';

/* NOTE: We have two containers here to fix the cross browser hover issue
Firefox has some rendering issues due to the transform property,
we use it to vertical align our navbar */
const AccountIconImg = ({ alt, hover, size, src }) => {
  const baseSrc = `${src}${
    src.includes('?') ? '&' : '?'
  }h=${size}&w=${size}&fit=crop&crop=faces&fm=png32&auto=format&lossless=true`;

  return (
    <div
      className={styles.AccountIconImg}
      data-testid="account-icon"
      size={size}
    >
      <div
        className={cn(styles['AccountIconImg-imageContainer'], {
          [styles['AccountIconImg-imageContainer--hover']]: hover,
        })}
        style={{
          '--AccountIconImg-imgSize': `${size + 6}px`,
        }}
      >
        <img
          alt={alt}
          className={styles['AccountIconImg-img']}
          src={baseSrc}
          srcSet={`${baseSrc} 1x, ${baseSrc}&dpr=2 2x`}
        />
      </div>
    </div>
  );
};

AccountIconImg.propTypes = {
  /**
   * Alt text to show
   */
  alt: PropTypes.string.isRequired,

  /**
   * Should this icon have a special style on hover?
   */
  hover: PropTypes.bool.isRequired,

  /**
   * Width and height of this icon, in pixels.
   */
  size: PropTypes.number.isRequired,

  /**
   * The source path of the icon.
   */
  src: PropTypes.string.isRequired,
};

export default AccountIconImg;
