import { useMemo } from 'react';

import { Badges } from 'site-react/components/typography';
import { ProductPriceFormatter } from 'site-react/helpers/productPriceFormatter';
import usePriceType from 'site-react/hooks/usePriceType';

import styles from './MeetingRoomProductList.module.css';
import MeetingRoomBookButton from '../MeetingRoomBookButton/MeetingRoomBookButton';

const MeetingRoomProductList = ({ products, isInstantBookAvailable }) => {
  const priceType = usePriceType();

  const hasDailyPrice = products.some((product) => !!product.pricePerDay);
  const hasPerHourPrice = products.some((product) => !!product.pricePerHour);

  const filteredProducts = useMemo(() => {
    // if instant book is available only
    // displays products that can actually be
    // instantly booked
    if (isInstantBookAvailable) {
      return products.filter((product) => product.isInstantlyBookable);
    } else {
      return products;
    }
  }, [isInstantBookAvailable, products]);

  const priceFormatter = new ProductPriceFormatter('en-GB', 'GBP');

  return (
    <table className={styles.MeetingRoomProductList}>
      <thead className={styles['MeetingRoomProductList-header']}>
        <tr>
          <th />
          <th>Hourly</th>
          <th className={styles['MeetingRoomProductList-headerDaily']}>
            Daily{' '}
            {hasDailyPrice && hasPerHourPrice && (
              <Badges.Badge
                color="green-600"
                isBorderVariant
                isInverted
                label="Best Value"
              />
            )}
          </th>
          <th />
        </tr>
      </thead>
      <tbody className={styles['MeetingRoomProductList-body']}>
        {filteredProducts.map((product) => {
          const pricePerHour =
            product[priceFormatter.meetingRoomPriceConfig.perHour[priceType]];
          const pricePerDay =
            product[priceFormatter.meetingRoomPriceConfig.perDay[priceType]];

          const meetingRoomPricePerHour =
            ![null, undefined].includes(pricePerHour) &&
            priceFormatter.getFormattedPrice(pricePerHour, priceType);
          const meetingRoomPricePerDay =
            ![null, undefined].includes(pricePerDay) &&
            priceFormatter.getFormattedPrice(pricePerDay, priceType);

          return (
            <tr
              aria-label={`${product?.capacity} Person Meeting Room`}
              key={product.id}
            >
              <td>
                <strong className={styles['MeetingRoomProductList-capacity']}>
                  {product.capacity} person
                </strong>
                <div
                  className={styles['MeetingRoomProductList-mobilePrices']}
                  data-testid="MobilePrice"
                >
                  {meetingRoomPricePerHour && (
                    <strong>{`${meetingRoomPricePerHour} / hour`}</strong>
                  )}
                  {meetingRoomPricePerDay && (
                    <strong>{`${meetingRoomPricePerDay} / day`}</strong>
                  )}
                </div>
              </td>
              <td
                className={styles['MeetingRoomProductList-priceDetail']}
                data-testid="DesktopPricePerHour"
              >
                <strong>
                  {meetingRoomPricePerHour
                    ? `${meetingRoomPricePerHour} / hour`
                    : '-'}
                </strong>
              </td>
              <td
                className={styles['MeetingRoomProductList-priceDetail']}
                data-testid="DesktopPricePerDay"
              >
                <strong>
                  {meetingRoomPricePerDay
                    ? `${meetingRoomPricePerDay} / day`
                    : '-'}
                </strong>
              </td>
              <td className={styles['MeetingRoomProductList-button']}>
                <MeetingRoomBookButton product={product} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MeetingRoomProductList;
