import { useState, useEffect } from 'react';

const useSegmentDestinations = (writeKey) => {
  const [destinations, setDestinations] = useState([]);

  async function getDestinations() {
    const res = await fetch(
      `https://cdn.segment.com/v1/projects/${writeKey}/integrations`,
    );

    if (!res.ok) {
      throw new Error(
        `Failed to fetch integrations: HTTP ${res.status} ${res.statusText}`,
      );
    }

    const response = await res.json();

    // Rename creationName to id to abstract the weird data model
    for (const destination of response) {
      destination.id = destination.creationName;
      delete destination.creationName;
    }

    setDestinations(response);
  }

  useEffect(() => {
    getDestinations();
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return destinations;
};

export default useSegmentDestinations;
