import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Heading, RuledHeading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import useFacilitiesList from 'site-react/hooks/useFacilitiesList';

import { PassFacilitiesList } from '../FacilitiesList';

const useFacilities = (building) => {
  const facilitiesList = useFacilitiesList({ isAvailableOnPass: true });

  const reduceFacilities = useCallback(
    (facilities) => {
      return facilities.reduce((reducedFacilities, slug) => {
        const facility = facilitiesList.filter(
          (facility) => facility.slug === slug,
        )[0];

        if (facility) {
          reducedFacilities.push(facility);
        }
        return reducedFacilities;
      }, []);
    },
    [facilitiesList],
  );

  const extraFacilities = useMemo(() => {
    if (!Array.isArray(facilitiesList)) {
      return [];
    }

    if (Array.isArray(building?.onDemandProduct?.extraPassFacilities)) {
      return reduceFacilities(building.onDemandProduct.extraPassFacilities);
    }

    return [];
  }, [building, facilitiesList, reduceFacilities]);

  const includedFacilities = useMemo(() => {
    if (!Array.isArray(facilitiesList)) {
      return [];
    }

    if (Array.isArray(building?.onDemandProduct?.includedPassFacilities)) {
      return reduceFacilities(building.onDemandProduct.includedPassFacilities);
    }

    return [];
  }, [building, facilitiesList, reduceFacilities]);

  return { extraFacilities, includedFacilities };
};

const PassFacilities = ({ building }) => {
  const { extraFacilities, includedFacilities } = useFacilities(building);

  return (
    <>
      <RuledHeading level="2">Building Facilities</RuledHeading>
      <VerticalSpacing size="md" />

      {includedFacilities.length > 0 && (
        <>
          <Heading level="3" type="title4">
            Included with your day pass
          </Heading>
          <VerticalSpacing size="md" />

          <PassFacilitiesList facilities={includedFacilities} />
        </>
      )}

      {extraFacilities.length > 0 && (
        <>
          <VerticalSpacing size="xl" />

          <Heading level="3" type="title4">
            Available at extra cost
          </Heading>
          <VerticalSpacing size="lg" />

          <PassFacilitiesList facilities={extraFacilities} />
        </>
      )}
    </>
  );
};

PassFacilities.propTypes = {
  /**
   * Building object returned by PassApi.
   */
  building: PropTypes.shape({
    extraFacilities: PropTypes.arrayOf(PropTypes.string),
    includedFacilities: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PassFacilities;
