import config from 'site-react/config';
import { defaultIntegrations } from 'site-react/data/analytics/integrations';

import cookieTypes from './cookieTypes';

function loadAnalytics(destinations, preferences) {
  if (preferences) {
    preferences.forEach((currentPreference) => {
      if (currentPreference.name === cookieTypes.marketingAndAnalytics) {
        defaultIntegrations['Segment.io'] = true;
      }
    });
  }

  const integrations = { ...defaultIntegrations };
  let areAnyIntegrationsEnabled = false;

  const allowedDestinationCategories = preferences.reduce((acc, curr) => {
    acc.push(...curr.categories);
    return acc;
  }, []);

  for (const destination of destinations) {
    const isEnabled = allowedDestinationCategories.includes(
      destination.category,
    );

    integrations[destination.id] = isEnabled;

    if (isEnabled) {
      areAnyIntegrationsEnabled = true;
    }
  }

  // Most of these are required for hot-reloading issues.
  if (areAnyIntegrationsEnabled && window.analytics && window.analytics.load) {
    window.analytics.load(config.CUSTOMER_EVENTS_SEGMENT_TOKEN, {
      integrations,
    });
  }
}

export default loadAnalytics;
