import debounce from 'lodash.debounce';

import getCoordinates from './getCoordinates';
/**
 * The callback to be fired when the dragend event is fired by google maps.
 * It gets the coordinates of the bounds of the map in current view, and calls
 * setMapQuery with an object containing the coordinates as the only parameter.
 * @param {Object} map - the google map
 * @param {function} setMapQuery - the function to update the query, passed in from outside hooks.
 */
const mapPostionChangeCallback = (map, setMapQuery) => {
  const coordinates = getCoordinates(map);
  setMapQuery({
    coordinates,
    locationType: 'Polygon',
    searchAreaId: null,
  });
};

/**
 * Adds additional event listeners for maps with searching capability.
 * @param {Boolean} isSearchable - a toggle to determine whether we want searches to be triggered by moving the map.
 * @param {Object} map - the google map.
 * @param {function} setListeners - a setter for setting the current state of the listeners.
 * @param {Function} setActiveId - callback for setting the active id highlighted on the map. controls nanocard, active pin.
 * @param {function} setMapQuery - the function to update the query, passed in from outside hooks.
 * @returns {Array<Object>} - an array of the new listeners objects.
 */
const addMapListeners = (
  isFittingBoundsRef,
  isSearchable,
  map,
  setActiveId,
  setMapQuery,
) => {
  const listeners = [];
  // Hide the current nano card, deselect active pin.
  listeners.push(map.addListener('touchstart', () => setActiveId(null)));
  listeners.push(map.addListener('mousedown', () => setActiveId(null)));
  if (isSearchable) {
    listeners.push(
      // after 200 miliseconds, start a new search
      map.addListener(
        'dragend',
        debounce(() => mapPostionChangeCallback(map, setMapQuery), 200),
      ),
    );

    // after 200 miliseconds, start a new search
    const zoomChanged = debounce(() => {
      setActiveId();
      mapPostionChangeCallback(map, setMapQuery);
    }, 200);

    listeners.push(
      map.addListener('zoom_changed', () => {
        // only trigger the zoom_changed callback if we're not currently
        // adjusting the map's viewport to make all the pins visible
        if (!isFittingBoundsRef.current) {
          zoomChanged();
        }
      }),
    );
  }

  return listeners;
};

/**
 * Removes event listeners from the map.
 * @param {Object} GoogleMap - the google maps api
 * @param {Array<Object>} listeners - an array of listeners currently active on the map.
 * @param {function} setListeners - a setter for setting the current state of the listeners.
 */
const removeMapListeners = (GoogleMap, listeners) => {
  if (listeners.length) {
    listeners.forEach((listener) => GoogleMap.event.removeListener(listener));
  }
};

export { addMapListeners, mapPostionChangeCallback, removeMapListeners };
