/**
 * ░░░░░▄▀▀▀▄░░░░░░░░░░░░░░░░░
 * ▄███▀░◐░░░▌░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▐░░░░░▐░░░░░░░░░░░░░░░░
 * ░░░░▌░░░░░▐▄▄░░░░░░░░░░░░░░
 * ░░░░▌░░░░▄▀▒▒▀▀▀▀▄░░░░░░░░░
 * ░░░▐░░░░▐▒▒▒▒▒▒▒▒▀▀▄░░░░░░░
 * ░░░▐░░░░▐▄▒▒▒▒▒▒▒▒▒▒▀▄░░░░░
 * ░░░░▀▄░░░░▀▄▒▒▒▒▒▒▒▒▒▒▀▄░░░
 * ░░░░░░▀▄▄▄▄▄█▄▄▄▄▄▄▄▄▄▄▄▀▄░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░░░▌▌░▌▌░░░░░░░░░░░
 * ░░░░░░░░░▄▄▌▌▄▌▌░░░░░░░░░░░
 *
 * MIGRATION NOTICE
 *
 * We are in the process of re-writing our components using function components
 * and React Hooks, rather than extending Component.
 *
 * We have found that hooks make our components better, and - once you get the
 * hang of them - produce fewer bugs and edge cases than conventional lifecycle
 * methods (like componentDidMount and getDerivedStateFromProps).
 *
 * However, hooks can’t be used from class components. Therefore, we are trying
 * to eliminate class components wherever we can.
 *
 * If you’re changing this file, please consider contributing to this effort by
 * changing this component to be a function that uses hooks, and then removing
 * this notice.
 */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  componentDidMount() {
    const { selector = 'body' } = this.props;
    this.element = document.querySelector(selector);
    this.forceUpdate();
  }

  render() {
    const { children = null } = this.props;

    if (!this.element) {
      return null;
    }

    return ReactDOM.createPortal(children, this.element);
  }
}

Portal.propTypes = {
  children: PropTypes.node,
  selector: PropTypes.string,
};

export default Portal;
