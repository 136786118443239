import PropTypes from 'prop-types';
import React from 'react';

import { ErrorMessage } from 'site-react/components/typography';

import styles from './MultipleSelect.module.css';
import Label from '../Label';

/**
 * A <select> element with the multiple attribute applied, styled to look like a Hubble input element.
 */
const MultipleSelect = ({
  children,
  errorText = null,
  labelText = '',
  status = null,
  ...otherSelectOptions
}) => {
  const inputElement = (
    <select
      className={styles['MultipleSelect-display']}
      multiple
      {...otherSelectOptions}
    >
      {children}
    </select>
  );

  return (
    <>
      {labelText && <Label labelText={labelText}>{inputElement}</Label>}
      {!labelText && inputElement}
      {status === 'error' && errorText !== null && (
        <ErrorMessage scrollIntoView>{errorText}</ErrorMessage>
      )}
    </>
  );
};

export default MultipleSelect;

MultipleSelect.propTypes = {
  /**
   * Options to show inside this MultipleSelect. Should be <option> or <optgroup>
   * elements.
   *
   * If you want to show a different value inside the dropdown vs. what's shown
   * when the dropdown is closed, you can attach a data-display-value attribute
   *
   * For example, if you have a country code dropdown, and you want it to show
   * `+44` when it's closed, but inside the dropdown, you want to show
   * `United Kingdom +44`, you could use:
   * ```html
   * <option value="+44" data-display-value="+44">United Kingdom +44</option>
   * ```
   */
  children: PropTypes.node,

  /**
   * Message to show if the status is set to 'error'.
   */
  errorText: PropTypes.string,

  /**
   * What to display above the input field.
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Used to dynamically display error messages.
   */
  status: PropTypes.oneOf(['error', 'success']),
};
