import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Spinner.module.css';

// Spinner derived from http://tobiasahlin.com/spinkit/

/**
 * A simple spinner. Used to denote a loading state.
 *
 * Note: the color of the Spinner always matches the text color of its parent.
 *
 * To use a custom color, wrap it in an element (like a `div`) with
 * `color: var(--color-brandprimary);` set.
 */
const Spinner = ({ size = 'normal' }) => {
  const sizeClassName =
    size === 'small' ? styles['Spinner--small'] : styles['Spinner--normal'];

  return (
    <div className={cn(styles['Spinner'], sizeClassName)} data-testid="Spinner">
      <div className={cn(styles['Spinner-circle1'])} />
      <div className={cn(styles['Spinner-circle2'])} />
      <div className={cn(styles['Spinner-circle3'])} />
    </div>
  );
};

Spinner.propTypes = {
  /**
   * The size of the spinner
   */
  size: PropTypes.oneOf(['small', 'normal']),
};

export default Spinner;
