import PropTypes from 'prop-types';
import React from 'react';

import styles from './CardGrid.module.css';
/**
 * `CardGrid` is used to display components (usually `ListingCards`) in a
 * flexible grid layout.
 */
const CardGrid = ({ children }) => (
  <div className={styles['CardGrid']} data-testid="CardGrid">
    {children}
  </div>
);

CardGrid.Item = ({ children }) => (
  <div className={styles['CardGrid-item']} data-testid="CardGrid.Item">
    {children}
  </div>
);

CardGrid.propTypes = {
  /**
   * Any number of grid items (usually CardGrid.Item components), each
   * containing a child component (usually a ListingCard) to be displayed in
   * the grid.
   */
  children: PropTypes.node.isRequired,
};

CardGrid.Item.propTypes = {
  /**
   * Element to display in a grid item, usually a ListingCard.
   */
  children: PropTypes.node.isRequired,
};

export default CardGrid;
