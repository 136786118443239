import { css, cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import theme from 'site-react/theme';

const buttonBorderWidth = theme.spacing.base / 2;
const buttonSizeMobile = theme.spacing.xxl - buttonBorderWidth * 2;
const buttonSizeDesktop = theme.spacing.base * 12 - buttonBorderWidth * 2;
const buttonIconPosition = theme.spacing.base + 1;

export const baseButtonStyle = css`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${buttonSizeMobile}px;
  justify-content: center;
  overflow: hidden;
  transition-duration: var(--animation-short);
  transition-timing-function: linear;
  width: ${buttonSizeMobile}px;

  :disabled {
    opacity: 0.3;
  }

  @media (min-width: 768px) {
    height: ${buttonSizeDesktop}px;
    width: ${buttonSizeDesktop}px;
  }
`;

export const buttonStyle = css`
  ${baseButtonStyle};
  background-color: var(--color-white);
  color: var(--color-neutral-900);
  transition-property: color;

  :hover:not(:disabled),
  :focus:not(:disabled) {
    color: var(--color-neutral-800);
  }

  :active:not(:disabled) {
    color: var(--color-neutral-700);
  }
`;

export const invertedButtonStyle = css`
  ${baseButtonStyle};
  background-color: var(--color-neutral-900);
  color: var(--color-white);
  transition-property: background-color;

  :hover:not(:disabled),
  :focus:not(:disabled) {
    background-color: var(--color-neutral-800);
  }

  :active:not(:disabled) {
    background-color: var(--color-neutral-700);
  }
`;

export const buttonSmallStyle = css`
  @media (min-width: 768px) {
    height: ${buttonSizeMobile}px;
    width: ${buttonSizeMobile}px;
  }
`;

export const buttonIconStyle = (iconPosition) => css`
  font-size: var(--font-size-xl);
  height: var(--space-md);
  line-height: var(--space-md);
  margin: 0;
  padding: 0;
  position: relative;
  right: ${iconPosition}px;
  width: var(--space-md);
`;

export const buttonWrapperStyle = css`
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const nextButtonWrapperStyle = css`
  ${buttonWrapperStyle};
  right: var(--space-sm);

  @media (min-width: 768px) {
    right: var(--space-xl);
  }
`;

export const nextButtonWrapperSmallStyle = css`
  @media (min-width: 768px) {
    right: var(--space-sm);
  }
`;

export const previousButtonWrapperStyle = css`
  ${buttonWrapperStyle};
  left: var(--space-sm);

  @media (min-width: 768px) {
    left: var(--space-xl);
  }
`;

export const previousButtonWrapperSmallStyle = css`
  @media (min-width: 768px) {
    left: var(--space-sm);
  }
`;

const ImageCarouselMoveButton = ({
  direction,
  disabled = false,
  isExpandedView = false,
  onClick = () => {},
  smallArrowButtons = false,
}) => {
  const iconType = direction === 'next' ? 'navigate_next' : 'navigate_before';
  const btnStyle = isExpandedView ? invertedButtonStyle : buttonStyle;

  return (
    <span
      /* eslint-disable sort-keys */
      className={cx({
        [nextButtonWrapperStyle]: direction === 'next',
        [nextButtonWrapperSmallStyle]:
          direction === 'next' && smallArrowButtons,

        [previousButtonWrapperStyle]: direction === 'previous',
        [previousButtonWrapperSmallStyle]:
          direction === 'previous' && smallArrowButtons,
      })}
      /* eslint-enable sort-keys */
    >
      <button
        aria-label={`${direction} image`}
        className={cx(btnStyle, {
          [buttonSmallStyle]: smallArrowButtons,
        })}
        disabled={disabled}
        onClick={onClick}
        type="button"
      >
        <MaterialIcon
          className={
            iconType === 'navigate_before'
              ? buttonIconStyle(buttonIconPosition + 1)
              : buttonIconStyle(buttonIconPosition)
          }
          iconType={iconType}
        />
      </button>
    </span>
  );
};

ImageCarouselMoveButton.propTypes = {
  /**
   * The direction of this button.
   */
  direction: PropTypes.oneOf(['next', 'previous']).isRequired,

  /**
   * Is this button disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Is the carousel expanded?
   */
  isExpandedView: PropTypes.bool,

  /**
   * Callback when the button activates.
   */
  onClick: PropTypes.func,

  /**
   * Is this button in the small style?
   */
  smallArrowButtons: PropTypes.bool,
};

export default ImageCarouselMoveButton;
