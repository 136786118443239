import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'site-react/components/form';
import { Grid, Modal } from 'site-react/components/page';
import { Heading, RuledHeading } from 'site-react/components/typography';
import { UnstyledList } from 'site-react/components/utility';
import Map from 'site-react/features/Map';
import TransitStop from 'site-react/features/TransitStop';
import transformLandmarksToNearbyLocations from 'site-react/helpers/transformLandmarksToNearbyLocations';

import styles from './Location.module.css';
import MiniMap from './MiniMap';

const Location = ({ buildingName, isPass, landmarks, lat, lng }) => {
  const nearbyLocations = transformLandmarksToNearbyLocations(landmarks, 3);

  const results = [
    {
      buildingLocation: {
        coordinates: [lng, lat],
      },
      id: 1,
    },
  ];

  return (
    <Grid>
      <Grid.Item colSpan="12">
        <RuledHeading level="2">Location</RuledHeading>
      </Grid.Item>
      <Grid.Item colSpan="12">
        <Modal
          isFullScreen
          modalName="Open map"
          renderTrigger={({ openModal }) => (
            <Grid.Item colSpan="12">
              <div className={styles['Location-map']}>
                <MiniMap buildingName={buildingName} lat={lat} lng={lng} />
                <div className={styles['Location-mapButton']}>
                  <Button
                    isCompact
                    name="Open map"
                    onClick={openModal}
                    styleType="white"
                    type="button"
                  >
                    Open map
                  </Button>
                </div>
              </div>
            </Grid.Item>
          )}
        >
          <Grid>
            <Grid.Item align="end" colSpan="12" justify="center" rowSpan="4">
              <Heading level="2" type="title1">
                {buildingName}
              </Heading>
            </Grid.Item>
            <Grid.Item colSpan="12">
              <div className={styles['Location-expandedMap']}>
                <Map results={results} />
              </div>
            </Grid.Item>
          </Grid>
        </Modal>
      </Grid.Item>
      <Grid.Item colSpan="12">
        <UnstyledList
          aria-label="Nearby Transit Stops"
          className={styles['Location-nearbyTransitStops']}
          type="ol"
        >
          {nearbyLocations.length
            ? nearbyLocations
                .filter(({ kind }) => kind === 'transit-stop')
                .map(({ isSearchArea, lines, name, slug, walkingMinutes }) => (
                  <li aria-label="Transit Stop" key={slug}>
                    <TransitStop
                      isPass={isPass}
                      isSearchArea={isSearchArea}
                      lines={lines}
                      name={name}
                      slug={slug}
                      walkingMinutes={walkingMinutes}
                    />
                  </li>
                ))
            : null}
        </UnstyledList>
      </Grid.Item>
    </Grid>
  );
};

Location.propTypes = {
  isPass: PropTypes.bool,
  landmarks: PropTypes.arrayOf(
    PropTypes.shape({
      distance: PropTypes.number.isRequired,
      landmark: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            img: PropTypes.string.isRequired,
          }),
        ),
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default Location;
