import colors from 'site-react/theme/colors';

const polygonStyle = {
  clickable: false,
  fillColor: colors.brandSecondary,
  fillOpacity: 0.25,
  strokeColor: colors.brandSecondary,
  strokeOpacity: 1,
  strokeWeight: 2,
};

export default polygonStyle;
