/*
 * Wherever possible, we want to use shadows from this file.
 *
 * Using out-of-band drop shadows can create an unusual, visually-confusing
 * experience. Shadows come from light sources and distance; those should not
 * change arbitrarily from component to component.
 */

import colors from './colors';

export default {
  focus: `0 0 4px 6px rgba(${colors.neutral900Rgb}, 0.2)`,
  raised: `0 60px 80px 0 rgba(${colors.neutral900Rgb}, 0.1)`,
  resting: `0 10px 20px 0 rgba(${colors.neutral900Rgb}, 0.1)`,
  subtle: `0 2px 4px 0 rgba(${colors.neutral900Rgb}, 0.1)`,
};
