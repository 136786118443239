import { Link, OutboundLink } from 'site-react/components/navigation';
import { Heading } from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';

import links from './data/links';
import styles from './UsefulLinks.module.css';

export default function UsefulLinks() {
  return (
    <UnstyledList className={styles.UsefulLinks}>
      {links.map((section) => {
        return (
          <li className={styles['UsefulLinks-section']} key={section.title}>
            <section>
              <Heading type="title4">{section.title}</Heading>
              <VerticalSpacing size="lg" />
              <UnstyledList>
                {section.links.map((link) => {
                  const LinkElement = link.target ? OutboundLink : Link;

                  return (
                    <li className={styles['UsefulLinks-link']} key={link.href}>
                      <LinkElement
                        href={link.href}
                        isUnderlined={false}
                        styleType="onDark"
                        target={link.target ?? null}
                      >
                        {link.displayContent}
                      </LinkElement>
                    </li>
                  );
                })}
              </UnstyledList>
            </section>
          </li>
        );
      })}
    </UnstyledList>
  );
}
