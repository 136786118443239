import PropTypes from 'prop-types';
import { useContext } from 'react';

import { CloseButton } from 'site-react/components/navigation';
import { UICard } from 'site-react/components/page';
import { DrawerContext } from 'site-react/components/page/Drawer';
import {
  Heading,
  ListItemWithIcon,
  Paragraph,
} from 'site-react/components/typography';
import {
  ImgixImage,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';
import { ADVISOR_GENERIC_IMAGE_SRC } from 'site-react/data/advisors';

import styles from './Success.module.css';

export default function Success({ advisor, viewingRequestedItems }) {
  const { closeDrawer } = useContext(DrawerContext);

  return (
    <div className={styles.Success}>
      <div className={styles['Success-heading']}>
        <div className={styles['Success-advisor']}>
          <ImgixImage
            alt="Advisor"
            height={80}
            src={advisor?.image ?? ADVISOR_GENERIC_IMAGE_SRC}
            width={advisor?.image ? 80 : 217}
          />
          <Heading type="title3">
            “I’ll get your viewing(s) booked ASAP”
          </Heading>
          {advisor && (
            <Paragraph>
              <b>{advisor.name.split(' ')[0]}</b>
            </Paragraph>
          )}
        </div>
        <div className={styles['Success-close']}>
          <CloseButton onClick={closeDrawer} />
        </div>
      </div>

      <VerticalSpacing size="md" />

      <UICard>
        <UnstyledList>
          {viewingRequestedItems.map((item) => (
            <li className={styles['Success-building']} key={item.id}>
              <span>{item.building.name}</span>
            </li>
          ))}
        </UnstyledList>
      </UICard>

      <VerticalSpacing size="md" />

      <Heading type="title3">Here’s what will happen next</Heading>

      <VerticalSpacing size="md" />

      <UnstyledList type="ol">
        <ListItemWithIcon
          bubbleColor="neutral-900"
          contentType="content3"
          heading={`${advisor ? advisor.name.split(' ')[0] : 'Your advisor'} will book your viewings`}
          iconColor="white"
          number="1"
          text="If you’ve booked multiple viewings these will be in the best order with plenty of travel time in-between"
        />
        <ListItemWithIcon
          bubbleColor="neutral-900"
          contentType="content3"
          heading="You’ll receive confirmation emails"
          iconColor="white"
          number="2"
          text="These will be sent shortly and include everything you need to know about your viewing(s)"
        />
        <ListItemWithIcon
          bubbleColor="neutral-900"
          contentType="content3"
          heading="You’ll receive a schedule summary"
          iconColor="white"
          number="3"
          text="Your advisor will reach out to confirm your viewing schedule"
        />
      </UnstyledList>
    </div>
  );
}

Success.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),

  viewingRequestedItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),
};
