import { useContext } from 'react';

import { CookiePreferencesContext } from 'site-react/data/core/CookiePreferencesContext';

function useCookiePreferences() {
  const context = useContext(CookiePreferencesContext);
  if (!context) {
    throw new Error(
      'useCookiePreferences must be used within a CookiePreferences provider',
    );
  }
  return context;
}

export default useCookiePreferences;
