import { createContext } from 'react';

const Context = createContext({
  hasPersona: false,
  persistPersona: () => {},
  persona: {},
  updatePersona: () => {},
});

const { Provider } = Context;

export { Context, Provider };
