import { useContext } from 'react';

import { ViewingRequestContext } from 'site-react/data/listing/ViewingRequestContext';

function usePricePlan() {
  const context = useContext(ViewingRequestContext);
  if (!context) {
    throw new Error(
      'usePricePlan must be used within a ViewingRequestProvider',
    );
  }
  return context;
}

export default usePricePlan;
