import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from 'site-react/components/typography';

import styles from './ListItemWithIcon.module.css';

/**
 * Component which displays a listitem using a Material Icon as a custom bullet.
 * This makes using a custom bullet in a list more accessible and ensures that content text
 * wraps as we would expect it would when using the default bullet styling.
 *
 * This component should _always_ be used inside of an ordered or unordered list.
 */
const ListItemWithIcon = ({
  bubbleColor = null,
  color = null,
  contentType = 'content1',
  heading = null,
  iconColor = null,
  iconName = null,
  number = null,
  text = null,
}) => {
  const hasHeading = Boolean(heading && heading.length > 0);
  const isNumbered = Boolean(number && number.length > 0);
  const hasBubbleBackground = Boolean(bubbleColor && bubbleColor.length !== 0);

  return (
    <li
      className={cn(styles['ListItemWithIcon'], {
        [styles['ListItemWithIcon--content1']]: contentType === 'content1',
        [styles['ListItemWithIcon--content2']]: contentType === 'content2',
        [styles['ListItemWithIcon--content3']]: contentType === 'content3',
        [styles['ListItemWithIcon--hasHeading']]: hasHeading,
        [styles['ListItemWithIcon--hasBubbleBackground']]: hasBubbleBackground,
        [styles['ListItemWithIcon--isNumbered']]: isNumbered,
      })}
      style={{
        '--ListItemWithIcon-bubbleColor': `var(--color-${bubbleColor})`,
        '--ListItemWithIcon-iconColor': `var(--color-${iconColor})`,
        '--ListItemWithIcon-iconContent': `'${isNumbered ? number : iconName}'`,
      }}
    >
      {hasHeading && (
        <Heading level="3" type="title4">
          {heading}
        </Heading>
      )}
      {typeof text === 'string' ? (
        <p
          className={cn(styles['ListItemWithIcon-paragraph'], {
            [styles['ListItemWithIcon-paragraph--content1']]:
              contentType === 'content1',
            [styles['ListItemWithIcon-paragraph--content2']]:
              contentType === 'content2',
            [styles['ListItemWithIcon-paragraph--content3']]:
              contentType === 'content3',
          })}
          style={{
            color: `var(--color-${color})`,
          }}
        >
          {text}
        </p>
      ) : (
        text
      )}
    </li>
  );
};

ListItemWithIcon.propTypes = {
  /**
   * The color of the solid background bubble around the icon
   * "bubbleColor" should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  bubbleColor: PropTypes.string,
  /**
   * Typography rules to apply to the content.
   */
  contentType: PropTypes.oneOf(['content1', 'content2', 'content3']),
  /**
   * If set, will use separate icon color to the text.
   * See theme/colors.js for available colors
   */
  heading: PropTypes.string,
  /**
   * The number to display in place of the icon
   * should be a `--color-{substring}` substring of the color name in theme/colors.css
   */
  iconColor: PropTypes.string,
  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconName: PropTypes.string,
  /**
   * Heading content to be displayed alongside the icon, above the text
   */
  number: PropTypes.string,
  /**
   * Text content to be displayed inside the list item
   */
  text: PropTypes.node,
};

export default ListItemWithIcon;
