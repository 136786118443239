import PropTypes from 'prop-types';

import { UnstyledList } from 'site-react/components/utility';

import AdvisorRecommendationList from './components/AdvisorRecommendationList';

export default function AdvisorRecommendationLists({
  recommendationLists,
  onDelete = () => {},
  onUpdate = () => {},
}) {
  return (
    <UnstyledList>
      {recommendationLists.map((recommendationList) => {
        return (
          <AdvisorRecommendationList
            key={recommendationList.id}
            onDelete={onDelete}
            onUpdate={onUpdate}
            recommendationList={recommendationList}
          />
        );
      })}
    </UnstyledList>
  );
}

AdvisorRecommendationLists.propTypes = {
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  recommendationLists: PropTypes.arrayOf(
    PropTypes.shape({
      advisor: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      requesterEmail: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
