import { css } from '@emotion/css';
import React from 'react';

import { ListItemWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

const contentWrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const listStyle = css`
  list-style: none;
  padding: 0;
`;

const trustpilotStyle = css`
  max-width: 300px;
  margin-left: 25px;
`;

const ShortlistContent = () => {
  return (
    <aside className={contentWrapperStyle}>
      <VerticalSpacing size="sm" />

      <ul className={listStyle}>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Easily save and compare multiple options in one place"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Share your shortlisted offices with other members of your team"
        />
      </ul>
      <VerticalSpacing size="sm" />
      <img
        alt="Trustpilot rating of 4.8 stars"
        className={trustpilotStyle}
        src="https://hubble.imgix.net/site/homepage/trustpilot-black-letters.svg?auto=compress"
      />
    </aside>
  );
};

export default ShortlistContent;
