import cn from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { OutboundLink } from 'site-react/components/navigation';
import styles from 'site-react/components/styles/button.module.css';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

/**
 * A link component with the same styling properties as our Button
 */
const LinkAsButton = React.forwardRef(
  (
    {
      analyticsMetadata,
      disabled,
      isBlock,
      isCompact,
      children = 'Submit',
      href,
      linkType = 'a',
      name,
      styleAtSmall,
      styleType = 'primary',
      ...otherLinkProps
    },
    ref,
  ) => {
    if (linkType === 'OutboundLink' && typeof href !== 'string') {
      throw new Error(
        "Implementing href as a url object only works for internal links when `linkType` is 'a'",
      );
    }

    if (otherLinkProps.hasOwnProperty('className')) {
      throw new Error(
        'You should not override or extend the built in styling of this component. If you need something more bespoke you should implement it as a bespoke component.',
      );
    }

    const onClickWithAnalytics = () => {
      analytics.track(
        'Button clicked',
        {
          href,
          label: name,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
    };

    const linkClassName = cn(styles['Button'], {
      /* eslint-disable sort-keys */
      // other modifiers
      [styles['Button--block']]: isBlock,
      [styles['Button--compact']]: isCompact,

      // `styleType`s
      [styles['Button--primary']]: styleType === 'primary',
      [styles['Button--secondary']]: styleType === 'secondary',
      [styles['Button--secondaryWhite']]: styleType === 'secondaryWhite',
      [styles['Button--white']]: styleType === 'white',

      // `styleAtSmall`s
      [styles['Button--smallFullWidth']]: styleAtSmall === 'fullWidth',
      [styles['Button--smallIconOnly']]: styleAtSmall === 'iconOnly',
      /* eslint-enable sort-keys */

      // Modifiers
      [styles['isDisabled']]: disabled,
    });

    if (linkType === 'OutboundLink') {
      return (
        <OutboundLink
          aria-hidden={disabled}
          className={linkClassName}
          href={href}
          onClick={onClickWithAnalytics}
          ref={ref}
          {...otherLinkProps}
        >
          {children}
        </OutboundLink>
      );
    } else {
      return (
        <NextLink
          aria-hidden={disabled}
          className={linkClassName}
          href={href}
          onClick={onClickWithAnalytics}
          ref={ref}
          {...otherLinkProps}
        >
          {children}
        </NextLink>
      );
    }
  },
);

LinkAsButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Content to be displayed within the button.
   */
  children: PropTypes.node,

  /**
   * Where this link leads. Or, if you're using an app link, the route to link
   * to (ie. the path within Next's `pages` folder for the page you're linking
   * to)
   */
  href: PropTypes.oneOfType([
    PropTypes.string,
    // https://nextjs.org/docs/api-reference/next/link#with-url-object
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,

  /**
   * Should this button always fill its container, regardless of styleAtSmall?
   */
  isBlock: PropTypes.bool,

  /**
   * Show a slightly-less-padded button. Preferred in places where we have less
   * real estate to play with.
   */
  isCompact: PropTypes.bool,

  /**
   * The type of the link.:
   * - a for internal links in this project. Gets benefits of performance enhancements
   *   from next/link.
   * - OutboundLink for a link that leads off-site. Requires different logic for
   *   analytics tracking.
   */
  linkType: PropTypes.oneOf(['OutboundLink', 'a']),

  /**
   * Label to be sent with analytics event
   */
  name: PropTypes.string.isRequired,

  /**
   * With the exception of `href`, any attribute native to the <a> tag
   * can be passed as a prop and directly spread as an attribute on the component.
   *
   * For instance, if you need to pass a datatest-id, do so like this:
   * ```
   * <LinkAsButton href="/" data-testid="my-link">Home</LinkAsButton>
   * ```
   */
  otherLinkProps: PropTypes.any,

  /**
   * The style of this button, specifically for small viewports. Defaults to
   * fullWidth for historical reasons.
   */
  styleAtSmall: PropTypes.oneOf(['auto', 'fullWidth', 'iconOnly']),

  /**
   * The pre-defined style of this button.
   */
  styleType: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryWhite',
    'white',
  ]),
};

export default LinkAsButton;
