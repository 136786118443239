import useUser from '../useUser';

const usePermissions = () => {
  const { user } = useUser();

  return {
    canRequestEventSpace: Boolean(
      user?.permissions?.bookEventSpace === 'allow',
    ),
    canRequestMeetingRoom: Boolean(
      user?.permissions?.bookMeetingRooms === 'allow',
    ),
    canRequestPrivateOffice: Boolean(
      user?.permissions?.bookPrivateOffices === 'allow',
    ),
  };
};

export default usePermissions;
